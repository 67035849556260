import AccountDetail from 'containers/Group/components/AccountDetail';
import RecodingScreen from 'containers/RecodingScreen';
import React, { lazy } from 'react';
import { Navigate } from 'react-router';

const HomeLayout = lazy(() => import('./components/Layout/HomeLayout'));
const MainLayout = lazy(() => import('./components/Layout/MainLayout'));
const HomeVideoContainer = lazy(() => import('containers/HomeVideo'));
const ResetPassword = lazy(() => import('containers/Auth/Login/components/ResetPassword/ResetPassword'));
const EditVideoContainer = lazy(() => import('containers/EditVideo'));
const Group = lazy(() => import('containers/Group'));
const GroupDetail = lazy(() => import('containers/Group/components/GroupDetail'));
const AllTemplate = lazy(() => import('containers/HomeVideo/components/AllTemplate'));
const Profile = lazy(() => import('containers/Profile'));
const Order = lazy(() => import('containers/Order'));
const ShareContainer = lazy(() => import('containers/Share'));
const PreviewFullScreen = lazy(() => import('containers/PreviewFullScreen'));
const BuildCompletePage = lazy(() => import('containers/BuildComplete'));
const RegisterPage = lazy(() => import('pages/Register/Register'));
const SocialAuthPage = lazy(() => import('pages/SocialAuth/SocialAuth'));
const Accountant = lazy(() => import('./containers/Accountant'));
const ForgotPassword = lazy(() => import('./containers/Auth/Login/components/ForgotPassword/ForgotPassword'));
const History = lazy(() => import('./containers/History'));
const InvitePage = lazy(() => import('./pages/Invite'));
const LoginPage = lazy(() => import('./pages/Login/Login'));
const NotFoundPage = lazy(() => import('./pages/NotFound/NotFound'));

const routes = [
  {
    path: '/auth/:media/*',
    element: <SocialAuthPage />
  },
  {
    path: '/share/:id',
    element: <ShareContainer />
  },
  {
    path: '/share/:share_token/preview',
    element: <ShareContainer />
  },
  {
    path: '/share/:share_token/embed',
    element: <PreviewFullScreen />
  },
  {
    path: '/share/:share_token/recoding',
    element: <RecodingScreen />
  },
  {
    path: '/',
    element: <HomeLayout />,
    children: [
      { path: '', element: <HomeVideoContainer /> },
      { path: 'all-template', element: <AllTemplate /> },
      { path: 'profile', element: <Profile /> },
      { path: 'account', element: <AccountDetail /> },
      { path: 'group', element: <Group /> },
      { path: 'group/:id', element: <GroupDetail /> },
      { path: 'history', element: <History /> },
      { path: 'order/:id', element: <Order /> },
      { path: 'accountant', element: <Accountant /> },
      { path: 'editor/:id', element: <EditVideoContainer /> },
      { path: 'build-complete', element: <BuildCompletePage /> },
      { path: 'not-found', element: <NotFoundPage /> },
      { path: '*', element: <Navigate to="/not-found" replace /> }
    ]
  },
  {
    path: '',
    element: <MainLayout />,
    children: [
      {
        path: '/login',
        element: <LoginPage />
      },
      {
        path: '/forgot-password',
        element: <ForgotPassword />
      },
      {
        path: '/reset-password',
        element: <ResetPassword />
      },
      {
        path: '/invite/:id',
        element: <InvitePage />
      },
      { path: '/', element: <Navigate to="/login" replace /> },
      { path: '/register', element: <RegisterPage /> },
      { path: '/not-found', element: <NotFoundPage /> },
      { path: '*', element: <Navigate to="/not-found" replace /> }
    ]
  }
];

export default routes;
