export const updateData = (json, textLayer, { text, font, fontSize, color }) => {
  if (text) {
    json.layers[textLayer].t.d.k[0].s.t = text;
  }
  if (font) {
    // eslint-disable-next-line prefer-destructuring
    json.fonts.list[0].fFamily = font.split(',')[1];
    // eslint-disable-next-line prefer-destructuring
    json.fonts.list[0].fName = font.split(',')[0];
  }
  if (fontSize) {
    json.layers[textLayer].t.d.k[0].s.s = fontSize;
  }
  if (color) {
    json.layers[textLayer].t.d.k[0].s.fc = [color.r / 255, color.g / 255, color.b / 255];
  }
};
