export const defaultJson = {
  v: '5.9.2',
  fr: 25,
  ip: 0,
  op: 40,
  w: 3456,
  h: 1080,
  nm: '7-Byword',
  ddd: 0,
  assets: [],
  fonts: {
    list: [
      {
        origin: 0,
        fPath: '',
        fClass: '',
        fFamily: 'Montserrat',
        fWeight: '',
        fStyle: 'ExtraBold',
        fName: 'Montserrat-ExtraBold',
        ascent: 69.5092065427452
      }
    ]
  },
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 5,
      nm: 'By ',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [0] },
            { t: 10, s: [100] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [1176, 708, 0],
              to: [0.87, 2.611, 0],
              ti: [-2.142, -6.427, 0]
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 7,
              s: [1181.398, 682.194, 0],
              to: [1.55, 4.649, 0],
              ti: [-0.63, -1.889, 0]
            },
            { t: 10, s: [1185, 735, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      t: {
        d: {
          k: [
            {
              s: {
                s: 200,
                f: 'Montserrat-ExtraBold',
                t: 'BY ',
                ca: 1,
                j: 0,
                tr: 0,
                lh: 240.000015258789,
                ls: 0,
                fc: [0, 0, 0]
              },
              t: 0
            }
          ]
        },
        p: {},
        m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
        a: []
      },
      ip: 0,
      op: 50,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 5,
      nm: 'HEllo',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [0] },
            { t: 10, s: [100] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [1485, 687, 0],
              to: [3.191, 4.352, 0],
              ti: [-7.855, -10.711, 0]
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 7,
              s: [1522.793, 683.99, 0],
              to: [5.682, 7.749, 0],
              ti: [-2.309, -3.148, 0]
            },
            { t: 10, s: [1518, 732, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      t: {
        d: {
          k: [
            {
              s: {
                s: 200,
                f: 'Montserrat-ExtraBold',
                t: 'HELLO',
                ca: 1,
                j: 0,
                tr: 0,
                lh: 240.000015258789,
                ls: 0,
                fc: [0, 0, 0]
              },
              t: 0
            }
          ]
        },
        p: {},
        m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
        a: []
      },
      ip: 0,
      op: 50,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};

export const textLayer = 1;
