import { createTypes } from 'reduxsauce';
import { ENDPOINTS } from './config';
import axiosClient from '../../utils/axiosClient';
import axiosClient2 from '../../utils/axiosClient2';
import { AppTypes } from '../App/actions';

export const GroupTypes = createTypes(`
  GROUPS_FETCH_SUCCESS
  GROUPS_FETCH_FAILURE
  GROUPS_WP_FETCH_SUCCESS
  GROUPS_WP_FETCH_FAILURE
  GROUP_DETAIL_FETCH_SUCCESS
  GROUP_DETAIL_FETCH_FAILURE
  GROUP_MEMBERS_FETCH_SUCCESS
  GROUP_MEMBERS_FETCH_FAILURE
  ADD_GROUP_MEMBER_SUCCESS
  ADD_GROUP_MEMBER_FAILURE
  ADD_GROUP_SUCCESS
  ADD_GROUP_FAILURE
  RESET_ADD_GROUP_FAILURE
  LEAVE_GROUP_SUCCESS
  LEAVE_GROUP_FAILURE
  REMOVE_MEMBER_SUCCESS
  REMOVE_MEMBER_FAILURE
  ACTIVE_GROUP_SUCCESS
  ACTIVE_GROUP_FAILURE
  CHANGE_PERMISSION_SUCCESS
  CHANGE_PERMISSION_FAILURE
  ENTRUST_VIDEO_SUCCESS
  ENTRUST_VIDEO_FAILURE
  GROUPS_ALL_FETCH_SUCCESS
  GROUPS_ALL_FETCH_FAILURE
  INVITE_MEMBER_REQUEST
  INVITE_MEMBER_SUCCESS
  INVITE_MEMBER_FAILURE
  INVITE_DETAIL_SUCCESS
  INVITE_DETAIL_FAILURE
  GROUP_DETAIL_MEMBER_FETCH_SUCCESS
  GROUP_DETAIL_MEMBER_FETCH_FAILURE
  GROUP_MEMBERS_INVITE_FETCH_SUCCESS
  GROUP_MEMBERS_INVITE_FETCH_FAILURE
`);

export const fetchSuccess = (response) => ({ type: GroupTypes.GROUPS_FETCH_SUCCESS, data: response });
export const fetchFailure = (error) => ({ type: GroupTypes.GROUPS_FETCH_FAILURE, error });
export const fetchGroups = (params) => async (dispatch) => {
  try {
    const response = await axiosClient2.get(ENDPOINTS.groups(), { params });
    dispatch(fetchSuccess(response.data));
  } catch (error) {
    dispatch(fetchFailure(error));
  }
};

export const fetchWithoutPaginateSuccess = (response) => ({ type: GroupTypes.GROUPS_WP_FETCH_SUCCESS, data: response });
export const fetchWithoutPaginateFailure = (error) => ({ type: GroupTypes.GROUPS_WP_FETCH_FAILURE, error });
export const fetchGroupsWithoutPaginate = (params) => async (dispatch) => {
  try {
    const response = await axiosClient2.get(ENDPOINTS.groups(), { params });
    dispatch(fetchWithoutPaginateSuccess(response.data));
  } catch (error) {
    dispatch(fetchWithoutPaginateFailure(error));
  }
};

export const fetchDetailSuccess = (response) => ({ type: GroupTypes.GROUP_DETAIL_FETCH_SUCCESS, data: response });
export const fetchDetailFailure = (error) => ({ type: GroupTypes.GROUP_DETAIL_FETCH_FAILURE, error });
export const fetchDetailGroup = (id) => async (dispatch) => {
  try {
    const response = await axiosClient2.get(ENDPOINTS.detail(id));
    dispatch(fetchDetailSuccess(response.data?.result));
  } catch (error) {
    dispatch(fetchDetailFailure(error));
  }
};

export const fetchDetailMemberSuccess = (response) => ({
  type: GroupTypes.GROUP_DETAIL_MEMBER_FETCH_SUCCESS,
  data: response
});
export const fetchDetailMemberFailure = (error) => ({ type: GroupTypes.GROUP_DETAIL_MEMBER_FETCH_FAILURE, error });
export const fetchDetailMemberGroup = (id, params) => async (dispatch) => {
  try {
    const response = await axiosClient2.get(ENDPOINTS.detailMember(id), { params });
    dispatch(fetchDetailMemberSuccess(response.data?.result));
  } catch (error) {
    dispatch(fetchDetailMemberFailure(error));
  }
};

export const fetchGroupMembersSuccess = (response) => ({
  type: GroupTypes.GROUP_MEMBERS_FETCH_SUCCESS,
  data: response
});
export const fetchGroupMembersFailure = (error) => ({
  type: GroupTypes.GROUP_MEMBERS_FETCH_FAILURE,
  error
});
export const fetchGroupMembers = (groupId, queryParams) => async (dispatch) => {
  try {
    const response = await axiosClient2.get(ENDPOINTS.groupMembers(groupId), {
      params: queryParams
    });
    dispatch(fetchGroupMembersSuccess(response.data?.result));
  } catch (error) {
    dispatch(fetchGroupMembersFailure(error));
  }
};

export const fetchGroupMembersInviteSuccess = (response) => ({
  type: GroupTypes.GROUP_MEMBERS_INVITE_FETCH_SUCCESS,
  data: response
});
export const fetchGroupMembersInviteFailure = (error) => ({
  type: GroupTypes.GROUP_MEMBERS_INVITE_FETCH_FAILURE,
  error
});
export const fetchGroupMembersInvite = (groupId, queryParams) => async (dispatch) => {
  try {
    const response = await axiosClient2.get(ENDPOINTS.groupMembersInvite(groupId), {
      params: queryParams
    });
    dispatch(fetchGroupMembersInviteSuccess(response.data?.result));
  } catch (error) {
    dispatch(fetchGroupMembersInviteFailure(error));
  }
};

export const addGroupMemberSuccess = (response) => ({ type: GroupTypes.ADD_GROUP_MEMBER_SUCCESS, data: response });
export const addGroupMemberFailure = (error) => ({ type: GroupTypes.ADD_GROUP_MEMBER_FAILURE, error });
export const addGroupMember = (id, payload) => async (dispatch) => {
  try {
    const response = await axiosClient2.post(ENDPOINTS.addGroupMember(id), payload);
    dispatch(addGroupMemberSuccess(response.data?.result));
  } catch (error) {
    dispatch(addGroupMemberFailure(error));
  }
};

export const addGroupSuccess = (response) => ({ type: GroupTypes.ADD_GROUP_SUCCESS, data: response });
export const addGroupFailure = (error) => ({ type: GroupTypes.ADD_GROUP_FAILURE, error });
export const resetAddGroupFailure = () => ({ type: GroupTypes.RESET_ADD_GROUP_FAILURE });
export const addGroup = (payload) => async (dispatch) => {
  try {
    const response = await axiosClient2.post(ENDPOINTS.addGroup(), payload);
    if (response.data?.result) {
      dispatch(addGroupSuccess(response.data?.result));
    } else {
      dispatch(addGroupFailure({ error: response.data.result }));
    }
    return response;
  } catch (error) {
    dispatch(addGroupFailure(error));
  }
};

export const editGroup = (groupId, payload) => async (dispatch) => {
  try {
    const response = await axiosClient2.put(ENDPOINTS.editGroup(groupId), {}, { params: payload });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const removeMemberSuccess = (response) => ({ type: GroupTypes.REMOVE_MEMBER_SUCCESS, data: response });
export const removeMemberFailure = (error) => ({ type: GroupTypes.REMOVE_MEMBER_FAILURE, error });
export const removeMember = (id, params) => async (dispatch) => {
  try {
    const response = await axiosClient2.delete(ENDPOINTS.removeMember(id), { params });
    dispatch(removeMemberSuccess(response.data?.result));
  } catch (error) {
    dispatch(removeMemberFailure(error));
  }
};

export const leaveGroupSuccess = (response) => ({ type: GroupTypes.LEAVE_GROUP_SUCCESS, data: response });
export const leaveGroupFailure = (error) => ({ type: GroupTypes.LEAVE_GROUP_FAILURE, error });
export const leaveGroup = (id, params) => async (dispatch) => {
  try {
    const response = await axiosClient2.delete(ENDPOINTS.leaveGroup(id), { params });
    dispatch(leaveGroupSuccess(response.data?.result));
  } catch (error) {
    dispatch(leaveGroupFailure(error));
  }
};

export const activeGroupSuccess = (response) => ({ type: GroupTypes.ACTIVE_GROUP_SUCCESS, data: response });
export const activeGroupFailure = (error) => ({ type: GroupTypes.ACTIVE_GROUP_FAILURE, error });
export const activeGroup = (payload) => async (dispatch) => {
  try {
    const response = await axiosClient.post(ENDPOINTS.activeGroup(), payload);
    dispatch(activeGroupSuccess(response.user));
    dispatch({ type: AppTypes.LOGIN, user: response.user });
  } catch (error) {
    dispatch(activeGroupFailure(error));
  }
};

export const changePermissionSuccess = (response) => ({ type: GroupTypes.CHANGE_PERMISSION_SUCCESS, data: response });
export const changePermissionFailure = (error) => ({ type: GroupTypes.CHANGE_PERMISSION_FAILURE, error });
export const changePermission = (id, payload) => async (dispatch) => {
  try {
    const response = await axiosClient2.post(ENDPOINTS.changePermission(id), payload);
    dispatch(changePermissionSuccess(response.data?.result));
  } catch (error) {
    dispatch(changePermissionFailure(error));
  }
};

export const entrustVideoSuccess = (response) => ({ type: GroupTypes.ENTRUST_VIDEO_SUCCESS, data: response });
export const entrustVideoFailure = (error) => ({ type: GroupTypes.ENTRUST_VIDEO_FAILURE, error });
export const entrustVideo = (id, payload) => async (dispatch) => {
  try {
    const response = await axiosClient2.post(ENDPOINTS.entrustVideo(id), payload);
    dispatch(entrustVideoSuccess(response.data?.result));
  } catch (error) {
    dispatch(entrustVideoFailure(error));
  }
};

export const fetchGroupsAllSuccess = (response) => ({ type: GroupTypes.GROUPS_ALL_FETCH_SUCCESS, data: response });
export const fetchGroupsAllFailure = (error) => ({ type: GroupTypes.GROUPS_ALL_FETCH_FAILURE, error });
export const fetchGroupsAll = (params) => async (dispatch) => {
  try {
    const response = await axiosClient2.get(ENDPOINTS.groupsAll(), { params });
    dispatch(fetchGroupsAllSuccess(response.data));
  } catch (error) {
    dispatch(fetchGroupsAllFailure(error));
  }
};

export const inviteMemberSuccess = (response) => ({ type: GroupTypes.INVITE_MEMBER_SUCCESS, data: response });
export const inviteMemberFailure = (error) => ({ type: GroupTypes.INVITE_MEMBER_FAILURE, error });
export const inviteMember = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: GroupTypes.INVITE_MEMBER_REQUEST });
    const response = await axiosClient2.post(ENDPOINTS.inviteMember(id), payload);
    dispatch(inviteMemberSuccess(response.data?.result));
    return response;
  } catch (error) {
    dispatch(inviteMemberFailure(error));
  }
};

export const inviteDetailSuccess = (response) => ({ type: GroupTypes.INVITE_DETAIL_SUCCESS, data: response });
export const inviteDetailFailure = (error) => ({ type: GroupTypes.INVITE_DETAIL_FAILURE, error });
export const inviteDetail = (id) => async (dispatch) => {
  try {
    const response = await axiosClient2.get(ENDPOINTS.inviteDetail(id));
    dispatch(inviteDetailSuccess(response.data?.result));
  } catch (error) {
    dispatch(inviteDetailFailure(error));
  }
};
