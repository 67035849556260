import { createTypes } from 'reduxsauce';
import axiosClient from 'utils/axiosClient2';
import { ENDPOINTS } from './api';

export const coreTypes = createTypes(`
  ORGANIZATION_SUCCESS
  ORGANIZATION_FAILURE
`);

// Login action
export const organizationSuccess = (organization) => ({ type: coreTypes.ORGANIZATION_SUCCESS, organization });

export const organizationFailure = () => ({
  type: coreTypes.ORGANIZATION_FAILURE
});

export const organizationRequest = (organizationId) => async (dispatch) => {
  const res = await axiosClient.get(ENDPOINTS.organization(organizationId));
  if (res.data?.success) {
    dispatch(organizationSuccess(res?.data?.result));
  } else {
    dispatch(organizationFailure());
  }
};

export const trackAction = (params) => async () => {
  axiosClient.post(ENDPOINTS.track, params);
};
