import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { Input, Select } from 'antd';
import { toAbsoluteUrl } from 'utils/formatUrl';

const { Option } = Select;

const defaultFormValue = {
  emails: '',
  permission: 'editor'
};

const GroupMemberCreated = forwardRef(({ onSaveGroupMember }, ref) => {
  const formRef = useRef();

  useImperativeHandle(ref, () => ({
    submit: () => {
      formRef.current.handleSubmit();
    }
  }));

  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      initialValues={defaultFormValue}
      onSubmit={(values) => {
        onSaveGroupMember(values);
      }}
      innerRef={formRef}
    >
      {({ values, handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <div className="d-flex mb-3">
            <div className="form-left" />
            <div className="form-right text-modal">Nhập email tài khoản, mỗi tài khoản cách nhau bởi dấu ”,”</div>
          </div>
          <div className="d-flex mb-3">
            <div className="form-left">Tên thành viên</div>
            <div className="form-right">
              <Input
                type="text"
                name="emails"
                value={values.name}
                onChange={(e) => {
                  setFieldValue('emails', e.target.value);
                }}
              />
            </div>
          </div>
          <div className="d-flex">
            <div className="form-left">Vai trò</div>
            <div className="form-right">
              <Select
                value={values.permission}
                onChange={(value) => {
                  setFieldValue('permission', value);
                }}
                suffixIcon={<img src={toAbsoluteUrl('/icons/select.svg')} alt="select" />}
                style={{ width: '100%' }}
              >
                <Option value="admin">Quản lý</Option>
                <Option value="editor">Biên tập viên</Option>
              </Select>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
});

GroupMemberCreated.propTypes = {
  onSaveGroupMember: PropTypes.func
};

export default GroupMemberCreated;
