import React from 'react';
import { Pagination } from '@mui/material';
import PropTypes from 'prop-types';

PaginationUI.propTypes = {
  totalPage: PropTypes.number,
  currentPage: PropTypes.number,
  onChange: PropTypes.func
};

export default function PaginationUI({ totalPage, currentPage, onChange }) {
  return (
    <div className="d-flex justify-content-center align-items-center mt-30 mb-30">
      <Pagination
        count={totalPage}
        color="paginate"
        size="medium"
        shape="rounded"
        page={currentPage}
        onChange={(e, page) => onChange(page)}
      />
    </div>
  );
}
