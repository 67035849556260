import React from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import GroupDetailMemberFilter from './GroupDetailMemberFilter';
import UseGroupPermission from './UseGroupPermission';
import { formatTable2 } from '../../../utils/common';

GroupDetailMember.propTypes = {
  members: PropTypes.array
};

export default function GroupDetailMember({ members }) {
  const [onShowUserPermission] = UseGroupPermission();

  const columns = [
    { key: '', title: 'STT', dataIndex: '', render: (text, record, index) => <span>{index + 1}</span> },
    {
      key: 'name',
      title: 'Tên thành viên',
      dataIndex: 'name',
      render: (name) => <span className="text-bold">{name}</span>
    },
    {
      key: 'permission',
      title: 'Vai trò',
      dataIndex: 'permission',
      render: (permission) => onShowUserPermission(permission)
    },
    {
      key: 'statistic',
      title: 'Số dự án',
      dataIndex: 'statistic',
      render: (statistic) => <span>{statistic.videos_count || 0}</span>
    }
  ];

  return (
    <div className="d-flex flex-direct-column">
      <div className="d-flex filter-container justify-content-end align-items-center">
        <GroupDetailMemberFilter />
      </div>
      <Table
        rowKey="_id"
        rowClassName={(record, index) => formatTable2(index)}
        columns={columns}
        dataSource={members}
        pagination={false}
      />
    </div>
  );
}
