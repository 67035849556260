import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  navBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    backgroundColor: 'white'
  },
  emptyNotif: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 'inherit'
  },
  point: {
    borderRadius: '50%',
    backgroundColor: '#2E89FF',
    height: '8px',
    aspectRatio: 1
  }
}));
