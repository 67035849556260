import { Box } from '@mui/material';
import ScenesCompilation from 'containers/EditVideo/components/ScenesCompilation';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import axiosClient from 'utils/axiosClient';
import { useStyles } from './styles';
import './styles.css';
import { useTranslation } from 'react-i18next';

function RecodingScreen() {
  const classes = useStyles();
  const { share_token } = useParams();
  const { t } = useTranslation();

  const [video, setVideo] = useState();

  useEffect(() => {
    const fetch = async () => {
      const rs = await axiosClient.get(`/videos/${share_token}/by-token`);
      setVideo(rs.video);
    };
    if (share_token) {
      fetch();
    }
  }, [share_token]);

  return (
    <Box className={classes.buildCompleteWrapper}>
      {video ? (
        <>
          <Box className="videoWrapper">
            {share_token ? (
              <Box className={classes.previewVideo}>
                <iframe
                  allow="camera; microphone; fullscreen; display-capture"
                  title="video-frame"
                  src={`${process.env.REACT_APP_PAYMENT_URL}/presentation/show/${video._id}?recoding=1`}
                  style={{ width: '100%', height: '100vh', display: 'block' }}
                />
              </Box>
            ) : (
              <ScenesCompilation video={video} isShowing />
            )}
          </Box>
        </>
      ) : (
        <div className="loading-wrapper">
          <div className="lds-ellipsis">
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      )}
    </Box>
  );
}

export default RecodingScreen;
