import React from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import GroupDetailMemberInviteFilter from './GroupDetailMemberInviteFilter';
import UseGroupPermission from './UseGroupPermission';
import { formatTable2 } from '../../../utils/common';

GroupDetailMemberInvite.propTypes = {
  members: PropTypes.array
};

export default function GroupDetailMemberInvite({ members }) {
  const [onShowUserPermission] = UseGroupPermission();
  const { inviteMemberLoading } = useSelector((state) => state.group);

  const columns = [
    { key: '', title: 'STT', dataIndex: '', render: (text, record, index) => <span>{index + 1}</span> },
    {
      key: 'email',
      title: 'Email',
      dataIndex: 'email',
      render: (email) => <span className="text-bold">{email}</span>
    },
    {
      key: 'permission',
      title: 'Vai trò',
      dataIndex: 'permission',
      render: (permission) => onShowUserPermission(permission)
    }
  ];

  return (
    <div className="d-flex flex-direct-column">
      <div className="d-flex filter-container justify-content-end align-items-center">
        <GroupDetailMemberInviteFilter />
      </div>
      <Table
        rowKey="_id"
        rowClassName={(record, index) => formatTable2(index)}
        columns={columns}
        dataSource={members}
        pagination={false}
        loading={inviteMemberLoading}
      />
    </div>
  );
}
