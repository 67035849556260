import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import Dialog from '@mui/material/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import GroupMemberCreated from '../GroupMemberCreated';
import { fetchDetailGroup, inviteMember } from '../../actions';
import { useNotify } from '../../../../hooks/useNotify';
import { useModal } from '../../../../hooks/useModal';
import { GROUP_MODAL } from '../../constants';
import { toAbsoluteUrl } from 'utils/formatUrl';

const GroupAddMemberModal = forwardRef(({ groupId, user }, ref) => {
  const { openModal, closeModal, isShowing } = useModal(GROUP_MODAL.ADD_MEMBER);
  const formRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { successNotify, errorNotify, warningNotify } = useNotify();
  const { group } = useSelector((state) => state.group);

  useImperativeHandle(ref, () => ({
    onOpen: openModal
  }));

  const onClickCreatedMemberButton = async () => {
    await formRef.current.submit();
    closeModal();
  };

  const onSaveGroupMember = async (values) => {
    try {
      const payload = {
        ...values,
        user_id: user._id,
        baseInviteUrl: `${window.location.origin}${toAbsoluteUrl('/invite')}`
      };
      const res = await dispatch(inviteMember(groupId, payload));
      closeModal();
      if (res.data.code === 'package_over') {
        warningNotify(`Gói của bạn giới hạn là ${group.balance?.plan?.package?.rules?.actived?.user} người dùng`);
      } else {
        dispatch(fetchDetailGroup(groupId));
        successNotify(t('group.member.inviteSuccess'));
      }
    } catch (e) {
      errorNotify(t('group.member.inviteFailure'));
    }
  };

  return (
    <Dialog fullWidth open={isShowing} onClose={() => closeModal()} style={{ zIndex: 50 }}>
      <div className="modal-add-member d-flex flex-direct-column">
        <div className="text-2 d-flex justify-content-center mb-20">Thêm thành viên</div>
        <div className="mb-30">
          <GroupMemberCreated ref={formRef} onSaveGroupMember={onSaveGroupMember} />
        </div>
        <div className="d-flex justify-content-center">
          <button type="button" className="button" onClick={onClickCreatedMemberButton}>
            Thêm ngay
          </button>
        </div>
      </div>
    </Dialog>
  );
});

GroupAddMemberModal.propTypes = {
  groupId: PropTypes.string,
  user: PropTypes.object
};

export default GroupAddMemberModal;
