import {
  changeVolumeVideoAndSceneRequest,
  hotUpdateSceneRequest,
  uploadSpeechRequest
} from 'containers/EditVideo/actions';
import { LOGO_DISTANCE, LOGO_POSITION } from 'containers/EditVideo/constants';
import {
  getChangeFormatLoading,
  getFormat,
  getSelectingScene,
  getSelectingStatus,
  getBackgroundSound,
  getSceneVolume,
  getBackgroundVolume,
  getVideo,
  getListScene
} from 'containers/EditVideo/selectors';
import { useAspectRatioKeeper } from 'hooks/useAspectRatioKeeper';
import { useNotify } from 'hooks/useNotify';
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { cloneDeep, debounce } from 'lodash';
import { MusicIcon, VolumnIcon } from '../../icons';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

export const getSXProps = (property, intrinsicRatio, dimensionsProps) => {
  if (!property) return {};
  const props = {};

  switch (property.position) {
    case LOGO_POSITION.TOP_LEFT: {
      props.top = LOGO_DISTANCE;
      props.left = LOGO_DISTANCE;
      break;
    }
    case LOGO_POSITION.TOP_RIGHT: {
      props.top = LOGO_DISTANCE;
      props.right = LOGO_DISTANCE;
      break;
    }
    case LOGO_POSITION.BOTTOM_LEFT: {
      props.bottom = LOGO_DISTANCE;
      props.left = LOGO_DISTANCE;
      break;
    }
    case LOGO_POSITION.BOTTOM_RIGHT: {
      props.bottom = LOGO_DISTANCE;
      props.right = LOGO_DISTANCE;
      break;
    }
    default:
  }
  props.width = property.size * dimensionsProps.width;
  props.height = props.width / intrinsicRatio;
  props.opacity = property.opacity ?? 1;
  return props;
};

export const useSceneView = () => {
  const { id: videoID } = useParams();
  const dispatch = useDispatch();

  const changeFormatLoading = useSelector(getChangeFormatLoading());
  const video = useSelector(getVideo());
  const format = useSelector(getFormat());
  const selectingScene = useSelector(getSelectingScene());
  const listScene = useSelector(getListScene());
  const selectingStatus = useSelector(getSelectingStatus());
  const sceneVolume = useSelector(getSceneVolume());
  const backgroundVolume = useSelector(getBackgroundVolume());
  const { t } = useTranslation();
  const animRef = useRef();
  const timelineTextAndVoiceRef = useRef();
  const refMediaUrl = useRef(null);
  const refElementEffect = useRef();

  const [image, setImage] = useState({ width: 0, height: 0 });
  const [idxEffect, setIdxEffect] = useState(-1);
  const [loadingScene, setLoadingScene] = useState(false);
  const [idxScene, setIdxScene] = useState(0);
  const [listEffect, setListEffect] = useState([]);
  const [totalTimeDuration, setTotalTimeDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [nextTime, setNextTime] = useState(0);
  const countClick = useRef(0);
  const timeoutLoadingSlice = useRef();
  const hasNextEffect = useRef(true);
  const timeNextEffect = useRef();
  const hasDelayEffect = useRef(true);
  const delayEffect = useRef();
  const timePrevEffect = useRef();
  const hasDelayPrevEffect = useRef(true);
  const endTransitionNextEffect = useRef(false);
  const controlToolbarMedia = useRef();
  const refElementEffectStart = useRef();

  const { mediaWrapperRef, dimensionsProps, breakpoint } = useAspectRatioKeeper(format, {
    widthBreakpoints: {
      xs: 200,
      sm: 450
    }
  });

  const wrapperRef = useRef(null);
  const [editVideoDialogWidth, setEditVideoDialogWidth] = useState(0);
  const [editMode, setEditMode] = useState('');
  const [currentTool, setCurrentTool] = useState({ name: '', ref: null });
  const { errorNotify, warningNotify } = useNotify();
  const handleCloseTool = useCallback(() => {
    setCurrentTool({ name: '', ref: null });
  }, []);

  const handleUpdateScene = useCallback(
    debounce((data) => {
      let totalTime = 0;
      if (selectingScene?.speeches) {
        totalTime = selectingScene?.speeches?.reduce((initTime, scene) => {
          return initTime + Math.ceil(+scene.duration);
        }, Math.ceil(+selectingScene.speech.duration || 0));
      } else {
        totalTime = +selectingScene.speech.duration || 0;
      }
      if (+data.duration >= totalTime + 1) {
        dispatch(hotUpdateSceneRequest({ videoID, sceneID: selectingScene._id, data }));
      } else {
        if (+data.duration !== +selectingScene.duration) {
          warningNotify(t('editVideo.commons.warningTime', { time: totalTime + 1 }));
        }
      }
    }, 200),
    [videoID, selectingScene]
  );
  const handleDeleteMedia = () =>
    dispatch(
      hotUpdateSceneRequest({ videoID, sceneID: selectingScene._id, data: { media: null }, isDeleteMedia: true })
    );

  const mediaToolbarItems = useMemo(() => {
    const items = {
      music_background: {
        icon: <MusicIcon />,
        textToolTip: 'Âm thành nền',
        handle: () => {
          setCurrentTool({ name: 'music_background' });
        }
      },
      volume: {
        icon: <VolumnIcon />,
        textToolTip: 'Âm thanh trình chiếu',
        handle: (e) => {
          setCurrentTool({ name: 'volume', ref: e.currentTarget });
        }
      },
      note: {
        icon: <HelpOutlineIcon />,
        textToolTip: 'Hướng dẫn',
        handle: (e) => {
          setCurrentTool({ name: 'note' });
        },
        style: { position: 'absolute', right: '9px' }
      }
    };
    return items;
  }, []);

  const onCloseEdit = useCallback(() => {
    setEditMode('');
  }, []);

  useLayoutEffect(() => {
    if (wrapperRef.current) {
      setEditVideoDialogWidth(wrapperRef.current.getBoundingClientRect().right);
    }
  }, [wrapperRef]);

  const handleChangeVideoAndSceneVolume = async (sceneVol, videoVol, voiceSpeech, speedSpeech) => {
    try {
      handleCloseTool();
      await dispatch(changeVolumeVideoAndSceneRequest(videoID, sceneVol, videoVol, voiceSpeech, speedSpeech));
    } catch (error) {
      errorNotify(t('editVideo.toolbarVolumePopover.applyFailed'));
    }
  };

  const audioRef = useRef();
  const handlePlaySound = (url) => {
    audioRef.current = new Audio(url);
    audioRef.current.volume = sceneVolume / 100;
    audioRef.current.play();
  };

  const nextEffectPlayAudio = () => {
    countClick.current += 1;
    refMediaUrl.current?.contentWindow.dispatchEffects(1);
    setListEffect((prev) => {
      return [...prev, selectingScene.effects[countClick.current - 1]];
    });
    setIdxEffect(countClick.current - 1);
  };

  const resetPlayEffect = () => {
    timeoutPlayAudio.current.forEach((timeout) => {
      clearTimeout(timeout);
    });
    clearTimeout(timeoutPlayAudioStart.current);
    setIsPlaying(false);
    setTotalTimeDuration(0);
    timeoutPlayAudio.current = [];
    timeoutPlayAudioStart.current = null;
  };

  const playBackgroundSound = () => {
    backgroundSoundRef.current.currentTime = 0;
    backgroundSoundRef.current.volume = backgroundVolume / 100;
    backgroundSoundRef.current.play();
  };

  const pauseBackgroundSound = () => {
    backgroundSoundRef.current.pause();
  };

  const timeoutPlayAudio = useRef([]);
  const timeoutPlayAudioStart = useRef(null);

  const handleTogglePlayButton = () => {
    for (let i = 0; i < countClick.current; i++) {
      refMediaUrl.current?.contentWindow.aSlideShow.rewindEffect();
    }
    setIdxEffect(-1);
    setListEffect([]);
    refElementEffectStart.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    countClick.current = 0;
    if (timeoutPlayAudio.current.length > 0 || timeoutPlayAudioStart.current || isPlaying) {
      resetPlayEffect();
      pauseBackgroundSound();
      if (audioRef.current) {
        audioRef.current.pause();
      }
      return;
    }

    setIsPlaying(true);
    playBackgroundSound();
    let timeDuration = Math.ceil(+selectingScene.speech.duration || 0) * 1000;
    const timeoutAudioStart = setTimeout(() => {
      handlePlaySound(selectingScene.speech.full_url);
    }, (+selectingScene.speech?.offset || 0) * 1000);
    timeoutPlayAudio.current.push(timeoutAudioStart);

    if (selectingScene.speeches?.length > 0) {
      timeoutPlayAudioStart.current = setTimeout(() => {
        nextEffectPlayAudio();
        const timeoutAudio = setTimeout(() => {
          handlePlaySound(selectingScene.speeches[0].full_url);
        }, (+selectingScene.speeches[0].offset || 0) * 1000);
        timeoutPlayAudio.current.push(timeoutAudio);
      }, (Math.ceil(+selectingScene.speech?.duration || 0) + (+selectingScene.speech?.offset || 0)) * 1000);

      selectingScene.speeches?.forEach((speech, idx) => {
        const duration = Math.ceil(+speech.duration || 0) * 1000;
        timeDuration += duration + (+speech.offset || 0);
        const timeout = setTimeout(() => {
          if (countClick.current < selectingScene.clicks) {
            nextEffectPlayAudio();
            setTimeout(() => {
              handlePlaySound(selectingScene.speeches[idx + 1].full_url);
            }, (+selectingScene.speeches[idx + 1].offset || 0) * 1000);
          }
        }, timeDuration);
        timeoutPlayAudio.current.push(timeout);
      });
    }

    setTotalTimeDuration(timeDuration);
  };

  const scrollHorizontally = (e) => {
    const delta = Math.max(-1, Math.min(1, e.deltaY));
    if (timelineTextAndVoiceRef.current) {
      timelineTextAndVoiceRef.current.scrollTop += delta * 70;
    }
  };

  const onSetImage = () => {
    setImage({ width: refMediaUrl.current.clientWidth, height: refMediaUrl.current.clientHeight });
  };

  const onClickPrevEffect = () => {
    if (isPlaying) {
      handleTogglePlayButton();
    }
    if (timePrevEffect.current) {
      clearTimeout(timePrevEffect.current);
    }

    if (selectingScene.clicks > 0 && countClick.current > 0 && hasDelayPrevEffect.current) {
      refMediaUrl.current?.contentWindow?.aSlideShow?.rewindEffect();
      const cloneListEffect = cloneDeep(listEffect);
      cloneListEffect.pop();
      setListEffect(cloneListEffect);
      countClick.current -= 1;
      setIdxEffect(countClick.current - 1);
    }

    hasDelayPrevEffect.current = false;
    timePrevEffect.current = setTimeout(() => {
      hasDelayPrevEffect.current = true;
    }, 200);
  };

  const onClickNextEffect = () => {
    if (endTransitionNextEffect.current === false) {
      return;
    }
    if (isPlaying) {
      resetPlayEffect();
    }
    if (timeNextEffect.current) {
      clearTimeout(timeNextEffect.current);
    }
    if (delayEffect.current) {
      clearTimeout(delayEffect.current);
    }
    if (
      selectingScene.clicks > 0 &&
      countClick.current < selectingScene.clicks &&
      hasNextEffect.current === false &&
      hasDelayEffect.current === true
    ) {
      refMediaUrl.current?.contentWindow?.dispatchEffects(1);
      hasNextEffect.current = true;
    }
    if (
      selectingScene.clicks > 0 &&
      countClick.current < selectingScene.clicks &&
      hasNextEffect.current &&
      hasDelayEffect.current
    ) {
      refMediaUrl.current?.contentWindow?.dispatchEffects(1);
      countClick.current += 1;
      setListEffect((prev) => {
        return [...prev, selectingScene.effects[countClick.current - 1]];
      });
      setIdxEffect(countClick.current - 1);
    }

    if (selectingScene?.effects?.[countClick.current - 1]?.duration) {
      const durationEffect = selectingScene.effects[countClick.current - 1].duration.toString().replace('s', '');
      hasNextEffect.current = false;
      timeNextEffect.current = setTimeout(() => {
        hasNextEffect.current = true;
      }, +durationEffect * 1000);
    }

    hasDelayEffect.current = false;
    delayEffect.current = setTimeout(() => {
      hasDelayEffect.current = true;
    }, 200);
  };

  const activeSpeech = (idx) => {
    if (idx === idxEffect) {
      return true;
    }
    return false;
  };

  const activeSpeechStart = () => {
    if (idxEffect === -1) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    onSetImage();
  }, [refMediaUrl, loadingScene]);

  useEffect(() => {
    window.addEventListener('resize', debounce(onSetImage, 100));
    return () => {
      window.removeEventListener('resize', onSetImage);
    };
  }, []);

  useEffect(() => {
    if (refElementEffect.current) {
      refElementEffect.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [idxEffect, listEffect, refElementEffect]);

  const backgroundSound = useSelector(getBackgroundSound());
  const backgroundSoundRef = useRef(null);

  useEffect(() => {
    const idxScene = listScene?.findIndex((scene) => scene._id === selectingScene._id);
    setIdxScene(idxScene);
    setTotalTimeDuration(0);
    setListEffect([]);
    countClick.current = 0;
    if (isPlaying) {
      handleTogglePlayButton();
    }
    setIsPlaying(false);
    setIdxEffect(-1);
  }, [selectingScene._id]);

  useEffect(() => {
    endTransitionNextEffect.current = false;
    if (timeoutLoadingSlice.current) {
      clearTimeout(timeoutLoadingSlice.current);
    }
  }, [videoID, selectingScene._id]);

  useEffect(() => {
    setLoadingScene(true);
  }, [videoID]);

  useEffect(() => {
    if (
      Array.isArray(selectingScene.effects) &&
      selectingScene.effects.length > 0 &&
      (!selectingScene.speeches || (Array.isArray(selectingScene.speeches) && selectingScene.speeches.length === 0))
    ) {
      let totalDuration = +selectingScene.speech.duration || 3;
      let speechDuration = +selectingScene.speech.duration || 3;
      const speeches = selectingScene.effects?.map((effect) => {
        const durationEffect = +effect.duration.toString().replace('s', '');
        totalDuration += durationEffect + 0.25;
        return { duration: durationEffect + 0.25 };
      });
      dispatch(
        hotUpdateSceneRequest({
          videoID,
          sceneID: selectingScene._id,
          data: {
            duration: totalDuration,
            speeches: speeches,
            speech: {
              ...selectingScene.speech,
              duration: +speechDuration
            }
          }
        })
      );
    } else {
      if (selectingScene?.effects?.length === 0 && !selectingScene.speeches) {
        dispatch(
          hotUpdateSceneRequest({
            videoID,
            sceneID: selectingScene._id,
            data: {
              speeches: [],
              speech: {
                duration: 3
              }
            }
          })
        );
      }
    }
  }, [selectingScene, videoID, dispatch]);

  useEffect(() => {
    if (timeoutLoadingSlice.current) {
      clearTimeout(timeoutLoadingSlice.current);
    }
    if (nextTime > 0 && video?.svgFile) {
      timeoutLoadingSlice.current = setTimeout(
        () => {
          try {
            refMediaUrl.current?.contentWindow?.switchSlide(idxScene);
            setLoadingScene(false);
            setTimeout(() => {
              endTransitionNextEffect.current = true;
            }, 1500);
          } catch (error) {
            if (error.name === 'TypeError') {
              setNextTime((prev) => (prev += 1));
            }
          }
        },
        nextTime === 1 ? 0 : 500
      );
    }
  }, [nextTime, idxScene, refMediaUrl, video?.svgFile]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!controlToolbarMedia.current?.contains(e.target)) {
        handleCloseTool();
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleCloseTool, controlToolbarMedia]);

  const soundRef = useRef();
  useEffect(() => {
    if (isPlaying) {
      soundRef.current = setTimeout(() => {
        pauseBackgroundSound();
      }, Math.ceil(+selectingScene.duration || 0) * 1000);
    }
    if (!isPlaying) {
      clearTimeout(soundRef.current);
      pauseBackgroundSound();
    }
    return () => {
      clearTimeout(soundRef.current);
    };
  }, [selectingScene, isPlaying]);

  return {
    backgroundSoundRef,
    t,
    handleDeleteMedia,
    backgroundSound,
    handleChangeVideoAndSceneVolume,
    mediaToolbarItems,
    currentTool,
    handleCloseTool,
    mediaWrapperRef,
    dimensionsProps,
    breakpoint,
    scene: selectingScene,
    loading: selectingStatus.loading || changeFormatLoading,
    handleUpdateScene,

    editMode,

    editVideoDialogWidth,
    wrapperRef,
    onCloseEdit,

    animRef,

    sceneVolume,
    handleTogglePlayButton,
    scrollHorizontally,
    timelineTextAndVoiceRef,
    refMediaUrl,
    refElementEffect,
    image,
    setLoadingScene,
    setIdxEffect,
    idxEffect,
    video,
    loadingScene,
    idxScene,
    onClickPrevEffect,
    onClickNextEffect,
    listEffect,
    totalTimeDuration,
    setTotalTimeDuration,
    isPlaying,
    setIsPlaying,
    activeSpeechStart,
    activeSpeech,
    timeoutLoadingSlice,
    setNextTime,
    endTransitionNextEffect,
    refElementEffectStart
  };
};
