export const defaultJson = {
  v: '5.9.2',
  fr: 25,
  ip: 0,
  op: 25,
  w: 1920,
  h: 600,
  nm: '9-Circle',
  ddd: 0,
  assets: [],
  fonts: {
    list: [
      {
        origin: 0,
        fPath: '',
        fClass: '',
        fFamily: 'Montserrat',
        fWeight: '',
        fStyle: 'ExtraBold',
        fName: 'Montserrat-ExtraBold',
        ascent: 69.5092065427452
      }
    ]
  },
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 5,
      nm: '<empty text layer>',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1638, 462, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      t: {
        d: {
          k: [
            { s: { s: 120, f: 'Montserrat-ExtraBold', t: '', ca: 1, j: 2, tr: 0, lh: 144, ls: 0, fc: [1, 1, 1] }, t: 0 }
          ]
        },
        p: {},
        m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
        a: []
      },
      ip: 0,
      op: 50,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 5,
      nm: 'text',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 0,
          k: [948, 309.25, 0],
          ix: 2,
          l: 2,
          x: 'var $bm_rt;\nvar H, W;\nH = thisComp.height;\nW = thisComp.width;\n$bm_rt = [\n    W / 2,\n    H / 2\n];'
        },
        a: {
          a: 0,
          k: [2.791, -54.25, 0],
          ix: 1,
          l: 2,
          x: 'var $bm_rt;\nvar sourceSize, T, L, W, H;\nsourceSize = thisLayer.sourceRectAtTime(time, false);\nT = sourceSize.top;\nL = sourceSize.left;\nW = sourceSize.width;\nH = sourceSize.height;\n$bm_rt = [\n    L + W / 2,\n    T + H / 2\n];'
        },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      t: {
        d: {
          k: [
            {
              s: {
                sz: [515, 403.000091552734],
                ps: [-678.5, -115.008323669434],
                s: 50,
                f: 'Montserrat-ExtraBold',
                t: 'HELLO',
                ca: 1,
                j: 2,
                tr: 0,
                lh: 60.0000038146973,
                ls: 0,
                fc: [1, 1, 1]
              },
              t: 0
            }
          ]
        },
        p: {},
        m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
        a: [
          {
            nm: 'Animator 1',
            s: {
              t: 0,
              xe: { a: 0, k: 0, ix: 7 },
              ne: { a: 0, k: 0, ix: 8 },
              a: { a: 0, k: 100, ix: 4 },
              b: 1,
              rn: 0,
              sh: 1,
              sm: { a: 0, k: 0, ix: 6 },
              s: {
                a: 1,
                k: [
                  { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 0, s: [0] },
                  { t: 7, s: [100] }
                ],
                ix: 1
              },
              r: 1
            },
            a: { o: { a: 0, k: 0, ix: 9 } }
          }
        ]
      },
      ip: 0,
      op: 50,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Shape Layer 1',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [0] },
            { t: 7, s: [100] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [960, 300, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 0,
              s: [80, 80, 100]
            },
            { t: 7, s: [100, 100, 100] }
          ],
          ix: 6,
          l: 2,
          x: "var $bm_rt;\nvar h, w, s, t1, t2;\nh = thisComp.layer('text').sourceRectAtTime().height;\nw = thisComp.layer('text').sourceRectAtTime().width;\ns = h > w ? h : w;\nt1 = thisLayer.transform.opacity.key(1).time;\nt2 = thisLayer.transform.opacity.key(2).time;\n$bm_rt = ease(time, t1, t2, [\n    0,\n    0\n], $bm_div($bm_mul([\n    100,\n    100\n], s), 450));"
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [560, 560], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.917647298177, 0, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-14, 8], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Ellipse 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};

export const textLayer = 1;
