import React, { useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

const duration = {
  transitionProperty: 'width, left',
  transitionDuration: '0.5s, 0.5s',
  transitionTimingFunction: 'ease, ease'
};

MyTabPanel.propTypes = {
  tab: PropTypes.string,
  name: PropTypes.string,
  currentTab: PropTypes.string,
  onChangeTabAndStyle: PropTypes.func,
  setTabStyle: PropTypes.func
};

MyTab.propTypes = {
  currentTab: PropTypes.string,
  children: PropTypes.any,
  onChangeTab: PropTypes.func
};

export function MyTab({ children, currentTab, onChangeTab }) {
  const [style, setTabStyle] = useState(null);
  const onChangeTabAndStyle = (tabRef, currentTabParam) => {
    setTabStyle({
      width: `${tabRef.current.offsetWidth}px`,
      left: `${tabRef.current.offsetLeft}px`,
      bottom: '0',
      ...duration
    });
    if (typeof onChangeTab === 'function') {
      onChangeTab(currentTabParam);
    }
  };

  return (
    <div className="d-flex tab-border align-items-center position-relative">
      {React.Children.map(children, (child) => {
        if (child.type === MyTabPanel) {
          return React.cloneElement(child, { currentTab, onChangeTabAndStyle, setTabStyle });
        }
        return child;
      })}
      <div className="tab-line position-absolute" style={style} />
    </div>
  );
}

export function MyTabPanel(props) {
  const { tab, name, onChangeTabAndStyle, currentTab, setTabStyle } = props;
  const tabRef = useRef(null);

  const isTabActive = () => tab === currentTab;

  useLayoutEffect(() => {
    if (tabRef.current && isTabActive()) {
      setTabStyle({ width: `${tabRef.current.offsetWidth}px`, bottom: '0', left: `0px` });
    }
  }, [tabRef]);

  return (
    <div className="tab" ref={tabRef}>
      <div
        className="d-flex flex-direct-column pointer"
        aria-hidden="true"
        onClick={() => onChangeTabAndStyle(tabRef, tab)}
      >
        <span className={isTabActive() ? 'text-2 text-primary-2' : ''}>{name}</span>
      </div>
    </div>
  );
}
