import { AppTypes, login } from 'containers/App/actions';
import { createTypes } from 'reduxsauce';
import axiosClient from 'utils/axiosClient';
import { ENDPOINTS } from './config';

export const LoginTypes = createTypes(`
  LOGIN_REQUEST
  LOGIN_SUCCESS
  LOGIN_FAILURE
  UPDATE_USER_SUCCESS
  UPDATE_USER_FAILURE
  CHANGE_PASSWORD_SUCCESS
  CHANGE_PASSWORD_FAILURE
`);

// Login action
export const loginSuccess = (user) => ({ type: LoginTypes.LOGIN_SUCCESS, user });

export const loginFailure = (error) => ({
  error,
  type: LoginTypes.LOGIN_FAILURE
});

export const loginRequest =
  ({ email, password }) =>
  async (dispatch) => {
    dispatch({ type: LoginTypes.LOGIN_REQUEST });
    const rs = await axiosClient.post(ENDPOINTS.login, { email, password });
    dispatch(loginSuccess(rs.user));
    dispatch(login({ access_token: rs.access_token, user: rs.user }));
    return rs.user;
  };

export const updateUserSuccess = (user) => ({ type: LoginTypes.UPDATE_USER_SUCCESS, user });
export const updateUserFailure = (error) => ({ type: LoginTypes.UPDATE_USER_FAILURE, error });
export const updateUser = (payload) => async (dispatch) => {
  try {
    const formData = new FormData();
    if (payload.avatar) formData.append('avatar', payload.avatar);
    if (payload.name) formData.append('name', payload.name);
    if (payload.phone) formData.append('phone', payload.phone);
    if (payload.birthday) formData.append('birthday', payload.birthday);
    if (payload.email) formData.append('email', payload.email);
    const response = await axiosClient.post(ENDPOINTS.user, formData);
    dispatch(updateUserSuccess(response.user));
    dispatch({ type: AppTypes.UPDATE_USER, user: response.user });
  } catch (e) {
    dispatch(updateUserFailure(e));
  }
};

export const changePasswordSuccess = () => ({ type: LoginTypes.CHANGE_PASSWORD_SUCCESS });
export const changePasswordFailure = (error) => ({ type: LoginTypes.CHANGE_PASSWORD_FAILURE, error });
export const changePassword = (payload) => async (dispatch) => {
  try {
    await axiosClient.patch(ENDPOINTS.changePassword, payload);
    dispatch(changePasswordSuccess());
  } catch (e) {
    dispatch(changePasswordFailure(e));
  }
};
