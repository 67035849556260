import { useTranslation } from 'react-i18next';
import { Box, MenuItem, Popover, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'containers/App/actions';
import { useAuth } from 'hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { activeGroup, fetchGroupsWithoutPaginate } from '../../containers/Group/actions';
import { toAbsoluteUrl } from 'utils/formatUrl';
import './styles.css';

export function AccountPopover() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const { user } = useAuth();
  // const { groupsWithoutPaginate } = useSelector((state) => state.group);
  const { organization } = useSelector((state) => state.core);

  const onClickProfile = () => {
    navigate(`/profile`);
  };

  const onClickAccount = () => {
    navigate(`/account`);
  };

  const onClickDrive = () => {
    window.open(`/drive`);
  };

  const onClickGroup = async (group) => {
    try {
      await dispatch(activeGroup({ organization_id: group._id }));
      // navigate(`/`);
    } catch (e) {
      throw Error(e);
    }
  };

  useEffect(() => {
    dispatch(fetchGroupsWithoutPaginate({ user_id: user._id }));
  }, []);

  const onGetActiveGroup = (group) => {
    if (group._id === user.organization_id) {
      return 'text-1';
    }
    return '';
  };

  return (
    <>
      <Box
        sx={{
          display: 'inline-flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          cursor: 'pointer'
        }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <Typography
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            marginLeft: '5px',
            marginRight: '8px'
          }}
        >
          {user.name}
        </Typography>
        {user.avatar ? (
          <img
            className="avatar"
            src={user.avatar || toAbsoluteUrl('/static/avatar-placeholder.png')}
            alt="avatar"
            style={{ width: '30px', height: '30px', borderRadius: '5px' }}
          />
        ) : (
          <Typography
            variant="span"
            sx={{
              width: '30px',
              height: '30px',
              borderRadius: '5px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontWeight: 'bold',
              color: '#1BC5BD',
              backgroundColor: '#C9F7F5'
            }}
          >
            <span>{user?.name && user?.name[0]}</span>
          </Typography>
        )}
      </Box>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        sx={{ right: 10 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem
          key={organization?._id}
          onClick={() => onClickGroup(organization)}
          sx={{
            width: '300px',
            height: '70px',
            backgroundImage: `url(${toAbsoluteUrl('/static/bg-1.jpg')})`,
            color: 'white',
            backgroundSize: 'cover'
          }}
        >
          Nhóm:&nbsp;
          <span style={{ lineClamp: 1, boxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {organization?.name}
          </span>{' '}
        </MenuItem>
        <div className="line-1" />
        <MenuItem onClick={onClickDrive}>
          <div className="d-flex align-items-center">
            <img
              className="mr-12 nav-menu-item"
              style={{ width: '20px' }}
              src={toAbsoluteUrl('/dlow-logo-black.png')}
              alt="setting"
            />
            <span>Drive</span>
          </div>
        </MenuItem>
        <MenuItem onClick={onClickProfile}>
          <div className="d-flex align-items-center">
            <img className="mr-12 nav-menu-item" src={toAbsoluteUrl('/icons/setting.svg')} alt="setting" />
            <span>{t('accountPopover.setting')}</span>
          </div>
        </MenuItem>
        <MenuItem onClick={onClickAccount}>
          <div className="d-flex align-items-center">
            <img className="mr-12 nav-menu-item" src={`${toAbsoluteUrl('/icons/user.png')}`} alt="setting" />
            <span>{t('accountPopover.account')}</span>
          </div>
        </MenuItem>
        <MenuItem onClick={() => dispatch(logout())}>
          <div className="d-flex align-items-center">
            <img className="mr-12 nav-menu-item" src={toAbsoluteUrl('/icons/logout.svg')} alt="logout" />
            <span>{t('accountPopover.logout')}</span>
          </div>
        </MenuItem>
      </Popover>
    </>
  );
}
