import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { AccountPopover } from 'components/AccountPopover';
import NotifcationsMenu from 'components/NotificationsMenu';
import { LogoIcon } from 'containers/BuildComplete/icons';
import { useEffect, useState } from 'react';
import Pusher from 'react-pusher';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import axiosClient from 'utils/axiosClient';
import { updateVideo } from '../HomeTemplate/actions';
import { useStyles } from './styles';

function NavBar() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [listNotif, setListNotif] = useState([]);

  const onPusherUpdate = (data) => {
    fetchNotif();
    dispatch(updateVideo(data.notice.video._id, { produce_flg: false }));
  };

  const fetchNotif = async () => {
    try {
      const rs = await axiosClient.get('/notifications');
      setListNotif(rs.notifications.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickNotif = (notif) => {
    navigate('/build-complete', { state: { video: notif.video } });
    axiosClient.patch(`/notifications/${notif._id}`);
  };

  // useEffect(() => {
  //   fetchNotif();
  // }, []);

  return (
    <Box className={classes.navBar}>
      {/* <Pusher
        channel={process.env.REACT_APP_PUSHER_BUILD_CHANNEL}
        event={process.env.REACT_APP_PUSHER_BUILD_EVENT}
        onUpdate={onPusherUpdate}
      /> */}
      <IconButton
        sx={{
          marginLeft: '25px'
        }}
        onClick={() => navigate('/')}
      >
        {window.APP_LOGO ? <img alt="logo" src={window.APP_LOGO} /> : <LogoIcon />}
      </IconButton>
      <Box
        sx={{
          marginRight: '25px',
          display: 'flex'
        }}
      >
        {/* <NotifcationsMenu listNotif={listNotif} handleClickNotif={handleClickNotif} /> */}
        <AccountPopover />
      </Box>
    </Box>
  );
}
export default NavBar;
