export const formatMoney = (value) => value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

export const formatTable = (index) => (index % 2 === 0 ? 'table-row-dark pointer' : 'table-row-light pointer');

export const formatTable2 = (index) => (index % 2 === 0 ? 'table-row-dark' : 'table-row-light');

export const createMarkup = (value) => ({ __html: value });

export const getTimeVideoUsed = (time) => {
  if (time) {
    if (time < 60) {
      return `${time} giây`;
    }
    const minute = Math.floor(time / 60);
    const second = time % 60 > 0 ? `${time % 60} giây` : '';
    return `${minute} phút ${second}`;
  }
  return '0';
};

export const removeFileExtension = (filename) => {
  if (filename && filename.lastIndexOf('.') >= 0) {
    return filename.substring(0, filename.lastIndexOf('.'));
  }
  return filename;
};
