export const defaultJson = {
  v: '5.9.2',
  fr: 25,
  ip: 0,
  op: 44,
  w: 1920,
  h: 600,
  nm: '10-Shutter',
  ddd: 0,
  assets: [],
  fonts: {
    list: [
      {
        origin: 0,
        fPath: '',
        fClass: '',
        fFamily: 'Montserrat',
        fWeight: '',
        fStyle: 'ExtraBold',
        fName: 'Montserrat-ExtraBold',
        ascent: 69.5092065427452
      }
    ]
  },
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'top',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [0] },
            { t: 16, s: [100] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [952, 312, 0],
              to: [0, -14.983, 0],
              ti: [0, 3.017, 0]
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 16,
              s: [952, 287, 0],
              to: [0, -18, 0],
              ti: [0, 0, 0]
            },
            { t: 21, s: [952, 219, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [-14, -161, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 0,
              s: [0, 0, 100]
            },
            { t: 16, s: [80, 35.307, 100] }
          ],
          ix: 6,
          l: 2,
          x: "var $bm_rt;\nvar textTime, textWidth;\ntextTime = thisComp.layer('text').transform.opacity.key(1).time;\ntextWidth = thisComp.layer('text').sourceRectAtTime().width;\n$bm_rt = ease(time, 0, textTime, [\n    0,\n    0\n], [\n    $bm_div(textWidth, 8),\n    35\n]);"
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: { a: 0, k: [836, 22], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              r: { a: 0, k: 0, ix: 4 },
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-14, -161], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Rectangle 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 44,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'down',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [0] },
            { t: 16, s: [100] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [952, 312, 0],
              to: [0, -14.983, 0],
              ti: [0, 3.017, 0]
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 16,
              s: [952, 286, 0],
              to: [0, -18, 0],
              ti: [0, 0, 0]
            },
            { t: 21, s: [952, 390, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [-14, -161, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 0,
              s: [0, 0, 100]
            },
            { t: 16, s: [80, 35.307, 100] }
          ],
          ix: 6,
          l: 2,
          x: "var $bm_rt;\nvar textTime, textWidth;\ntextTime = thisComp.layer('text').transform.opacity.key(1).time;\ntextWidth = thisComp.layer('text').sourceRectAtTime().width;\n$bm_rt = ease(time, 0, textTime, [\n    0,\n    0\n], [\n    $bm_div(textWidth, 8),\n    35\n]);"
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: { a: 0, k: [836, 22], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              r: { a: 0, k: 0, ix: 4 },
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-14, -161], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Rectangle 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 44,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 5,
      nm: 'text',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 20, s: [0] },
            { t: 39, s: [100] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 0,
          k: [984, 286.25, 0],
          ix: 2,
          l: 2,
          x: 'var $bm_rt;\nvar H, W;\nH = thisComp.height;\nW = thisComp.width;\n$bm_rt = [\n    W / 2,\n    H / 2\n];'
        },
        a: {
          a: 0,
          k: [2.791, -54.25, 0],
          ix: 1,
          l: 2,
          x: 'var $bm_rt;\nvar sourceSize, T, L, W, H;\nsourceSize = thisLayer.sourceRectAtTime(time, false);\nT = sourceSize.top;\nL = sourceSize.left;\nW = sourceSize.width;\nH = sourceSize.height;\n$bm_rt = [\n    L + W / 2,\n    T + H / 2\n];'
        },
        s: { a: 0, k: [118, 118, 100], ix: 6, l: 2 }
      },
      ao: 0,
      t: {
        d: {
          k: [
            {
              s: {
                sz: [1579.50854492188, 112.935272216797],
                ps: [-674.5, 34.3907814025879],
                s: 90,
                f: 'Montserrat-ExtraBold',
                t: 'HELLO',
                ca: 1,
                j: 2,
                tr: 0,
                lh: 108.000007629395,
                ls: 0,
                fc: [0.842, 0, 0.721]
              },
              t: 0
            }
          ]
        },
        p: {},
        m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
        a: []
      },
      ip: 0,
      op: 50,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};

export const textLayer = 2;
