import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import Navbar from '../../HomeVideo/components/NavBar';
import ProfileInfo from '../../Profile/components/ProfileInfo';
import { TAB, GROUP_MEMBER_PERMISSION } from '../constants';
import GroupDetailInfoAdmin from './GroupDetailInfoAdmin';
import GroupDetailInfo from './GroupDetailInfo';
import GroupDetailMember from './GroupDetailMember';
import GroupDetailMemberAdmin from './GroupDetailMemberAdmin';
import GroupDetailVideo from './GroupDetailVideo';
import GroupDetailVideoAdmin from './GroupDetailVideoAdmin';
import { MyTab, MyTabPanel } from '../../../components/Common/Tab';
import GroupDetailMemberInvite from './GroupDetailMemberInvite';
import '../styles.css';
import { fetchDetailGroup, fetchDetailMemberGroup } from '../actions';
import * as Selectors from '../../App/selectors';
import PackageDetail from './PackageDetail';

function AccountDetail() {
  const user = useSelector(Selectors.getUser());
  const dispatch = useDispatch();
  const [tab, setTab] = useState(TAB.PACKAGE);
  const param = useParams();
  const navigate = useNavigate();
  const { group, member, members, membersInvite } = useSelector((state) => state.group);

  useEffect(() => {
    dispatch(fetchDetailMemberGroup(user.organization_id, { user_id: user._id }));
    dispatch(fetchDetailGroup(user.organization_id));
  }, [param, user, dispatch]);

  const onChangeTab = (item) => {
    setTab(item);
  };

  const permission = member?.permission;

  return (
    <>
      <Navbar />
      <div className="main">
        <div className="left-main">
          <ProfileInfo />
        </div>
        <div className="right-main d-flex flex-direct-column">
          <div className="d-flex flex-direct-column">
            <MyTab currentTab={tab} onChangeTab={onChangeTab}>
              <MyTabPanel tab={TAB.PACKAGE} name="Gói" />
              <div className="tab-space" />
              {/* <MyTabPanel tab={TAB.GROUP} name="Nhóm làm việc" />
              <div className="tab-space" /> */}
              <MyTabPanel tab={TAB.MEMBER} name={`Thành viên (${group?.statistic?.member_count || 0})`} />
              <div className="tab-space" />
              <MyTabPanel tab={TAB.MEMBER_INVITE} name={`Đã mời (${group?.statistic?.member_invite || 0})`} />
            </MyTab>
          </div>
          <div className="d-flex flex-direct-column content-account-package mt-4">
            {tab === TAB.PACKAGE && <PackageDetail member={member} group={group} permission={permission} />}
            {/* {tab === TAB.GROUP &&
              (permission === GROUP_MEMBER_PERMISSION.SUPPER_ADMIN || permission === GROUP_MEMBER_PERMISSION.ADMIN) && (
                <GroupDetailInfoAdmin member={member} group={group} permission={permission} />
              )}
            {tab === TAB.GROUP && permission === GROUP_MEMBER_PERMISSION.EDITOR && (
              <GroupDetailInfo member={member} group={group} permission={permission} />
            )} */}
            {tab === TAB.MEMBER &&
              (permission === GROUP_MEMBER_PERMISSION.SUPPER_ADMIN || permission === GROUP_MEMBER_PERMISSION.ADMIN) && (
                <GroupDetailMemberAdmin members={members} groupId={group._id} />
              )}
            {tab === TAB.MEMBER && permission === GROUP_MEMBER_PERMISSION.EDITOR && (
              <GroupDetailMember members={members} groupId={group._id} />
            )}
            {tab === TAB.MEMBER_INVITE && <GroupDetailMemberInvite members={membersInvite} groupId={group._id} />}
            {tab === TAB.VIDEO && permission === GROUP_MEMBER_PERMISSION.EDITOR && (
              <GroupDetailVideo groupId={group._id} />
            )}
            {tab === TAB.VIDEO &&
              (permission === GROUP_MEMBER_PERMISSION.SUPPER_ADMIN || permission === GROUP_MEMBER_PERMISSION.ADMIN) && (
                <GroupDetailVideoAdmin groupId={group._id} />
              )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AccountDetail;
