import { createReducer } from 'reduxsauce';
import { CreateVideoTypes } from './actions';

const requestState = {
  error: null,
  loading: false,
  listQuiz: []
};

export const INITIAL_STATE = {
  ...requestState
};
const getQuizRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true
});
const getQuizSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  loading: false,
  listQuiz: actions.listQuiz
});
const getQuizFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  loading: false,
  error: actions.error
});
export const HANDLERS = {
  [CreateVideoTypes.GET_QUIZ_REQUEST]: getQuizRequest,
  [CreateVideoTypes.GET_QUIZ_SUCCESS]: getQuizSuccess,
  [CreateVideoTypes.GET_QUIZ_FAILURE]: getQuizFailure
};

export const quizReducer = createReducer(INITIAL_STATE, HANDLERS);
