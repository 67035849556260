export const defaultJson = {
  v: '5.9.2',
  fr: 25,
  ip: 0,
  op: 50,
  w: 1920,
  h: 600,
  nm: '12-Line',
  ddd: 0,
  assets: [],
  fonts: {
    list: [
      {
        origin: 0,
        fPath: '',
        fClass: '',
        fFamily: 'Montserrat',
        fWeight: '',
        fStyle: 'ExtraBold',
        fName: 'Montserrat-ExtraBold',
        ascent: 70.9991455078125
      }
    ]
  },
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 3,
      nm: 'Global Transform - Controls for Premiere',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10, x: "var $bm_rt;\n$bm_rt = effect('Rotation')('ADBE Angle Control-0001');" },
        p: {
          a: 0,
          k: [960, 540, 0],
          ix: 2,
          l: 2,
          x: "var $bm_rt;\n$bm_rt = $bm_sum([\n    thisComp.width / 2,\n    thisComp.height / 2\n], effect('Position')('ADBE Point Control-0001'));"
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6,
          l: 2,
          x: "var $bm_rt;\nvar heightScale, widthScale, usedScale;\nheightScale = $bm_div(thisComp.height, 1080);\nwidthScale = $bm_div(thisComp.width, 1920);\nusedScale = $bm_mul(Math.max(heightScale, widthScale), 100);\n$bm_rt = $bm_mul([\n    usedScale,\n    usedScale\n], $bm_div(effect('Scale')('ADBE Slider Control-0001'), 100));"
        }
      },
      ao: 0,
      ef: [
        {
          ty: 5,
          nm: 'Position',
          np: 3,
          mn: 'ADBE Point Control',
          ix: 1,
          en: 1,
          ef: [{ ty: 3, nm: 'Point', mn: 'ADBE Point Control-0001', ix: 1, v: { a: 0, k: [0, 0], ix: 1 } }]
        },
        {
          ty: 5,
          nm: 'Rotation',
          np: 3,
          mn: 'ADBE Angle Control',
          ix: 2,
          en: 1,
          ef: [{ ty: 0, nm: 'Angle', mn: 'ADBE Angle Control-0001', ix: 1, v: { a: 0, k: 0, ix: 1 } }]
        },
        {
          ty: 5,
          nm: 'Scale',
          np: 3,
          mn: 'ADBE Slider Control',
          ix: 3,
          en: 1,
          ef: [{ ty: 0, nm: 'Slider', mn: 'ADBE Slider Control-0001', ix: 1, v: { a: 0, k: 100, ix: 1 } }]
        }
      ],
      ip: 0,
      op: 36.7033700367034,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 3,
      nm: 'Show Guide - Controls',
      parent: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [290, 163.5, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [130.278, 130.278, 100], ix: 6, l: 2 }
      },
      ao: 0,
      ef: [
        {
          ty: 5,
          nm: 'Show Guide',
          np: 3,
          mn: 'ADBE Checkbox Control',
          ix: 1,
          en: 1,
          ef: [{ ty: 7, nm: 'Checkbox', mn: 'ADBE Checkbox Control-0001', ix: 1, v: { a: 0, k: 0, ix: 1 } }]
        }
      ],
      ip: 0,
      op: 59.2258925592259,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 3,
      nm: 'Position Handle',
      parent: 2,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [-222.601, -125.501, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [76.759, 76.759, 100], ix: 6, l: 2 }
      },
      ao: 0,
      ip: 0,
      op: 36.7033700367034,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Show Guide',
      parent: 3,
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
          x: "var $bm_rt;\n$bm_rt = thisComp.layer('Show Guide - Controls').effect('Show Guide')(1) == 1 ? 50 : 0;"
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [0.4, 0.25, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0.375, 0.25, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      ef: [
        {
          ty: 5,
          nm: 'Transform',
          np: 14,
          mn: 'ADBE Geometry2',
          ix: 1,
          en: 1,
          ef: [
            { ty: 3, nm: 'Anchor Point', mn: 'ADBE Geometry2-0001', ix: 1, v: { a: 0, k: [960, 300], ix: 1 } },
            { ty: 3, nm: 'Position', mn: 'ADBE Geometry2-0002', ix: 2, v: { a: 0, k: [960, 300], ix: 2 } },
            { ty: 7, nm: 'Uniform Scale', mn: 'ADBE Geometry2-0011', ix: 3, v: { a: 0, k: 1, ix: 3 } },
            { ty: 0, nm: 'Scale Height', mn: 'ADBE Geometry2-0003', ix: 4, v: { a: 0, k: 100, ix: 4 } },
            { ty: 0, nm: 'Scale Width', mn: 'ADBE Geometry2-0004', ix: 5, v: { a: 0, k: 100, ix: 5 } },
            { ty: 0, nm: 'Skew', mn: 'ADBE Geometry2-0005', ix: 6, v: { a: 0, k: 0, ix: 6 } },
            { ty: 0, nm: 'Skew Axis', mn: 'ADBE Geometry2-0006', ix: 7, v: { a: 0, k: 0, ix: 7 } },
            { ty: 0, nm: 'Rotation', mn: 'ADBE Geometry2-0007', ix: 8, v: { a: 0, k: 0, ix: 8 } },
            { ty: 0, nm: 'Opacity', mn: 'ADBE Geometry2-0008', ix: 9, v: { a: 0, k: 100, ix: 9 } },
            {
              ty: 7,
              nm: 'Use Composition’s Shutter Angle',
              mn: 'ADBE Geometry2-0009',
              ix: 10,
              v: { a: 0, k: 1, ix: 10 }
            },
            { ty: 0, nm: 'Shutter Angle', mn: 'ADBE Geometry2-0010', ix: 11, v: { a: 0, k: 0, ix: 11 } },
            { ty: 7, nm: 'Sampling', mn: 'ADBE Geometry2-0012', ix: 12, v: { a: 0, k: 1, ix: 12 } }
          ]
        }
      ],
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-468.919, 0],
                    [468.869, 0]
                  ],
                  c: false
                },
                ix: 2,
                x: "var $bm_rt;\nvar controlsLayer, controlsLayer, origPath, nullLayerNames, inTangs, outTangs, pathPoints, getNullLayers, nullLayer, nullLayer, inTan, inTan, outTan, outTan;\nif (thisComp.layer('Show Guide - Controls').effect('Show Guide')(1) == 1) {\n    controlsLayer = thisComp.layer('Line');\n    if (typeof controlsLayer == 'undefined') {\n        controlsLayer = thisLayer;\n    }\n    origPath = thisProperty;\n    nullLayerNames = [];\n    inTangs = [];\n    outTangs = [];\n    pathPoints = [];\n    getNullLayers = [];\n    for (var i = 1; i <= controlsLayer('Effects').numProperties; i++) {\n        if (!controlsLayer.effect(i)._name.match(/^Line: Path/))\n            continue;\n        try {\n            nullLayer = controlsLayer.effect(i)(1);\n        } catch (e) {\n            nullLayer = null;\n        }\n        if (!nullLayer || nullLayer.index == thisLayer.index)\n            continue;\n        pathPoints.push(fromCompToSurface(nullLayer.toComp(nullLayer.anchorPoint)));\n        try {\n            inTan = nullLayer.effect('Path Curvature In')(1);\n        } catch (e) {\n            inTan = [\n                0,\n                0\n            ];\n        }\n        try {\n            outTan = nullLayer.effect('Path Curvature Out')(1);\n        } catch (e) {\n            outTan = [\n                0,\n                0\n            ];\n        }\n        inTangs.push(inTan);\n        outTangs.push(outTan);\n    }\n    if (pathPoints.length > 1 && pathPoints.length == inTangs.length && outTangs.length == inTangs.length)\n        $bm_rt = createPath(pathPoints, inTangs, outTangs, origPath.isClosed());\n    else\n        $bm_rt = value;\n} else {\n    $bm_rt = value;\n}"
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.815686285496, 0.063975393772, 0.063975393772, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 40, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.815686285496, 0.063975393772, 0.063975393772, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 40, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 2',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Shape 1',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 36.7033700367034,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'Line',
      parent: 3,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [-107.625, 64.25, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0.375, 0.25, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      ef: [
        {
          ty: 5,
          nm: 'Transform',
          np: 14,
          mn: 'ADBE Geometry2',
          ix: 1,
          en: 1,
          ef: [
            { ty: 3, nm: 'Anchor Point', mn: 'ADBE Geometry2-0001', ix: 1, v: { a: 0, k: [960, 300], ix: 1 } },
            { ty: 3, nm: 'Position', mn: 'ADBE Geometry2-0002', ix: 2, v: { a: 0, k: [960, 300], ix: 2 } },
            { ty: 7, nm: 'Uniform Scale', mn: 'ADBE Geometry2-0011', ix: 3, v: { a: 0, k: 1, ix: 3 } },
            { ty: 0, nm: 'Scale Height', mn: 'ADBE Geometry2-0003', ix: 4, v: { a: 0, k: 100, ix: 4 } },
            { ty: 0, nm: 'Scale Width', mn: 'ADBE Geometry2-0004', ix: 5, v: { a: 0, k: 100, ix: 5 } },
            { ty: 0, nm: 'Skew', mn: 'ADBE Geometry2-0005', ix: 6, v: { a: 0, k: 0, ix: 6 } },
            { ty: 0, nm: 'Skew Axis', mn: 'ADBE Geometry2-0006', ix: 7, v: { a: 0, k: 0, ix: 7 } },
            { ty: 0, nm: 'Rotation', mn: 'ADBE Geometry2-0007', ix: 8, v: { a: 0, k: 0, ix: 8 } },
            { ty: 0, nm: 'Opacity', mn: 'ADBE Geometry2-0008', ix: 9, v: { a: 0, k: 100, ix: 9 } },
            {
              ty: 7,
              nm: 'Use Composition’s Shutter Angle',
              mn: 'ADBE Geometry2-0009',
              ix: 10,
              v: { a: 0, k: 1, ix: 10 }
            },
            { ty: 0, nm: 'Shutter Angle', mn: 'ADBE Geometry2-0010', ix: 11, v: { a: 0, k: 0, ix: 11 } },
            { ty: 7, nm: 'Sampling', mn: 'ADBE Geometry2-0012', ix: 12, v: { a: 0, k: 1, ix: 12 } }
          ]
        },
        {
          ty: 5,
          nm: 'Line: Path 1 [1.1.0]',
          np: 3,
          mn: 'ADBE Layer Control',
          ix: 2,
          en: 1,
          ef: [{ ty: 10, nm: 'Layer', mn: 'ADBE Layer Control-0001', ix: 1, v: { a: 0, k: 5, ix: 1 } }]
        },
        {
          ty: 5,
          nm: 'Line: Path 1 [1.1.1]',
          np: 3,
          mn: 'ADBE Layer Control',
          ix: 3,
          en: 1,
          ef: [{ ty: 10, nm: 'Layer', mn: 'ADBE Layer Control-0001', ix: 1, v: { a: 0, k: 5, ix: 1 } }]
        }
      ],
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-438.094, 0],
                    [438.094, 0]
                  ],
                  c: false
                },
                ix: 2,
                x: "var $bm_rt;\nvar controlsLayer, origPath, nullLayerNames, inTangs, outTangs, pathPoints, getNullLayers, nullLayer, nullLayer, inTan, inTan, outTan, outTan;\nif (typeof controlsLayer == 'undefined') {\n    controlsLayer = thisLayer;\n}\norigPath = thisProperty;\nnullLayerNames = [];\ninTangs = [];\noutTangs = [];\npathPoints = [];\ngetNullLayers = [];\nfor (var i = 1; i <= controlsLayer('Effects').numProperties; i++) {\n    if (!controlsLayer.effect(i)._name.match(/^Line: Path/))\n        continue;\n    try {\n        nullLayer = controlsLayer.effect(i)(1);\n    } catch (e) {\n        nullLayer = null;\n    }\n    if (!nullLayer || nullLayer.index == thisLayer.index)\n        continue;\n    pathPoints.push(fromCompToSurface(nullLayer.toComp(nullLayer.anchorPoint)));\n    try {\n        inTan = nullLayer.effect('Path Curvature In')(1);\n    } catch (e) {\n        inTan = [\n            0,\n            0\n        ];\n    }\n    try {\n        outTan = nullLayer.effect('Path Curvature Out')(1);\n    } catch (e) {\n        outTan = [\n            0,\n            0\n        ];\n    }\n    inTangs.push(inTan);\n    outTangs.push(outTan);\n}\nif (pathPoints.length > 1 && pathPoints.length == inTangs.length && outTangs.length == inTangs.length)\n    $bm_rt = createPath(pathPoints, inTangs, outTangs, origPath.isClosed());\nelse\n    $bm_rt = value;"
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 0.400000029919, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 0.400000029919, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: {
                a: 1,
                k: [
                  { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0.167] }, t: 2.503, s: [0] },
                  { i: { x: [0.704], y: [1] }, o: { x: [0.188], y: [0] }, t: 5.835, s: [20] },
                  { i: { x: [0.833], y: [0.833] }, o: { x: [0.333], y: [0] }, t: 27.503, s: [20] },
                  { t: 30.8349609375, s: [0] }
                ],
                ix: 5
              },
              lc: 2,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 2',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Shape 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'tm',
          s: {
            a: 1,
            k: [
              { i: { x: [0.303], y: [1] }, o: { x: [0.795], y: [0] }, t: 9.176, s: [0] },
              { t: 32.509765625, s: [100] }
            ],
            ix: 1
          },
          e: {
            a: 1,
            k: [
              { i: { x: [0.303], y: [1] }, o: { x: [0.795], y: [0] }, t: 0, s: [0] },
              { t: 23.333984375, s: [100] }
            ],
            ix: 2
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false
        }
      ],
      ip: 0,
      op: 33.3667000333667,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 6,
      ty: 5,
      nm: 'Hello',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [0] },
            { t: 35, s: [100] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.806, y: 1 },
              o: { x: 0.712, y: 0 },
              t: 0,
              s: [527.516, 286.085, 0],
              to: [69.667, 0, 0],
              ti: [-69.667, 0, 0]
            },
            { t: 27, s: [945.516, 286.085, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [0.516, -31.415, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      t: {
        d: {
          k: [
            {
              s: {
                sz: [1349, 175],
                ps: [-674.5, -115.008323669434],
                s: 155,
                f: 'Montserrat-ExtraBold',
                t: 'HELLO',
                ca: 1,
                j: 2,
                tr: 0,
                lh: 186,
                ls: 0,
                fc: [0, 0, 0]
              },
              t: 0
            }
          ]
        },
        p: {},
        m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
        a: []
      },
      ip: 0,
      op: 50,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};

export const textLayer = 5;
