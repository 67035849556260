export const FORMAT_TO_RATIO = {
  1: '16/9',
  2: '1/1',
  3: '9/16',
  4: '3/4'
};

export const MEDIA_TYPE = {
  NONE: 0,
  VIDEO: 1,
  IMAGE: 2
};
