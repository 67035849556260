import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  emptyNotif: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 'inherit'
  },
  point: {
    borderRadius: '50%',
    backgroundColor: '#2E89FF',
    height: '8px',
    aspectRatio: 1
  }
});
