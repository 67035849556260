import { Container, CssBaseline, CircularProgress } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import React, { Suspense } from 'react';
import { useRoutes } from 'react-router';
import theme from 'theme';
import './i18n';
import './pusher';
import routes from './routes';
import './App.css';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { organizationRequest } from 'core/actions';
import { useAuth } from 'hooks/useAuth';

export function App() {
  const dispatch = useDispatch();
  const routing = useRoutes(routes);
  const { user } = useAuth();

  useEffect(() => {
    if (user?.organization_id) {
      dispatch(organizationRequest(user.organization_id));
    }
  }, [user?.organization_id]);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth={false} disableGutters sx={{ height: '100%' }}>
        <CssBaseline />
        <Suspense
          fallback={
            <div
              className="d-flex flex-direct-column justify-content-center align-items-center"
              style={{ height: '100%' }}
            >
              <CircularProgress />
            </div>
          }
        >
          {routing}
        </Suspense>
      </Container>
    </ThemeProvider>
  );
}
