import { LOGO_DISTANCE, LOGO_POSITION, MEDIA_TYPE, TRANSITION_EFFECT } from 'containers/EditVideo/constants';
import { useAspectRatioKeeper } from 'hooks/useAspectRatioKeeper';
import { useMedia } from 'hooks/useMedia';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const epsilon = 0.5;
export const TRANSITION_EFFECT_DURATION = 1;

const transitionProps = {
  [TRANSITION_EFFECT.FADE]: [{ in: 'fadeIn', out: '' }],
  [TRANSITION_EFFECT.FADEBLACK]: [{ in: 'fadeInHalf', out: 'fadeOutHalf' }, 'black'],
  [TRANSITION_EFFECT.FADEWHITE]: [{ in: 'fadeInHalf', out: 'fadeOutHalf' }, 'white'],
  [TRANSITION_EFFECT.WIPELEFT]: [{ in: 'wipeInLeft', out: '' }],
  [TRANSITION_EFFECT.WIPERIGHT]: [{ in: 'wipeInRight', out: '' }],
  [TRANSITION_EFFECT.SLIDELEFT]: [{ in: 'wipeInLeft', out: 'wipeOutLeft' }],
  [TRANSITION_EFFECT.SLIDERIGHT]: [{ in: 'wipeInRight', out: 'wipeOutRight' }]
};
const getTransitionEffectProps = (transition) => transitionProps[transition] || transitionProps[TRANSITION_EFFECT.FADE];

export const useScenesCompilation = ({ isShowing, video }) => {
  const { t } = useTranslation();
  const [volume, setVolume] = useState(1);

  const { mediaWrapperRef, dimensionsProps } = useAspectRatioKeeper(video.format);
  const sceneIdx = useRef(0);
  const [listPlayingScene, setListPlayingScene] = useState([]);
  const [playing, setPlaying] = useState(false);
  const [timeTravel, setTimeTravel] = useState(false);
  const timerIDRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [minimizedControlBar, setMinimizedControlBar] = useState(false);
  const [transitionEffectClass, setTransitionEffectClass] = useState(getTransitionEffectProps(video.transition));
  const [backgroundColor, setBackgroundColor] = useState('black');

  const sceneVolume = useMemo(() => (video.scene_volume || 0) / 100, [video]);
  const logo = useMemo(
    () =>
      video.logo
        ? {
            url: video.logo.url,
            type: MEDIA_TYPE.IMAGE
          }
        : null,
    [video.logo]
  );
  const { getComponent: getLogoComponent, intrinsicRatio } = useMedia(logo);
  const backgroundSoundRef = useRef(new Audio(video.background_sound?.url));

  const formatDuration = (duration) => duration || '0';

  const totalTime = useMemo(() => {
    if (video.scenes?.length) {
      return (
        video.scenes.reduce((prev, cur) => prev + parseInt(formatDuration(cur.duration), 10), 0) +
        TRANSITION_EFFECT_DURATION * (video.scenes.length - 1)
      );
    }
    return 0;
  }, [video]);

  const listScenePreview = useMemo(() => {
    if (video.scenes?.length) {
      const initialData = [{ endTime: 1 }];
      return video.scenes
        .reduce((prev, scene, idx, arr) => {
          const startTime = prev.at(-1).endTime - TRANSITION_EFFECT_DURATION;
          const endTime =
            prev.at(-1).endTime +
            (idx !== 0 ? 0 : -TRANSITION_EFFECT_DURATION) +
            parseInt(formatDuration(scene.duration), 10) +
            (idx !== arr.length - 1 ? TRANSITION_EFFECT_DURATION : 0);
          return [
            ...prev,
            {
              ...scene,
              order: idx,
              startTime,
              endTime,
              duration: endTime - startTime,
              isLastScene: idx === arr.length - 1
            }
          ];
        }, initialData)
        .slice(1);
    }
    return [];
  }, [video]);

  const listStartTime = useMemo(() => listScenePreview.map((scene) => scene.startTime), [listScenePreview]);

  const getCurrentSceneIndex = () => {
    const index = listStartTime.findIndex((time) => time - epsilon > currentTime);
    if (index === -1) return listStartTime.length - 1;
    return index === 0 ? 0 : index - 1;
  };

  const reset = () => {
    setPlaying(false);
    sceneIdx.current = 0;
    backgroundSoundRef.current.currentTime = 0;
    backgroundSoundRef.current.pause();
    setCurrentTime(0);
    setListPlayingScene(listScenePreview.length ? listScenePreview.slice(0, 1) : []);
    if (timerIDRef.current) {
      clearInterval(timerIDRef.current);
    }
  };

  const onSceneEnd = useCallback(
    (id) => {
      setListPlayingScene((prev) => prev.filter((scene) => scene._id !== id));
    },
    [listPlayingScene]
  );

  const togglePlay = () => {
    if (!playing) {
      timerIDRef.current = setInterval(() => setCurrentTime((old) => old + 0.25), 250);
    } else {
      clearInterval(timerIDRef.current);
    }
    setPlaying(!playing);
  };

  const handleSliderChange = (e, newValue) => {
    setCurrentTime(newValue);
    backgroundSoundRef.current.currentTime = newValue;
    setTimeTravel(true);
  };

  const handleChangeVolume = useCallback((val) => {
    setVolume(val);
    backgroundSoundRef.current.volume = val;
  }, []);

  const logoSXProps = useMemo(() => {
    const logoProperty = video.logo;
    if (!logoProperty) return {};
    const props = {};

    switch (logoProperty.position) {
      case LOGO_POSITION.TOP_LEFT: {
        props.top = LOGO_DISTANCE;
        props.left = LOGO_DISTANCE;
        break;
      }
      case LOGO_POSITION.TOP_RIGHT: {
        props.top = LOGO_DISTANCE;
        props.right = LOGO_DISTANCE;
        break;
      }
      case LOGO_POSITION.BOTTOM_LEFT: {
        props.bottom = LOGO_DISTANCE;
        props.left = LOGO_DISTANCE;
        break;
      }
      case LOGO_POSITION.BOTTOM_RIGHT: {
        props.bottom = LOGO_DISTANCE;
        props.right = LOGO_DISTANCE;
        break;
      }
      default:
    }
    props.width = logoProperty.size * dimensionsProps.width;
    props.height = props.width / intrinsicRatio;
    props.opacity = logoProperty.opacity;
    return props;
  }, [video, intrinsicRatio, dimensionsProps]);

  useEffect(() => {
    if (currentTime >= totalTime && playing) {
      setTimeout(reset, 100);
    } else {
      const newSceneIdx = getCurrentSceneIndex();
      if (newSceneIdx !== sceneIdx.current || !listPlayingScene.length) {
        setListPlayingScene(
          listScenePreview.reduce(
            (prev, cur) =>
              cur.startTime - epsilon <= currentTime && currentTime <= cur.endTime ? [...prev, cur] : prev,
            []
          )
        );
        sceneIdx.current = newSceneIdx;
      }
    }
  }, [currentTime, playing]);

  useEffect(() => {
    backgroundSoundRef.current.src = video.background_sound?.url;
    backgroundSoundRef.current.load();
    backgroundSoundRef.current.loop = true;
    backgroundSoundRef.current.volume = (video.background_volume || 0) / 100;
    const [transitionClass, bgColor = 'transparent'] = getTransitionEffectProps(video.transition);
    setTransitionEffectClass(transitionClass);
    setBackgroundColor(bgColor);
  }, [video]);

  useEffect(() => {
    if (playing) backgroundSoundRef.current.play();
    else backgroundSoundRef.current.pause();
  }, [playing]);

  useEffect(() => {
    if (!isShowing) {
      reset();
    }
  }, [isShowing]);

  useEffect(() => {
    setTimeTravel(false);
  }, [timeTravel]);

  return {
    t,
    mediaWrapperRef,
    dimensionsProps,
    listPlayingScene,
    onSceneEnd,
    currentTime,
    togglePlay,
    playing,
    totalTime,
    handleSliderChange,
    timeTravel,
    minimizedControlBar,
    setMinimizedControlBar,
    volume,
    handleChangeVolume,
    getLogoComponent,
    logoSXProps,
    transitionEffectClass,
    backgroundColor,
    sceneVolume
  };
};
