/* eslint-disable prefer-destructuring */
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import { hotUpdateSceneRequest } from 'containers/EditVideo/actions';
import React, { useState } from 'react';
import { updateData } from 'utils/lottieJson';
import { SketchPicker } from 'react-color';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getSelectingStatus, getSelectingSceneTextEffect } from '../../selectors';
import { getAnimation } from '../TextTab';
import { useStyles } from './styles';

TextToolbar.propTypes = {};
export const listFont = [
  {
    label: 'Georgia, serif',
    value: 'Georgia, serif'
  },
  {
    label: 'Gill Sans, sans-serif',
    value: '"Gill Sans", sans-serif'
  },
  {
    label: 'Cursive',
    value: 'cursive, cursive'
  },
  {
    label: 'GoogleSans-Black',
    value: 'GoogleSans-Black, GoogleSans-Black'
  },
  {
    label: 'GoogleSans-BlackItalic',
    value: 'GoogleSans-BlackItalic, GoogleSans-BlackItalic'
  },
  {
    label: 'GoogleSans-Bold',
    value: 'GoogleSans-Bold, GoogleSans-Bold'
  },
  {
    label: 'GoogleSans-BoldItalic',
    value: 'GoogleSans-BoldItalic, GoogleSans-BoldItalic'
  },
  {
    label: 'GoogleSans-Italic',
    value: 'GoogleSans-Italic, GoogleSans-Italic'
  },
  {
    label: 'GoogleSans-Light',
    value: 'GoogleSans-Light, GoogleSans-Light'
  },
  {
    label: 'GoogleSans-LightItalic',
    value: 'GoogleSans-LightItalic, GoogleSans-LightItalic'
  },
  {
    label: 'GoogleSans-Medium',
    value: 'GoogleSans-Medium, GoogleSans-Medium'
  },
  {
    label: 'GoogleSans-MediumItalic',
    value: 'GoogleSans-MediumItalic, GoogleSans-MediumItalic'
  },
  {
    label: 'GoogleSans-Regular',
    value: 'GoogleSans-Regular, GoogleSans-Regular'
  },
  {
    label: 'GoogleSans-Thin',
    value: 'GoogleSans-Thin, GoogleSans-Thin'
  },
  {
    label: 'GoogleSans-ThinItalic',
    value: 'GoogleSans-ThinItalic, GoogleSans-ThinItalic'
  },
  {
    label: 'Gotham-Medium',
    value: 'Gotham-Medium, Gotham-Medium'
  },
  {
    label: 'Gotham-Thin',
    value: 'Gotham-Thin, Gotham-Thin'
  }
];
function TextToolbar() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { id: videoID } = useParams();
  const textEffect = useSelector(getSelectingSceneTextEffect());
  const { sceneID } = useSelector(getSelectingStatus());
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [currentPopup, setCurrentPopup] = useState('');
  const handleClick = (event, name) => {
    setCurrentPopup(name);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setCurrentPopup('');
  };
  const handleChangeProperty = (key, value) => {
    const animation = getAnimation(textEffect.type);
    const lottieJson = textEffect?.json ? JSON.parse(textEffect.json) : getAnimation(textEffect?.type).defaultJson;
    updateData(lottieJson, animation.textLayer, { [key]: value });
    dispatch(
      hotUpdateSceneRequest({
        videoID,
        sceneID,
        data: { text_effect: { ...textEffect, json: JSON.stringify(lottieJson), [key]: value } }
      })
    );
  };
  return (
    <Box className={classes.textToolbar}>
      <Button variant="text" endIcon={<ArrowDropDownIcon />} onClick={(e) => handleClick(e, 'font')}>
        Tên font
      </Button>
      <Menu
        open={open && currentPopup === 'font'}
        onClose={handleClose}
        anchorEl={anchorEl}
        PaperProps={{
          sx: { marginTop: '4px', maxWidth: 540, maxHeight: 540 }
        }}
      >
        {listFont.map(({ label, value }) => (
          <MenuItem
            key={value}
            sx={{ fontFamily: value, color: value !== textEffect?.font ? 'inherit' : 'blue' }}
            onClick={() => {
              handleChangeProperty('font', value);
              handleClose();
            }}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
      <Button variant="text" endIcon={<ArrowDropDownIcon />} onClick={(e) => handleClick(e, 'size')}>
        Cỡ chữ
      </Button>
      <Menu
        open={open && currentPopup === 'size'}
        onClose={handleClose}
        anchorEl={anchorEl}
        PaperProps={{
          sx: { marginTop: '4px', maxWidth: 250, maxHeight: 250 }
        }}
      >
        {[
          ...Array(21)
            .fill(1)
            .map((_, i) => 50 + i * 10)
        ].map((value) => (
          <MenuItem
            key={value}
            sx={{ color: value !== textEffect?.size ? 'inherit' : 'blue' }}
            onClick={() => {
              handleChangeProperty('size', value);
              handleClose();
            }}
          >
            {value}
          </MenuItem>
        ))}
      </Menu>
      <Button variant="text" endIcon={<ArrowDropDownIcon />} onClick={(e) => handleClick(e, 'color')}>
        Màu chữ
      </Button>
      <Menu
        open={open && currentPopup === 'color'}
        onClose={handleClose}
        anchorEl={anchorEl}
        PaperProps={{
          sx: {
            '& .MuiList-root': {
              padding: '0 !important'
            },
            marginTop: '4px',
            maxWidth: 540,
            maxHeight: 540
          }
        }}
      >
        <SketchPicker
          disableAlpha
          color={textEffect?.color || '#0000'}
          onChange={(color) => {
            handleChangeProperty('color', color.rgb);
          }}
        />
      </Menu>
    </Box>
  );
}

export default TextToolbar;
