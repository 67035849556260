import React, { forwardRef, useImperativeHandle, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { useModal } from '../../../../hooks/useModal';
import { changePermission, fetchGroupMembers } from '../../actions';
import { useNotify } from '../../../../hooks/useNotify';
import { GROUP_MODAL, GROUP_MEMBER_PERMISSION } from '../../constants';
import { toAbsoluteUrl } from 'utils/formatUrl';

const { Option } = Select;

const GroupPermissionModal = forwardRef(({ groupId }, ref) => {
  const { openModal, closeModal, isShowing, data } = useModal(GROUP_MODAL.CHANGE_MEMBER_PERMISSION);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { successNotify, errorNotify } = useNotify();
  const [permission, setPermission] = useState(GROUP_MEMBER_PERMISSION.EDITOR);

  const onChangePermission = async () => {
    try {
      const payload = {
        user_id: data._id,
        permission
      };
      await dispatch(changePermission(groupId, payload));
      successNotify(t('group.member.changePermissionSuccess'));
      dispatch(fetchGroupMembers(groupId));
      closeModal();
    } catch (e) {
      errorNotify(t('group.member.changePermissionFailure'));
    }
  };

  useImperativeHandle(ref, () => ({
    onOpen: openModal
  }));

  const onSelectPermission = (value) => {
    setPermission(value);
  };

  return (
    <Dialog fullWidth open={isShowing} onClose={() => closeModal()} style={{ zIndex: 50 }}>
      <div className="modal-change-permission d-flex flex-direct-column">
        <div className="d-flex text-2 mb-30 justify-content-center">Thay đổi quyền</div>
        <Select
          value={permission}
          onChange={onSelectPermission}
          style={{ width: '100%' }}
          suffixIcon={<img src={toAbsoluteUrl('/icons/select.svg')} alt="select" />}
        >
          <Option value="admin">Quản lý</Option>
          <Option value="editor">Biên tập viên</Option>
        </Select>
        <div className="d-flex justify-content-center mt-30">
          <button type="button" className="button" onClick={onChangePermission}>
            Thay đổi
          </button>
        </div>
      </div>
    </Dialog>
  );
});

GroupPermissionModal.propTypes = {
  groupId: PropTypes.string
};

export default GroupPermissionModal;
