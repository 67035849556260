export const defaultJson = {
  v: '5.9.2',
  fr: 25,
  ip: 0,
  op: 25,
  w: 911,
  h: 859,
  nm: '2-splash',
  ddd: 0,
  assets: [],
  fonts: {
    list: [
      {
        origin: 0,
        fPath: '',
        fClass: '',
        fFamily: 'Arial',
        fWeight: '',
        fStyle: 'Bold',
        fName: 'Arial-BoldMT',
        ascent: 75.6576929409057
      }
    ]
  },
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 5,
      nm: 'text',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 3, s: [0] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 5, s: [13.333] },
            { t: 18, s: [100] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 0,
          k: [188, -96, 0],
          ix: 2,
          l: 2,
          x: 'var $bm_rt;\nvar H, W;\nH = thisComp.height;\nW = thisComp.width;\n$bm_rt = [\n    W / 2,\n    H / 2\n];'
        },
        a: {
          a: 0,
          k: [0, 0, 0],
          ix: 1,
          l: 2,
          x: 'var $bm_rt;\nvar sourceSize, T, L, W, H;\nsourceSize = thisLayer.sourceRectAtTime(time, false);\nT = sourceSize.top;\nL = sourceSize.left;\nW = sourceSize.width;\nH = sourceSize.height;\n$bm_rt = [\n    L + W / 2,\n    T + H / 2\n];'
        },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 3,
              s: [100, 100, 100]
            },
            { t: 18, s: [100, 100, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      t: {
        d: {
          k: [
            {
              s: {
                sz: [827, 647.000061035156],
                ps: [0, -261.421569824219],
                s: 120,
                f: 'Arial-BoldMT',
                t: 'HELLO',
                ca: 1,
                j: 0,
                tr: -1,
                lh: 144,
                ls: 0,
                fc: [0.228, 0.04, 0.603],
                sc: [0, 0, 0],
                sw: 0.00999999977648,
                of: false
              },
              t: 0
            }
          ]
        },
        p: {},
        m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
        a: [
          {
            nm: 'Animator 1',
            s: {
              t: 0,
              xe: { a: 0, k: 0, ix: 7 },
              ne: { a: 0, k: 0, ix: 8 },
              a: { a: 0, k: 100, ix: 4 },
              b: 1,
              rn: 0,
              sh: 1,
              sm: { a: 0, k: 100, ix: 6 },
              r: 1
            },
            a: { s: { a: 0, k: [100, 100, 100], ix: 3 } }
          }
        ]
      },
      ip: -7,
      op: 43,
      st: -7,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Shape Layer 3',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [0] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 5, s: [100] },
            { t: 8, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [886.749, 8.142, 0],
              to: [-58.333, 7, 0],
              ti: [88.744, -47.922, 0]
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 4,
              s: [536.749, 50.142, 0],
              to: [-88.744, 47.922, 0],
              ti: [-38, -184.333, 0]
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 5,
              s: [354.286, 295.674, 0],
              to: [9.185, 44.554, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 8,
              s: [494.749, 380.142, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 15,
              s: [499.873, 380.142, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            { t: 22, s: [496.749, 380.142, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [-21.526, -167.349, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 4,
              s: [43, 43, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 5,
              s: [176, 176, 100]
            },
            { t: 8, s: [0, 0, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-10, -80],
                    [-16, 22],
                    [-34, 0]
                  ],
                  o: [
                    [0, 0],
                    [10, 80],
                    [16, -22],
                    [34, 0]
                  ],
                  v: [
                    [148.5, -171.5],
                    [56.5, -89.5],
                    [132.5, -57.5],
                    [176.5, -167.5]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.992156922583, 0.129411764706, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-1.515, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: -53.989, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Shape 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Shape Layer 4',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [455.5, 429.5, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: { a: 0, k: { i: [[0, 0]], o: [[0, 0]], v: [[-181.5, -147.5]], c: false }, ix: 2 },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.992156922583, 0.129411764706, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Shape 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'Layer 7 Outlines',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 5, s: [0] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 15, s: [60] },
            { t: 25, s: [100] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 5,
              s: [474.537, 419.308, 0],
              to: [10.333, 19, 0],
              ti: [0.667, -29.333, 0]
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 15,
              s: [536.537, 533.308, 0],
              to: [-0.667, 29.333, 0],
              ti: [11, -10.333, 0]
            },
            { t: 26, s: [470.537, 595.308, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [16.118, 13.618, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 5,
              s: [0, 0, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 15,
              s: [60, 60, 100]
            },
            { t: 25, s: [100, 100, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 8.764],
                    [-8.764, 0],
                    [0, -8.764],
                    [8.764, 0]
                  ],
                  o: [
                    [0, -8.764],
                    [8.764, 0],
                    [0, 8.764],
                    [-8.764, 0]
                  ],
                  v: [
                    [-15.868, 0],
                    [0, -15.868],
                    [15.868, 0],
                    [0, 15.868]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.992005591299, 0.129397358614, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [16.118, 16.118], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: 'Layer 6 Outlines',
      parent: 5,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [-17.155, -155.355, 0], ix: 2, l: 2 },
        a: { a: 0, k: [11.47, 11.471, 0], ix: 1, l: 2 },
        s: { a: 0, k: [108.696, 108.696, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 6.197],
                    [-6.197, 0],
                    [0, -6.196],
                    [6.197, 0]
                  ],
                  o: [
                    [0, -6.196],
                    [6.197, 0],
                    [0, 6.197],
                    [-6.197, 0]
                  ],
                  v: [
                    [-11.22, -0.001],
                    [0, -11.22],
                    [11.22, -0.001],
                    [0, 11.22]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.992005591299, 0.129397358614, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [11.47, 11.471], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: 'Layer 5 Outlines',
      parent: 5,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [81.963, -402.011, 0], ix: 2, l: 2 },
        a: { a: 0, k: [8.97, 8.97, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 4.816],
                    [-4.816, 0],
                    [0, -4.816],
                    [4.816, 0]
                  ],
                  o: [
                    [0, -4.816],
                    [4.816, 0],
                    [0, 4.816],
                    [-4.816, 0]
                  ],
                  v: [
                    [-8.72, -0.001],
                    [0, -8.72],
                    [8.72, -0.001],
                    [0, 8.72]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.992005591299, 0.129397358614, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [8.97, 8.971], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: 'Layer 4 Outlines',
      parent: 5,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [-74.113, -372.708, 0], ix: 2, l: 2 },
        a: { a: 0, k: [20.225, 22.321, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [12.33, 10.357],
                    [2.713, -6.659],
                    [-11.343, -3.453]
                  ],
                  o: [
                    [0, 0],
                    [-12.33, -10.358],
                    [-2.712, 6.658],
                    [11.344, 3.452]
                  ],
                  v: [
                    [12.577, 15.906],
                    [7.645, -11.713],
                    [-17.262, -6.534],
                    [-4.439, 18.619]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.992005591299, 0.129397358614, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [20.224, 22.321], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 9,
      ty: 4,
      nm: 'Layer 3 Outlines',
      parent: 5,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [-328.251, -132.155, 0], ix: 2, l: 2 },
        a: { a: 0, k: [143.262, 75.106, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-23.348, -27.073],
                    [69.222, -5.273],
                    [10.553, -10.554],
                    [26.364, 42.505],
                    [-25.334, 0.739],
                    [-16.517, 12.04]
                  ],
                  o: [
                    [0, 0],
                    [-69.222, 5.273],
                    [-10.554, 10.554],
                    [-26.364, -42.505],
                    [25.335, -0.739],
                    [16.516, -12.04]
                  ],
                  v: [
                    [95.26, -47.783],
                    [65.368, 1.786],
                    [-12.557, 18.221],
                    [-125.07, 32.351],
                    [-85.157, -45.686],
                    [9.557, -37.884]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.992005591299, 0.129397358614, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [152.684, 74.105], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 10,
      ty: 4,
      nm: 'Layer 2 Outlines',
      parent: 5,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [-146.617, -540.436, 0], ix: 2, l: 2 },
        a: { a: 0, k: [45.299, 53.597, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-20.548, 21.339],
                    [-24.5, -20.549],
                    [2.371, -7.113],
                    [27.661, 19.759]
                  ],
                  o: [
                    [0, 0],
                    [24.5, 20.549],
                    [-2.371, 7.113],
                    [-27.662, -19.757]
                  ],
                  v: [
                    [-24.5, -30.943],
                    [15.806, -38.845],
                    [42.677, 36.236],
                    [-11.854, 27.541]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.992005591299, 0.129397358614, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [45.298, 59.645], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 11,
      ty: 4,
      nm: 'Layer 8 Outlines',
      parent: 5,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [208.859, -125.878, 0], ix: 2, l: 2 },
        a: { a: 0, k: [11.47, 11.47, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 6.197],
                    [-6.197, 0],
                    [0, -6.196],
                    [6.197, 0]
                  ],
                  o: [
                    [0, -6.196],
                    [6.197, 0],
                    [0, 6.197],
                    [-6.197, 0]
                  ],
                  v: [
                    [-11.22, -0.001],
                    [0, -11.22],
                    [11.22, -0.001],
                    [0, 11.22]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.992005591299, 0.129397358614, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [11.47, 11.471], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 12,
      ty: 4,
      nm: 'Layer 9 Outlines',
      parent: 5,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [421.234, 16.14, 0], ix: 2, l: 2 },
        a: { a: 0, k: [34.629, 34.63, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 18.987],
                    [-18.987, 0],
                    [0, -18.987],
                    [18.987, 0]
                  ],
                  o: [
                    [0, -18.987],
                    [18.987, 0],
                    [0, 18.987],
                    [-18.987, 0]
                  ],
                  v: [
                    [-34.379, 0],
                    [0, -34.38],
                    [34.379, 0],
                    [0, 34.38]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.992005591299, 0.129397358614, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [34.629, 34.63], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 13,
      ty: 4,
      nm: 'Layer 10 Outlines',
      parent: 5,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [301.499, -465.17, 0], ix: 2, l: 2 },
        a: { a: 0, k: [94.299, 102.202, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [32.404, 2.371],
                    [-41.097, 47.42],
                    [0, 13.436],
                    [-44.258, -10.274],
                    [15.806, -16.597],
                    [2.877, -18.178]
                  ],
                  o: [
                    [0, 0],
                    [41.097, -47.42],
                    [0, -13.436],
                    [44.258, 10.275],
                    [-15.807, 16.596],
                    [-2.876, 18.177]
                  ],
                  v: [
                    [-40.307, 99.581],
                    [-52.952, 49],
                    [-13.436, -11.065],
                    [49.791, -91.678],
                    [74.291, -16.596],
                    [8.409, 38.727]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.992005591299, 0.129397358614, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [94.299, 102.202], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 14,
      ty: 4,
      nm: 'Layer 11 Outlines',
      parent: 5,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [24.094, 212.524, 0], ix: 2, l: 2 },
        a: { a: 0, k: [63.081, 68.416, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-4.741, -54.532],
                    [-22.524, 9.484],
                    [18.968, 35.565]
                  ],
                  o: [
                    [0, 0],
                    [4.742, 54.533],
                    [22.525, -9.483],
                    [-18.968, -35.565]
                  ],
                  v: [
                    [-3.557, -64.887],
                    [-58.09, 13.355],
                    [14.225, 60.775],
                    [43.863, -30.508]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.992005591299, 0.129397358614, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [63.081, 66.323], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};

export const textLayer = 0;
