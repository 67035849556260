export const defaultJson = {
  v: '5.9.2',
  fr: 25,
  ip: 0,
  op: 50,
  w: 1920,
  h: 1080,
  nm: '1-focus',
  ddd: 0,
  assets: [],
  fonts: {
    list: [
      {
        origin: 0,
        fPath: '',
        fClass: '',
        fFamily: 'Montserrat',
        fWeight: '',
        fStyle: 'ExtraBold',
        fName: 'Montserrat-ExtraBold',
        ascent: 69.5092065427452
      }
    ]
  },
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 5,
      nm: '<empty text layer>',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1248, -12, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      t: {
        d: {
          k: [
            {
              s: {
                s: 90,
                f: 'Montserrat-ExtraBold',
                t: '',
                ca: 1,
                j: 2,
                tr: 0,
                lh: 108.000007629395,
                ls: 0,
                fc: [1, 1, 1]
              },
              t: 0
            }
          ]
        },
        p: {},
        m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
        a: []
      },
      ip: 0,
      op: 50,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 5,
      nm: 'text',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 23, s: [0] },
            { t: 34, s: [100] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 0,
          k: [1109.08, 328, 0],
          ix: 2,
          l: 2,
          x: 'var $bm_rt;\nvar H, W;\nH = thisComp.height;\nW = thisComp.width;\n$bm_rt = [\n    W / 2,\n    H / 2\n];'
        },
        a: {
          a: 0,
          k: [-265.658, 69.898, 0],
          ix: 1,
          l: 2,
          x: 'var $bm_rt;\nvar sourceSize, T, L, W, H;\nsourceSize = thisLayer.sourceRectAtTime(time, false);\nT = sourceSize.top;\nL = sourceSize.left;\nW = sourceSize.width;\nH = sourceSize.height;\n$bm_rt = [\n    L + W / 2,\n    T + H / 2\n];'
        },
        s: { a: 0, k: [125.208, 125.208, 100], ix: 6, l: 2 }
      },
      ao: 0,
      t: {
        d: {
          k: [
            {
              s: {
                sz: [666.158935546875, 524.87646484375],
                ps: [-920.475280761719, -54.3093414306641],
                s: 90,
                f: 'Montserrat-ExtraBold',
                t: 'HELLO',
                ca: 1,
                j: 2,
                tr: 0,
                lh: 108.000007629395,
                ls: 0,
                fc: [1, 1, 1]
              },
              t: 0
            }
          ]
        },
        p: {},
        m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
        a: []
      },
      ip: 0,
      op: 50,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'roundleft 2',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [0] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 28, s: [61.25] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 30, s: [65.625] },
            { t: 32, s: [40] }
          ],
          ix: 11
        },
        r: { a: 0, k: 720, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [623, 1231, 0],
              to: [46.351, -103.679, 0],
              ti: [117.241, 326.601, 0]
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 28,
              s: [1023.633, 646.827, 0],
              to: [-5.841, -16.271, 0],
              ti: [8.889, 17.143, 0]
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 30,
              s: [1001.602, 596.714, 0],
              to: [-7.725, -14.899, 0],
              ti: [10.034, 15.58, 0]
            },
            { t: 32, s: [927, 551, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [-325, -25, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 0,
              s: [0, 0, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 28,
              s: [87.5, 87.5, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 30,
              s: [93.75, 93.75, 100]
            },
            { t: 32, s: [100, 100, 100] }
          ],
          ix: 6,
          l: 2,
          x: "var $bm_rt;\nvar h, w, s;\nh = thisComp.layer('text').sourceRectAtTime().height;\nw = thisComp.layer('text').sourceRectAtTime().width;\ns = h > w ? h : w;\n$bm_rt = $bm_div($bm_mul([\n    100,\n    100\n], s), 550);"
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [806, 806], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 0.4, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.45246591007, 0.144313722498, 0.541176470588, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-341, -25], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Ellipse 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'roundleft 3',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [0] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 28, s: [61.25] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 30, s: [65.625] },
            { t: 32, s: [40] }
          ],
          ix: 11
        },
        r: { a: 0, k: 720, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [-53, -1, 0],
              to: [569, -103, 0],
              ti: [15.616, -121.338, 0]
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 28,
              s: [965.37, 487.433, 0],
              to: [-27.427, 9.548, 0],
              ti: [20.545, -7.139, 0]
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 30,
              s: [1045.042, 532.59, 0],
              to: [-45.537, 15.824, 0],
              ti: [0, 0, 0]
            },
            { t: 32, s: [956.696, 521.701, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [-325, -25, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 0,
              s: [0, 0, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 28,
              s: [87.5, 87.5, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 30,
              s: [93.75, 93.75, 100]
            },
            { t: 32, s: [100, 100, 100] }
          ],
          ix: 6,
          l: 2,
          x: "var $bm_rt;\nvar h, w, s;\nh = thisComp.layer('text').sourceRectAtTime().height;\nw = thisComp.layer('text').sourceRectAtTime().width;\ns = h > w ? h : w;\n$bm_rt = $bm_div($bm_mul([\n    100,\n    100\n], s), 550);"
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [806, 806], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 0.4, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.45246591007, 0.144313722498, 0.541176470588, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-341, -25], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Ellipse 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'roundleft',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [0] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 28, s: [61.25] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 30, s: [65.625] },
            { t: 32, s: [40] }
          ],
          ix: 11
        },
        r: { a: 0, k: 720, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [2055, 115, 0],
              to: [-1007, -195, 0],
              ti: [15.616, -121.338, 0]
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 28,
              s: [1045.37, 475.433, 0],
              to: [-27.427, 9.548, 0],
              ti: [20.545, -7.139, 0]
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 30,
              s: [973.042, 500.59, 0],
              to: [-45.537, 15.824, 0],
              ti: [0, 0, 0]
            },
            { t: 32, s: [900.696, 525.701, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [-446, -37, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 0,
              s: [0, 0, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 28,
              s: [87.5, 87.5, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 30,
              s: [93.75, 93.75, 100]
            },
            { t: 32, s: [100, 100, 100] }
          ],
          ix: 6,
          l: 2,
          x: "var $bm_rt;\nvar h, w, s;\nh = thisComp.layer('text').sourceRectAtTime().height;\nw = thisComp.layer('text').sourceRectAtTime().width;\ns = h > w ? h : w;\n$bm_rt = $bm_div($bm_mul([\n    100,\n    100\n], s), 550);"
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [806, 806], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 0.4, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.45246591007, 0.144313722498, 0.541176470588, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-341, -25], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Ellipse 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};

export const textLayer = 1;
