import { Box, TextField, Typography } from '@mui/material';
import clsx from 'clsx';
import { hotUpdateSceneRequest } from 'containers/EditVideo/actions';
import { useDebounce } from 'hooks/useDebounce';
import _ from 'lodash';
import { Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { updateData } from 'utils/lottieJson';
import { getSelectingScene } from '../../selectors';
import * as reunionAnimation from '../../textEffectJson/reunion';
import * as scaleAnimation from '../../textEffectJson/scale';
import * as scaleFastAnimation from '../../textEffectJson/scaleFast';
import * as slideInAnimation from '../../textEffectJson/slideIn';
import * as styleSlideInAnimation from '../../textEffectJson/styleSlideIn';
import * as typewriterAnimation from '../../textEffectJson/typewriter';
import * as fadeAnimation from '../../textEffectJson/fade';
import * as trailAnimation from '../../textEffectJson/trail';
import * as candyAnimation from '../../textEffectJson/candy';
import * as stripesAnimation from '../../textEffectJson/stripes';
import * as circleAnimation from '../../textEffectJson/circle';
import * as lineAnimation from '../../textEffectJson/line';
import * as blowAnimation from '../../textEffectJson/blow';
import * as focusAnimation from '../../textEffectJson/focus';
import * as regAnimation from '../../textEffectJson/reg';
import * as doubleAnimation from '../../textEffectJson/double';
import * as blindsAnimation from '../../textEffectJson/blinds';
import * as burstAnimation from '../../textEffectJson/burst';
import * as bywordAnimation from '../../textEffectJson/byword';
import * as shutterAnimation from '../../textEffectJson/shutter';
import * as splashAnimation from '../../textEffectJson/splash';
import * as stereoAnimation from '../../textEffectJson/stereo';
import { listFont } from '../TextToolbar';
import { useStyles } from './styles';
import { toAbsoluteUrl } from 'utils/formatUrl';

TextTab.propTypes = {};

const animations = {
  scale: scaleAnimation,
  slideIn: slideInAnimation,
  scaleFast: scaleFastAnimation,
  reunion: reunionAnimation,
  styleSlideIn: styleSlideInAnimation,
  typewriter: typewriterAnimation,
  fade: fadeAnimation,
  trail: trailAnimation,
  candy: candyAnimation,
  stripes: stripesAnimation,
  circle: circleAnimation,
  line: lineAnimation,
  blow: blowAnimation,
  focus: focusAnimation,
  reg: regAnimation,
  double: doubleAnimation,
  blinds: blindsAnimation,
  burst: burstAnimation,
  byword: bywordAnimation,
  shutter: shutterAnimation,
  splash: splashAnimation,
  stereo: stereoAnimation
};

export const getAnimation = (type) => animations[type] || animations.scale;

const defaultTextEffect = {
  font: listFont[0].value,
  size: 90,
  color: { r: 0, g: 0, b: 0 }
};

function TextTab() {
  const classes = useStyles();

  const { t } = useTranslation();
  const { id: videoID } = useParams();
  const dispatch = useDispatch();
  const selectingScene = useSelector(getSelectingScene());
  const [text, setText] = useState(selectingScene?.text_effect?.text);
  const debouncedText = useDebounce(text, 500);

  const onClickChangeEffect = (type) => {
    const newAnimation = getAnimation(type);
    const json = newAnimation.defaultJson;
    const values = _(selectingScene.text_effect)
      .pick(['text', 'font', 'size', 'color'])
      .defaults({ text: 'HELLO' }, { font: listFont[0].value }, { size: 90 }, { color: { r: 0, g: 0, b: 0 } })
      .value();
    updateData(json, newAnimation.textLayer, { ...values, text: values.text || 'HELLO' });
    const data = {
      text_effect: {
        ...defaultTextEffect,
        ...selectingScene.text_effect,
        text: selectingScene.text_effect?.text || 'HELLO',
        type,
        json: JSON.stringify(json)
      }
    };

    dispatch(
      hotUpdateSceneRequest({
        videoID,
        sceneID: selectingScene._id,
        data
      })
    );
  };

  useEffect(() => {
    if (selectingScene) {
      setText(selectingScene.text_effect?.text || '');
    }
  }, [selectingScene]);

  useEffect(() => {
    if (
      selectingScene &&
      (selectingScene.text_effect?.text || debouncedText) &&
      debouncedText !== selectingScene.text_effect?.text
    ) {
      let data;
      if (!debouncedText) {
        data = {
          text_effect: {
            ...defaultTextEffect,
            ...selectingScene.text_effect,
            json: '',
            text: ''
          }
        };
      } else {
        const animation = getAnimation(selectingScene.text_effect?.type);
        let newTextEffect;
        if (!selectingScene.text_effect?.json) {
          newTextEffect = animation.defaultJson;
        } else {
          newTextEffect = JSON.parse(selectingScene.text_effect.json);
        }

        updateData(newTextEffect, animation.textLayer, { text: debouncedText });
        data = {
          text_effect: {
            ...defaultTextEffect,
            ...selectingScene.text_effect,
            json: JSON.stringify(newTextEffect),
            text: debouncedText
          }
        };
      }

      dispatch(
        hotUpdateSceneRequest({
          videoID,
          sceneID: selectingScene._id,
          data
        })
      );
    }
  }, [debouncedText]);

  const onShowImage = (index) => (index < 17 ? index % 17 : 0);

  return (
    <Box className={classes.textTabWrapper}>
      <Box className={classes.textEdit}>
        <Typography className="title">{t('editVideo.textTab.content')}</Typography>
        <TextField
          className="textField"
          multiline
          minRows={4}
          maxRows={4}
          fullWidth
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      </Box>
      <Box className={classes.divider} />
      <Box>
        <Typography className="title">{t('editVideo.textTab.textEffect')}</Typography>
      </Box>
      <Box className={classes.textEffect}>
        {Object.keys(animations).map((type, idx) => (
          <Box
            className={clsx(
              ((!selectingScene?.text_effect?.type && type === 'scale') ||
                selectingScene?.text_effect?.type === type) &&
                classes.active
            )}
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
            key={type}
          >
            <Box className={classes.gifItem}>
              <img
                src={`${toAbsoluteUrl(`/static/${type}.gif`)}`}
                alt="animation-gif"
                width="120px"
                height="70px"
                className="gif"
                style={{ objectFit: 'cover' }}
              />
            </Box>
            <Tooltip title={t(`editVideo.textTab.${type}`)}>
              <Box className={classes.thumbnailItem} onClick={() => onClickChangeEffect(type)}>
                <img
                  src={toAbsoluteUrl(`/static/${onShowImage(idx + 1)}.png`)}
                  alt=""
                  width="120px"
                  height="70px"
                  className="png"
                  style={{ objectFit: 'cover' }}
                />
              </Box>
            </Tooltip>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default TextTab;
