import React, { useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useNotify } from '../../../hooks/useNotify';
import { createMarkup, formatMoney } from '../../../utils/common';
import * as Selectors from '../../App/selectors';
import { addOrder } from '../../Order/actions';
import { fetchPackages } from '../../Package/actions';
import moment from 'moment';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { sortBy } from 'lodash';

PackageDetail.propTypes = {
  member: PropTypes.object,
  group: PropTypes.object,
  permission: PropTypes.string
};

export default function PackageDetail({ member, group, permission }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(Selectors.getUser());
  const { t } = useTranslation();
  const { errorNotify } = useNotify();
  const { packages } = useSelector((state) => state.package);
  const [packageActive, setPackageActive] = useState(packages[0]);
  const [updatePackage, setUpdatePackage] = useState(false);
  const [showPackageDesc, setShowPackageDesc] = useState(false);
  const packageRef = useRef();

  useEffect(() => {
    dispatch(fetchPackages());
  }, []);

  const onCreateOrder = async () => {
    const payload = {
      grantee: group._id,
      sale_package_id: packageActive._id,
      user_id: user._id,
      qty: 1
    };
    try {
      const order = await dispatch(addOrder(payload));
      navigate(`/order/${order._id}`);
    } catch (e) {
      errorNotify(t('commons.error'));
    }
  };

  const isActivePackage = (packageItem) => {
    if (packageItem && packageActive && packageItem._id === packageActive._id) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (updatePackage) {
      setTimeout(() => {
        packageRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 0);
    }
  }, [updatePackage]);

  return (
    <>
      <div className="d-flex justify-content-center">
        <div className="group-detail-info d-flex flex-direct-column mt-4 margin-horizontal-9">
          <div className="text-primary-2 mb-20 current-package">Thông tin nhóm</div>
          <div className="d-flex justify-content-between mb-20 mt-5">
            <div>Vai trò của tôi</div>
            <div className="fw-700">{t(`permission.${permission}`)}</div>
          </div>
          <div className="d-flex justify-content-between mb-20">
            <div>Dự án của tôi</div>
            <div className="fw-700">{member?.statistic?.videos_count}</div>
          </div>
          <div className="d-flex justify-content-between mb-20">
            <div>Gói hiện tại</div>
            <div className="fw-700">
              {group?.balance?.plan?.name}
              <span className="update-package" onClick={() => setUpdatePackage(true)}>
                {' '}
                (Nâng cấp)
              </span>
            </div>
          </div>
          <div className="d-flex justify-content-between mb-20">
            <div>Thành viên</div>
            <div className="fw-700">{group?.statistic?.member_count}</div>
          </div>
          <div className="d-flex justify-content-between mb-20">
            <div>Thời gian hết hạn</div>
            <div className="fw-700">{moment.unix(group?.balance?.time_expire).format('DD/MM/YYYY')}</div>
          </div>
          <div className="d-flex flex-column justify-content-between mb-20">
            <div className="cursor-pointer" onClick={() => setShowPackageDesc(!showPackageDesc)}>
              Xem chi tiết gói hiện tại {showPackageDesc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </div>
            {showPackageDesc && (
              <div
                className="mt-2 px-3 package-desc-detail"
                dangerouslySetInnerHTML={{ __html: group?.balance?.plan?.package?.description }}
              ></div>
            )}
          </div>
        </div>
      </div>
      {updatePackage && (
        <>
          <div className="d-flex flex-direct-column mb-70">
            <div className="d-flex justify-content-center package" ref={packageRef}>
              Nâng cấp gói dịch vụ
            </div>
            <div className="list-package d-flex">
              {sortBy(packages, ['position']).map((packageItem) => {
                return (
                  <div
                    key={packageItem._id}
                    className={`package-detail ${clsx({ 'is-active': isActivePackage(packageItem) })}`}
                    onClick={() => {
                      setPackageActive(packageItem);
                    }}
                  >
                    {packageItem._id === group?.balance?.plan?._id && (
                      <div className="package-ribbon">Gói hiên tại</div>
                    )}
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="package-title">{packageItem.name}</span>
                    </div>
                    <div className="price-month">Giá: {formatMoney(packageItem.price)} VNĐ</div>
                    <div
                      className="packageDesc"
                      dangerouslySetInnerHTML={createMarkup(packageItem.package?.description)}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          {packageActive?._id && (
            <div className="d-flex justify-content-center">
              <button type="button" className="button button-pay-package" aria-label="Save" onClick={onCreateOrder}>
                Tiếp tục
              </button>
            </div>
          )}
        </>
      )}
    </>
  );
}
