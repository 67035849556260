export const defaultJson = {
  v: '5.9.2',
  fr: 25,
  ip: 0,
  op: 50,
  w: 1920,
  h: 1080,
  nm: '06-Trail',
  ddd: 0,
  assets: [],
  fonts: {
    list: [
      {
        origin: 0,
        fPath: '',
        fClass: '',
        fFamily: 'Montserrat',
        fWeight: '',
        fStyle: 'ExtraBold',
        fName: 'Montserrat-ExtraBold',
        ascent: 70.9991455078125
      }
    ]
  },
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Shape Layer 1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 0,
              s: [2455, 298, 0],
              to: [-323, 0.333, 0],
              ti: [323, -0.333, 0]
            },
            { t: 22, s: [517, 300, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [-470.5, 1.5, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 20,
              s: [100, 100, 100]
            },
            { t: 33, s: [9, 100, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: { a: 0, k: [886, 208.848], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              r: { a: 0, k: 0, ix: 4 },
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.243137000589, 0.278430983599, 0.60784295774, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.183006525975, 0.265897623698, 0.686274509804, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-27.5, 1.5], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Rectangle 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 5,
      nm: 'Hello',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 20, s: [0] },
            { t: 39, s: [100] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [960, 299.25, 0], ix: 2, l: 2 },
        a: { a: 0, k: [2.791, -54.25, 0], ix: 1, l: 2 },
        s: { a: 0, k: [110, 110, 100], ix: 6, l: 2 }
      },
      ao: 0,
      t: {
        d: {
          k: [
            {
              s: {
                sz: [1349, 175],
                ps: [-674.5, -115.008323669434],
                s: 155,
                f: 'Montserrat-ExtraBold',
                t: 'HELLO',
                ca: 1,
                j: 2,
                tr: 0,
                lh: 186,
                ls: 0,
                fc: [0, 0, 0]
              },
              t: 0
            }
          ]
        },
        p: {},
        m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
        a: []
      },
      ip: 0,
      op: 50,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};

export const textLayer = 1;
