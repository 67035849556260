import { makeStyles } from '@mui/styles';
import { TRANSITION_EFFECT_DURATION } from './hook';

export const useStyles = makeStyles({
  loadingWrapper: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  videoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  },
  video: {
    cursor: 'pointer',
    borderRadius: '5px',
    position: 'relative',
    overflow: 'hidden'
  },
  sceneWrapper: {
    backgroundColor: 'transparent',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%'
  },

  scene: {
    position: 'relative',
    width: '100%',
    height: '100%',
    backgroundColor: 'gray',
    '& video, & img': {
      objectFit: 'cover',
      borderRadius: '5px',
      width: '100%',
      height: '100%'
    },
    '& .media': {
      height: '100%',
      width: '100%'
    },
    '& .text': {
      position: 'absolute',
      width: '100%',
      height: '100%'
    },
    '& .avatar': {
      '& video': {
        borderRadius: 0
      }
    }
  },

  videoControlBar: {
    cursor: 'auto',
    zIndex: 1,
    position: 'absolute',
    bottom: '0',
    left: '0',
    width: '100%',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    transition: 'transform .15s ease-in-out',
    backgroundColor: 'rgba(23,28,48,.5)',
    padding: '8px 16px 0px 16px',
    '& .control': {
      color: 'white',
      fontSize: '14px',
      '& svg': {
        color: 'white',
        fontSize: '22px'
      },
      '& button': {
        padding: 0
      },
      '&:first-child': {
        marginLeft: '-18px'
      },
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  },
  videoControlBarHide: {
    transform: 'translateY(100%)'
  },
  videoProgressBarMinimize: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    left: 0
  },
  videoSlider: {
    padding: '10px 0px',
    '& .MuiSlider-rail': {
      color: 'rgba(255, 255, 255, 0.7);'
    },
    '& .MuiSlider-track': {
      color: 'white'
    },
    '& .MuiSlider-thumb': {
      color: 'white',
      width: '10px',
      height: '10px',
      '&:hover': {
        boxShadow: '0px 0px 0px 4px rgb(25 118 210 / 16%)'
      }
    }
  },
  fadeIn: {
    animation: `$fadeIn ${TRANSITION_EFFECT_DURATION}s ease-in-out`
  },

  fadeOutHalf: {
    animation: `$fadeOutHalf ${TRANSITION_EFFECT_DURATION}s ease-in-out`
  },

  fadeInHalf: {
    animation: `$fadeInHalf ${TRANSITION_EFFECT_DURATION}s ease-in-out`
  },

  wipeInLeft: {
    animation: `$wipeInLeft ${TRANSITION_EFFECT_DURATION}s ease-in-out`
  },

  wipeInRight: {
    animation: `$wipeInRight ${TRANSITION_EFFECT_DURATION}s ease-in-out`
  },

  wipeOutLeft: {
    animation: `$wipeOutLeft ${TRANSITION_EFFECT_DURATION}s ease-in-out`
  },

  wipeOutRight: {
    animation: `$wipeOutRight ${TRANSITION_EFFECT_DURATION}s ease-in-out`
  },

  '@keyframes fadeIn': {
    '0%': { opacity: 0, backgroundColor: 'transparent' },
    '100%': { opacity: 1, backgroundColor: 'gray' }
  },
  '@keyframes fadeOutHalf': {
    '0%': { opacity: 1 },
    '50%': { opacity: 0 },
    '100%': { opacity: 0 }
  },
  '@keyframes fadeInHalf': {
    '0%': { opacity: 0 },
    '50%': { opacity: 0 },
    '100%': { opacity: 1 }
  },
  '@keyframes wipeInLeft': {
    '0%': { transform: 'translateX(100%)' },
    '100%': { transform: 'translateX(0)' }
  },
  '@keyframes wipeInRight': {
    '0%': { transform: 'translateX(-100%)' },
    '100%': { transform: 'translateX(0)' }
  },
  '@keyframes wipeOutLeft': {
    '0%': { transform: 'translateX(0)' },
    '100%': { transform: 'translateX(-100%)' }
  },
  '@keyframes wipeOutRight': {
    '0%': { transform: 'translateX(0)' },
    '100%': { transform: 'translateX(100%)' }
  }
});
