import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as ModalActions from 'containers/App/actions';
import * as ModalSelectors from 'containers/App/selectors';

export const useModal = (name) => {
  const dispatch = useDispatch();
  const status = useSelector(ModalSelectors.getModalStatus());
  const currentModal = useSelector(ModalSelectors.getModalName());
  const rawData = useSelector(ModalSelectors.getModalData());

  const openModal = useCallback(
    (modalName, _data) => {
      dispatch(ModalActions.modalShow(modalName, _data));
    },
    [dispatch]
  );

  const closeModal = useCallback(
    (modalName) => {
      dispatch(ModalActions.modalClose(modalName));
    },
    [dispatch]
  );

  const refreshModal = useCallback(() => {
    dispatch(ModalActions.modalRefresh());
  }, []);

  const isShowing = useMemo(() => status && currentModal === name, [status, name, currentModal]);

  const data = useMemo(() => (isShowing ? rawData : null), [isShowing, rawData]);

  return {
    data,
    isShowing,
    openModal,
    closeModal,
    refreshModal
  };
};
