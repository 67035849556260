import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchDebounce } from '../../../hooks/useSearchDebounce';
import { fetchGroupMembers } from '../actions';
import Input from '../../../components/Common/Input';

export default function GroupDetailMemberFilter() {
  const param = useParams();
  const dispatch = useDispatch();
  const { group } = useSelector((state) => state.group);
  const [name, setName] = useSearchDebounce();

  useEffect(() => {
    if (name) {
      dispatch(fetchGroupMembers(group._id, { name }));
    } else {
      dispatch(fetchGroupMembers(group._id));
    }
  }, [name, group._id, dispatch]);

  return (
    <div className="d-flex">
      <Input placeholder="Tìm kiếm thành viên" onChange={(e) => setName(e.target.value)} />
    </div>
  );
}
