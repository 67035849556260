export const defaultJson = {
  v: '5.9.2',
  fr: 29.9700012207031,
  ip: 0,
  op: 122.000004969162,
  w: 1920,
  h: 1080,
  nm: 'Comp 1',
  ddd: 0,
  assets: [],
  fonts: {
    list: [
      {
        origin: 0,
        fPath: '',
        fClass: '',
        fFamily: 'Times New Roman',
        fWeight: '',
        fStyle: 'Regular',
        fName: 'TimesNewRomanPSMT',
        ascent: 69.5092065427452
      }
    ]
  },
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 5,
      nm: 'The quick brown fox jumps over the lazy dog',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [-1192.818, 580.364, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            { t: 119.000004846969, s: [341.936, 570.859, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      t: {
        d: {
          k: [
            {
              s: {
                s: 90,
                f: 'TimesNewRomanPSMT',
                t: 'Nguyen thai son',
                ca: 1,
                j: 0,
                tr: -30,
                lh: 70,
                ls: 0,
                fc: [1, 1, 1],
                sc: [0, 0, 0],
                sw: 0.00999999977648,
                of: false
              },
              t: 0
            }
          ]
        },
        p: {},
        m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
        a: []
      },
      ip: 0,
      op: 125.000005091354,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};

export const textLayer = 0;
