export const TAB = {
  GROUP: 'GROUP',
  MEMBER: 'MEMBER',
  MEMBER_INVITE: 'MEMBER_INVITE',
  VIDEO: 'VIDEO',
  PACKAGE: 'PACKAGE'
};

export const GROUP_MEMBER_PERMISSION = {
  SUPPER_ADMIN: 'supper_admin',
  ADMIN: 'admin',
  EDITOR: 'editor'
};

export const GROUP_MEMBER_STATUS = {
  MEMBER_STATUS_JOINED: 'joined',
  MEMBER_STATUS_DELETED: 'deleted',
  MEMBER_STATUS_LEAVE: 'leave',
  MEMBER_STATUS_INVITED: 'invited'
};

export const GROUP_MODAL = {
  ADD_MEMBER: 'add',
  REMOVE_MEMBER: 'remove',
  CHANGE_MEMBER_PERMISSION: 'permission',
  REMOVE_VIDEO: 'video',
  ENTRUST_VIDEO: 'entrust'
};

export const DEFAULT_LIST_GROUP_PARAMS = {
  is_paginate: 1,
  page: 1,
  items_per_page: 10
};
