// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: 'AIzaSyCW7avkiYCDbwCABwEz8y5BUCuRuABSg1Y',
  authDomain: 'dlow-d81a1.firebaseapp.com',
  projectId: 'dlow-d81a1',
  storageBucket: 'dlow-d81a1.appspot.com',
  messagingSenderId: '820658055745',
  appId: '1:820658055745:web:a191e0e0bbedbeab6c34c0',
  measurementId: 'G-42Y16W90GY'
};
