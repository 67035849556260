import { createSelector } from 'reselect';
import { FORMATS } from './constants';
import { INITIAL_STATE } from './reducer';

const selectState = (state) => state.editVideo || INITIAL_STATE;
const selectScenes = (state) => state.editVideo.video?.scenes || [];
const selectFolder = (state) => state.editVideo.video.folders || [];
const selectSelectingSceneID = (state) => state.editVideo.selectingStatus.sceneID;
const selectSelectingFolderID = (state) => state.editVideo.selectingStatus.folderID;
export const getLoading = () => createSelector(selectState, (state) => state.loading);

export const getError = () => createSelector(selectState, (state) => state.error);

export const getVideo = () => createSelector(selectState, (state) => state.video);

export const getFormat = () => createSelector(selectState, (state) => state.video?.format || FORMATS.LANDSCAPE);

export const getMedia = () => createSelector(selectState, (state) => state.video?.media || []);

export const getListScene = () =>
  createSelector(selectState, (state) =>
    state.video?.scenes?.map((scene) => {
      scene.count = 0;
      return scene;
    })
  );

export const getMediaUploadStatus = () => createSelector(selectState, (state) => state.mediaUploadStatus || {});
export const getSoundUploadStatus = () => createSelector(selectState, (state) => state.uploadSoundStatus || {});
export const getCreateSceneStatus = () => createSelector(selectState, (state) => state.createSceneStatus || {});

export const getSelectingStatus = () => createSelector(selectState, (state) => state.selectingStatus || {});

export const getSelectingScene = () =>
  createSelector(selectScenes, selectSelectingSceneID, (scenes, id) => scenes.find((el) => el._id === id) || null);
export const getSelectingFolder = () =>
  createSelector(selectFolder, selectSelectingFolderID, (folders, id) => folders.find((el) => el._id === id) || {});
export const getVideoState = () => createSelector(selectState, (state) => state.videoState || {});

export const getDuplicateSceneStatus = () => createSelector(selectState, (state) => state.duplicateSceneStatus || {});

export const getBuildStatus = () => createSelector(selectState, (state) => state.buildStatus || {});

export const getImportSlideStatus = () => createSelector(selectState, (state) => state.importSlideStatus || {});

export const getChangeFormatLoading = () => createSelector(selectState, (state) => state.changeFormatLoading);

export const getCropAndTrimStatus = () => createSelector(selectState, (state) => state.cropAndTrimStatus || {});

export const getLogoProperty = () => createSelector(selectState, (state) => state.video.logo);
export const getAudio = () => createSelector(selectState, (state) => state.video.sounds || []);
export const getFolders = () => createSelector(selectState, (state) => state.video?.folders || []);
export const getBackgroundVolume = () => createSelector(selectState, (state) => state.video?.background_volume || 0);
export const getSceneVolume = () => createSelector(selectState, (state) => state.video?.scene_volume || 0);
export const getBackgroundSoundID = () =>
  createSelector(selectState, (state) => state.video.background_sound?._id || null);
export const getBackgroundSound = () => createSelector(selectState, (state) => state.video?.background_sound || null);

export const getSelectingSceneTextEffect = () =>
  createSelector(selectScenes, selectSelectingSceneID, (scenes, id) => scenes.find((el) => el._id === id)?.text_effect);
export const getUrlBuildVideo = () => createSelector(selectState, (state) => state.buildStatus.url || '');

export const getTransition = () => createSelector(selectState, (state) => state.video.transition);

export const getSelectingSceneAvatar = () =>
  createSelector(
    selectScenes,
    selectSelectingSceneID,
    (scenes, id) => scenes.find((el) => el._id === id)?.avatar || null
  );

export const getTags = () => createSelector(selectState, (state) => state.tags);

export const getMyTemplates = () => createSelector(selectState, (state) => state.myTemplates || []);

export const getVoice = () => createSelector(selectState, (state) => state.video?.voice);
export const getSpeed = () => createSelector(selectState, (state) => state.video?.speed || 1);

export const getMediaLibrary = () => createSelector(selectState, (state) => state.mediaLibrary || []);

export const getTimeUpdateScene = () => createSelector(selectState, (state) => state.timeUpdateSceneSuccess || 0);
export const getSceneCheck = () => createSelector(selectState, (state) => state.sceneCheck);
