export const LAYOUT_XL = {
  NAVBAR_HEIGHT: 60,
  NAVBAR_WIDTH: 230,
  LIST_SLIDE_WIDTH: 230,
  SIDEBAR_WIDTH: 100,
  SLIDE_WIDTH: 100
};

export const LAYOUT_LG = {
  NAVBAR_HEIGHT: 60,
  NAVBAR_WIDTH: 230,
  LIST_SLIDE_WIDTH: 180,
  SIDEBAR_WIDTH: 100,
  SLIDE_WIDTH: 100
};

export const SIDEBAR_TABS = {
  TEMPLATE: 'template',
  TEXT: 'text',
  MEDIA: 'media',
  STYLE: 'style',
  MUSIC: 'music',
  AVATAR: 'avatar'
};

export const FORMATS = {
  LANDSCAPE: 1,
  SQUARE: 2,
  VERTICAL: 3,
  THREEFOUR: 4
};

export const FORMAT_TO_RATIO = {
  1: '16/9',
  2: '1/1',
  3: '9/16',
  4: '3/4'
};

export const MEDIA_TYPE = {
  NONE: 0,
  VIDEO: 1,
  IMAGE: 2
};
export const LAYOUT = {
  CONTAINER_WIDTH: 1170
};
export const COLOR = {
  PRIMARY_COLOR: '#2E6BB4'
};

export const LOGO_POSITION = {
  TOP_LEFT: '1',
  TOP_RIGHT: '2',
  BOTTOM_LEFT: '3',
  BOTTOM_RIGHT: '4'
};

export const LOGO_SIZE = {
  SMALL: 1,
  MEDIUM: 2,
  LARGE: 3
};

export const LOGO_DISTANCE = 16;

export const TRANSITION_EFFECT = {
  FADE: '0',
  FADEBLACK: '1',
  FADEWHITE: '2',
  WIPELEFT: '3',
  WIPERIGHT: '4',
  SLIDELEFT: '5',
  SLIDERIGHT: '6'
};

export const AVATAR_POSITION = {
  TOP_LEFT: '1',
  TOP_RIGHT: '2',
  BOTTOM_LEFT: '3',
  BOTTOM_RIGHT: '4'
};

export const TAB_VOICE = {
  AI: 'ai',
  UPLOAD_FILE: 'upload_file',
  VOID_ORIGIN: 'void_origin'
};

export const status = {
  PENDING: 3,
  FALSE: 2,
  SUCCESS: 1
};
