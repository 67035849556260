/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import history from './utils/history';
import appReducer from './containers/App/reducer';
import { loginReducer } from './containers/Auth/Login/reducer';
import { editVideoReducer } from './containers/EditVideo/reducer';
import { homeVideosReducer } from './containers/HomeVideo/components/HomeTemplate/reducer';
import { templatesListReducer } from './containers/HomeVideo/components/AllTemplate/reducer';
import { createNewVideoReducer } from './containers/HomeVideo/components/Modal/ImportSlideModal/reducer';
import { groupReducer } from './containers/Group/reducer';
import { packageReducer } from './containers/Package/reducer';
import { orderReducer } from './containers/Order/reducer';
import { historyReducer } from './containers/History/reducer';
import { registerReducer } from './containers/Auth/Register/reducer';
import { coreReducer } from 'core/reducer';
import { quizReducer } from 'containers/Quiz/reducer';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export const createReducer = (injectedReducers = {}) => {
  const rootReducer = combineReducers({
    app: appReducer,
    core: coreReducer,
    login: loginReducer,
    editVideo: editVideoReducer,
    homeVideos: homeVideosReducer,
    createVideo: createNewVideoReducer,
    templateList: templatesListReducer,
    group: groupReducer,
    package: packageReducer,
    order: orderReducer,
    history: historyReducer,
    register: registerReducer,
    quiz: quizReducer,
    router: connectRouter(history),
    ...injectedReducers
  });

  return rootReducer;
};
