import React from 'react';
import { useTranslation } from 'react-i18next';
import { toAbsoluteUrl } from 'utils/formatUrl';
import { GROUP_MEMBER_PERMISSION } from '../constants';

export default function UseGroupPermission() {
  const { t } = useTranslation();

  const onShowUserPermission = (permission) => {
    if (permission === GROUP_MEMBER_PERMISSION.SUPPER_ADMIN) {
      return (
        <div className="d-flex align-items-center">
          <img className="mr-12" src={toAbsoluteUrl('/icons/admin.svg')} alt="super_admin" />
          <div>{t(`permission.${GROUP_MEMBER_PERMISSION.SUPPER_ADMIN}`)}</div>
        </div>
      );
    }
    if (permission === GROUP_MEMBER_PERMISSION.ADMIN) {
      return (
        <div className="d-flex align-items-center">
          <img className="mr-12" src={toAbsoluteUrl('/icons/manager.svg')} alt="admin" />
          <div>{t(`permission.${GROUP_MEMBER_PERMISSION.ADMIN}`)}</div>
        </div>
      );
    }
    if (permission === GROUP_MEMBER_PERMISSION.EDITOR) {
      return (
        <div className="d-flex align-items-center">
          <img className="mr-12" src={toAbsoluteUrl('/icons/editor.svg')} alt="editor" />
          <div>{t(`permission.${GROUP_MEMBER_PERMISSION.EDITOR}`)}</div>
        </div>
      );
    }
    return null;
  };

  return [onShowUserPermission];
}
