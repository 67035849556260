import { createTypes } from 'reduxsauce';
import { ENDPOINTS } from './config';
import axiosClient from '../../utils/axiosClient2';

export const OrderTypes = createTypes(`
  ADD_ORDER_SUCCESS
  ADD_ORDER_FAILURE
  FETCH_ORDER_DETAIL_SUCCESS
  FETCH_ORDER_DETAIL_FAILURE
  FETCH_ORDER_PAYMENT_URL_SUCCESS
  FETCH_ORDER_PAYMENT_URL_FAILURE
  FETCH_ORDER_SUCCESS
  FETCH_ORDER_FAILURE
  PAID_ORDER_SUCCESS
  PAID_ORDER_FAILURE
`);

export const addOrderSuccess = (response) => ({ type: OrderTypes.ADD_ORDER_SUCCESS, data: response });
export const addOrderFailure = (error) => ({ type: OrderTypes.ADD_ORDER_FAILURE, error });
export const addOrder = (payload) => async (dispatch) => {
  try {
    const response = await axiosClient.post(ENDPOINTS.order(), payload);
    dispatch(addOrderSuccess(response.data?.result));
    return response.data?.result;
  } catch (error) {
    dispatch(addOrderFailure(error));
    return error;
  }
};

export const fetchOrderDetailSuccess = (response) => ({ type: OrderTypes.FETCH_ORDER_DETAIL_SUCCESS, data: response });
export const fetchOrderDetailFailure = (error) => ({ type: OrderTypes.FETCH_ORDER_DETAIL_FAILURE, error });
export const fetchOrderDetail = (id) => async (dispatch) => {
  try {
    const response = await axiosClient.get(ENDPOINTS.detail(id));
    dispatch(fetchOrderDetailSuccess(response.data?.result));
    return response.data?.result;
  } catch (error) {
    dispatch(fetchOrderDetailFailure(error));
    return error;
  }
};

export const fetchOrderPaymentUrlSuccess = (response) => ({
  type: OrderTypes.FETCH_ORDER_PAYMENT_URL_SUCCESS,
  data: response
});
export const fetchOrderPaymentUrlFailure = (error) => ({ type: OrderTypes.FETCH_ORDER_PAYMENT_URL_FAILURE, error });
export const fetchOrderPaymentUrl = (id, params) => async (dispatch) => {
  try {
    const response = await axiosClient.get(ENDPOINTS.payment(id), { params });
    dispatch(fetchOrderPaymentUrlSuccess(response.data?.result));
    return response.data;
  } catch (error) {
    dispatch(fetchOrderPaymentUrlFailure(error));
    return error;
  }
};

export const fetchOrderSuccess = (response) => ({ type: OrderTypes.FETCH_ORDER_SUCCESS, data: response });
export const fetchOrderFailure = (error) => ({ type: OrderTypes.FETCH_ORDER_FAILURE, error });
export const fetchOrder = (params) => async (dispatch) => {
  try {
    const response = await axiosClient.get(ENDPOINTS.order(), { params });
    dispatch(fetchOrderSuccess(response.data));
    return response.data?.result;
  } catch (error) {
    dispatch(fetchOrderFailure(error));
    return error;
  }
};

export const paidOrderSuccess = (response) => ({ type: OrderTypes.PAID_ORDER_SUCCESS, data: response });
export const paidOrderFailure = (error) => ({ type: OrderTypes.PAID_ORDER_FAILURE, error });
export const paidOrder = (id, payload) => async (dispatch) => {
  try {
    const response = await axiosClient.post(ENDPOINTS.paid(id), payload);
    dispatch(paidOrderSuccess(response.data));
    return response.data?.result;
  } catch (error) {
    dispatch(paidOrderFailure(error));
    return error;
  }
};
