import { FORMAT_TO_RATIO } from 'constants/video';
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

export const useAspectRatioKeeper = (format, props = { widthBreakpoints: {} }) => {
  const mediaWrapperRef = useRef(null);
  const wrapperInitialDimensions = useRef(null);
  const [dimensions, setDimensions] = useState({});
  const [breakpoint, setBreakpoint] = useState(false);
  const [render, triggerRender] = useState([]);
  const ratio = useMemo(() => {
    const aspectRatio = FORMAT_TO_RATIO[format];
    return format ? parseInt(aspectRatio.split('/')[0], 10) / parseInt(aspectRatio.split('/')[1], 10) : 1;
  }, [format]);

  useLayoutEffect(() => {
    const { width, height } = mediaWrapperRef.current.getBoundingClientRect();
    wrapperInitialDimensions.current = { width, height };
  }, [mediaWrapperRef.current, render]);

  useLayoutEffect(() => {
    const aspectRatio = FORMAT_TO_RATIO[format] || '16/9';
    const { width: fullWidth, height: fullHeight } = wrapperInitialDimensions.current;
    let calWidth = fullWidth;
    let calHeight = fullHeight;
    const widthRatio = parseInt(aspectRatio.split('/')[0], 10);
    const heightRatio = parseInt(aspectRatio.split('/')[1], 10);
    if ((fullWidth / widthRatio) * heightRatio <= fullHeight) {
      calHeight = (fullWidth / widthRatio) * heightRatio;
    } else {
      calWidth = (fullHeight / heightRatio) * widthRatio;
    }

    setBreakpoint(() => {
      let bp = false;
      Object.keys(props.widthBreakpoints).every((key) => {
        if (calWidth <= props.widthBreakpoints[key]) {
          bp = key;
          return false;
        }
        return true;
      });
      return bp;
    });
    setDimensions({ width: calWidth, height: calHeight });
  }, [format, render]);

  const rerender = useCallback(() => {
    triggerRender([...render]);
  }, [render]);

  useEffect(() => {
    window.addEventListener('resize', rerender);
    return () => window.removeEventListener('resize', rerender);
  }, []);

  return {
    mediaWrapperRef,
    dimensionsProps: dimensions,
    breakpoint,
    ratio
  };
};
