import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  textTabWrapper: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
    '& .title': {
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '22px',
      marginBottom: '16px',
      marginTop: '8px'
    }
  },
  noSceneSelected: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  textEdit: {
    marginBottom: '16px',
    '& .textField': {
      '& .MuiOutlinedInput-root': {
        '& > fieldset > legend': {
          float: 'left !important'
        },
        background: '#fff',

        '&:hover > fieldset': {
          boxShadow: 'none',
          borderWidth: '1px',
          borderColor: '#2E6BB4'
        },
        '&.Mui-focused fieldset': {
          boxShadow: 'none',
          borderWidth: '1px',
          borderColor: '#2E6BB4'
        }
      }
    }
  },
  divider: {
    width: 'calc(100% + 60px)',
    height: '2px',
    backgroundColor: 'white',
    marginTop: '-2px',
    marginLeft: '-16px'
  },
  textEffect: {
    marginTop: '16px',
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
    alignItems: 'center',
    columnGap: '20px',
    rowGap: '16px',
    flex: 1,
    overflow: 'auto'
  },
  gifItem: {
    cursor: 'pointer',
    borderRadius: '10px'
  },
  thumbnailItem: {
    position: 'absolute',
    '&:hover': {
      opacity: 0,
      cursor: 'pointer'
    }
  },
  active: {
    '& p': {
      fontWeight: 'bold'
    }
  }
});
