import React, { useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { Select as AntSelect } from 'antd';
import './styles.css';
import { toAbsoluteUrl } from 'utils/formatUrl';

const { Option } = AntSelect;

const Select = forwardRef(({ options, onChange, defaultValue, all, style }, ref) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  const onSelectItem = (item) => {
    setSelectedValue(item);
    onChange(item);
  };

  useImperativeHandle(ref, () => ({
    setFieldValue: setSelectedValue
  }));

  return (
    <div className="select-container" style={style}>
      <AntSelect
        value={selectedValue}
        className="ant-select"
        onChange={onSelectItem}
        suffixIcon={<img src={toAbsoluteUrl('/icons/select.svg')} alt="select" />}
      >
        {all && <Option value="ALL">{all}</Option>}
        {options.map((item) => (
          <Option key={item._id} value={item.value || item._id}>
            {item.name}
          </Option>
        ))}
      </AntSelect>
    </div>
  );
});

Select.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func,
  all: PropTypes.string,
  defaultValue: PropTypes.any,
  style: PropTypes.object
};

export default Select;
