import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchDebounce } from '../../../hooks/useSearchDebounce';
import { fetchGroupMembers } from '../actions';
import Input from '../../../components/Common/Input';
import Select from '../../../components/Common/Select';
import { GROUP_MEMBER_STATUS } from '../constants';

const options = [
  { _id: 1, name: 'Tất cả', value: 'ALL' },
  { _id: 2, name: 'Trong nhóm', value: GROUP_MEMBER_STATUS.MEMBER_STATUS_JOINED },
  { _id: 3, name: 'Đã xoá', value: GROUP_MEMBER_STATUS.MEMBER_STATUS_DELETED },
  { _id: 5, name: 'Đã rời', value: GROUP_MEMBER_STATUS.MEMBER_STATUS_LEAVE }
];

export default function GroupDetailMemberAdminFilter() {
  const param = useParams();
  const dispatch = useDispatch();
  const [name, setName] = useSearchDebounce();
  const [status, setStatus] = useState('ALL');
  const { group } = useSelector((state) => state.group);

  useEffect(() => {
    const paramsQuery = {};
    if (name) {
      paramsQuery.name = name;
    }
    if (Object.values(GROUP_MEMBER_STATUS).includes(status)) {
      paramsQuery.status = status;
    } else {
      delete paramsQuery.status;
    }
    dispatch(fetchGroupMembers(group._id, paramsQuery));
  }, [dispatch, group._id, name, status]);

  const onChangeStatus = (value) => {
    setStatus(value);
  };

  return (
    <div className="d-flex">
      <div className="mr-20">
        <Select defaultValue="ALL" options={options} onChange={onChangeStatus} />
      </div>
      <div>
        <Input placeholder="Tìm kiếm thành viên" onChange={(e) => setName(e.target.value)} />
      </div>
    </div>
  );
}
