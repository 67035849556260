import React, { forwardRef, useImperativeHandle } from 'react';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useModal } from '../../../../hooks/useModal';
import { deleteVideoRequest, getVideosByUserRequest } from '../../../HomeVideo/components/HomeTemplate/actions';
import { GROUP_MODAL, DEFAULT_LIST_GROUP_PARAMS } from '../../constants';
import { useNotify } from '../../../../hooks/useNotify';
import { toAbsoluteUrl } from 'utils/formatUrl';

const GroupRemoveVideoModal = forwardRef(({ groupId }, ref) => {
  const { openModal, closeModal, isShowing, data } = useModal(GROUP_MODAL.REMOVE_VIDEO);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { successNotify, errorNotify } = useNotify();

  const onRemoveVideo = async () => {
    try {
      await dispatch(deleteVideoRequest(data._id));
      successNotify(t('group.member.removeVideoSuccess'));
      dispatch(getVideosByUserRequest(groupId, DEFAULT_LIST_GROUP_PARAMS));
      closeModal();
    } catch (e) {
      errorNotify(t('group.member.removeVideoFailure'));
    }
  };

  useImperativeHandle(ref, () => ({
    onOpen: openModal
  }));

  return (
    <Dialog fullWidth open={isShowing} onClose={() => closeModal()}>
      <div className="modal-remove-video d-flex flex-direct-column">
        <div className="d-flex justify-content-center mb-30">
          <img className="leave-group-icon" src={toAbsoluteUrl('/icons/leave-group.svg')} alt="remove-video" />
        </div>
        <div className="d-flex text-2 mb-30 justify-content-center" onClick={onRemoveVideo} aria-hidden="true">
          Bạn chắc chắn muốn xoá {data?.name}?
        </div>
        <div className="d-flex justify-content-center">
          <button type="button" className="button-2 mr-20" onClick={onRemoveVideo}>
            Xoá
          </button>
          <button
            type="button"
            className="button-3"
            onClick={() => {
              closeModal();
            }}
          >
            Không
          </button>
        </div>
      </div>
    </Dialog>
  );
});

GroupRemoveVideoModal.propTypes = {
  groupId: PropTypes.string
};

export default GroupRemoveVideoModal;
