import React, { useRef } from 'react';
import { Table, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import GroupDetailMemberAdminFilter from './GroupDetailMemberAdminFilter';
import GroupRemoveMemberModal from './Modal/GroupRemoveMemberModal';
import GroupPermissionModal from './Modal/GroupPermissionModal';
import GroupAddMemberModal from './Modal/GroupAddMemberModal';
import * as Selectors from '../../App/selectors';
import UseGroupPermission from './UseGroupPermission';
import { formatTable2 } from '../../../utils/common';
import { GROUP_MEMBER_PERMISSION, GROUP_MODAL, GROUP_MEMBER_STATUS } from '../constants';
import { toAbsoluteUrl } from 'utils/formatUrl';

GroupDetailMemberAdmin.propTypes = {
  members: PropTypes.array,
  groupId: PropTypes.string
};

export default function GroupDetailMemberAdmin({ members, groupId }) {
  const [onShowUserPermission] = UseGroupPermission();
  const user = useSelector(Selectors.getUser());
  const { inviteMemberLoading } = useSelector((state) => state.group);
  const { t } = useTranslation();
  const addedMemberModalRef = useRef();
  const removedMemberModalRef = useRef();
  const changedPermissionModalRef = useRef();

  const onOpenRemoveMemberModal = (userRemove) => {
    removedMemberModalRef.current.onOpen(GROUP_MODAL.REMOVE_MEMBER, userRemove);
  };

  const onOpenAddMemberModal = () => {
    addedMemberModalRef.current.onOpen(GROUP_MODAL.ADD_MEMBER);
  };

  const onOpenPermissionModal = (userPermission) => {
    changedPermissionModalRef.current.onOpen(GROUP_MODAL.CHANGE_MEMBER_PERMISSION, userPermission);
  };

  const onFormatActionColumn = (record) => {
    if (
      record._id === user._id ||
      record.permission === GROUP_MEMBER_PERMISSION.SUPPER_ADMIN ||
      record.status === GROUP_MEMBER_STATUS.MEMBER_STATUS_LEAVE ||
      record.status === GROUP_MEMBER_STATUS.MEMBER_STATUS_DELETED
    ) {
      return null;
    }
    return (
      <>
        <Tooltip title="Xoá thành viên">
          <img
            onClick={() => onOpenRemoveMemberModal(record)}
            aria-hidden="true"
            className="icon pointer mr-20"
            src={toAbsoluteUrl('/icons/trash.svg')}
            alt="trash"
          />
        </Tooltip>
        <Tooltip title="Thay đổi quyền">
          <img
            onClick={() => onOpenPermissionModal(record)}
            aria-hidden="true"
            className="icon pointer mr-20"
            src={toAbsoluteUrl('/icons/entrust.svg')}
            alt="entrust"
          />
        </Tooltip>
      </>
    );
  };

  const onFormatStatusColumn = (status) => {
    if (status && !!Object.values(GROUP_MEMBER_STATUS).includes(status)) {
      return t(`group.member.${status}`);
    }
    return t(`group.member.${GROUP_MEMBER_STATUS.MEMBER_STATUS_JOINED}`);
  };

  const columns = [
    { key: '', title: 'STT', dataIndex: '', render: (text, record, index) => <span>{index + 1}</span> },
    {
      key: 'name',
      title: 'Tên thành viên',
      dataIndex: 'name',
      render: (name) => <span className="text-bold">{name}</span>
    },
    {
      key: 'permission',
      title: 'Vai trò',
      dataIndex: 'permission',
      render: (permission) => onShowUserPermission(permission)
    },
    {
      key: 'statistic',
      title: 'Số dự án',
      dataIndex: 'statistic',
      render: (statistic) => <span>{statistic.videos_count || 0}</span>
    },
    {
      key: 'status',
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (status) => onFormatStatusColumn(status)
    },
    {
      key: '',
      title: 'Hành động',
      dataIndex: '',
      render: (record) => onFormatActionColumn(record)
    }
  ];

  return (
    <div className="d-flex flex-direct-column">
      <div className="d-flex filter-container justify-content-between align-items-center">
        <GroupDetailMemberAdminFilter />
        <button type="button" className="button border-r" onClick={onOpenAddMemberModal}>
          Thêm thành viên
        </button>
      </div>
      <Table
        rowKey="_id"
        rowClassName={(record, index) => formatTable2(index)}
        columns={columns}
        dataSource={members}
        pagination={false}
        loading={inviteMemberLoading}
      />
      <GroupRemoveMemberModal groupId={groupId} ref={removedMemberModalRef} />
      <GroupAddMemberModal user={user} groupId={groupId} ref={addedMemberModalRef} />
      <GroupPermissionModal groupId={groupId} ref={changedPermissionModalRef} />
    </div>
  );
}
