import PauseIcon from '@mui/icons-material/Pause';
import PlayArrow from '@mui/icons-material/PlayArrow';
import { Box, IconButton, LinearProgress, Slider, Stack } from '@mui/material';
import clsx from 'clsx';
import { MEDIA_TYPE } from 'constants/video';
import { useMedia } from 'hooks/useMedia';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef, useState } from 'react';
import { updateData } from 'utils/lottieJson';
import Lottie from 'react-lottie';
import { parseTime } from 'utils/time';
import { getSXProps } from '../SceneView/hook';
import { getAnimation } from '../TextTab';
import { useScenesCompilation } from './hook';
import { useStyles } from './styles';
import { toAbsoluteUrl } from 'utils/formatUrl';

export default function ScenesCompilation({ isShowing, video }) {
  const classes = useStyles();
  const {
    mediaWrapperRef,
    dimensionsProps,
    listPlayingScene,
    onSceneEnd,
    currentTime,
    togglePlay,
    playing,
    totalTime,
    handleSliderChange,
    timeTravel,
    minimizedControlBar,
    setMinimizedControlBar,
    logoSXProps,
    getLogoComponent,
    transitionEffectClass,
    backgroundColor,
    sceneVolume
  } = useScenesCompilation({
    isShowing,
    video
  });

  return (
    <Box className={classes.videoWrapper} ref={mediaWrapperRef}>
      <Box
        className={classes.video}
        {...dimensionsProps}
        sx={{ backgroundColor }}
        onClick={togglePlay}
        onMouseEnter={() => setMinimizedControlBar(false)}
        onMouseLeave={() => setMinimizedControlBar(true)}
      >
        {listPlayingScene.map((scene) => (
          <Box key={scene._id} className={classes.sceneWrapper}>
            <ScenePreview
              key={scene._id}
              scene={scene}
              sceneVolume={sceneVolume}
              onSceneEnd={onSceneEnd}
              currentTime={currentTime}
              playing={playing}
              timeTravel={timeTravel}
              transitionEffectClass={transitionEffectClass}
              dimensionsProps={dimensionsProps}
            />
          </Box>
        ))}
        <Box sx={{ ...logoSXProps, position: 'absolute' }}>{getLogoComponent()}</Box>

        <Box
          className={clsx(classes.videoControlBar, minimizedControlBar && classes.videoControlBarHide)}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Box className="control">
            <Stack direction="row" spacing={1} alignItems="center">
              <IconButton onClick={togglePlay}>{playing ? <PauseIcon /> : <PlayArrow />}</IconButton>
              <Box>{`${parseTime(currentTime)} / ${parseTime(totalTime)}`}</Box>
            </Stack>

            {/* <Volume value={volume} onChange={handleChangeVolume} /> */}
          </Box>
          <Slider
            className={classes.videoSlider}
            max={totalTime - 0.01}
            value={currentTime}
            step={1}
            onChangeCommitted={handleSliderChange}
          />
        </Box>
        {minimizedControlBar && (
          <LinearProgress
            className={classes.videoProgressBarMinimize}
            variant="determinate"
            value={(currentTime / totalTime) * 100}
          />
        )}
      </Box>
    </Box>
  );
}

ScenesCompilation.propTypes = {
  isShowing: PropTypes.bool,
  video: PropTypes.object
};

function ScenePreview({
  scene,
  onSceneEnd,
  currentTime,
  playing,
  timeTravel,
  transitionEffectClass,
  sceneVolume,
  dimensionsProps
}) {
  const classes = useStyles();
  const { playMedia, pauseMedia, setCurrentTime, getComponent, isPlaying } = useMedia(scene?.media, {
    duration: scene.duration,
    cropData: scene.crop_data,
    trimData: scene.cut
  });
  const [transitionClass, setTransitionClass] = useState('');
  const [playSpeech, setPlaySpeech] = useState(false);
  const [visible, setVisible] = useState(!scene.order);
  const speechRef = useRef(new Audio(scene.speech?.url));
  const playCountRef = useRef(0);

  const avatarMedia = useMemo(
    () =>
      scene.avatar
        ? {
            url: toAbsoluteUrl('/static/avatar_1.mp4'),
            type: MEDIA_TYPE.VIDEO
          }
        : null,
    [scene.avatar]
  );

  const {
    getComponent: getAvatarComponent,
    intrinsicRatio: avatarIntrinsicRatio,
    pauseMedia: pauseAvatar,
    playMedia: playAvatar
  } = useMedia(avatarMedia, { videoProps: { loop: true } });

  const avatarSXProps = useMemo(
    () => getSXProps(scene.avatar, avatarIntrinsicRatio, dimensionsProps),
    [scene.avatar, avatarIntrinsicRatio, dimensionsProps]
  );

  const playScene = () => {
    playMedia();
    playAvatar();
    setPlaySpeech(true);
  };

  const pauseScene = () => {
    pauseMedia();
    pauseAvatar();
    setPlaySpeech(false);
    speechRef.current.pause();
  };

  useEffect(() => {
    speechRef.current.src = scene.speech?.url;
    speechRef.current.load();
    speechRef.current.volume = sceneVolume;
  }, [scene]);

  useEffect(() => {
    if (currentTime >= scene.startTime && currentTime <= scene.endTime) {
      if (currentTime - scene.startTime <= 1 && !timeTravel && scene.order > 0) {
        if (!transitionClass) {
          setTransitionClass(classes[transitionEffectClass.in]);
        }
      } else if (scene.endTime - currentTime <= 1 && !timeTravel && !scene.isLastScene) {
        if (!transitionClass) {
          setTransitionClass(classes[transitionEffectClass.out]);
        }
      } else {
        setTransitionClass('');
      }
      if (
        playing &&
        (!isPlaying || (speechRef.current.paused && currentTime - scene.startTime < speechRef.current.duration))
      ) {
        playScene();
      }
      setVisible(true);
    }
    if (!playing && isPlaying) {
      pauseScene();
    }
    if (currentTime >= scene.endTime) {
      onSceneEnd(scene._id);
      pauseScene();
      setVisible(false);
    }
  }, [currentTime, playing]);

  useEffect(() => {
    if (timeTravel) {
      setCurrentTime(currentTime - scene.startTime);
      speechRef.current.pause();
      speechRef.current.currentTime = currentTime - scene.startTime - (scene.order ? 1 : 0);
    }
  }, [timeTravel]);

  useEffect(
    () => () => {
      if (speechRef.current) {
        speechRef.current.pause();
      }
    },
    []
  );

  useEffect(() => {
    if (playSpeech) {
      if (scene.order === 0 || currentTime - scene.startTime >= 1) {
        speechRef.current.currentTime = currentTime - scene.startTime - (scene.order ? 1 : 0);
        speechRef.current.play();
        setPlaySpeech(false);
      }
    }
  }, [currentTime, playSpeech, scene.startTime]);

  const [defaultOptions, setDefaultOptions] = useState({
    loop: false,
    autoplay: false,
    animationData: getAnimation(scene.text_effect?.type).defaultJson,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  });

  const animRef = useRef();

  useEffect(() => {
    const animation = getAnimation(scene.text_effect?.type);
    const json = animation.defaultJson;
    if (scene.text_effect?.font) {
      updateData(json, animation.textLayer, { font: scene.text_effect?.font });
    }

    setDefaultOptions({ ...defaultOptions, animationData: json });
  }, [scene]);

  useEffect(() => {
    if (animRef.current) {
      const { anim } = animRef.current;
      const animation = getAnimation(scene.text_effect?.type);
      anim.renderer.elements[animation.textLayer].updateDocumentData(
        {
          t: scene.text_effect?.text || '',
          s: scene.text_effect?.size || 90,
          fc: scene.text_effect.color
            ? [scene.text_effect.color.r / 255, scene.text_effect.color.g / 255, scene.text_effect.color.b / 255]
            : [0, 0, 0]
        },
        0
      );
      if (!playCountRef.current) {
        anim.play();
        anim.goToAndStop(1, true);
        playCountRef.current = 1;
      }
    }
  }, [defaultOptions]);

  return (
    <Box className={clsx(classes.scene, transitionClass)} sx={{ visibility: visible ? 'visible' : 'hidden' }}>
      <Box className="media">{getComponent()}</Box>
      <Box
        className="text"
        sx={{ top: `${(scene.text_effect?.y || 0) * 100}%`, left: `${(scene.text_effect?.x || 0) * 100}%` }}
      >
        {scene.text_effect?.json && (
          <Lottie
            key={scene._id}
            ref={animRef}
            options={defaultOptions}
            isClickToPauseDisabled
            isStopped={!currentTime && !isPlaying}
            isPaused={!isPlaying}
          />
        )}
      </Box>
      <Box className="avatar" sx={{ ...avatarSXProps, position: 'absolute' }}>
        {getAvatarComponent()}
      </Box>
      <Box className="audio" />
    </Box>
  );
}

ScenePreview.propTypes = {
  scene: PropTypes.object,
  onSceneEnd: PropTypes.func,
  currentTime: PropTypes.number,
  playing: PropTypes.bool,
  timeTravel: PropTypes.bool,
  transitionEffectClass: PropTypes.object,
  sceneVolume: PropTypes.number,
  dimensionsProps: PropTypes.object
};
