import React, { forwardRef, useImperativeHandle } from 'react';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useModal } from '../../../../hooks/useModal';
import { fetchDetailGroup, fetchGroupMembers, removeMember } from '../../actions';
import { GROUP_MODAL } from '../../constants';
import { useNotify } from '../../../../hooks/useNotify';
import { toAbsoluteUrl } from 'utils/formatUrl';

const GroupRemoveMemberModal = forwardRef(({ groupId }, ref) => {
  const { openModal, closeModal, isShowing, data } = useModal(GROUP_MODAL.REMOVE_MEMBER);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { successNotify, errorNotify } = useNotify();

  const onRemoveMember = async () => {
    try {
      const params = {
        user_id: data._id
      };
      await dispatch(removeMember(groupId, params));
      successNotify(t('group.member.removeSuccess'));
      dispatch(fetchGroupMembers(groupId));
      dispatch(fetchDetailGroup(groupId));
      closeModal();
    } catch (e) {
      errorNotify(t('group.member.removeFailure'));
    }
  };

  useImperativeHandle(ref, () => ({
    onOpen: openModal
  }));

  return (
    <Dialog fullWidth open={isShowing} onClose={() => closeModal()}>
      <div className="modal-remove-member d-flex flex-direct-column">
        <div className="d-flex justify-content-center mb-30">
          <img className="leave-group-icon" src={toAbsoluteUrl('/icons/leave-group.svg')} alt="leave-group" />
        </div>
        <div className="d-flex text-2 mb-30 justify-content-center" onClick={onRemoveMember} aria-hidden="true">
          Bạn chắc chắn muốn xoá {data?.name} khỏi nhóm?
        </div>
        <div className="d-flex justify-content-center">
          <button type="button" className="button-2 mr-20" onClick={onRemoveMember}>
            Xoá
          </button>
          <button
            type="button"
            className="button-3"
            onClick={() => {
              closeModal();
            }}
          >
            Không
          </button>
        </div>
      </div>
    </Dialog>
  );
});

GroupRemoveMemberModal.propTypes = {
  groupId: PropTypes.string
};

export default GroupRemoveMemberModal;
