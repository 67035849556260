import React, { forwardRef, useImperativeHandle, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useNotify } from '../../../../hooks/useNotify';
import { entrustVideo } from '../../actions';
import { DEFAULT_LIST_GROUP_PARAMS, GROUP_MODAL } from '../../constants';
import { useModal } from '../../../../hooks/useModal';
import { getVideosByUserRequest } from '../../../HomeVideo/components/HomeTemplate/actions';

const { Option } = Select;

const GroupEntrustVideoModal = forwardRef(({ groupId, user }, ref) => {
  const { openModal, closeModal, isShowing, data } = useModal(GROUP_MODAL.ENTRUST_VIDEO);
  const { members } = useSelector((state) => state.group);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { successNotify, errorNotify } = useNotify();
  const [selectedUser, setSelectedUser] = useState();

  useImperativeHandle(ref, () => ({
    onOpen: openModal
  }));

  const onSelectUser = (value) => {
    setSelectedUser(value);
  };

  const onEntrustVideo = async () => {
    try {
      const payload = {
        user_id: user._id,
        to: selectedUser,
        video_ids: [data._id]
      };
      await dispatch(entrustVideo(groupId, payload));
      successNotify(t('group.member.entrustVideoSuccess'));
      dispatch(getVideosByUserRequest(groupId, DEFAULT_LIST_GROUP_PARAMS));
      closeModal();
    } catch (e) {
      errorNotify(t('group.member.entrustVideoFailure'));
    }
  };

  return (
    <Dialog fullWidth open={isShowing} onClose={() => closeModal()} style={{ zIndex: 50 }}>
      <div className="modal-entrust-video d-flex flex-direct-column">
        <div className="d-flex justify-content-center mb-30">
          <img className="leave-group-icon" src="/icons/paper.svg" alt="entrust-video" />
        </div>
        <div className="d-flex flex-direct-column text-2 mb-15" aria-hidden="true">
          <span className="d-flex justify-content-center">Chuyển quyền sở hữu tài nguyên</span>
          <span className="d-flex justify-content-center">
            từ tài khoản {data?.user_info?.name} sang tài khoản khác?
          </span>
        </div>
        <div className="d-flex flex-direct-column mb-20">
          <span className="d-flex justify-content-center">
            Người được nhận quyền sở hữu sẽ được quyền chỉnh sửa dự án đó.
          </span>
          <span className="d-flex justify-content-center">Những người khác trong nhóm chỉ được quyền xem.</span>
        </div>
        <Select
          value={selectedUser}
          onChange={onSelectUser}
          style={{ width: '100%' }}
          suffixIcon={<img src="/icons/select.svg" alt="select" />}
        >
          {members.map((member) => (
            <Option key={member._id} value={member._id}>
              {member.name}
            </Option>
          ))}
        </Select>
        <div className="d-flex justify-content-center mt-30">
          <button type="button" className="button mr-20" onClick={onEntrustVideo}>
            Chuyển
          </button>
          <button
            type="button"
            className="button-3"
            onClick={() => {
              closeModal();
            }}
          >
            Huỷ
          </button>
        </div>
      </div>
    </Dialog>
  );
});

GroupEntrustVideoModal.propTypes = {
  groupId: PropTypes.string,
  user: PropTypes.object
};

export default GroupEntrustVideoModal;
