export const ENDPOINTS = {
  getQuiz: '/quiz',
  createQuiz: '/quiz',
  deleteQuiz: (quizId) => `/quiz/${quizId}`,
  getQuizDetail: (quizId) => `/quiz/${quizId}`,
  updateQuiz: (quizId) => `/quiz/${quizId}`,
  addQuestion: '/quiz/question',
  updateQuestion: (questionId) => `/quiz/question/${questionId}`,
  deleteQuestion: (questionId) => `/quiz/question/${questionId}`,
  addNewFolder: () => `/library/folder`,
  uploadFile: () => `/library/file`,
  getFolders: () => `/library/file`,
  updateScene: (videoID, sceneID) => `/videos/${videoID}/scenes/${sceneID}`,
  updateMedia: (videoID, sceneID) => `/videos/${videoID}/scenes/${sceneID}/update-media`
};
