import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  buildCompleteWrapper: {
    display: 'flex',
    flexFlow: 'column',
    backgroundColor: '#f5f5f5',
    height: '100%',
    '& .title': {
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '30px',
      margin: '24px 0',
      textAlign: 'center'
    },
    '& .videoWrapper': {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '16px 0',
      maxHeight: '100%',
      position: 'relative'
    },
    '& .actions': {
      display: 'flex',
      justifyContent: 'center'
    }
  },
  buttonRecoding: {
    position: 'absolute',
    right: 10,
    top: 10,
    zIndex: 1000,
    cursor: 'pointer',
    border: '1px solid gray',
    padding: '2px 10px',
    borderRadius: '5px',
    fontWeight: 'bold'
  },
  previewVideo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    width: '100%',
    height: '100%',
    maxWidth: '100%'
  }
});
