import { createReducer } from 'reduxsauce';
import { coreTypes } from './actions';

const requestState = {
  error: null,
  organization: null,
  loading: true
};

export const INITIAL_STATE = {
  ...requestState
};

const organizationSuccess = (state = INITIAL_STATE, { organization }) => ({
  ...state,
  organization: organization,
  loading: false,
  error: false
});

const organizationFailure = (state = INITIAL_STATE) => ({
  ...state,
  error: true,
  loading: false
});

export const HANDLERS = {
  [coreTypes.ORGANIZATION_SUCCESS]: organizationSuccess,
  [coreTypes.ORGANIZATION_FAILURE]: organizationFailure
};

export const coreReducer = createReducer(INITIAL_STATE, HANDLERS);
