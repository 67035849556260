import { MEDIA_TYPE } from 'containers/EditVideo/constants';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export const useMedia = (media, props = {}) => {
  const {
    duration = 0,
    autoReset = false,
    backToStartWhenPause = false,
    cropData = null,
    trimData = null,
    showThumbnail = false,
    videoProps = {},
    showWithNoMedia = false
  } = props;

  const ref = useRef();
  const intervalIDRef = useRef(null);
  const [playDuration, setPlayDuration] = useState(duration);
  const [videoDuration, setVideoDuration] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setInternalCurrentTime] = useState(0);
  const [intrinsicDimension, setIntrinsicDimension] = useState({ width: 0, height: 0 });
  const [intrinsicRatio, setIntrinsicRatio] = useState(0);
  const [showBlankScreen, setShowBlankScreen] = useState(false);

  const startTime = useMemo(
    () => moment(trimData?.ss, 'hh:mm:ss.SSSS').diff(moment().startOf('day'), 'milliseconds') / 1000 || 0,
    [trimData]
  );
  const endTime = useMemo(
    () => moment(trimData?.to, 'hh:mm:ss.SSSS').diff(moment().startOf('day'), 'milliseconds') / 1000 || videoDuration,
    [trimData, videoDuration]
  );

  const mediaType = useMemo(
    () => (showThumbnail && media ? MEDIA_TYPE.IMAGE : media?.type || MEDIA_TYPE.NONE),
    [media, showThumbnail]
  );

  const playMedia = useCallback(() => {
    if (!ref.current) return;
    switch (mediaType) {
      case MEDIA_TYPE.VIDEO: {
        if (ref.current.paused) {
          if (intervalIDRef.current) {
            clearInterval(intervalIDRef.current);
          }
          ref.current.play();
          if (startTime + currentTime < videoDuration || !videoDuration) {
            intervalIDRef.current = setInterval(() => {
              setInternalCurrentTime(ref.current.currentTime - startTime);
            }, 250);
          } else {
            intervalIDRef.current = setInterval(() => {
              setInternalCurrentTime((prev) => prev + 0.25);
            }, 250);
            setShowBlankScreen(true);
          }
        }
        break;
      }
      case MEDIA_TYPE.NONE:
      case MEDIA_TYPE.IMAGE: {
        if (currentTime >= playDuration) {
          setCurrentTime(0);
        }
        if (intervalIDRef.current) {
          clearInterval(intervalIDRef.current);
        }
        intervalIDRef.current = setInterval(() => {
          setInternalCurrentTime((prev) => prev + 0.25);
        }, 250);
        break;
      }
      default:
    }
    setIsPlaying(true);
  }, [playDuration, currentTime, videoDuration, startTime, mediaType]);

  const pauseMedia = useCallback(() => {
    if (!ref.current) return;
    switch (mediaType) {
      case MEDIA_TYPE.VIDEO: {
        ref.current.pause();
        break;
      }
      case MEDIA_TYPE.IMAGE: {
        break;
      }
      default:
    }
    if (intervalIDRef.current) {
      clearInterval(intervalIDRef.current);
      intervalIDRef.current = null;
    }
    if (backToStartWhenPause) setCurrentTime(0);
    setIsPlaying(false);
  }, [mediaType]);

  const togglePlay = useCallback(() => {
    if (isPlaying) {
      pauseMedia();
    } else {
      playMedia();
    }
  }, [isPlaying, pauseMedia, playMedia]);

  const setCurrentTime = useCallback(
    (curTime) => {
      if (!ref.current) return;
      setShowBlankScreen(curTime + startTime >= endTime && videoDuration);
      switch (mediaType) {
        case MEDIA_TYPE.VIDEO: {
          setInternalCurrentTime(curTime);
          ref.current.currentTime = curTime + startTime;
          break;
        }
        case MEDIA_TYPE.NONE:
        case MEDIA_TYPE.IMAGE: {
          setInternalCurrentTime(curTime);
          break;
        }
        default:
      }
    },
    [mediaType, startTime, currentTime, endTime]
  );

  const getComponent = useCallback(() => {
    if (mediaType === MEDIA_TYPE.NONE) {
      return showWithNoMedia ? (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            ...cropData
          }}
        >
          <div ref={ref} style={{ backgroundColor: 'gray', width: '100%', height: '100%' }} />
        </div>
      ) : null;
    }
    const src = showThumbnail ? media.thumbnail : media.url;

    if (mediaType === MEDIA_TYPE.IMAGE) {
      return (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            ...cropData
          }}
        >
          <img ref={ref} alt="scene" src={src} width="100%" height="100%" style={{ objectFit: 'cover ' }} />
        </div>
      );
    }
    if (mediaType === MEDIA_TYPE.VIDEO) {
      return (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            visibility: showBlankScreen ? 'hidden' : 'inherit',
            ...cropData
          }}
        >
          <video ref={ref} key={media.url} width="100%" height="100%" style={{ objectFit: 'cover ' }} {...videoProps}>
            <source src={media.url} type="video/mp4" />
            <track default kind="captions" />
            Your browser does not support the video tag.
          </video>
        </div>
      );
    }
    return null;
  }, [media, cropData, showBlankScreen]);

  const reset = useCallback(() => {
    setIsLoaded(false);
    setIsPlaying(false);
    setShowBlankScreen(false);
    setCurrentTime(0);
    if (intervalIDRef.current) {
      clearInterval(intervalIDRef.current);
    }
  }, [intervalIDRef.current]);

  const resetVideo = useCallback(() => {
    clearInterval(intervalIDRef.current);
    setCurrentTime(0);
    setIsPlaying(false);
    setShowBlankScreen(false);
    ref.current.currentTime = startTime;
  }, [startTime]);

  useEffect(() => {
    if (ref.current) {
      if (mediaType === MEDIA_TYPE.VIDEO) {
        ref.current.load();
        setIsLoaded(false);
        ref.current.currentTime = startTime;

        ref.current.onloadeddata = () => {
          setIsLoaded(true);
          setVideoDuration(ref.current.duration);
          setIntrinsicDimension({ width: ref.current.videoWidth, height: ref.current.videoHeight });
          setIntrinsicRatio(ref.current.videoWidth / ref.current.videoHeight);
        };
      } else if (mediaType === MEDIA_TYPE.IMAGE) {
        setIsLoaded(true);
        ref.current.onload = () => {
          setIntrinsicDimension({ width: ref.current.naturalWidth, height: ref.current.naturalHeight });
          setIntrinsicRatio(ref.current.naturalWidth / ref.current.naturalHeight);
        };
      } else {
        setIsLoaded(true);
      }
    } else {
      setIsLoaded(true);
    }
    return () => {
      reset();
    };
  }, [ref, media, startTime]);

  useEffect(() => {
    if (mediaType === MEDIA_TYPE.VIDEO && currentTime + startTime >= endTime && !showBlankScreen && videoDuration) {
      clearInterval(intervalIDRef.current);
      intervalIDRef.current = setInterval(() => {
        setInternalCurrentTime((prev) => prev + 0.25);
      }, 250);
      setShowBlankScreen(true);
    }
    if (playDuration && currentTime >= playDuration) {
      pauseMedia();
      if (autoReset) {
        resetVideo();
      }
    }
  }, [currentTime]);

  useEffect(() => {
    setPlayDuration(duration === -1 ? videoDuration : duration);
  }, [duration, videoDuration]);

  return {
    ref,
    mediaType,
    isLoaded,
    isPlaying,
    currentTime,
    playMedia,
    pauseMedia,
    setCurrentTime,
    getComponent,
    intrinsicDimension,
    intrinsicRatio,
    playDuration,
    togglePlay,
    videoDuration
  };
};
