export default {
  app: 'ZenS',
  auth: {
    common: {
      forgotPassword: 'Quên mật khẩu',
      question1: 'Bạn đã có tài khoản?',
      question2: 'Bạn chưa có tài khoản?',
      chooseTypeTitle: 'HOẶC',
      googleLogin: 'Đăng nhập với Google',
      facebookLogin: 'Đăng nhập với Facebook',
      buttonTitle: 'Tiếp tục'
    },
    header: {
      home: 'Trang chủ',
      price: 'Bảng giá',
      contact: 'Liên hệ',
      try: 'Dùng thử miễn phí'
    },
    login: {
      title: 'Đăng nhập',
      forgot: {
        title: 'Quên mật khẩu',
        description: 'Hãy nhập địa chỉ email chúng tôi sẽ gửi mã OTP để lấy lại mật khẩu mới .'
      },
      reset: {
        title: 'Lấy lại mật khẩu',
        description: 'Nhật mật khẩu mới.'
      },
      notification: {
        success: 'Đăng nhập thành công',
        expired: 'Bạn đã hết hạn dùng thử, vui lòng liên hệ admin để được hỗ trợ',
        failure: 'Đăng nhập thất bại, email hoặc mật khẩu không chính xác',
        forgotRequestSuccess: 'Kiểm tra email để nhận mã OTP để lấy lại mật khẩu',
        forgotRequestFailure: 'Email không tồn tại',
        resetTitle: 'Kiểm tra email để lấy mã OTP để reset mật khẩu',
        resetSuccess: 'Lấy lại mật khẩu thành công',
        resetFailure: 'Lấy lại mật khẩu không thành công, mã OTP không chính xác'
      }
    },
    register: {
      title: 'Đăng ký',
      policyText1: 'Đồng ý các',
      policyLink: 'Điều khoản sử dụng',
      policyText2: '',
      notification: {
        success: 'Đăng ký thành công',
        failure: 'Đăng ký thất bại, email đã tồn tại'
      }
    },
    user: {
      updateSuccess: 'Cập nhật thông tin thành công',
      updateFailure: 'Cập nhật thông tin thất bại',
      changePasswordSuccess: 'Thay đổi mật khẩu thành công',
      changePasswordFailure: 'Thay đổi mật khẩu thất bại'
    }
  },
  entities: {
    users: {
      fields: {
        username: {
          label: 'Họ và tên',
          placeholder: 'Nhập họ và tên'
        },
        email: {
          label: 'Email',
          placeholder: 'Nhập email'
        },
        password: {
          label: 'Mật khẩu',
          placeholder: 'Nhập mật khẩu'
        },
        otp: {
          label: 'OTP',
          placeholder: 'Nhập mã OTP'
        }
      }
    },
    videoShare: {
      fields: {
        title: {
          label: 'Tiêu đề'
        }
      }
    },
    videoBuild: {
      fields: {
        title: {
          label: 'Tiêu đề'
        },
        description: {
          label: 'Mô tả'
        }
      }
    },
    videoExport: {
      fields: {
        title: {
          label: 'Tiêu đề'
        },
        description: {
          label: 'Mô tả'
        }
      }
    },
    video: {
      fields: {
        name: {
          defaultValue: 'Video chưa đặt tên'
        }
      }
    }
  },
  // See https://github.com/jquense/yup#using-a-custom-locale-dictionary
  /* eslint-disable */
  validation: {
    mixed: {
      default: '${path} không hợp lệ',
      required: '${path} bắt buộc',
      oneOf: '${path} must be one of the following values: ${values}',
      notOneOf: '${path} must not be one of the following values: ${values}',
      notType: ({ path, type, value, originalValue }) => {
        return `${path} must be a ${type}`;
      }
    },
    string: {
      length: '${path} phải có chính xác ${length} kí tự',
      min: '${path} phải có ít nhất ${min} kí tự',
      max: '${path} có nhiều nhất ${max} kí tự',
      matches: '${path} không bao gồm dấu và khoảng trắng',
      email: '${path} phải đúng định dạng là email',
      url: '${path} must be a valid URL',
      trim: '${path} must be a trimmed string',
      lowercase: '${path} must be a lowercase string',
      uppercase: '${path} must be a upper case string',
      selected: '${path} must be selected'
    },
    number: {
      min: '${path} must be greater than or equal to ${min}',
      max: '${path} must be less than or equal to ${max}',
      lessThan: '${path} must be less than ${less}',
      moreThan: '${path} must be greater than ${more}',
      notEqual: '${path} must be not equal to ${notEqual}',
      positive: '${path} must be a positive number',
      negative: '${path} must be a negative number',
      integer: '${path} must be an integer'
    },
    date: {
      min: '${path} field must be later than ${min}',
      max: '${path} field must be at earlier than ${max}'
    },
    boolean: {},
    object: {
      noUnknown: '${path} field cannot have keys not specified in the object shape'
    },
    array: {
      min: '${path} field must have at least ${min} items',
      max: '${path} field must have less than or equal to ${max} items'
    }
  },
  /* eslint-disable */
  commons: {
    actions: {
      upload: 'Tải lên',
      search: 'Tìm kiếm',
      build: 'Tạo video',
      share: 'Chia sẻ',
      create: 'Tạo video',
      delete: 'Xóa',
      duplicate: 'Nhân bản',
      sure: 'Chắc chắn',
      cancel: 'Hủy',
      ok: 'Đồng ý',
      reset: 'Đặt lại',
      save: 'Lưu',
      saved: 'Đã lưu',
      notSaved: 'Chưa lưu',
      download: 'Tải xuống',
      error: 'Có lỗi xảy ra',
      close: 'Đóng',
      extend: 'Gia hạn',
      startCreateVideo: 'Bắt đầu tạo video',
      preview: 'Xem trước',
      replace: 'Thay thế',
      DeleteSuccess: 'Xóa thành công'
    },
    messages: {
      copiedToClipboard: 'Sao chép thành công',
      somethingWrong: 'Đã xảy ra lỗi!',
      backToHome: 'Trở về trang chủ',
      uploading: 'Đang tải lên..',
      sureToDelete: 'Chắc chắn muốn xoá?',
      confirmation: 'Xác nhận',
      loading: 'Đang tải dữ liệu...',
      noMessage: 'Không có thông báo',
      none: 'Trống',
      sureToClone: 'Bạn có chắc chắn muốn sao chép bài giảng này?'
    },
    title: {
      instruct: 'Hướng dẫn tạo video'
    }
  },
  editVideo: {
    commons: {
      informVideoSupport: 'Trình duyệt của bạn không hỗ trợ video hoặc audio.',
      noSceneSelected: 'Chưa có màn hình nào được chọn',
      buildFinish: 'Video đã tạo thành công, vui lòng vào Xuất bản để tải xuống',
      buildSpeechSuccess: 'Tạo giọng đọc thành công',
      warningTime: 'Thời gian không được nhỏ hơn {{time}} giây',
      warningTimeDuration: 'Thời gian diễn ra hiệu ứng không được nhỏ hơn {{time}} giây',
      placeholderSpeech: 'Giọng đọc',
      loadingFileAudio: 'Đang tải',
      fileAudio: 'Tệp âm thanh',
      runoutRenderTime: 'Bạn đã dùng hết số phút render video, vui lòng gia hạn thêm.',
      noResults: 'Không có kết quả.',
      Delete: 'Xóa',
      uploadFile: 'Tải tệp lên'
    },
    listSlide: {
      upload: 'Tải slide',
      loadingUpload: 'Đang tải tệp',
      newSlide: 'Thêm',
      changeSceneWarning: 'Tệp đang được tải vui lòng đợi trong giây lát'
    },
    navbar: {
      preview: 'Xem trước',
      build: 'Tạo video',
      export: 'Xuất bản',
      share: 'Chia sẻ',
      exportVideo: 'Xuất video'
    },
    videoPreview: {
      title: 'Xem trước',
      buildVideo: 'Tạo video',
      continueEdit: 'Chỉnh sửa',
      shareVideo: 'Chia sẻ',
      exportToTemplate: 'Tạo mẫu video',
      loadingFilePreview: 'File đang được xử lý {{progress}}%',
      loadingFilePreviewFull: 'File đang được xử lý'
    },
    videoBuild: {
      title: 'Tạo video',
      summary: 'Thông tin video',
      videoTitle: 'Tiêu đề',
      slideCount: 'Số slide',
      description: 'Mô tả',
      buildTime: 'Thời gian tạo',
      continueEdit: 'Chỉnh sửa',
      shareVideo: 'Chia sẻ',
      exportToTemplate: 'Tạo mẫu video',
      success: 'Video đang trong hàng đợi để xuất bản!',
      failure: 'Yêu cầu xuất bản video thất bại! Vui lòng thử lại',
      downloadCurrentVersion: 'Tải xuống phiên bản hiện tại',
      runoutRenderTime: 'Thời lượng tạo video của bạn đã hết'
    },
    videoShare: {
      title: 'Chia sẻ video',
      buildVideo: 'Xuất bản video',
      continueEdit: 'Chỉnh sửa',
      videoTitle: 'Tiêu đề',
      slideCount: 'Số slide',
      shareVideo: 'Chia sẻ',
      summary: 'Thông tin video',
      exportToTemplate: 'Tạo mẫu video',
      copyLink: 'Sao chép link',
      embedLink: 'Link nhúng',
      shareOption: {
        label: 'Thiết lập',
        private: 'Private',
        organization: 'Organization',
        public: 'Public',
        viewer: 'Viewer',
        editor: 'Editor'
      },
      shareLink: 'Link'
    },
    videoExport: {
      buildVideo: 'Xuất bản video',
      title: 'Tạo mẫu video',
      summary: 'Thông tin mẫu video',
      videoTitle: 'Tiêu đề',
      slideCount: 'Số slide',
      description: 'Mô tả',
      exportTime: 'Thời gian tạo',
      continueEdit: 'Chỉnh sửa',
      shareVideo: 'Chia sẻ',
      exportToTemplate: 'Tạo mẫu video',
      exportVideo: 'Bắt đầu ghi màn hình'
    },
    sidebar: {
      template: 'Mẫu',
      text: 'Chữ',
      media: 'Media',
      style: 'Chuyển cảnh',
      music: 'Nhạc nền',
      avatar: 'Cá nhân hoá',
      help: {
        title: 'Trợ giúp',
        helpCenter: 'Trung tâm trợ giúp',
        contactUs: 'Liên hệ',
        videoTutorial: 'Video hướng dẫn'
      }
    },
    sceneView: {
      or: 'Hoặc',
      noScreenChosen: 'Chưa chọn màn hình',
      startSpeech: 'Bắt đầu',
      effectSpeech: 'Giọng đọc cho hiệu ứng {{idxEffect}}',
      uploadSpeech: 'Tải file thuyết trình lên',
      uploadSuccess: 'Tải lên thành công',
      uploadFail: 'Tải lên thất bại',
      text: 'Nội dung nói',
      voiceUpload: 'Tải tập ghi âm',
      noteSuccess: 'Nội dung đã được tạo âm thanh',
      uploadFileAudio: 'Tải lên giọng nói',
      notePending: 'Nội dung chưa được cập nhật âm thanh',
      delayTime: 'Bắt đầu phát âm thanh sau bao nhiêu giây',
      durationTime: 'Thời gian phát âm thanh',
      createPending: 'Đang tạo',
      playVoiceLoading: 'Không thể phát khi tạo giọng',
      language: {
        title: 'Language',
        vn: 'Vietnamese',
        en: 'English'
      },
      createSound: 'Giọng đọc',
      crop: {
        title: 'Cắt khung hình'
      },
      trim: {
        title: 'Cắt đoạn'
      },
      selectVoice: {
        voice1: 'Giọng Nữ 1',
        voice3: 'Giọng Nam 1',
        voice4: 'Giọng Nữ 2'
      },
      selectLanguage: {
        vi: 'Tiếng Việt',
        en: 'English',
        kr: 'Korean (한국인)',
        th: 'Thai (ประเทศไทย)',
        ja: 'Japan (日本)',
        cmn: 'China (中国)'
      },
      selectSpeed: 'Tốc độ',
      selectVoiceType: 'Giọng đọc',
      createSuccess: 'Tạo âm thanh thành công',
      warningDuration: 'Thời gian tổng không được nhỏ hơn thời gian diễn ra hiệu ứng',
      backgroundAudio: 'Âm thanh nền',
      speechAudio: 'Âm thanh trình chiếu',
      note: 'Hướng dẫn',
      changeSceneWarning: 'Tệp đang được tải vui lòng đợi trong giây lát'
    },
    mediaTab: {
      favorite: 'Yêu thích',
      all: 'Tất cả',
      image: 'Ảnh',
      video: 'Video',
      library: 'Thư viện hệ thống',
      myMedia: 'Thư viện của tôi',
      uploaded: 'Đã tải',
      favorites: 'Yêu thích',
      folder: 'Thư mục',
      newFolder: 'Thêm thư mục',
      newMedia: 'Thêm media',
      deleteFolder: 'Xoá thư mục',
      deleteFolderSuccess: 'Xoá thư mục thành công',
      deleteFolderFailure: 'Xoá thư mục thất bại',
      createFolderSuccess: 'Tạo thư mục thành công',
      createFolderFailure: 'Tạo thư mục thất bại',
      upload: {
        success: 'Tải lên thành công!',
        failed: 'Tải lên thất bại!'
      }
    },
    musicTab: {
      library: 'Thư viện',
      myMusic: 'Nhạc của tôi',
      uploaded: 'Đã tải',
      favorites: 'Yêu thích',
      filter: 'Lọc theo chủ đề',
      upload: {
        success: 'Tải lên thành công!',
        failed: 'Tải lên thất bại!'
      },
      addSoundToVideo: {
        failed: 'Thêm nhạc vào video thất bại!',
        success: 'Thêm nhạc vào video thành công!',
        removeBackgroundSound: 'Đã xoá nhạc khỏi video'
      },
      deleteSound: {
        failed: 'Xoá nhạc thất bại!',
        success: 'Xoá nhạc thành công!'
      },
      currentlyUsed: 'Đang sử dụng'
    },
    templateTab: {
      library: 'Thư viện',
      myTemplate: 'Mẫu video của tôi',
      created: 'Đã tải',
      favorites: 'Yêu thích',
      slides: 'Slides',
      useIt: 'Sử dụng mẫu này',
      applyForAll: 'Áp dụng tất cả'
    },
    templateDetails: {
      confirmApply: 'Thay thế phân cảnh hiện tại bằng phân cảnh mới chọn?',
      confirmApplyForAll: 'Thay thế tất cả phân cảnh hiện tại bằng phân cảnh mới?'
    },
    templateCard: {
      slides: 'Slides'
    },
    toolbarVolumePopover: {
      turnOn: 'Bật âm thanh',
      voiceVolume: 'Âm lượng thuyết trình',
      speedVolume: 'Tốc độ giọng thuyết trình',
      voice: 'Giọng thuyết trình',
      language: 'Ngôn ngữ thuyết trình',
      musicVolume: 'Âm lượng nhạc nền',
      applyForAll: 'Áp dụng',
      applySuccess: 'Áp dụng thành công!',
      applyFailed: 'Áp dụng thất bại!'
    },
    toolbarFilterPopover: {
      filter: 'Bộ lọc màu sắc',
      applyForAll: 'Áp dụng cho tất cả các trang',
      defaultName: 'Tên bộ lọc'
    },
    styleTab: {
      fontDefaultName: 'Tên font',
      fontColor: 'Màu chữ',
      backgroundColor: 'Màu nền',
      font: 'Font chữ',
      transitionEffect: 'Hiệu ứng chuyển cảnh',
      transitionEffectDefaultName: 'Tên hiệu ứng',
      logo: 'Logo',
      position: 'Vị trí',
      size: 'Kích cỡ',
      opacity: 'Độ mờ',
      uploadLogoPNGOnly: 'Chỉ chấp nhận ảnh PNG',
      fade: 'Mờ dần',
      fadeblack: 'Mờ dần đen',
      fadewhite: 'Mờ dần trắng',
      wipeleft: 'Lướt qua trái',
      wiperight: 'Lướt qua phải',
      slideleft: 'Trượt qua trái',
      slideright: 'Trượt qua phải'
    },
    textTab: {
      content: 'Nội dung',
      textEffect: 'Hiệu ứng',
      slideIn: 'Trượt vào',
      scale: 'Thu phóng chậm',
      scaleFast: 'Thu phóng nhanh',
      reunion: 'Tái hợp',
      styleSlideIn: 'Trượt vào với hiệu ứng',
      typewriter: 'Từng chữ',
      fade: 'Rõ dần',
      trail: 'Thanh trượt',
      candy: 'Chéo',
      stripes: 'Trượt từ trung tâm',
      circle: 'Vòng tròn',
      line: 'Gạch dưới',
      blow: 'Nổ',
      focus: 'Tập hợp',
      reg: 'Viền xung quanh',
      double: 'Viền và nền',
      blinds: 'Mù',
      burst: 'Nổ',
      byword: 'Bởi',
      shutter: 'Màn trập',
      splash: 'Bắn tung toé',
      stereo: 'Nổi'
    },
    popupBuildVideo: {
      title: 'Hệ thống đang tạo video',
      estimate: 'Thời gian dự kiến:',
      time: '{{ number}} phút',
      remained: 'Thời gian còn lại:',
      remainedTime: '{{ number}} phút',
      note: 'Bạn có thể đóng thông báo này để tạo các video khác. Sau khi tạo video xong, hệ thống sẽ gửi thông báo hoàn thành đến Email của bạn',
      close: 'Đóng thông báo',
      minute: 'phút'
    },
    avatarTab: {
      title: 'Chọn hình nhân vật',
      format: 'Định dạng',
      style: 'Kiểu',
      position: 'Vị trí',
      size: 'Kích thước',
      backgroundColor: 'Màu nền',
      avatarName: 'Tên hình',
      circleAndFull: 'Tròn/Đầy đủ',
      adjustPosition: 'Trái/Giữa/Phải',
      big: 'Lớn',
      top_left: 'Trên trái',
      top_right: 'Trên phải',
      bottom_left: 'Dưới trái',
      bottom_right: 'Dưới phải',
      none: 'Trống'
    },
    videoImport: {
      importError: 'Tải lên không thành công',
      dragAndDrop: 'Kéo thả hoặc chọn một tệp',
      loadingFile: "File đang được phân tích, vui lòng đợi khoảng 3'",
      here: 'tại đây'
    }
  },
  homeVideo: {
    common: {
      owner: 'Tạo bởi:',
      buildingVideo: 'Đang tạo video...',
      action: {
        restore: 'Khôi phục video',
        add: 'Video mới',
        import: 'Upload file',
        preview: 'Xem trước',
        edit: 'Chi tiết bài giảng',
        delete: 'Xóa video',
        share: 'Chia sẻ video',
        duplicate: 'Sao chép video',
        createTemplate: 'Tạo video mẫu',
        templateName: ' Template Name',
        slides: '{{ number }} slides',
        videoName: 'Tên video',
        deleteSuccess: 'Xóa thành công!',
        deleteFailed: 'Xóa thất bại!',
        restoreSuccess: 'Khôi phục thành công!',
        createTemplateSuccess: 'Tạo mẫu video thành công!',
        createTemplateFailed: 'Tạo mẫu video thất bại!',
        changeTemplateName: 'Thay đổi tên mẫu bài giảng',
        create: 'Tạo mẫu mới',
        close: 'Đóng',
        cloneSuccess: 'Sao chép thành công!'
      },
      templates: 'mẫu',
      title: {
        allTemplate: 'Xem tất cả mẫu video'
      }
    },
    modal: {
      addModal: {
        headerTitle: 'Tạo video từ tệp',
        tabs: {
          computer: {
            label: 'Máy tính',
            title: 'Tải tệp từ máy tính',
            content: 'Kéo và thả tệp ppt, pptx, pdf  từ máy tính vào đây',
            or: 'HOẶC',
            link: 'Click vào đây để tạo tệp'
          },
          link: {
            label: 'Link tệp',
            title: '(google slide, pdf, ư)',
            placeholder: 'Nhập url tệp'
          }
        },
        button: {
          chooseAgain: 'Chọn lại file khác',
          create: 'Tạo video'
        },
        fileSuccess: 'Tệp phải là dạng PPTX, PDF, DOC, MP4',
        fileUpload: 'Tải tệp lên',
        fileDragAndDrop: 'Kéo thả hoặc chọn một tệp tại đây'
      },
      previewModal: {
        button: 'Tạo video từ mẫu này'
      },
      deleteModal: {
        question: 'Bạn muốn xóa video này?',
        forceDelete: 'Bạn muốn xóa video này vĩnh viễn?',
        button: {
          cancel: 'Hủy',
          delete: 'Xóa'
        }
      }
    },
    menu: {
      title: 'Bắt đầu tạo trình chiếu',
      subTitle: {
        add: 'Tạo trình chiếu mới',
        import: 'Tạo trình chiếu nhanh',
        preview: 'Xem trưóc'
      }
    },
    homeTemplate: {
      emptyFile: 'Không có kết quả',
      title: {
        lecture: 'Bài giảng',
        shareWithMe: 'Chia sẻ với tôi',
        bin: 'Đã xoá',
        search: 'Tìm bài giảng',
        groupVideo: 'Bài giảng nhóm'
      },
      privacy: {
        private: 'Riêng tư',
        public: 'Công khai',
        organization: 'Org'
      },
      status: {
        draft: 'Nháp',
        ready: 'Công khai'
      },
      action: {
        duplicate: 'Sao chép',
        share: 'Chia sẻ',
        create: 'Tạo mẫu'
      },
      expired: {
        notification: 'Gói hiện tại đã hết hạn, để tiếp tục sử dụng vui lòng mua thêm gói',
        linkToPackage: 'Tại đây',
        exportExpire: 'Gói đã hết hạn, không thể xuất bản',
        shareExpire: 'Gói đã hết hạn, không thể chia sẻ',
        newSliceExpire: 'Gói đã hết hạn, không thể trình chiếu'
      }
    },
    allTemplate: {
      title: {
        library: 'Thư viện',
        myTemplates: 'Mẫu video của tôi'
      }
    }
  },
  buildComplete: {
    title: 'Video: {{name}} đã tạo thành công'
  },
  group: {
    createSuccess: 'Thêm nhóm thành công',
    createFailure: 'Thêm nhóm thất bại',
    editFailure: 'Chỉnh sửa thất bại',
    editSuccess: 'Chỉnh sửa thành công',
    validateField: 'Không được bỏ trống',
    editGroupName: 'Chỉnh sửa tên nhóm',
    member: {
      joined: 'Trong nhóm',
      deleted: 'Đã xoá',
      leave: 'Đã rời',
      invited: 'Đã mời',
      createSuccess: 'Thêm thành viên thành công',
      createFailure: 'Thêm thành viên thất bại',
      removeSuccess: 'Xoá thành viên thành công',
      removeFailure: 'Xoá thành viên thất bại',
      leaveSuccess: 'Rời nhóm thành công',
      leaveFailure: 'Rời nhóm thất bại',
      changePermissionSuccess: 'Thay đổi quyền thành công',
      changePermissionFailure: 'Thay đổi quyền thất bại',
      removeVideoSuccess: 'Xoá bài giảng thành công',
      removeVideoFailure: 'Xoá bài giảng thất bại',
      entrustVideoSuccess: 'Uỷ thác bài giảng thành công',
      entrustVideoFailure: 'Uỷ thác bài giảng thất bại',
      inviteSuccess: 'Mời thành viên thành công',
      inviteFailure: 'Mời thành viên thất bại'
    }
  },

  order: {
    paidSuccess: 'Thanh toán thành công',
    paidFailure: 'Thanh toán thất bại'
  },

  permission: {
    supper_admin: 'Chủ quản',
    admin: 'Quản lý',
    editor: 'Biên tập viên'
  },

  accountPopover: {
    profile: 'Thông tin cá nhân',
    logout: 'Đăng xuất',
    setting: 'Cài đặt',
    account: 'Tài khoản'
  },

  profile: {
    phoneNumberError: 'Số điện thoại không hợp lệ',
    birthdayError: 'Ngày sinh không hợp lệ',
    fullName: 'Họ và tên',
    email: 'Email',
    phone: 'Số điện thoại',
    birthday: 'Ngày sinh',
    avatar: 'Ảnh đại diện',
    edit: 'Chỉnh sửa',
    emailInvalid: 'Email không hợp lệ'
  },
  editPassword: {
    oldPassword: 'Mật khẩu cũ',
    hindPassword: 'Nếu chưa có mật khẩu thì bạn có thể bỏ qua',
    newPassword: 'Mật khẩu mới',
    retypeNewPassword: 'Nhập lại mật khẩu mới'
  }
};
