/**
 * The global state selectors
 */

import { createSelector } from 'reselect';
import { INITIAL_STATE } from './reducer';

const selectGlobal = (state) => state.app || INITIAL_STATE;

export const getLanguage = () => createSelector(selectGlobal, (state) => state?.language.value);

export const getLanguageError = () => createSelector(selectGlobal, (state) => state?.language.error);

export const getLanguageLoading = () => createSelector(selectGlobal, (state) => state?.language.loading);

export const getUser = () => createSelector(selectGlobal, (state) => state?.user);

export const getModalStatus = () => createSelector(selectGlobal, (state) => state?.modal.show);

export const getModalName = () => createSelector(selectGlobal, (state) => state?.modal.name);

export const getModalData = () => createSelector(selectGlobal, (state) => state?.modal.data);
