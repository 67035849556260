import { toFixedNumber } from 'utils/number';

export const parseTime = (_seconds) => {
  if (Number.isNaN(_seconds)) return undefined;
  const seconds = toFixedNumber(_seconds, 0);
  const timeString = new Date(seconds * 1000).toISOString().substr(11, 8);
  if (timeString.startsWith('00:00')) return timeString.slice(4);
  if (timeString.startsWith('00:')) return timeString.slice(3);
  return timeString;
};
