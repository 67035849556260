import React, { useRef } from 'react';
import { Table, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { formatTable2 } from '../../../utils/common';
import PaginationUI from '../../../components/Common/PaginationUI';
import GroupDetailVideoFilter from './GroupDetailVideoFilter';
import { DEFAULT_LIST_GROUP_PARAMS, GROUP_MEMBER_PERMISSION, GROUP_MODAL } from '../constants';
import * as Selectors from '../../App/selectors';
import GroupRemoveVideoModal from './Modal/GroupRemoveVideoModal';
import GroupEntrustVideoModal from './Modal/GroupEntrustVideoModal';
import UseGroupDetailVideo from './UseGroupDetailVideo';
import { toAbsoluteUrl } from 'utils/formatUrl';

GroupDetailVideoAdmin.propTypes = {
  groupId: PropTypes.string
};

export default function GroupDetailVideoAdmin({ groupId }) {
  const { videoParams, handleTableVideoChange, onChangeUser } = UseGroupDetailVideo({
    params: DEFAULT_LIST_GROUP_PARAMS
  });
  const user = useSelector(Selectors.getUser());
  const { videosByUser, total } = useSelector((state) => state.homeVideos);
  const removedMemberModalRef = useRef();
  const entrustedVideoModalRef = useRef();

  const onOpenRemoveMemberModal = (video) => {
    removedMemberModalRef.current.onOpen(GROUP_MODAL.REMOVE_VIDEO, video);
  };

  const onOpenEntrustVideoModal = (video) => {
    entrustedVideoModalRef.current.onOpen(GROUP_MODAL.ENTRUST_VIDEO, video);
  };

  const onFormatActionColumn = (record) => {
    if (record._id === user._id || record.permission === GROUP_MEMBER_PERMISSION.SUPPER_ADMIN) {
      return <img className="icon pointer" src="/icons/link.svg" alt="link" />;
    }
    return (
      <>
        <Tooltip title="Xoá dự án">
          <img
            onClick={() => onOpenRemoveMemberModal(record)}
            aria-hidden="true"
            className="icon pointer mr-20"
            src={toAbsoluteUrl('/icons/trash.svg')}
            alt="trash"
          />
        </Tooltip>
        <Tooltip title="Uỷ thác dự án">
          <img
            onClick={() => onOpenEntrustVideoModal(record)}
            aria-hidden="true"
            className="icon pointer"
            src={toAbsoluteUrl('/icons/link.svg')}
            alt="link"
          />
        </Tooltip>
      </>
    );
  };

  const columns = [
    { key: '', title: 'STT', dataIndex: '', render: (text, record, index) => <span>{index + 1}</span> },
    {
      key: 'video',
      title: 'Tên dự án',
      dataIndex: 'name',
      render: (name) => <span className="text-bold">{name}</span>
    },
    {
      key: 'name',
      title: 'Chủ dự án',
      dataIndex: 'user_info',
      render: (userInfo) => <span>{userInfo?.name}</span>
    },
    { key: 'scenes', title: 'Màn hình', dataIndex: 'scenes', render: (scenes) => <span>{scenes?.length || 0}</span> },
    {
      key: '',
      title: 'Hành động',
      dataIndex: '',
      render: (record) => onFormatActionColumn(record)
    }
  ];

  return (
    <div className="d-flex flex-direct-column">
      <GroupDetailVideoFilter groupId={groupId} onChangeUser={onChangeUser} />
      <Table
        rowKey="_id"
        rowClassName={(record, index) => formatTable2(index)}
        columns={columns}
        dataSource={videosByUser}
        pagination={false}
        onChange={handleTableVideoChange}
      />
      {total > videoParams.items_per_page && (
        <PaginationUI
          totalPage={Math.ceil(total / videoParams.items_per_page)}
          currentPage={videoParams.page}
          onChange={handleTableVideoChange}
        />
      )}
      <GroupRemoveVideoModal groupId={groupId} ref={removedMemberModalRef} />
      <GroupEntrustVideoModal user={user} groupId={groupId} ref={entrustedVideoModalRef} />
    </div>
  );
}
