import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchDebounce } from '../../../hooks/useSearchDebounce';
import { fetchGroupMembersInvite } from '../actions';
import Input from '../../../components/Common/Input';

export default function GroupDetailMemberInviteFilter() {
  const param = useParams();
  const dispatch = useDispatch();
  const [name, setName] = useSearchDebounce();
  const { group } = useSelector((state) => state.group);

  useEffect(() => {
    if (name) {
      dispatch(fetchGroupMembersInvite(group._id, { email: name }));
    } else {
      dispatch(fetchGroupMembersInvite(group._id));
    }
  }, [dispatch, group._id, name]);

  return (
    <div className="d-flex">
      <Input placeholder="Tìm kiếm thành viên" onChange={(e) => setName(e.target.value)} />
    </div>
  );
}
