import React from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { formatTable2 } from '../../../utils/common';
import PaginationUI from '../../../components/Common/PaginationUI';
import GroupDetailVideoFilter from './GroupDetailVideoFilter';
import { DEFAULT_LIST_GROUP_PARAMS } from '../constants';
import UseGroupDetailVideo from './UseGroupDetailVideo';

GroupDetailVideo.propTypes = {
  groupId: PropTypes.string
};

export default function GroupDetailVideo({ groupId }) {
  const { videoParams, handleTableVideoChange, onChangeUser } = UseGroupDetailVideo({
    params: DEFAULT_LIST_GROUP_PARAMS
  });
  const { videosByUser, total } = useSelector((state) => state.homeVideos);

  const columns = [
    { key: '', title: 'STT', dataIndex: '', render: (text, record, index) => <span>{index + 1}</span> },
    {
      key: 'name',
      title: 'Tên dự án',
      dataIndex: 'name',
      render: (name) => <span className="text-bold">{name}</span>
    },
    { key: 'scenes', title: 'Màn hình', dataIndex: 'scenes', render: (scenes) => <span>{scenes?.length || 0}</span> },
    {
      key: 'total_time',
      title: 'Độ dài (s) sd',
      dataIndex: 'total_time',
      render: (totalTime) => {
        console.log(totalTime);
        return <span>{Math.round(totalTime)}</span>;
      }
    }
  ];

  return (
    <div className="d-flex flex-direct-column">
      <GroupDetailVideoFilter groupId={groupId} onChangeUser={onChangeUser} />
      <Table
        rowKey="_id"
        rowClassName={(record, index) => formatTable2(index)}
        columns={columns}
        dataSource={videosByUser}
        pagination={false}
      />
      {total > videoParams.items_per_page && (
        <PaginationUI
          totalPage={Math.ceil(total / videoParams.items_per_page)}
          currentPage={videoParams.page}
          onChange={handleTableVideoChange}
        />
      )}
    </div>
  );
}
