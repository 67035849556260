export const defaultJson = {
  v: '5.9.2',
  fr: 25,
  ip: 0,
  op: 50,
  w: 1920,
  h: 1080,
  nm: '01-Float',
  ddd: 0,
  assets: [],
  fonts: {
    list: [
      {
        origin: 0,
        fPath: '',
        fClass: '',
        fFamily: 'Montserrat',
        fWeight: '',
        fStyle: 'ExtraBold',
        fName: 'Montserrat-ExtraBold',
        ascent: 74.2089135739952
      },
      {
        origin: 0,
        fPath: '',
        fClass: '',
        fFamily: 'Anton',
        fWeight: '',
        fStyle: 'Regular',
        fName: 'Anton-Regular',
        ascent: 85.9474999997765
      }
    ]
  },
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 5,
      nm: ' ',
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11
        },
        r: {
          a: 0,
          k: 0,
          ix: 10
        },
        p: {
          a: 0,
          k: [714, 616, 0],
          ix: 2,
          l: 2
        },
        a: {
          a: 0,
          k: [0, 0, 0],
          ix: 1,
          l: 2
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      t: {
        d: {
          k: [
            {
              s: {
                s: 200,
                f: 'Anton-Regular',
                t: ' ',
                ca: 1,
                j: 0,
                tr: -1,
                lh: 320,
                ls: 0,
                fc: [1, 1, 1],
                sc: [0, 0, 0],
                sw: 0.00999999977648,
                of: false
              },
              t: 0
            }
          ]
        },
        p: {},
        m: {
          g: 1,
          a: {
            a: 0,
            k: [0, 0],
            ix: 2
          }
        },
        a: []
      },
      ip: 0,
      op: 50,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 5,
      nm: 'Text',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: {
                x: [0.833],
                y: [0.833]
              },
              o: {
                x: [0.167],
                y: [0.167]
              },
              t: 0,
              s: [0]
            },
            {
              t: 35,
              s: [100]
            }
          ],
          ix: 11
        },
        r: {
          a: 0,
          k: 0,
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.667,
                y: 1
              },
              o: {
                x: 0.859,
                y: 0
              },
              t: 0,
              s: [527.516, 286.085, 0],
              to: [69.667, 0, 0],
              ti: [-71.702, 0, 0]
            },
            {
              i: {
                x: 0.667,
                y: 1
              },
              o: {
                x: 0.167,
                y: 0
              },
              t: 25,
              s: [945.516, 286.085, 0],
              to: [71.702, 0, 0],
              ti: [-2.035, 0, 0]
            },
            {
              t: 35,
              s: [957.725, 286.085, 0]
            }
          ],
          ix: 2,
          l: 2
        },
        a: {
          a: 0,
          k: [0.516, -31.415, 0],
          ix: 1,
          l: 2
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      t: {
        d: {
          k: [
            {
              s: {
                sz: [1349, 383.000030517578],
                ps: [-674.5, -115.008323669434],
                s: 155,
                f: 'Montserrat-ExtraBold',
                t: 'HELLO',
                ca: 1,
                j: 2,
                tr: 0,
                lh: 186,
                ls: 0,
                fc: [1, 1, 1]
              },
              t: 0
            }
          ]
        },
        p: {},
        m: {
          g: 1,
          a: {
            a: 0,
            k: [0, 0],
            ix: 2
          }
        },
        a: []
      },
      ip: 0,
      op: 50,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Shape Layer 1',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: {
                x: [0.833],
                y: [0.833]
              },
              o: {
                x: [0.167],
                y: [0.167]
              },
              t: 0,
              s: [0]
            },
            {
              t: 35,
              s: [100]
            }
          ],
          ix: 11
        },
        r: {
          a: 0,
          k: 0,
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.667,
                y: 1
              },
              o: {
                x: 0.813,
                y: 0
              },
              t: 0,
              s: [1408.5, 323.5, 0],
              to: [-67.333, 0, 0],
              ti: [67.333, 0, 0]
            },
            {
              t: 25,
              s: [1004.5, 323.5, 0]
            }
          ],
          ix: 2,
          l: 2
        },
        a: {
          a: 0,
          k: [-28.5, -0.5, 0],
          ix: 1,
          l: 2
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: {
                a: 0,
                k: [470, 140.848],
                ix: 2,
                x: "var $bm_rt;\nvar text_width, text_height, box_width, box_height;\ntext_width = thisComp.layer('Text').sourceRectAtTime().width;\ntext_height = thisComp.layer('Text').sourceRectAtTime().height;\nbox_width = text_width;\nbox_height = text_height;\n$bm_rt = [\n    box_width,\n    box_height\n];"
              },
              p: {
                a: 0,
                k: [0, 0],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 4
              },
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.243137000589, 0.278430983599, 0.60784295774, 1],
                ix: 3
              },
              o: {
                a: 0,
                k: 100,
                ix: 4
              },
              w: {
                a: 0,
                k: 0,
                ix: 5
              },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.183006525975, 0.265897623698, 0.686274509804, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [-27.5, 1.5],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Rectangle 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};

export const textLayer = 1;
