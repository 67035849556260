export const defaultJson = {
  v: '5.9.2',
  fr: 25,
  ip: 0,
  op: 50,
  w: 3456,
  h: 1080,
  nm: '8-Stereo',
  ddd: 0,
  assets: [],
  fonts: {
    list: [
      {
        origin: 0,
        fPath: '',
        fClass: '',
        fFamily: 'Montserrat',
        fWeight: '',
        fStyle: 'ExtraBold',
        fName: 'Montserrat-ExtraBold',
        ascent: 70.4992065429688
      }
    ]
  },
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 5,
      nm: 'text',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 28, s: [0] },
            { t: 38, s: [100] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 0,
          k: [1728, 539.25, 0],
          ix: 2,
          l: 2,
          x: 'var $bm_rt;\nvar H, W;\nH = thisComp.height;\nW = thisComp.width;\n$bm_rt = [\n    W / 2,\n    H / 2\n];'
        },
        a: {
          a: 0,
          k: [2.791, -54.25, 0],
          ix: 1,
          l: 2,
          x: 'var $bm_rt;\nvar sourceSize, T, L, W, H;\nsourceSize = thisLayer.sourceRectAtTime(time, false);\nT = sourceSize.top;\nL = sourceSize.left;\nW = sourceSize.width;\nH = sourceSize.height;\n$bm_rt = [\n    L + W / 2,\n    T + H / 2\n];'
        },
        s: { a: 0, k: [110, 110, 100], ix: 6, l: 2 }
      },
      ao: 0,
      t: {
        d: {
          k: [
            {
              s: {
                sz: [1767.18188476563, 655],
                ps: [-899.954528808594, -264.099243164063],
                s: 150,
                f: 'Montserrat-ExtraBold',
                t: 'HELLO',
                ca: 1,
                j: 2,
                tr: 0,
                lh: 180,
                ls: 0,
                fc: [0.98, 0.942, 0.942],
                sc: [1, 1, 1],
                sw: 1,
                of: true
              },
              t: 0
            }
          ]
        },
        p: {},
        m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
        a: []
      },
      ip: 0,
      op: 50,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'front',
      parent: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 0,
          k: [-560, -225.455, 0],
          ix: 2,
          l: 2,
          x: "var $bm_rt;\nvar textPos, textAnch, textProps, myAnch, pos, myTime;\ntextPos = thisComp.layer('text').position;\ntextAnch = thisComp.layer('text').transform.anchorPoint;\ntextProps = thisComp.layer('text');\nmyAnch = thisLayer.transform.anchorPoint;\npos = [\n    $bm_sum($bm_sub($bm_sum($bm_sum(textPos[0], textAnch[0]), myAnch[0]), $bm_div(textProps.width, 2)), -1670),\n    $bm_sum($bm_sub($bm_sum($bm_sum(textPos[1], textAnch[1]), myAnch[1]), $bm_div(textProps.height, 2)), -460)\n];\nmyTime = thisLayer.transform.scale.key(2).time;\n$bm_rt = ease(time, myTime, $bm_sum(myTime, 0.3), pos, [\n    $bm_sum(pos[0], 30),\n    $bm_sub(pos[1], 30)\n]);"
        },
        a: {
          a: 0,
          k: [0, 0, 0],
          ix: 1,
          l: 2,
          x: 'var $bm_rt;\nvar sourceSize, T, L, W, H;\nsourceSize = thisLayer.sourceRectAtTime(time, false);\nT = sourceSize.top;\nL = sourceSize.left;\nW = sourceSize.width;\nH = sourceSize.height;\n$bm_rt = [\n    L,\n    T + H / 2\n];'
        },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 19,
              s: [0, 90.909, 100]
            },
            { t: 29, s: [90.909, 90.909, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: {
                a: 0,
                k: [740, 284],
                ix: 2,
                x: "var $bm_rt;\nvar margin, text_width, text_height, box_width, box_height;\nmargin = 100;\ntext_width = thisComp.layer('text').sourceRectAtTime().width;\ntext_height = thisComp.layer('text').sourceRectAtTime().height;\nbox_width = $bm_sum(text_width, $bm_mul(margin, 3));\nbox_height = $bm_sum(text_height, $bm_mul(margin, 2));\n$bm_rt = [\n    box_width,\n    box_height\n];"
              },
              p: { a: 0, k: [0, 0], ix: 3 },
              r: { a: 0, k: 0, ix: 4 },
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.888090186026, 0.019331027012, 0.985882448683, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-12, -4], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Rectangle 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'tm',
          s: { a: 0, k: 0, ix: 1 },
          e: { a: 0, k: 100, ix: 2 },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false
        }
      ],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'back',
      parent: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 0,
          k: [125, 0, 0],
          ix: 2,
          l: 2,
          x: "var $bm_rt;\nvar textPos, textAnch, textProps, myAnch, pos;\ntextPos = thisComp.layer('text').position;\ntextAnch = thisComp.layer('text').transform.anchorPoint;\ntextProps = thisComp.layer('text');\nmyAnch = thisLayer.transform.anchorPoint;\n$bm_rt = pos = [\n    $bm_sum($bm_sub($bm_sum($bm_sum(textPos[0], textAnch[0]), myAnch[0]), $bm_div(textProps.width, 2)), -1670),\n    $bm_sum($bm_sub($bm_sum($bm_sum(textPos[1], textAnch[1]), myAnch[1]), $bm_div(textProps.height, 2)), -450)\n];"
        },
        a: { a: 0, k: [-427.746, -4, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 19,
              s: [0, 90.909, 100]
            },
            { t: 29, s: [90.909, 90.909, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: {
                a: 0,
                k: [740, 284],
                ix: 2,
                x: "var $bm_rt;\nvar margin, text_width, text_height, box_width, box_height;\nmargin = 100;\ntext_width = thisComp.layer('text').sourceRectAtTime().width;\ntext_height = thisComp.layer('text').sourceRectAtTime().height;\nbox_width = $bm_sum(text_width, $bm_mul(margin, 3));\nbox_height = $bm_sum(text_height, $bm_mul(margin, 2));\n$bm_rt = [\n    box_width,\n    box_height\n];"
              },
              p: { a: 0, k: [0, 0], ix: 3 },
              r: { a: 0, k: 0, ix: 4 },
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.662934905407, 0.018290205563, 0.706666714537, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-12, -4], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Rectangle 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'tm',
          s: { a: 0, k: 0, ix: 1 },
          e: { a: 0, k: 100, ix: 2 },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false
        }
      ],
      ip: 0,
      op: 50,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};

export const textLayer = 0;
