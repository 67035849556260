export const ENDPOINTS = {
  groups: () => `/organization/by-user`,
  groupsAll: () => `/organization`,
  detail: (id) => `/organization/${id}`,
  detailMember: (id) => `/organization/${id}/member-detail`,
  groupMembers: (id) => `/organization/${id}/members`,
  groupMembersInvite: (id) => `/organization/${id}/invite`,
  addGroupMember: (id) => `/organization/invite/${id}/add-member`,
  addGroup: () => `/organization`,
  editGroup: (groupId) => `/organization/${groupId}`,
  leaveGroup: (id) => `/organization/${id}/member-leave`,
  removeMember: (id) => `/organization/${id}/member`,
  activeGroup: () => `/users`,
  changePermission: (id) => `/organization/${id}/permission/change`,
  entrustVideo: (id) => `/organization/${id}/video/transfer`,
  inviteMember: (id) => `/organization/${id}/invite`,
  inviteDetail: (token) => `/organization/invite/${token}`
};
