export const defaultJson = {
  v: '5.9.2',
  fr: 30,
  ip: 0,
  op: 60,
  w: 1920,
  h: 1021,
  nm: '15-Blow',
  ddd: 0,
  assets: [],
  fonts: {
    list: [
      {
        origin: 0,
        fPath: '',
        fClass: '',
        fFamily: 'Montserrat',
        fWeight: '',
        fStyle: 'ExtraBold',
        fName: 'Montserrat-ExtraBold',
        ascent: 70.9991455078125
      }
    ]
  },
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 5,
      nm: 'Hello',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [960, 818.378, 0], ix: 2, l: 2 },
        a: { a: 0, k: [2.791, -54.25, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.758, 0.758, 0.333], y: [0, 0, 0] },
              t: 18,
              s: [0, 0, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 29,
              s: [110, 110, 100]
            },
            { t: 35, s: [100, 100, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      t: {
        d: {
          k: [
            {
              s: {
                sz: [1349, 175],
                ps: [-674.5, -115.008323669434],
                s: 155,
                f: 'Montserrat-ExtraBold',
                t: 'HELLO',
                ca: 1,
                j: 2,
                tr: 0,
                lh: 186,
                ls: 0,
                fc: [0, 0, 0]
              },
              t: 0
            }
          ]
        },
        p: {},
        m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
        a: []
      },
      ip: 0,
      op: 60,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Shape Layer 1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [960, 510.5, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: 'f',
          pt: {
            a: 1,
            k: [
              {
                t: 21,
                s: [
                  {
                    i: [
                      [9.082, 4.401],
                      [0.167, 0],
                      [0.166, 0],
                      [0.5, -0.5],
                      [0.28, -0.72],
                      [1.878, -8.825],
                      [1.144, -9.327],
                      [5.138, -26.878],
                      [6.01, -23.459],
                      [6.848, -3.179],
                      [1.82, -6.746],
                      [6.558, 4.942],
                      [7.327, 4.173],
                      [7.019, 4.481],
                      [9.837, 1.663],
                      [-2.337, -5.99],
                      [-2.446, -5.5],
                      [-2.556, -5.987],
                      [-2.102, -6.045],
                      [16.829, 7.086],
                      [20.917, 0.421],
                      [-4.999, -7.168],
                      [-4.075, -8.092],
                      [10.064, -3.629],
                      [6.878, -7.036],
                      [-5.286, -23.619],
                      [-30.451, 2.429],
                      [2.894, 4.282],
                      [-1.056, 7.606],
                      [-11.186, 6.687],
                      [-13.82, 0.904],
                      [-11.378, -4.791],
                      [-7.358, -5.76],
                      [1.208, 6.458],
                      [-0.456, 8.123],
                      [-19.199, 3.345],
                      [-15.621, -10.229],
                      [-11.452, -11.048],
                      [-3.203, -19.297],
                      [-23.872, 2.129],
                      [-8.427, -10.574],
                      [1.642, -14.535],
                      [4.849, -6.621],
                      [-32.244, 1.075],
                      [-2.968, 24.048],
                      [18.947, 7.661],
                      [4.77, 37.375],
                      [26.427, -4.379],
                      [16.058, -9.611],
                      [-3.397, 49.286],
                      [10.378, -10.289],
                      [39.957, -18.376],
                      [14.329, 64.769]
                    ],
                    o: [
                      [-0.166, 0],
                      [-0.167, 0],
                      [-0.72, 0.28],
                      [-0.5, 0.5],
                      [-3.344, 7.371],
                      [-1.878, 8.825],
                      [-3.439, 28.029],
                      [-5.138, 26.878],
                      [-8.788, -3.464],
                      [-6.848, 3.179],
                      [-6.443, -5.057],
                      [-6.558, -4.942],
                      [-7.085, -4.414],
                      [-7.02, -4.481],
                      [-1.796, 6.542],
                      [2.337, 5.99],
                      [2.672, 6.009],
                      [2.556, 5.987],
                      [-21.767, -1.236],
                      [-16.829, -7.086],
                      [1.931, 10.236],
                      [4.998, 7.168],
                      [-10.864, -0.551],
                      [-10.064, 3.629],
                      [-15.595, 15.953],
                      [5.286, 23.619],
                      [-2.48, -3.266],
                      [-2.894, -4.282],
                      [1.436, -10.346],
                      [11.186, -6.687],
                      [14.13, -0.925],
                      [11.378, 4.791],
                      [-2.623, -5.043],
                      [-1.208, -6.459],
                      [4.584, -18.85],
                      [19.199, -3.345],
                      [20.99, 1.51],
                      [11.452, 11.048],
                      [15.835, -9.834],
                      [14.105, -1.258],
                      [6.719, 8.43],
                      [-0.982, 8.694],
                      [33.685, -5.787],
                      [21.136, -7.563],
                      [3.044, -24.661],
                      [20.4, -11.924],
                      [-2.327, -18.235],
                      [-24.245, 4.756],
                      [5.973, -50.039],
                      [-15.584, 5.082],
                      [-25.283, 36.384],
                      [-14.882, -65.193],
                      [-1.79, -8.089]
                    ],
                    v: [
                      [113.5, 98],
                      [113, 98],
                      [112.5, 98],
                      [110.67, 99.17],
                      [109.5, 101],
                      [101.85, 125.533],
                      [97.5, 153],
                      [84.428, 235.927],
                      [67.5, 312],
                      [43.274, 312.342],
                      [29.5, 328],
                      [10.163, 312.837],
                      [-10.5, 299],
                      [-30.937, 284.937],
                      [-55.5, 275],
                      [-53.681, 293.781],
                      [-45.5, 311],
                      [-37.573, 328.973],
                      [-30.5, 347],
                      [-86.137, 331.388],
                      [-140.5, 317],
                      [-129.107, 342.108],
                      [-114.5, 364],
                      [-146.49, 368.81],
                      [-172.5, 385],
                      [-189.034, 452.787],
                      [-136.5, 493],
                      [-145.652, 482.255],
                      [-149.5, 465],
                      [-128.788, 438.919],
                      [-89.5, 427],
                      [-50.921, 433.986],
                      [-22.5, 451],
                      [-28.309, 433.809],
                      [-29.5, 412],
                      [10.722, 379.191],
                      [67.5, 390],
                      [115.84, 409.16],
                      [137.5, 455],
                      [181.5, 418],
                      [219.5, 435],
                      [234.5, 474],
                      [222.5, 492],
                      [309.5, 500],
                      [357.5, 460],
                      [321.5, 420],
                      [370.5, 344],
                      [326.5, 314],
                      [267.5, 344],
                      [287.5, 194],
                      [257.5, 226],
                      [167.5, 323],
                      [123.5, 123]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 22,
                s: [
                  {
                    i: [
                      [-17, 0],
                      [27.277, 41.01],
                      [5.936, 5.731],
                      [4.546, 20.407],
                      [21.173, 1.68],
                      [20.833, 18.932],
                      [24.54, -12.704],
                      [-4.183, 48.848],
                      [9.616, 11.79],
                      [4.102, -7.571],
                      [14.206, -24.127],
                      [20.85, -25.817],
                      [11.504, 57.336],
                      [10.241, 7.634],
                      [12.987, -14.013],
                      [8.253, 18.242],
                      [12.587, 13.569],
                      [20.411, -68.742],
                      [3.114, -9.843],
                      [-2.817, -9.681],
                      [-14.01, -78.572],
                      [38.152, 13.758],
                      [13.826, -5.341],
                      [-12.398, -24.602],
                      [15.309, -16.767],
                      [-73.093, 8.031],
                      [-4.368, -0.034],
                      [-3, 8.333],
                      [0.388, 33.987],
                      [-28.386, 3.058],
                      [-51.253, -3.098],
                      [5.111, 9.075],
                      [-22.978, 18.501],
                      [-6.375, 1.958],
                      [-5.333, 0],
                      [-10.999, -10.999],
                      [-10.92, -1.107],
                      [-2.82, 15.444],
                      [-6.299, 11.657],
                      [-18.173, 3.827],
                      [15.639, -64.21],
                      [-0.787, -13.476],
                      [-7.828, 5.838],
                      [-7.525, 6.142],
                      [-53.386, -6.719],
                      [1.774, -30.441],
                      [10.518, -11.126],
                      [-0.096, -10.238],
                      [-20.39, -3.017],
                      [0.659, -24.326],
                      [11.852, -5.115],
                      [3.716, -12.121],
                      [-13.592, -4.408]
                    ],
                    o: [
                      [43.634, 0.604],
                      [-5.547, -6.12],
                      [12.108, -9.325],
                      [-3.584, -16.088],
                      [14.089, -33.344],
                      [-14.652, -18.142],
                      [4.358, -47.411],
                      [1.287, -15.032],
                      [-12.483, -4.141],
                      [-15.603, 22.73],
                      [-21.322, 28.678],
                      [-15.549, -50.724],
                      [-2.386, -11.89],
                      [-16.578, 10.422],
                      [-10.337, -15.347],
                      [-7.915, -17.495],
                      [-13.38, 75.333],
                      [-3.135, 10.558],
                      [-2.478, 7.834],
                      [-45.218, -46.386],
                      [-35.48, -15.571],
                      [-11.843, -4.271],
                      [8.069, 32.265],
                      [-23.058, 6.715],
                      [-41.654, 45.622],
                      [4.094, -0.239],
                      [10.65, -0.683],
                      [-26.263, -10.351],
                      [-0.362, -31.731],
                      [64.536, -6.952],
                      [0.735, -9.65],
                      [-12.627, -22.42],
                      [5.543, -2.79],
                      [5.333, 0],
                      [17.275, 3.726],
                      [6.323, 6.323],
                      [7.702, -11.458],
                      [2.887, -15.809],
                      [9.669, -12.331],
                      [62.609, 0.405],
                      [-3.18, 13.056],
                      [11.972, -1.694],
                      [6.099, -7.568],
                      [21.848, -24.819],
                      [24.337, 4.33],
                      [-3.308, 17.181],
                      [-6.278, 6.64],
                      [15.26, 1.208],
                      [18.162, 5.504],
                      [-4.707, 11.644],
                      [-10.748, 4.638],
                      [12.64, 5.36],
                      [17, 0]
                    ],
                    v: [
                      [419.5, 511],
                      [468.5, 424],
                      [449.5, 408],
                      [487.5, 377],
                      [427.5, 364],
                      [411.5, 264],
                      [329.5, 249],
                      [343.5, 101],
                      [341.5, 56],
                      [319.5, 71],
                      [274.5, 141],
                      [213.5, 232],
                      [176.5, 61],
                      [163.5, 26],
                      [126.5, 70],
                      [99.5, 19],
                      [73.5, -32],
                      [26.5, 188],
                      [20.5, 220],
                      [12.5, 242],
                      [-117.5, 296],
                      [-227.5, 252],
                      [-266.5, 244],
                      [-224.5, 325],
                      [-280.5, 354],
                      [-222.5, 498],
                      [-212.5, 495],
                      [-184.5, 489],
                      [-235.5, 431],
                      [-184.5, 373],
                      [-42.5, 420],
                      [-55.5, 394],
                      [-50.5, 316],
                      [-33.5, 308],
                      [-17.5, 308],
                      [22.5, 332],
                      [44.5, 350],
                      [55.5, 306],
                      [68.5, 264],
                      [106.5, 236],
                      [186.5, 362],
                      [175.5, 400],
                      [195.5, 379],
                      [214.5, 357],
                      [311.5, 314],
                      [339.5, 372],
                      [316.5, 413],
                      [299.5, 435],
                      [346.5, 428],
                      [379.5, 466],
                      [352.5, 487],
                      [321.5, 504],
                      [368.5, 511]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 23,
                s: [
                  {
                    i: [
                      [0.808, -0.141],
                      [0.05, -0.283],
                      [0.095, -0.239],
                      [0.122, -0.211],
                      [0.132, -0.201],
                      [3.808, -23.942],
                      [4.206, -23.544],
                      [5.42, -22.33],
                      [7.45, -20.3],
                      [2.02, -7.537],
                      [1.793, -7.776],
                      [1.002, -7.726],
                      [-0.355, -7.388],
                      [-2.298, 1.868],
                      [-1.848, 2.319],
                      [-1.875, 2.291],
                      [-2.382, 1.785],
                      [-5.902, 3.931],
                      [-6.872, 2.962],
                      [-8.409, 1.424],
                      [-10.513, -0.68],
                      [-6.724, -3.026],
                      [-5.757, -3.993],
                      [-5.154, -4.596],
                      [-4.916, -4.834],
                      [-1.331, -1.919],
                      [-1.45, -1.8],
                      [-2.018, -1.232],
                      [-3.038, -0.212],
                      [-0.054, 4.734],
                      [0.769, 4.825],
                      [1.063, 4.803],
                      [0.829, 4.669],
                      [2.148, 12.012],
                      [2.22, 11.976],
                      [2.337, 11.851],
                      [2.499, 11.636],
                      [0.474, 2.291],
                      [0.7, 2.25],
                      [1.126, 1.959],
                      [1.752, 1.419],
                      [1.494, -1.508],
                      [0.938, -2.023],
                      [0.725, -2.129],
                      [0.856, -1.824],
                      [3.922, -8.216],
                      [3.912, -8.103],
                      [3.79, -7.797],
                      [2.677, 20.386],
                      [4.941, 19.862],
                      [1.994, 20.918],
                      [5.067, 20.394],
                      [0.388, 0.278]
                    ],
                    o: [
                      [0.011, 0.344],
                      [-0.05, 0.283],
                      [-0.095, 0.239],
                      [-0.122, 0.211],
                      [-4.227, 23.524],
                      [-3.808, 23.942],
                      [-4.206, 23.544],
                      [-5.42, 22.33],
                      [-1.682, 7.009],
                      [-2.02, 7.537],
                      [-1.793, 7.776],
                      [-1.002, 7.726],
                      [3.228, -0.939],
                      [2.298, -1.868],
                      [1.848, -2.319],
                      [1.875, -2.291],
                      [5.5, -4.334],
                      [5.902, -3.931],
                      [6.872, -2.962],
                      [8.409, -1.424],
                      [8.056, 1.694],
                      [6.724, 3.026],
                      [5.757, 3.993],
                      [5.154, 4.596],
                      [1.664, 1.586],
                      [1.331, 1.919],
                      [1.45, 1.8],
                      [2.018, 1.232],
                      [1.406, -4.532],
                      [0.054, -4.734],
                      [-0.769, -4.825],
                      [-1.063, -4.803],
                      [-2.123, -11.96],
                      [-2.148, -12.012],
                      [-2.219, -11.976],
                      [-2.337, -11.851],
                      [-0.447, -2.082],
                      [-0.474, -2.291],
                      [-0.7, -2.25],
                      [-1.126, -1.959],
                      [-2.393, 0.583],
                      [-1.494, 1.508],
                      [-0.938, 2.023],
                      [-0.725, 2.129],
                      [-3.82, 8.135],
                      [-3.922, 8.216],
                      [-3.912, 8.103],
                      [-3.352, -20.596],
                      [-2.677, -20.386],
                      [-4.959, -19.933],
                      [-1.993, -20.918],
                      [-0.639, -0.028],
                      [-0.388, -0.278]
                    ],
                    v: [
                      [73.5, -296],
                      [73.436, -295.063],
                      [73.215, -294.285],
                      [72.885, -293.614],
                      [72.5, -293],
                      [60.652, -221.598],
                      [48.834, -150.166],
                      [34.6, -81.15],
                      [15.5, -17],
                      [9.806, 4.891],
                      [3.945, 27.932],
                      [-0.389, 51.256],
                      [-1.5, 74],
                      [6.67, 69.67],
                      [12.769, 63.269],
                      [18.234, 56.234],
                      [24.5, 50],
                      [41.46, 37.46],
                      [60.479, 26.979],
                      [83.258, 20.258],
                      [111.5, 19],
                      [133.579, 26.171],
                      [152.21, 36.79],
                      [168.486, 49.764],
                      [183.5, 64],
                      [187.88, 69.37],
                      [191.939, 75.061],
                      [197.028, 79.722],
                      [204.5, 82],
                      [206.557, 68.072],
                      [205.352, 53.705],
                      [202.47, 39.236],
                      [199.5, 25],
                      [193.105, -10.98],
                      [186.565, -46.985],
                      [179.742, -82.747],
                      [172.5, -118],
                      [171.168, -124.621],
                      [169.457, -131.494],
                      [166.767, -137.871],
                      [162.5, -143],
                      [156.756, -139.761],
                      [153.195, -134.361],
                      [150.787, -128.031],
                      [148.5, -122],
                      [136.859, -97.426],
                      [125.081, -72.899],
                      [113.5, -49],
                      [105.192, -110.55],
                      [94.5, -171],
                      [85.582, -232.655],
                      [76.5, -295],
                      [75.126, -295.626]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 24,
                s: [
                  {
                    i: [
                      [5.351, 20.086],
                      [0.25, 0],
                      [0.25, 0],
                      [0.25, 0],
                      [0.25, 0],
                      [0.118, -0.215],
                      [0.139, -0.194],
                      [0.076, -0.257],
                      [-0.07, -0.404],
                      [1.882, -8.02],
                      [1.889, -8.007],
                      [1.438, -8.233],
                      [0.527, -8.7],
                      [0.355, -6.236],
                      [0.473, -5.985],
                      [0.757, -5.882],
                      [1.206, -5.926],
                      [3.573, -15.034],
                      [3.792, -14.9],
                      [3.698, -14.541],
                      [3.292, -13.958],
                      [0.405, -1.431],
                      [0.178, -1.539],
                      [-0.441, -1.338],
                      [-1.452, -0.83],
                      [-1.334, 1.367],
                      [-0.787, 1.905],
                      [-0.684, 1.961],
                      [-1.026, 1.534],
                      [-1.439, 1.443],
                      [-1.5, 1.288],
                      [-1.462, 1.448],
                      [-25.921, -0.95],
                      [-15.887, -11.12],
                      [-4.08, -2.92],
                      [-3.009, -3.991],
                      [-2.869, -4.298],
                      [-6.693, -0.474],
                      [1.812, 8.12],
                      [1.572, 8.206],
                      [1.094, 8.778],
                      [0.964, 9.233],
                      [5.845, 19.407],
                      [0.335, 22.512],
                      [1.875, -4.291],
                      [3.149, -3.018],
                      [6.436, -12.481],
                      [6.898, -9.435],
                      [0.674, 10.619],
                      [1.902, 10.258],
                      [2.556, 9.257],
                      [1.947, 9.107],
                      [2.099, 20.755]
                    ],
                    o: [
                      [-0.25, 0],
                      [-0.25, 0],
                      [-0.25, 0],
                      [-0.25, 0],
                      [-0.014, 0.32],
                      [-0.118, 0.215],
                      [-0.139, 0.194],
                      [-0.076, 0.257],
                      [-1.416, 8.273],
                      [-1.882, 8.02],
                      [-1.889, 8.007],
                      [-1.438, 8.233],
                      [-0.402, 6.635],
                      [-0.355, 6.236],
                      [-0.473, 5.985],
                      [-0.757, 5.882],
                      [-3.041, 14.943],
                      [-3.573, 15.034],
                      [-3.792, 14.9],
                      [-3.698, 14.541],
                      [-0.24, 1.015],
                      [-0.405, 1.431],
                      [-0.178, 1.539],
                      [0.441, 1.338],
                      [2.327, -0.347],
                      [1.334, -1.367],
                      [0.787, -1.905],
                      [0.684, -1.961],
                      [1.279, -1.912],
                      [1.439, -1.443],
                      [1.5, -1.288],
                      [15.431, -13.922],
                      [25.921, 0.951],
                      [3.673, 3.328],
                      [4.08, 2.92],
                      [4.832, 2.335],
                      [2.869, 4.298],
                      [-0.181, -8.312],
                      [-1.812, -8.121],
                      [-1.659, -8.656],
                      [-1.094, -8.778],
                      [-2.284, -21.861],
                      [-5.845, -19.407],
                      [-5.351, 0.816],
                      [-1.875, 4.291],
                      [-6.358, 11.642],
                      [-6.436, 12.481],
                      [-1.401, -11.267],
                      [-0.674, -10.619],
                      [-1.829, -9.863],
                      [-2.556, -9.257],
                      [-4.253, -19.894],
                      [-2.099, -20.755]
                    ],
                    v: [
                      [74.5, -328],
                      [73.75, -328],
                      [73, -328],
                      [72.25, -328],
                      [71.5, -328],
                      [71.281, -327.219],
                      [70.874, -326.626],
                      [70.53, -325.97],
                      [70.5, -325],
                      [65.439, -300.62],
                      [59.667, -276.64],
                      [54.562, -252.339],
                      [51.5, -227],
                      [50.406, -207.731],
                      [49.206, -189.437],
                      [47.403, -171.674],
                      [44.5, -154],
                      [34.5, -108.979],
                      [23.376, -64.023],
                      [12.063, -19.805],
                      [1.5, 23],
                      [0.435, 26.746],
                      [-0.538, 31.278],
                      [-0.242, 35.671],
                      [2.5, 39],
                      [7.881, 36.309],
                      [10.951, 31.281],
                      [13.046, 25.363],
                      [15.5, 20],
                      [19.601, 15.045],
                      [24.033, 11.026],
                      [28.5, 7],
                      [95.659, -11.282],
                      [163.5, 8],
                      [175.498, 17.002],
                      [186.5, 27],
                      [196.604, 38.396],
                      [209.5, 47],
                      [206.043, 22.421],
                      [200.5, -2],
                      [196.479, -28.067],
                      [193.5, -55],
                      [179.038, -115.512],
                      [167.5, -177],
                      [157.849, -168.151],
                      [151.5, -156],
                      [132.405, -118.845],
                      [112.5, -85],
                      [109.876, -117.757],
                      [106.5, -149],
                      [99.588, -177.567],
                      [92.5, -205],
                      [84.323, -266.356]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 25,
                s: [
                  {
                    i: [
                      [6.002, 18.414],
                      [4.078, -23.655],
                      [4.097, -23.636],
                      [4.37, -23.363],
                      [4.897, -22.836],
                      [5.678, -22.055],
                      [-0.485, 0.071],
                      [-0.642, 0.689],
                      [-0.587, 0.774],
                      [-0.317, 0.326],
                      [-2.157, 1.593],
                      [-2.169, 1.581],
                      [-2.303, 1.447],
                      [-2.558, 1.192],
                      [-13.058, 0.953],
                      [-12.695, -2.902],
                      [-10.664, -5.529],
                      [-6.966, -6.927],
                      [-0.948, -1.052],
                      [-1, -1],
                      [-1.052, -0.948],
                      [-1.105, -0.896],
                      [-0.408, -0.592],
                      [-0.436, -0.564],
                      [-0.435, -0.565],
                      [-0.404, -0.596],
                      [-0.501, -0.681],
                      [-0.443, -0.695],
                      [-0.345, -0.041],
                      [-0.208, 1.279],
                      [1.058, 4.858],
                      [1.062, 4.898],
                      [0.973, 4.966],
                      [0.793, 5.062],
                      [1.137, 4.936],
                      [1.245, 4.888],
                      [1.11, 4.883],
                      [0.733, 4.921],
                      [0.717, 5.286],
                      [0.661, 5.329],
                      [0.613, 5.378],
                      [0.574, 5.434],
                      [0.28, 5.431],
                      [0.349, 5.467],
                      [0.769, 5.427],
                      [1.541, 5.311],
                      [3.537, -10.88],
                      [3.542, -10.875],
                      [4.109, -10.308],
                      [5.238, -9.179],
                      [2.876, 21.541],
                      [2.164, 22.253],
                      [3.206, 21.211]
                    ],
                    o: [
                      [-4.314, 23.42],
                      [-4.078, 23.655],
                      [-4.097, 23.636],
                      [-4.37, 23.363],
                      [-4.897, 22.836],
                      [0.114, 1.081],
                      [0.485, -0.071],
                      [0.642, -0.689],
                      [0.587, -0.774],
                      [2.267, -1.483],
                      [2.157, -1.593],
                      [2.169, -1.581],
                      [2.303, -1.447],
                      [11.754, -6.037],
                      [13.058, -0.953],
                      [12.695, 2.902],
                      [10.664, 5.529],
                      [0.895, 1.105],
                      [0.948, 1.052],
                      [1, 1],
                      [1.052, 0.948],
                      [0.352, 0.648],
                      [0.408, 0.592],
                      [0.436, 0.564],
                      [0.435, 0.565],
                      [0.521, 0],
                      [0.501, 0.681],
                      [0.443, 0.695],
                      [0.345, 0.041],
                      [-0.961, -4.846],
                      [-1.058, -4.858],
                      [-1.062, -4.898],
                      [-0.973, -4.966],
                      [-0.787, -5.028],
                      [-1.137, -4.936],
                      [-1.245, -4.888],
                      [-1.11, -4.883],
                      [-0.782, -5.249],
                      [-0.717, -5.286],
                      [-0.661, -5.329],
                      [-0.613, -5.378],
                      [-0.562, -5.319],
                      [-0.28, -5.431],
                      [-0.349, -5.467],
                      [-0.769, -5.427],
                      [-4.093, 10.323],
                      [-3.537, 10.88],
                      [-3.542, 10.874],
                      [-4.109, 10.308],
                      [-5.342, -19.075],
                      [-2.876, -21.541],
                      [-2.164, -22.253],
                      [-3.206, -21.211]
                    ],
                    v: [
                      [74.5, -373],
                      [61.975, -302.324],
                      [49.775, -231.324],
                      [37.137, -160.762],
                      [23.3, -91.4],
                      [7.5, -24],
                      [8.45, -22.618],
                      [10.194, -23.89],
                      [12.091, -26.217],
                      [13.5, -28],
                      [20.104, -32.646],
                      [26.562, -37.438],
                      [33.239, -42.011],
                      [40.5, -46],
                      [78.135, -56.177],
                      [117.182, -52.945],
                      [152.638, -39.991],
                      [179.5, -21],
                      [182.265, -17.765],
                      [185.186, -14.687],
                      [188.265, -11.765],
                      [191.5, -9],
                      [192.648, -7.148],
                      [193.922, -5.422],
                      [195.235, -3.735],
                      [196.5, -2],
                      [198.043, -0.812],
                      [199.469, 1.418],
                      [200.66, 2.69],
                      [201.5, 1],
                      [198.449, -13.55],
                      [195.247, -28.176],
                      [192.172, -42.965],
                      [189.5, -58],
                      [186.553, -72.935],
                      [182.919, -87.66],
                      [179.326, -102.305],
                      [176.5, -117],
                      [174.253, -132.801],
                      [172.188, -148.723],
                      [170.279, -164.783],
                      [168.5, -181],
                      [167.324, -197.145],
                      [166.468, -213.512],
                      [164.878, -229.873],
                      [161.5, -246],
                      [150.195, -214.055],
                      [139.717, -181.283],
                      [128.38, -149.37],
                      [114.5, -120],
                      [102.612, -181.361],
                      [95.49, -247.49],
                      [87.874, -313.124]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 26,
                s: [
                  {
                    i: [
                      [1.875, 1.041],
                      [0.083, 0],
                      [0.083, 0],
                      [0.083, 0],
                      [0.083, 0],
                      [0.083, 0],
                      [0.083, 0],
                      [0.083, 0],
                      [0.083, 0],
                      [0, -0.2],
                      [0, -0.2],
                      [0, -0.2],
                      [0, -0.2],
                      [0, -0.2],
                      [2, -11.742],
                      [2.633, -11.409],
                      [2.816, -11.337],
                      [2.55, -11.526],
                      [1.021, -6.031],
                      [0.837, -6.083],
                      [0.773, -6.136],
                      [0.828, -6.188],
                      [1.385, -5.814],
                      [1.389, -5.765],
                      [0.809, -5.819],
                      [-0.354, -5.975],
                      [-1.401, 1.957],
                      [-0.852, 2.403],
                      [-0.65, 2.495],
                      [-0.796, 2.233],
                      [-2.392, 7.543],
                      [-2.189, 7.76],
                      [-1.953, 7.837],
                      [-1.683, 7.774],
                      [-1.939, -1.946],
                      [-1.836, -2.075],
                      [-1.729, -2.216],
                      [-1.619, -2.37],
                      [-1.277, -2.609],
                      [-1.337, -2.557],
                      [-1.766, -2.17],
                      [-2.566, -1.446],
                      [0.357, 8.165],
                      [0.768, 8.19],
                      [1.027, 8.18],
                      [1.134, 8.135],
                      [1.454, 7.986],
                      [1.465, 7.924],
                      [1.154, 7.851],
                      [0.521, 7.767],
                      [-0.188, 2.544],
                      [0.001, 2.663],
                      [0.689, 2.162]
                    ],
                    o: [
                      [-0.083, 0],
                      [-0.083, 0],
                      [-0.083, 0],
                      [-0.083, 0],
                      [-0.083, 0],
                      [-0.083, 0],
                      [-0.083, 0],
                      [-0.083, 0],
                      [0, 0.2],
                      [0, 0.2],
                      [0, 0.2],
                      [0, 0.2],
                      [0, 0.2],
                      [-0.918, 12.336],
                      [-2, 11.742],
                      [-2.633, 11.409],
                      [-2.816, 11.337],
                      [-1.322, 5.978],
                      [-1.021, 6.031],
                      [-0.837, 6.083],
                      [-0.773, 6.136],
                      [-0.798, 5.965],
                      [-1.385, 5.814],
                      [-1.389, 5.765],
                      [-0.809, 5.819],
                      [2.298, -1.156],
                      [1.401, -1.957],
                      [0.852, -2.403],
                      [0.65, -2.495],
                      [2.56, -7.186],
                      [2.392, -7.543],
                      [2.189, -7.76],
                      [1.953, -7.837],
                      [2.038, 1.832],
                      [1.939, 1.946],
                      [1.836, 2.075],
                      [1.729, 2.216],
                      [1.587, 2.324],
                      [1.277, 2.609],
                      [1.337, 2.557],
                      [1.766, 2.17],
                      [0.206, -8.104],
                      [-0.357, -8.165],
                      [-0.768, -8.19],
                      [-1.027, -8.18],
                      [-1.12, -8.036],
                      [-1.454, -7.986],
                      [-1.465, -7.924],
                      [-1.154, -7.851],
                      [-0.121, -1.805],
                      [0.188, -2.544],
                      [-0.001, -2.663],
                      [-0.689, -2.162]
                    ],
                    v: [
                      [79.5, -395],
                      [79.25, -395],
                      [79, -395],
                      [78.75, -395],
                      [78.5, -395],
                      [78.25, -395],
                      [78, -395],
                      [77.75, -395],
                      [77.5, -395],
                      [77.5, -394.4],
                      [77.5, -393.8],
                      [77.5, -393.2],
                      [77.5, -392.6],
                      [77.5, -392],
                      [73.01, -355.947],
                      [65.948, -321.285],
                      [57.661, -287.23],
                      [49.5, -253],
                      [46.015, -234.986],
                      [43.258, -216.815],
                      [40.872, -198.486],
                      [38.5, -180],
                      [35.079, -162.357],
                      [30.772, -145.015],
                      [27.328, -127.666],
                      [26.5, -110],
                      [31.962, -114.758],
                      [35.254, -121.386],
                      [37.419, -128.82],
                      [39.5, -136],
                      [46.936, -158.128],
                      [53.816, -181.117],
                      [60.037, -204.548],
                      [65.5, -228],
                      [71.467, -222.336],
                      [77.13, -216.308],
                      [82.478, -209.876],
                      [87.5, -203],
                      [91.704, -195.516],
                      [95.532, -187.683],
                      [100.095, -180.508],
                      [106.5, -175],
                      [106.235, -199.412],
                      [104.51, -223.954],
                      [101.779, -248.518],
                      [98.5, -273],
                      [94.559, -297.036],
                      [90.101, -320.904],
                      [86.093, -344.57],
                      [83.5, -368],
                      [83.725, -374.679],
                      [84.131, -382.646],
                      [83.221, -390.04]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 27,
                s: [
                  {
                    i: [
                      [0.328, 0.088],
                      [0, -0.125],
                      [0, -0.125],
                      [0, -0.125],
                      [0, -0.125],
                      [0, -0.125],
                      [0, -0.125],
                      [0, -0.125],
                      [0, -0.125],
                      [0.445, -4.833],
                      [0.641, -4.762],
                      [0.78, -4.707],
                      [0.861, -4.667],
                      [0.885, -4.643],
                      [0.852, -4.634],
                      [0.761, -4.641],
                      [0.614, -4.663],
                      [0.289, -2.384],
                      [0.279, -2.384],
                      [0.281, -2.382],
                      [0.297, -2.379],
                      [0.327, -2.374],
                      [0.37, -2.368],
                      [0.427, -2.36],
                      [0.497, -2.351],
                      [0.545, -2.286],
                      [0.557, -2.295],
                      [0.518, -2.308],
                      [0.428, -2.324],
                      [0.287, -2.344],
                      [0.094, -2.367],
                      [-0.15, -2.394],
                      [-0.445, -2.423],
                      [-1.049, 3.541],
                      [-1.083, 3.606],
                      [-1.093, 3.644],
                      [-1.08, 3.655],
                      [-1.044, 3.638],
                      [-1.12, 4.705],
                      [-1.315, 4.511],
                      [-1.912, 4.05],
                      [-2.912, 3.322],
                      [-1.292, -6.041],
                      [-1.302, -6.031],
                      [-1.609, -5.724],
                      [-2.212, -5.121],
                      [1.431, 15.841],
                      [1.995, 15.362],
                      [1.817, 15.715],
                      [0.897, 16.899],
                      [0.246, 0.171],
                      [0.204, 0.213],
                      [0.231, 0.185]
                    ],
                    o: [
                      [0, 0.125],
                      [0, 0.125],
                      [0, 0.125],
                      [0, 0.125],
                      [0, 0.125],
                      [0, 0.125],
                      [0, 0.125],
                      [0, 0.125],
                      [-0.192, 4.919],
                      [-0.445, 4.833],
                      [-0.641, 4.762],
                      [-0.78, 4.707],
                      [-0.861, 4.667],
                      [-0.885, 4.643],
                      [-0.852, 4.634],
                      [-0.761, 4.641],
                      [-0.314, 2.382],
                      [-0.289, 2.384],
                      [-0.279, 2.384],
                      [-0.281, 2.382],
                      [-0.297, 2.379],
                      [-0.327, 2.374],
                      [-0.37, 2.368],
                      [-0.427, 2.36],
                      [-0.482, 2.28],
                      [-0.545, 2.286],
                      [-0.557, 2.295],
                      [-0.518, 2.308],
                      [-0.428, 2.324],
                      [-0.287, 2.344],
                      [-0.094, 2.367],
                      [0.15, 2.394],
                      [0.993, -3.447],
                      [1.049, -3.541],
                      [1.083, -3.606],
                      [1.093, -3.644],
                      [1.08, -3.655],
                      [1.329, -4.633],
                      [1.12, -4.705],
                      [1.315, -4.511],
                      [1.912, -4.05],
                      [1.578, 5.755],
                      [1.292, 6.041],
                      [1.302, 6.031],
                      [1.609, 5.724],
                      [-0.124, -17.151],
                      [-1.431, -15.841],
                      [-1.995, -15.362],
                      [-1.817, -15.715],
                      [-0.357, -0.06],
                      [-0.246, -0.171],
                      [-0.204, -0.213],
                      [-0.231, -0.185]
                    ],
                    v: [
                      [75.5, -448],
                      [75.5, -447.625],
                      [75.5, -447.25],
                      [75.5, -446.875],
                      [75.5, -446.5],
                      [75.5, -446.125],
                      [75.5, -445.75],
                      [75.5, -445.375],
                      [75.5, -445],
                      [74.529, -430.376],
                      [72.885, -415.988],
                      [70.739, -401.789],
                      [68.264, -387.732],
                      [65.631, -373.771],
                      [63.011, -359.859],
                      [60.577, -345.951],
                      [58.5, -332],
                      [57.599, -324.85],
                      [56.75, -317.699],
                      [55.914, -310.549],
                      [55.05, -303.406],
                      [54.117, -296.275],
                      [53.074, -289.161],
                      [51.882, -282.067],
                      [50.5, -275],
                      [48.946, -268.152],
                      [47.279, -261.282],
                      [45.653, -254.378],
                      [44.22, -247.431],
                      [43.134, -240.43],
                      [42.55, -233.364],
                      [42.621, -226.225],
                      [43.5, -219],
                      [46.569, -229.489],
                      [49.773, -240.216],
                      [53.043, -251.099],
                      [56.309, -262.055],
                      [59.5, -273],
                      [63.073, -287.074],
                      [66.625, -300.966],
                      [71.364, -313.875],
                      [78.5, -325],
                      [82.732, -307.232],
                      [86.55, -289.05],
                      [90.843, -271.343],
                      [96.5, -255],
                      [93.982, -304.281],
                      [88.659, -350.879],
                      [82.756, -397.287],
                      [78.5, -446],
                      [77.614, -446.364],
                      [76.957, -446.957],
                      [76.322, -447.572]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 28,
                s: [
                  {
                    i: [
                      [0.25, 0],
                      [0.038, -0.128],
                      [0.059, -0.107],
                      [0.07, -0.097],
                      [0.07, -0.097],
                      [0.059, -0.108],
                      [0.038, -0.129],
                      [0.007, -0.16],
                      [-0.035, -0.202],
                      [-0.097, -1.344],
                      [0.023, -1.273],
                      [0.11, -1.209],
                      [0.167, -1.15],
                      [0.192, -1.098],
                      [0.186, -1.052],
                      [0.148, -1.012],
                      [0.08, -0.978],
                      [-0.003, -0.693],
                      [-0.046, -0.694],
                      [-0.074, -0.697],
                      [-0.085, -0.702],
                      [-0.08, -0.71],
                      [-0.058, -0.72],
                      [-0.021, -0.732],
                      [0.032, -0.747],
                      [0.638, -3.817],
                      [0.946, -3.822],
                      [1.089, -3.81],
                      [1.068, -3.78],
                      [0.882, -3.733],
                      [1.196, -4.764],
                      [1.061, -4.872],
                      [0.709, -5.047],
                      [0.142, -5.288],
                      [-1.219, 2.636],
                      [-0.707, 3.022],
                      [-0.635, 3.125],
                      [-1.001, 2.945],
                      [-1.075, 2.897],
                      [-1.267, 2.712],
                      [-1.559, 2.334],
                      [-1.95, 1.764],
                      [-0.618, -0.299],
                      [-0.365, -0.552],
                      [-0.565, -0.352],
                      [-1.217, 0.301],
                      [0.038, 8.773],
                      [1.153, 8.504],
                      [1.413, 8.229],
                      [0.819, 7.947],
                      [0.25, 0],
                      [0.25, 0],
                      [0.25, 0]
                    ],
                    o: [
                      [-0.007, 0.16],
                      [-0.038, 0.128],
                      [-0.059, 0.107],
                      [-0.07, 0.097],
                      [-0.07, 0.097],
                      [-0.059, 0.108],
                      [-0.038, 0.129],
                      [-0.007, 0.16],
                      [0.247, 1.42],
                      [0.097, 1.344],
                      [-0.023, 1.273],
                      [-0.11, 1.209],
                      [-0.167, 1.15],
                      [-0.192, 1.098],
                      [-0.186, 1.052],
                      [-0.148, 1.012],
                      [-0.056, 0.694],
                      [0.003, 0.693],
                      [0.046, 0.694],
                      [0.074, 0.697],
                      [0.085, 0.702],
                      [0.08, 0.71],
                      [0.058, 0.72],
                      [0.021, 0.732],
                      [-0.165, 3.794],
                      [-0.638, 3.817],
                      [-0.946, 3.822],
                      [-1.089, 3.81],
                      [-1.068, 3.78],
                      [-1.116, 4.722],
                      [-1.196, 4.764],
                      [-1.061, 4.872],
                      [-0.709, 5.047],
                      [2.169, -1.966],
                      [1.219, -2.636],
                      [0.707, -3.022],
                      [0.635, -3.125],
                      [0.982, -2.889],
                      [1.075, -2.897],
                      [1.267, -2.712],
                      [1.559, -2.334],
                      [1.323, -0.406],
                      [0.618, 0.299],
                      [0.365, 0.552],
                      [0.565, 0.352],
                      [1.931, -9.036],
                      [-0.038, -8.773],
                      [-1.153, -8.504],
                      [-1.413, -8.229],
                      [-0.25, 0],
                      [-0.25, 0],
                      [-0.25, 0],
                      [-0.25, 0]
                    ],
                    v: [
                      [76.5, -460],
                      [76.43, -459.57],
                      [76.281, -459.219],
                      [76.085, -458.915],
                      [75.874, -458.626],
                      [75.678, -458.322],
                      [75.53, -457.97],
                      [75.46, -457.54],
                      [75.5, -457],
                      [76.008, -452.856],
                      [76.112, -448.932],
                      [75.904, -445.211],
                      [75.481, -441.674],
                      [74.934, -438.303],
                      [74.359, -435.079],
                      [73.85, -431.984],
                      [73.5, -429],
                      [73.424, -426.92],
                      [73.502, -424.841],
                      [73.686, -422.755],
                      [73.928, -420.657],
                      [74.178, -418.539],
                      [74.389, -416.395],
                      [74.513, -414.218],
                      [74.5, -412],
                      [73.256, -400.58],
                      [70.839, -389.117],
                      [67.745, -377.664],
                      [64.467, -366.274],
                      [61.5, -355],
                      [57.977, -340.787],
                      [54.538, -326.349],
                      [51.83, -311.486],
                      [50.5, -296],
                      [55.473, -302.973],
                      [58.253, -311.532],
                      [60.157, -320.824],
                      [62.5, -330],
                      [65.56, -338.727],
                      [69.048, -347.187],
                      [73.262, -354.804],
                      [78.5, -361],
                      [81.297, -361.047],
                      [82.658, -359.657],
                      [83.94, -358.189],
                      [86.5, -358],
                      [89.126, -384.716],
                      [87.125, -410.634],
                      [83.062, -435.735],
                      [79.5, -460],
                      [78.75, -460],
                      [78, -460],
                      [77.25, -460]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 29,
                s: [
                  {
                    i: [
                      [0.516, 0.928],
                      [0.185, -1.544],
                      [0.188, -1.558],
                      [0.191, -1.57],
                      [0.192, -1.58],
                      [0.192, -1.59],
                      [0.192, -1.598],
                      [0.19, -1.605],
                      [0.187, -1.611],
                      [0.183, -1.616],
                      [0.178, -1.619],
                      [0.172, -1.621],
                      [0.165, -1.622],
                      [0.156, -1.621],
                      [0.147, -1.62],
                      [0.137, -1.617],
                      [0.125, -1.613],
                      [0.113, -1.607],
                      [0.099, -1.601],
                      [0.085, -1.593],
                      [0.069, -1.584],
                      [0.053, -1.573],
                      [0.035, -1.562],
                      [0.016, -1.549],
                      [-0.395, 1.139],
                      [-0.347, 1.186],
                      [-0.31, 1.224],
                      [-0.284, 1.25],
                      [-0.268, 1.265],
                      [-0.263, 1.27],
                      [-0.269, 1.264],
                      [-0.286, 1.247],
                      [-0.314, 1.219],
                      [-0.353, 1.181],
                      [-0.402, 1.131],
                      [-0.462, 1.071],
                      [-0.533, 1.001],
                      [-0.614, 0.919],
                      [-0.707, 0.826],
                      [0.114, 1.331],
                      [0.08, 1.365],
                      [0.055, 1.389],
                      [0.04, 1.404],
                      [0.035, 1.409],
                      [0.04, 1.405],
                      [0.054, 1.39],
                      [0.078, 1.366],
                      [0.112, 1.333],
                      [0.155, 1.289],
                      [0.208, 1.237],
                      [0.27, 1.174],
                      [0.343, 1.102],
                      [0.425, 1.02]
                    ],
                    o: [
                      [-0.18, 1.53],
                      [-0.185, 1.544],
                      [-0.188, 1.558],
                      [-0.191, 1.57],
                      [-0.192, 1.58],
                      [-0.192, 1.59],
                      [-0.192, 1.598],
                      [-0.19, 1.605],
                      [-0.187, 1.611],
                      [-0.183, 1.616],
                      [-0.178, 1.619],
                      [-0.172, 1.621],
                      [-0.165, 1.622],
                      [-0.156, 1.621],
                      [-0.147, 1.62],
                      [-0.137, 1.617],
                      [-0.125, 1.613],
                      [-0.113, 1.607],
                      [-0.099, 1.601],
                      [-0.085, 1.593],
                      [-0.069, 1.584],
                      [-0.053, 1.573],
                      [-0.035, 1.562],
                      [0.453, -1.08],
                      [0.395, -1.139],
                      [0.347, -1.186],
                      [0.31, -1.224],
                      [0.284, -1.25],
                      [0.268, -1.265],
                      [0.263, -1.27],
                      [0.269, -1.264],
                      [0.286, -1.247],
                      [0.314, -1.219],
                      [0.353, -1.181],
                      [0.402, -1.131],
                      [0.462, -1.071],
                      [0.533, -1.001],
                      [0.614, -0.919],
                      [-0.157, -1.287],
                      [-0.114, -1.331],
                      [-0.08, -1.365],
                      [-0.055, -1.389],
                      [-0.04, -1.404],
                      [-0.035, -1.409],
                      [-0.04, -1.405],
                      [-0.054, -1.39],
                      [-0.078, -1.366],
                      [-0.112, -1.333],
                      [-0.155, -1.289],
                      [-0.208, -1.237],
                      [-0.27, -1.174],
                      [-0.343, -1.102],
                      [-0.425, -1.02]
                    ],
                    v: [
                      [76.5, -477],
                      [75.952, -472.389],
                      [75.392, -467.736],
                      [74.823, -463.045],
                      [74.248, -458.32],
                      [73.671, -453.564],
                      [73.095, -448.782],
                      [72.523, -443.976],
                      [71.959, -439.151],
                      [71.404, -434.311],
                      [70.863, -429.459],
                      [70.339, -424.599],
                      [69.834, -419.734],
                      [69.352, -414.869],
                      [68.897, -410.006],
                      [68.471, -405.151],
                      [68.078, -400.306],
                      [67.72, -395.475],
                      [67.401, -390.663],
                      [67.124, -385.872],
                      [66.892, -381.106],
                      [66.709, -376.37],
                      [66.577, -371.667],
                      [66.5, -367],
                      [67.77, -370.33],
                      [68.879, -373.821],
                      [69.862, -377.438],
                      [70.749, -381.151],
                      [71.574, -384.926],
                      [72.368, -388.732],
                      [73.165, -392.535],
                      [73.996, -396.304],
                      [74.894, -400.006],
                      [75.891, -403.609],
                      [77.02, -407.08],
                      [78.313, -410.387],
                      [79.802, -413.498],
                      [81.521, -416.379],
                      [83.5, -419],
                      [83.096, -422.929],
                      [82.808, -426.975],
                      [82.609, -431.109],
                      [82.468, -435.301],
                      [82.356, -439.523],
                      [82.246, -443.746],
                      [82.107, -447.941],
                      [81.911, -452.078],
                      [81.629, -456.129],
                      [81.231, -460.065],
                      [80.689, -463.856],
                      [79.974, -467.475],
                      [79.057, -470.891],
                      [77.909, -474.075]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 30,
                s: [
                  {
                    i: [
                      [0.083, 0],
                      [0.114, -1.269],
                      [0.155, -1.259],
                      [0.188, -1.25],
                      [0.213, -1.241],
                      [0.229, -1.233],
                      [0.237, -1.226],
                      [0.237, -1.219],
                      [0.228, -1.213],
                      [0.211, -1.208],
                      [0.186, -1.203],
                      [0.152, -1.199],
                      [0.111, -1.196],
                      [0.06, -1.193],
                      [0.002, -1.191],
                      [-0.065, -1.19],
                      [-0.208, 0.125],
                      [-0.21, 0.124],
                      [-0.21, 0.123],
                      [-0.21, 0.123],
                      [-0.21, 0.124],
                      [-0.208, 0.125],
                      [-0.207, 0.127],
                      [-0.204, 0.129],
                      [-0.201, 0.132],
                      [-0.198, 0.136],
                      [-0.194, 0.14],
                      [-0.189, 0.144],
                      [-0.184, 0.15],
                      [-0.178, 0.156],
                      [-0.171, 0.162],
                      [-0.09, 0.998],
                      [-0.079, 1.078],
                      [-0.067, 1.142],
                      [-0.055, 1.191],
                      [-0.042, 1.223],
                      [-0.028, 1.24],
                      [-0.014, 1.242],
                      [0.001, 1.227],
                      [0.017, 1.197],
                      [0.034, 1.151],
                      [0.051, 1.089],
                      [0.068, 1.012],
                      [0.087, 0.919],
                      [0.106, 0.81],
                      [0.125, 0.685],
                      [0.083, 0],
                      [0.083, 0],
                      [0.083, 0],
                      [0.083, 0],
                      [0.083, 0],
                      [0.083, 0],
                      [0.083, 0]
                    ],
                    o: [
                      [-0.064, 1.28],
                      [-0.114, 1.269],
                      [-0.155, 1.259],
                      [-0.188, 1.25],
                      [-0.213, 1.241],
                      [-0.229, 1.233],
                      [-0.237, 1.226],
                      [-0.237, 1.219],
                      [-0.228, 1.213],
                      [-0.211, 1.208],
                      [-0.186, 1.203],
                      [-0.152, 1.199],
                      [-0.111, 1.196],
                      [-0.06, 1.193],
                      [-0.002, 1.191],
                      [0.207, -0.127],
                      [0.208, -0.125],
                      [0.21, -0.124],
                      [0.21, -0.123],
                      [0.21, -0.123],
                      [0.21, -0.124],
                      [0.208, -0.125],
                      [0.207, -0.127],
                      [0.204, -0.129],
                      [0.201, -0.132],
                      [0.198, -0.136],
                      [0.194, -0.14],
                      [0.189, -0.144],
                      [0.184, -0.15],
                      [0.178, -0.156],
                      [0.101, -0.902],
                      [0.09, -0.998],
                      [0.079, -1.078],
                      [0.067, -1.142],
                      [0.055, -1.191],
                      [0.042, -1.223],
                      [0.028, -1.24],
                      [0.014, -1.242],
                      [-0.001, -1.227],
                      [-0.017, -1.197],
                      [-0.034, -1.151],
                      [-0.051, -1.089],
                      [-0.068, -1.012],
                      [-0.087, -0.919],
                      [-0.106, -0.81],
                      [-0.083, 0],
                      [-0.083, 0],
                      [-0.083, 0],
                      [-0.083, 0],
                      [-0.083, 0],
                      [-0.083, 0],
                      [-0.083, 0],
                      [-0.083, 0]
                    ],
                    v: [
                      [79.5, -485],
                      [79.231, -481.177],
                      [78.826, -477.386],
                      [78.309, -473.623],
                      [77.706, -469.888],
                      [77.041, -466.177],
                      [76.34, -462.49],
                      [75.628, -458.823],
                      [74.928, -455.175],
                      [74.267, -451.544],
                      [73.669, -447.928],
                      [73.159, -444.325],
                      [72.763, -440.733],
                      [72.504, -437.149],
                      [72.408, -433.572],
                      [72.5, -430],
                      [73.123, -430.377],
                      [73.75, -430.75],
                      [74.38, -431.12],
                      [75.011, -431.489],
                      [75.641, -431.859],
                      [76.268, -432.232],
                      [76.891, -432.609],
                      [77.507, -432.993],
                      [78.116, -433.384],
                      [78.715, -433.785],
                      [79.302, -434.198],
                      [79.876, -434.624],
                      [80.435, -435.065],
                      [80.977, -435.523],
                      [81.5, -436],
                      [81.787, -438.855],
                      [82.041, -441.973],
                      [82.261, -445.307],
                      [82.444, -448.811],
                      [82.59, -452.436],
                      [82.694, -456.135],
                      [82.757, -459.863],
                      [82.776, -463.57],
                      [82.748, -467.21],
                      [82.672, -470.736],
                      [82.546, -474.101],
                      [82.367, -477.257],
                      [82.135, -480.157],
                      [81.847, -482.753],
                      [81.5, -485],
                      [81.25, -485],
                      [81, -485],
                      [80.75, -485],
                      [80.5, -485],
                      [80.25, -485],
                      [80, -485],
                      [79.75, -485]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 31,
                s: [
                  {
                    i: [
                      [0.389, 0.653],
                      [0.106, -0.453],
                      [0.101, -0.47],
                      [0.094, -0.484],
                      [0.084, -0.495],
                      [0.071, -0.503],
                      [0.055, -0.507],
                      [0.036, -0.509],
                      [0.014, -0.508],
                      [-0.01, -0.503],
                      [-0.038, -0.496],
                      [-0.068, -0.486],
                      [-0.101, -0.472],
                      [-0.137, -0.456],
                      [0.17, -0.788],
                      [0.127, -0.831],
                      [0.099, -0.86],
                      [0.085, -0.873],
                      [0.087, -0.872],
                      [0.103, -0.855],
                      [0.134, -0.824],
                      [0.18, -0.778],
                      [0, -0.333],
                      [0, -0.333],
                      [0, -0.333],
                      [0, -0.333],
                      [0, -0.333],
                      [0, -0.333],
                      [0, -0.333],
                      [0, -0.333],
                      [-0.166, 0.209],
                      [-0.076, 0.299],
                      [-0.012, 0.363],
                      [0.027, 0.402],
                      [0.04, 0.415],
                      [0.027, 0.402],
                      [-0.012, 0.363],
                      [-0.076, 0.299],
                      [-0.213, 0.829],
                      [-0.225, 0.817],
                      [-0.244, 0.797],
                      [-0.271, 0.77],
                      [-0.306, 0.735],
                      [-0.349, 0.693],
                      [-0.398, 0.643],
                      [-0.456, 0.586],
                      [0.129, 0.912],
                      [0.1, 0.942],
                      [0.093, 0.949],
                      [0.108, 0.934],
                      [0.145, 0.897],
                      [0.204, 0.838],
                      [0.285, 0.756]
                    ],
                    o: [
                      [-0.107, 0.433],
                      [-0.106, 0.453],
                      [-0.101, 0.47],
                      [-0.094, 0.484],
                      [-0.084, 0.495],
                      [-0.071, 0.503],
                      [-0.055, 0.507],
                      [-0.036, 0.509],
                      [-0.014, 0.508],
                      [0.01, 0.503],
                      [0.038, 0.496],
                      [0.068, 0.486],
                      [0.101, 0.472],
                      [-0.228, 0.73],
                      [-0.17, 0.788],
                      [-0.127, 0.831],
                      [-0.099, 0.86],
                      [-0.085, 0.873],
                      [-0.087, 0.872],
                      [-0.103, 0.855],
                      [-0.134, 0.824],
                      [0, 0.333],
                      [0, 0.333],
                      [0, 0.333],
                      [0, 0.333],
                      [0, 0.333],
                      [0, 0.333],
                      [0, 0.333],
                      [0, 0.333],
                      [0.282, -0.093],
                      [0.166, -0.209],
                      [0.076, -0.299],
                      [0.012, -0.363],
                      [-0.027, -0.402],
                      [-0.04, -0.415],
                      [-0.027, -0.402],
                      [0.012, -0.363],
                      [0.208, -0.833],
                      [0.213, -0.829],
                      [0.225, -0.817],
                      [0.244, -0.797],
                      [0.271, -0.77],
                      [0.306, -0.735],
                      [0.349, -0.693],
                      [0.398, -0.643],
                      [-0.181, -0.861],
                      [-0.129, -0.912],
                      [-0.1, -0.942],
                      [-0.093, -0.949],
                      [-0.108, -0.934],
                      [-0.145, -0.897],
                      [-0.204, -0.838],
                      [-0.285, -0.756]
                    ],
                    v: [
                      [76.5, -495],
                      [76.179, -493.67],
                      [75.868, -492.285],
                      [75.574, -490.853],
                      [75.306, -489.385],
                      [75.073, -487.888],
                      [74.884, -486.373],
                      [74.746, -484.847],
                      [74.67, -483.321],
                      [74.662, -481.804],
                      [74.733, -480.304],
                      [74.891, -478.83],
                      [75.143, -477.393],
                      [75.5, -476],
                      [74.906, -473.719],
                      [74.463, -471.287],
                      [74.128, -468.747],
                      [73.856, -466.144],
                      [73.602, -463.524],
                      [73.321, -460.93],
                      [72.968, -458.407],
                      [72.5, -456],
                      [72.5, -455],
                      [72.5, -454],
                      [72.5, -453],
                      [72.5, -452],
                      [72.5, -451],
                      [72.5, -450],
                      [72.5, -449],
                      [72.5, -448],
                      [73.165, -448.46],
                      [73.522, -449.228],
                      [73.647, -450.228],
                      [73.617, -451.383],
                      [73.511, -452.614],
                      [73.404, -453.846],
                      [73.375, -455],
                      [73.5, -456],
                      [74.13, -458.495],
                      [74.784, -460.966],
                      [75.485, -463.39],
                      [76.257, -465.743],
                      [77.122, -468.003],
                      [78.102, -470.148],
                      [79.22, -472.155],
                      [80.5, -474],
                      [80.041, -476.666],
                      [79.703, -479.452],
                      [79.419, -482.294],
                      [79.124, -485.124],
                      [78.751, -487.876],
                      [78.233, -490.484],
                      [77.505, -492.88]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 32,
                s: [
                  {
                    i: [
                      [0.091, 0.133],
                      [0.08, -0.196],
                      [0.073, -0.203],
                      [0.067, -0.209],
                      [0.06, -0.215],
                      [0.054, -0.221],
                      [0.049, -0.227],
                      [0.043, -0.232],
                      [0.038, -0.237],
                      [0.034, -0.242],
                      [0.029, -0.247],
                      [0.025, -0.251],
                      [0.021, -0.255],
                      [0.017, -0.258],
                      [0.014, -0.262],
                      [0.011, -0.265],
                      [0.008, -0.267],
                      [0.006, -0.27],
                      [0.004, -0.272],
                      [0.002, -0.274],
                      [0.001, -0.275],
                      [-0.001, -0.276],
                      [-0.002, -0.277],
                      [-0.002, -0.278],
                      [-0.003, -0.278],
                      [-0.003, -0.278],
                      [-0.002, -0.278],
                      [-0.002, -0.278],
                      [-0.001, -0.277],
                      [0, -0.276],
                      [-0.185, 0.15],
                      [-0.166, 0.192],
                      [-0.148, 0.23],
                      [-0.131, 0.264],
                      [-0.114, 0.295],
                      [-0.098, 0.322],
                      [-0.082, 0.344],
                      [-0.068, 0.363],
                      [-0.053, 0.378],
                      [-0.039, 0.389],
                      [-0.026, 0.396],
                      [-0.014, 0.399],
                      [-0.002, 0.399],
                      [0.01, 0.394],
                      [0.021, 0.385],
                      [0.031, 0.373],
                      [0.041, 0.357],
                      [0.05, 0.336],
                      [0.058, 0.312],
                      [0.066, 0.284],
                      [0.073, 0.252],
                      [0.08, 0.216],
                      [0.086, 0.177]
                    ],
                    o: [
                      [-0.087, 0.189],
                      [-0.08, 0.196],
                      [-0.073, 0.203],
                      [-0.067, 0.209],
                      [-0.06, 0.215],
                      [-0.054, 0.221],
                      [-0.049, 0.227],
                      [-0.043, 0.232],
                      [-0.038, 0.237],
                      [-0.034, 0.242],
                      [-0.029, 0.247],
                      [-0.025, 0.251],
                      [-0.021, 0.255],
                      [-0.017, 0.258],
                      [-0.014, 0.262],
                      [-0.011, 0.265],
                      [-0.008, 0.267],
                      [-0.006, 0.27],
                      [-0.004, 0.272],
                      [-0.002, 0.274],
                      [-0.001, 0.275],
                      [0.001, 0.276],
                      [0.002, 0.277],
                      [0.002, 0.278],
                      [0.003, 0.278],
                      [0.003, 0.278],
                      [0.002, 0.278],
                      [0.002, 0.278],
                      [0.001, 0.277],
                      [0.204, -0.103],
                      [0.185, -0.15],
                      [0.166, -0.192],
                      [0.148, -0.23],
                      [0.131, -0.264],
                      [0.114, -0.295],
                      [0.098, -0.322],
                      [0.082, -0.344],
                      [0.068, -0.363],
                      [0.053, -0.378],
                      [0.039, -0.389],
                      [0.026, -0.396],
                      [0.014, -0.399],
                      [0.002, -0.399],
                      [-0.01, -0.394],
                      [-0.021, -0.385],
                      [-0.031, -0.373],
                      [-0.041, -0.357],
                      [-0.05, -0.336],
                      [-0.058, -0.312],
                      [-0.066, -0.284],
                      [-0.073, -0.252],
                      [-0.08, -0.216],
                      [-0.086, -0.177]
                    ],
                    v: [
                      [77.5, -499],
                      [77.249, -498.423],
                      [77.019, -497.825],
                      [76.81, -497.208],
                      [76.619, -496.571],
                      [76.447, -495.915],
                      [76.292, -495.242],
                      [76.154, -494.553],
                      [76.031, -493.848],
                      [75.923, -493.129],
                      [75.829, -492.395],
                      [75.748, -491.649],
                      [75.679, -490.89],
                      [75.621, -490.12],
                      [75.574, -489.34],
                      [75.536, -488.551],
                      [75.506, -487.752],
                      [75.484, -486.947],
                      [75.469, -486.134],
                      [75.46, -485.315],
                      [75.456, -484.492],
                      [75.456, -483.664],
                      [75.46, -482.833],
                      [75.465, -482],
                      [75.473, -481.165],
                      [75.48, -480.33],
                      [75.488, -479.495],
                      [75.494, -478.661],
                      [75.499, -477.829],
                      [75.5, -477],
                      [76.082, -477.38],
                      [76.609, -477.893],
                      [77.08, -478.527],
                      [77.498, -479.27],
                      [77.866, -480.11],
                      [78.184, -481.036],
                      [78.455, -482.036],
                      [78.68, -483.098],
                      [78.86, -484.211],
                      [78.999, -485.362],
                      [79.097, -486.541],
                      [79.157, -487.735],
                      [79.18, -488.932],
                      [79.167, -490.122],
                      [79.121, -491.292],
                      [79.044, -492.43],
                      [78.936, -493.525],
                      [78.801, -494.566],
                      [78.64, -495.54],
                      [78.454, -496.435],
                      [78.245, -497.241],
                      [78.015, -497.944],
                      [77.766, -498.535]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 33,
                s: [
                  {
                    i: [
                      [0.106, 0.202],
                      [-0.036, -0.244],
                      [-0.024, -0.243],
                      [-0.013, -0.242],
                      [-0.003, -0.24],
                      [0.005, -0.239],
                      [0.013, -0.238],
                      [0.02, -0.236],
                      [0.026, -0.234],
                      [0.03, -0.232],
                      [0.034, -0.231],
                      [0.036, -0.229],
                      [0.038, -0.227],
                      [0.038, -0.225],
                      [0.038, -0.222],
                      [0.036, -0.22],
                      [0.033, -0.218],
                      [0.029, -0.215],
                      [0.024, -0.213],
                      [0.019, -0.21],
                      [0.012, -0.208],
                      [0.004, -0.205],
                      [-0.005, -0.202],
                      [-0.016, -0.199],
                      [-0.027, -0.196],
                      [-0.039, -0.193],
                      [-0.052, -0.19],
                      [-0.067, -0.187],
                      [-0.082, -0.183],
                      [-0.098, -0.18],
                      [-0.153, 0.155],
                      [-0.134, 0.18],
                      [-0.116, 0.202],
                      [-0.098, 0.223],
                      [-0.082, 0.241],
                      [-0.065, 0.258],
                      [-0.05, 0.272],
                      [-0.035, 0.285],
                      [-0.021, 0.295],
                      [-0.008, 0.303],
                      [0.005, 0.309],
                      [0.017, 0.313],
                      [0.028, 0.315],
                      [0.039, 0.315],
                      [0.048, 0.313],
                      [0.058, 0.309],
                      [0.066, 0.303],
                      [0.074, 0.295],
                      [0.081, 0.284],
                      [0.087, 0.272],
                      [0.093, 0.258],
                      [0.098, 0.241],
                      [0.102, 0.223]
                    ],
                    o: [
                      [0.049, 0.245],
                      [0.036, 0.244],
                      [0.024, 0.243],
                      [0.013, 0.242],
                      [0.003, 0.24],
                      [-0.005, 0.239],
                      [-0.013, 0.238],
                      [-0.02, 0.236],
                      [-0.026, 0.234],
                      [-0.03, 0.232],
                      [-0.034, 0.231],
                      [-0.036, 0.229],
                      [-0.038, 0.227],
                      [-0.038, 0.225],
                      [-0.038, 0.222],
                      [-0.036, 0.22],
                      [-0.033, 0.218],
                      [-0.029, 0.215],
                      [-0.024, 0.213],
                      [-0.019, 0.21],
                      [-0.012, 0.208],
                      [-0.004, 0.205],
                      [0.005, 0.202],
                      [0.016, 0.199],
                      [0.027, 0.196],
                      [0.039, 0.193],
                      [0.052, 0.19],
                      [0.067, 0.187],
                      [0.082, 0.183],
                      [0.173, -0.128],
                      [0.153, -0.155],
                      [0.134, -0.18],
                      [0.116, -0.202],
                      [0.098, -0.223],
                      [0.082, -0.241],
                      [0.065, -0.258],
                      [0.05, -0.272],
                      [0.035, -0.285],
                      [0.021, -0.295],
                      [0.008, -0.303],
                      [-0.005, -0.309],
                      [-0.017, -0.313],
                      [-0.028, -0.315],
                      [-0.039, -0.315],
                      [-0.048, -0.313],
                      [-0.058, -0.309],
                      [-0.066, -0.303],
                      [-0.074, -0.295],
                      [-0.081, -0.284],
                      [-0.087, -0.272],
                      [-0.093, -0.258],
                      [-0.098, -0.241],
                      [-0.102, -0.223]
                    ],
                    v: [
                      [75.5, -503],
                      [75.628, -502.266],
                      [75.718, -501.535],
                      [75.774, -500.807],
                      [75.798, -500.084],
                      [75.795, -499.365],
                      [75.766, -498.65],
                      [75.716, -497.94],
                      [75.648, -497.234],
                      [75.563, -496.534],
                      [75.467, -495.84],
                      [75.361, -495.151],
                      [75.25, -494.467],
                      [75.135, -493.79],
                      [75.022, -493.12],
                      [74.911, -492.456],
                      [74.807, -491.799],
                      [74.713, -491.149],
                      [74.632, -490.507],
                      [74.567, -489.872],
                      [74.522, -489.245],
                      [74.499, -488.626],
                      [74.501, -488.016],
                      [74.532, -487.414],
                      [74.595, -486.822],
                      [74.693, -486.238],
                      [74.83, -485.664],
                      [75.008, -485.099],
                      [75.23, -484.545],
                      [75.5, -484],
                      [75.989, -484.425],
                      [76.42, -484.927],
                      [76.796, -485.5],
                      [77.117, -486.138],
                      [77.387, -486.834],
                      [77.608, -487.584],
                      [77.781, -488.379],
                      [77.909, -489.215],
                      [77.993, -490.084],
                      [78.037, -490.981],
                      [78.041, -491.9],
                      [78.008, -492.835],
                      [77.941, -493.778],
                      [77.841, -494.725],
                      [77.71, -495.669],
                      [77.551, -496.603],
                      [77.365, -497.521],
                      [77.155, -498.419],
                      [76.922, -499.288],
                      [76.67, -500.123],
                      [76.399, -500.918],
                      [76.113, -501.667],
                      [75.812, -502.363]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 34,
                s: [
                  {
                    i: [
                      [0.032, 0.154],
                      [0.042, -0.119],
                      [0.043, -0.118],
                      [0.043, -0.118],
                      [0.043, -0.118],
                      [0.043, -0.118],
                      [0.042, -0.119],
                      [0.042, -0.119],
                      [0.041, -0.12],
                      [0.04, -0.121],
                      [0.038, -0.123],
                      [0.037, -0.124],
                      [0.035, -0.126],
                      [0.033, -0.128],
                      [0.031, -0.13],
                      [0.029, -0.132],
                      [0.027, -0.134],
                      [0.024, -0.137],
                      [0.021, -0.14],
                      [0.018, -0.143],
                      [0.015, -0.146],
                      [0.011, -0.15],
                      [0.007, -0.154],
                      [0.003, -0.158],
                      [-0.001, -0.162],
                      [-0.005, -0.166],
                      [-0.01, -0.17],
                      [-0.014, -0.175],
                      [-0.019, -0.18],
                      [-0.024, -0.185],
                      [-0.172, 0.055],
                      [-0.151, 0.076],
                      [-0.131, 0.095],
                      [-0.112, 0.114],
                      [-0.094, 0.13],
                      [-0.078, 0.145],
                      [-0.062, 0.159],
                      [-0.048, 0.171],
                      [-0.035, 0.181],
                      [-0.022, 0.19],
                      [-0.011, 0.198],
                      [-0.001, 0.203],
                      [0.008, 0.208],
                      [0.015, 0.211],
                      [0.022, 0.212],
                      [0.028, 0.212],
                      [0.032, 0.21],
                      [0.036, 0.207],
                      [0.038, 0.202],
                      [0.039, 0.195],
                      [0.039, 0.187],
                      [0.038, 0.178],
                      [0.036, 0.167]
                    ],
                    o: [
                      [-0.042, 0.119],
                      [-0.042, 0.119],
                      [-0.043, 0.118],
                      [-0.043, 0.118],
                      [-0.043, 0.118],
                      [-0.043, 0.118],
                      [-0.042, 0.119],
                      [-0.042, 0.119],
                      [-0.041, 0.12],
                      [-0.04, 0.121],
                      [-0.038, 0.123],
                      [-0.037, 0.124],
                      [-0.035, 0.126],
                      [-0.033, 0.128],
                      [-0.031, 0.13],
                      [-0.029, 0.132],
                      [-0.027, 0.134],
                      [-0.024, 0.137],
                      [-0.021, 0.14],
                      [-0.018, 0.143],
                      [-0.015, 0.146],
                      [-0.011, 0.15],
                      [-0.007, 0.154],
                      [-0.003, 0.158],
                      [0.001, 0.162],
                      [0.005, 0.166],
                      [0.01, 0.17],
                      [0.014, 0.175],
                      [0.019, 0.18],
                      [0.194, -0.032],
                      [0.172, -0.055],
                      [0.151, -0.076],
                      [0.131, -0.095],
                      [0.112, -0.114],
                      [0.094, -0.13],
                      [0.078, -0.145],
                      [0.062, -0.159],
                      [0.048, -0.171],
                      [0.035, -0.181],
                      [0.022, -0.19],
                      [0.011, -0.198],
                      [0.001, -0.203],
                      [-0.008, -0.208],
                      [-0.015, -0.211],
                      [-0.022, -0.212],
                      [-0.028, -0.212],
                      [-0.032, -0.21],
                      [-0.036, -0.207],
                      [-0.038, -0.202],
                      [-0.039, -0.195],
                      [-0.039, -0.187],
                      [-0.038, -0.178],
                      [-0.036, -0.167]
                    ],
                    v: [
                      [76.5, -507],
                      [76.374, -506.643],
                      [76.247, -506.288],
                      [76.118, -505.934],
                      [75.99, -505.579],
                      [75.862, -505.225],
                      [75.734, -504.869],
                      [75.609, -504.512],
                      [75.486, -504.152],
                      [75.365, -503.79],
                      [75.248, -503.424],
                      [75.135, -503.054],
                      [75.027, -502.68],
                      [74.924, -502.3],
                      [74.827, -501.914],
                      [74.737, -501.522],
                      [74.653, -501.122],
                      [74.578, -500.715],
                      [74.511, -500.3],
                      [74.452, -499.875],
                      [74.404, -499.441],
                      [74.366, -498.996],
                      [74.338, -498.541],
                      [74.322, -498.075],
                      [74.318, -497.596],
                      [74.326, -497.104],
                      [74.348, -496.6],
                      [74.384, -496.081],
                      [74.435, -495.548],
                      [74.5, -495],
                      [75.049, -495.13],
                      [75.534, -495.326],
                      [75.956, -495.583],
                      [76.321, -495.897],
                      [76.63, -496.263],
                      [76.889, -496.676],
                      [77.098, -497.133],
                      [77.263, -497.627],
                      [77.386, -498.155],
                      [77.471, -498.713],
                      [77.521, -499.295],
                      [77.54, -499.896],
                      [77.53, -500.513],
                      [77.495, -501.141],
                      [77.438, -501.775],
                      [77.363, -502.411],
                      [77.273, -503.043],
                      [77.171, -503.668],
                      [77.061, -504.281],
                      [76.945, -504.877],
                      [76.828, -505.451],
                      [76.713, -506],
                      [76.602, -506.517]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 35,
                s: [
                  {
                    i: [
                      [0.022, 0],
                      [0, -0.072],
                      [0, -0.072],
                      [0, -0.072],
                      [0, -0.072],
                      [0, -0.072],
                      [0, -0.072],
                      [0, -0.072],
                      [0, -0.072],
                      [0, -0.072],
                      [0, -0.072],
                      [0, -0.072],
                      [0, -0.072],
                      [0, -0.072],
                      [0, -0.072],
                      [0, -0.072],
                      [0, -0.072],
                      [0, -0.072],
                      [0, -0.072],
                      [0, -0.072],
                      [0, -0.072],
                      [0, -0.072],
                      [0, -0.072],
                      [0, -0.072],
                      [-0.106, -0.341],
                      [-0.101, -0.209],
                      [-0.094, -0.092],
                      [-0.085, 0.01],
                      [-0.074, 0.097],
                      [-0.061, 0.17],
                      [-0.047, 0.227],
                      [-0.031, 0.27],
                      [-0.013, 0.298],
                      [0.007, 0.311],
                      [0.028, 0.309],
                      [0.052, 0.292],
                      [0.077, 0.26],
                      [0.104, 0.214],
                      [0.132, 0.152],
                      [0.022, 0],
                      [0.022, 0],
                      [0.022, 0],
                      [0.022, 0],
                      [0.022, 0],
                      [0.022, 0],
                      [0.022, 0],
                      [0.022, 0],
                      [0.022, 0],
                      [0.022, 0],
                      [0.022, 0],
                      [0.022, 0],
                      [0.022, 0],
                      [0.022, 0]
                    ],
                    o: [
                      [0, 0.072],
                      [0, 0.072],
                      [0, 0.072],
                      [0, 0.072],
                      [0, 0.072],
                      [0, 0.072],
                      [0, 0.072],
                      [0, 0.072],
                      [0, 0.072],
                      [0, 0.072],
                      [0, 0.072],
                      [0, 0.072],
                      [0, 0.072],
                      [0, 0.072],
                      [0, 0.072],
                      [0, 0.072],
                      [0, 0.072],
                      [0, 0.072],
                      [0, 0.072],
                      [0, 0.072],
                      [0, 0.072],
                      [0, 0.072],
                      [0, 0.072],
                      [0.11, 0.487],
                      [0.106, 0.341],
                      [0.101, 0.209],
                      [0.094, 0.092],
                      [0.085, -0.01],
                      [0.074, -0.097],
                      [0.061, -0.17],
                      [0.047, -0.227],
                      [0.031, -0.27],
                      [0.013, -0.298],
                      [-0.007, -0.311],
                      [-0.028, -0.309],
                      [-0.052, -0.292],
                      [-0.077, -0.26],
                      [-0.104, -0.214],
                      [-0.022, 0],
                      [-0.022, 0],
                      [-0.022, 0],
                      [-0.022, 0],
                      [-0.022, 0],
                      [-0.022, 0],
                      [-0.022, 0],
                      [-0.022, 0],
                      [-0.022, 0],
                      [-0.022, 0],
                      [-0.022, 0],
                      [-0.022, 0],
                      [-0.022, 0],
                      [-0.022, 0],
                      [-0.022, 0]
                    ],
                    v: [
                      [75.5, -510],
                      [75.5, -509.783],
                      [75.5, -509.565],
                      [75.5, -509.348],
                      [75.5, -509.13],
                      [75.5, -508.913],
                      [75.5, -508.696],
                      [75.5, -508.478],
                      [75.5, -508.261],
                      [75.5, -508.043],
                      [75.5, -507.826],
                      [75.5, -507.609],
                      [75.5, -507.391],
                      [75.5, -507.174],
                      [75.5, -506.957],
                      [75.5, -506.739],
                      [75.5, -506.522],
                      [75.5, -506.304],
                      [75.5, -506.087],
                      [75.5, -505.87],
                      [75.5, -505.652],
                      [75.5, -505.435],
                      [75.5, -505.217],
                      [75.5, -505],
                      [75.824, -503.762],
                      [76.135, -502.941],
                      [76.427, -502.494],
                      [76.695, -502.374],
                      [76.933, -502.539],
                      [77.136, -502.944],
                      [77.298, -503.543],
                      [77.415, -504.292],
                      [77.481, -505.147],
                      [77.49, -506.063],
                      [77.437, -506.996],
                      [77.317, -507.9],
                      [77.125, -508.732],
                      [76.854, -509.447],
                      [76.5, -510],
                      [76.433, -510],
                      [76.367, -510],
                      [76.3, -510],
                      [76.233, -510],
                      [76.167, -510],
                      [76.1, -510],
                      [76.033, -510],
                      [75.967, -510],
                      [75.9, -510],
                      [75.833, -510],
                      [75.767, -510],
                      [75.7, -510],
                      [75.633, -510],
                      [75.567, -510]
                    ],
                    c: true
                  }
                ],
                h: 1
              }
            ],
            ix: 1
          },
          o: {
            a: 1,
            k: [
              { t: 20, s: [0], h: 1 },
              { t: 21, s: [100], h: 1 },
              { t: 36, s: [0], h: 1 }
            ],
            ix: 3
          },
          x: { a: 0, k: 0, ix: 4 },
          nm: 'Mask 1'
        },
        {
          inv: false,
          mode: 'f',
          pt: {
            a: 1,
            k: [
              {
                t: 22,
                s: [
                  {
                    i: [
                      [-3.274, 0.274],
                      [-0.702, 4.548],
                      [-0.6, 4.65],
                      [-1.098, 4.152],
                      [-2.197, 3.053],
                      [-5.789, -0.264],
                      [-5.486, -0.904],
                      [-5.204, -0.813],
                      [-4.942, 0.007],
                      [2.017, 2.262],
                      [2.55, 1.772],
                      [2.632, 1.624],
                      [2.266, 1.818],
                      [2.983, 2.702],
                      [2.934, 2.7],
                      [3.051, 2.561],
                      [4.386, 4.114],
                      [4.991, 3.509],
                      [2.317, 2.016],
                      [2.757, 1.576],
                      [2.285, 2.248],
                      [2.717, -0.601],
                      [-3.93, -21.57],
                      [-6.017, -19.483],
                      [-0.073, -2.927]
                    ],
                    o: [
                      [1.404, -3.846],
                      [0.702, -4.548],
                      [0.6, -4.65],
                      [1.098, -4.152],
                      [6.113, -1.107],
                      [5.789, 0.264],
                      [5.486, 0.904],
                      [5.204, 0.813],
                      [-1.036, -3.094],
                      [-2.017, -2.262],
                      [-2.55, -1.772],
                      [-2.632, -1.624],
                      [-3.199, -2.567],
                      [-2.983, -2.702],
                      [-2.934, -2.7],
                      [-4.465, -4.035],
                      [-4.386, -4.114],
                      [-1.974, -2.36],
                      [-2.317, -2.016],
                      [-2.141, -1.611],
                      [-2.285, -2.248],
                      [5.262, 20.238],
                      [3.93, 21.57],
                      [0.433, 2.567],
                      [0.073, 2.927]
                    ],
                    v: [
                      [-115.5, 257],
                      [-112.491, 244.259],
                      [-110.689, 230.311],
                      [-108.293, 216.957],
                      [-103.5, 206],
                      [-85.652, 204.918],
                      [-68.744, 206.851],
                      [-52.714, 209.609],
                      [-37.5, 211],
                      [-42.192, 203.051],
                      [-49.155, 197.085],
                      [-57.041, 192.077],
                      [-64.5, 187],
                      [-73.731, 179.063],
                      [-82.565, 170.926],
                      [-91.5, 163],
                      [-104.606, 150.606],
                      [-118.5, 139],
                      [-124.912, 132.412],
                      [-132.5, 127],
                      [-139.068, 120.341],
                      [-146.5, 117],
                      [-133.567, 180.567],
                      [-119.5, 243],
                      [-119.631, 252.131]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 23,
                s: [
                  {
                    i: [
                      [0.213, -0.046],
                      [-2.491, -9.467],
                      [-2.476, -9.483],
                      [-2.492, -9.466],
                      [-2.541, -9.418],
                      [-2.621, -9.338],
                      [-2.732, -9.226],
                      [-2.875, -9.083],
                      [-3.05, -8.908],
                      [-4.945, 5.675],
                      [-7.182, 3.613],
                      [-8.36, 1.469],
                      [-8.479, -0.755],
                      [-7.539, -3.061],
                      [6.517, 8.066],
                      [7.145, 7.439],
                      [7.43, 7.153],
                      [7.374, 7.209],
                      [6.834, 7.749],
                      [6.614, 7.969],
                      [6.598, 7.985],
                      [6.786, 7.798],
                      [0.03, 0.137],
                      [0.068, 0.098],
                      [0.129, 0.037]
                    ],
                    o: [
                      [2.537, 9.421],
                      [2.491, 9.467],
                      [2.476, 9.483],
                      [2.492, 9.466],
                      [2.541, 9.418],
                      [2.621, 9.338],
                      [2.732, 9.226],
                      [2.875, 9.083],
                      [1.648, -7.657],
                      [4.945, -5.675],
                      [7.182, -3.613],
                      [8.36, -1.469],
                      [8.479, 0.755],
                      [-5.547, -9.036],
                      [-6.517, -8.066],
                      [-7.145, -7.439],
                      [-7.43, -7.153],
                      [-7.258, -7.325],
                      [-6.834, -7.749],
                      [-6.614, -7.969],
                      [-6.598, -7.985],
                      [-0.014, -0.153],
                      [-0.03, -0.137],
                      [-0.068, -0.098],
                      [-0.129, -0.037]
                    ],
                    v: [
                      [-220.5, -44],
                      [-212.966, -15.66],
                      [-205.524, 12.773],
                      [-198.08, 41.204],
                      [-190.538, 69.537],
                      [-182.804, 97.679],
                      [-174.783, 125.533],
                      [-166.38, 153.005],
                      [-157.5, 180],
                      [-147.346, 159.981],
                      [-128.891, 146.029],
                      [-105.314, 138.386],
                      [-79.791, 137.296],
                      [-55.5, 143],
                      [-73.682, 117.432],
                      [-94.26, 94.26],
                      [-116.208, 72.458],
                      [-138.5, 51],
                      [-159.588, 28.337],
                      [-179.709, 4.709],
                      [-199.476, -19.274],
                      [-219.5, -43],
                      [-219.561, -43.439],
                      [-219.702, -43.798],
                      [-219.993, -44.007]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 24,
                s: [
                  {
                    i: [
                      [0.667, 0],
                      [0.333, 0],
                      [0.806, -0.86],
                      [50.491, -117.508],
                      [6.074, -0.731],
                      [23.832, 25.501],
                      [48.375, -4.314],
                      [15.689, -12.829],
                      [-7.736, -40.038],
                      [-6.658, 4.675],
                      [-9.43, 4.408],
                      [-18.271, -30.052],
                      [-11.096, -30.877],
                      [-11.24, -4.093],
                      [-1.838, 10.496],
                      [-74.746, -9.774],
                      [13.31, -72.886],
                      [-7.775, -4.208],
                      [-3.774, 11.822],
                      [64.153, 13.803],
                      [11.259, -3.412],
                      [-0.991, 23.032],
                      [8.511, -7.823],
                      [9.174, -2.498],
                      [-16.515, 94.153]
                    ],
                    o: [
                      [-0.333, 0],
                      [-0.261, 1.406],
                      [-54.569, 113.429],
                      [-4.877, 5.226],
                      [-5.071, -44.263],
                      [-30.565, -22.091],
                      [-21.436, 1.912],
                      [-23.984, 19.613],
                      [10.147, -1.187],
                      [10.57, -0.17],
                      [43.93, -20.532],
                      [17.621, 25.329],
                      [3.431, 9.548],
                      [7.839, -4.494],
                      [33.581, -40.302],
                      [66.474, 8.692],
                      [-1.065, 5.832],
                      [8.617, -7.034],
                      [25.151, -78.787],
                      [-20.163, -2.16],
                      [6.048, -21.302],
                      [-15.239, 1.094],
                      [-8.359, 6.645],
                      [23.736, -86.933],
                      [-0.667, 0]
                    ],
                    v: [
                      [430.5, -172],
                      [429.5, -172],
                      [426.5, -170],
                      [252.5, 160],
                      [239.5, 179],
                      [198.5, 72],
                      [84.5, 22],
                      [30.5, 46],
                      [-3.5, 128],
                      [13.5, 111],
                      [37.5, 97],
                      [168.5, 128],
                      [213.5, 215],
                      [229.5, 243],
                      [239.5, 216],
                      [410.5, 161],
                      [492.5, 303],
                      [493.5, 323],
                      [510.5, 291],
                      [439.5, 99],
                      [390.5, 106],
                      [410.5, 42],
                      [386.5, 67],
                      [360.5, 88],
                      [432.5, -172]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 25,
                s: [
                  {
                    i: [
                      [4.199, 4.431],
                      [10.168, -13.332],
                      [12.209, -11.291],
                      [-0.227, 0.273],
                      [-0.095, 0.405],
                      [0, 0.333],
                      [0, 0.334],
                      [-11.004, 33.829],
                      [-12.013, 32.821],
                      [-4.408, 10.802],
                      [0.354, 13.112],
                      [9.129, -16.537],
                      [10.095, -15.572],
                      [10.196, -15.638],
                      [7.081, -18.752],
                      [16.674, -29.659],
                      [13.659, -32.674],
                      [-25.243, 9.594],
                      [-32.174, -1.999],
                      [-19.726, -33.734],
                      [-4.433, -15.453],
                      [-7.021, -1.177],
                      [27.248, 35.489],
                      [27.556, 3.111],
                      [-1.904, 25.175]
                    ],
                    o: [
                      [-8.12, 15.38],
                      [-10.168, 13.332],
                      [-0.001, -0.502],
                      [0.227, -0.273],
                      [0, -0.334],
                      [0, -0.333],
                      [9.971, -34.862],
                      [11.004, -33.829],
                      [2.721, -11.679],
                      [4.408, -10.802],
                      [-10.388, 15.279],
                      [-9.129, 16.537],
                      [-8.135, 17.698],
                      [-10.196, 15.637],
                      [-17.597, 28.737],
                      [-18.473, 27.86],
                      [18.609, -13.528],
                      [25.034, -9.515],
                      [51.93, 3.226],
                      [8.127, 13.898],
                      [1.163, 4.055],
                      [3.084, -61.265],
                      [-15.135, -15.532],
                      [2.518, -24.553],
                      [0.408, -5.398]
                    ],
                    v: [
                      [432.5, -32],
                      [405.066, 11.067],
                      [371.5, 48],
                      [371.928, 46.928],
                      [372.5, 46],
                      [372.5, 45],
                      [372.5, 44],
                      [403.968, -59.031],
                      [438.5, -159],
                      [450.806, -191.926],
                      [458.5, -227],
                      [429.78, -178.719],
                      [401.5, -130],
                      [372.709, -81.291],
                      [345.5, -31],
                      [294.5, 57],
                      [257.5, 159],
                      [317.5, 119],
                      [408.5, 110],
                      [503.5, 184],
                      [523.5, 227],
                      [530.5, 241],
                      [483.5, 92],
                      [421.5, 62],
                      [435.5, -14]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 26,
                s: [
                  {
                    i: [
                      [4.584, 12.237],
                      [0.107, 0.143],
                      [0.164, 0.087],
                      [0.225, 0.025],
                      [0.291, -0.041],
                      [2.542, -10.208],
                      [2.685, -10.065],
                      [2.675, -10.075],
                      [2.514, -10.236],
                      [-1.25, 0.667],
                      [-1.242, 0.674],
                      [-1.496, 0.42],
                      [-2.012, -0.096],
                      [-2.129, -6.082],
                      [-2.049, -6.294],
                      [-1.77, -6.315],
                      [-1.293, -6.145],
                      [-0.338, -3.138],
                      [-1.143, -2.65],
                      [-2.987, -1.254],
                      [0.912, 4.806],
                      [0.67, 4.675],
                      [1.252, 16.416],
                      [0.726, 16.52],
                      [0.428, 14.678]
                    ],
                    o: [
                      [-0.055, -0.195],
                      [-0.107, -0.143],
                      [-0.164, -0.087],
                      [-0.225, -0.025],
                      [-2.248, 10.502],
                      [-2.542, 10.208],
                      [-2.685, 10.065],
                      [-2.675, 10.075],
                      [1.519, -0.398],
                      [1.25, -0.667],
                      [1.242, -0.674],
                      [1.496, -0.42],
                      [2.011, 5.678],
                      [2.129, 6.082],
                      [2.049, 6.294],
                      [1.77, 6.315],
                      [0.571, 2.717],
                      [0.338, 3.138],
                      [1.143, 2.65],
                      [3.32, -4.101],
                      [-0.912, -4.806],
                      [-2.266, -15.815],
                      [-1.252, -16.416],
                      [-0.706, -16.052],
                      [-0.427, -14.678]
                    ],
                    v: [
                      [164.5, -266],
                      [164.258, -266.508],
                      [163.853, -266.853],
                      [163.272, -267.022],
                      [162.5, -267],
                      [155.278, -235.972],
                      [147.399, -205.601],
                      [139.321, -175.429],
                      [131.5, -145],
                      [135.588, -146.662],
                      [139.26, -148.739],
                      [143.303, -150.447],
                      [148.5, -151],
                      [154.759, -133.313],
                      [161.077, -114.701],
                      [166.856, -95.739],
                      [171.5, -77],
                      [172.604, -67.991],
                      [174.564, -59.083],
                      [180.5, -53],
                      [182.992, -66.569],
                      [179.5, -81],
                      [174.345, -129.471],
                      [171.5, -179],
                      [170.909, -225.361]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 27,
                s: [
                  {
                    i: [
                      [0.569, 14.181],
                      [0.811, -4.703],
                      [1.056, -4.543],
                      [1.219, -4.409],
                      [1.301, -4.303],
                      [1.301, -4.224],
                      [1.219, -4.171],
                      [1.056, -4.146],
                      [0.811, -4.147],
                      [-0.396, 0.889],
                      [-0.296, 0.913],
                      [-0.261, 0.892],
                      [-0.288, 0.827],
                      [-0.38, 0.718],
                      [-0.536, 0.563],
                      [-0.755, 0.365],
                      [-1.037, 0.122],
                      [-2.568, -3.92],
                      [-1.395, -6.513],
                      [-0.875, -7.264],
                      [-1.007, -6.173],
                      [-1.793, -3.239],
                      [1.01, 13.74],
                      [1.024, 13.726],
                      [0.877, 13.873]
                    ],
                    o: [
                      [-0.485, 4.89],
                      [-0.811, 4.703],
                      [-1.056, 4.543],
                      [-1.219, 4.409],
                      [-1.301, 4.303],
                      [-1.301, 4.224],
                      [-1.219, 4.171],
                      [-1.056, 4.146],
                      [0.559, -0.82],
                      [0.396, -0.889],
                      [0.296, -0.913],
                      [0.261, -0.892],
                      [0.288, -0.827],
                      [0.38, -0.718],
                      [0.536, -0.563],
                      [0.755, -0.365],
                      [4.394, -0.515],
                      [2.568, 3.92],
                      [1.395, 6.513],
                      [0.875, 7.264],
                      [1.007, 6.173],
                      [-0.836, -13.915],
                      [-1.01, -13.74],
                      [-1.024, -13.726],
                      [-0.877, -13.873]
                    ],
                    v: [
                      [167.5, -300],
                      [165.536, -285.617],
                      [162.714, -271.755],
                      [159.28, -258.333],
                      [155.479, -245.271],
                      [151.555, -232.488],
                      [147.754, -219.902],
                      [144.321, -207.433],
                      [141.5, -195],
                      [142.915, -197.574],
                      [143.937, -200.287],
                      [144.756, -203.005],
                      [145.564, -205.595],
                      [146.551, -207.923],
                      [147.909, -209.856],
                      [149.828, -211.259],
                      [152.5, -212],
                      [162.78, -206.431],
                      [168.562, -190.32],
                      [171.803, -169.193],
                      [174.463, -148.578],
                      [178.5, -134],
                      [175.691, -175.441],
                      [172.6, -216.6],
                      [169.709, -257.959]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 28,
                s: [
                  {
                    i: [
                      [0.25, 0],
                      [0.657, -3.066],
                      [0.825, -2.981],
                      [0.914, -2.922],
                      [0.922, -2.889],
                      [0.85, -2.882],
                      [0.698, -2.901],
                      [0.466, -2.945],
                      [0.154, -3.016],
                      [-0.66, 0.406],
                      [-0.323, 0.744],
                      [-0.253, 0.814],
                      [-0.451, 0.616],
                      [-0.917, 0.15],
                      [-0.611, -2.306],
                      [-0.623, -2.294],
                      [-1.201, -1.716],
                      [-2.344, -0.572],
                      [-0.208, 7.239],
                      [0, 7.456],
                      [0.208, 7.239],
                      [0.416, 6.588],
                      [0.25, 0],
                      [0.25, 0],
                      [0.25, 0]
                    ],
                    o: [
                      [-0.408, 3.177],
                      [-0.657, 3.066],
                      [-0.825, 2.981],
                      [-0.914, 2.922],
                      [-0.922, 2.889],
                      [-0.85, 2.882],
                      [-0.698, 2.901],
                      [-0.466, 2.945],
                      [1.265, 0.199],
                      [0.66, -0.406],
                      [0.323, -0.744],
                      [0.253, -0.814],
                      [0.451, -0.616],
                      [1.165, 1.752],
                      [0.611, 2.306],
                      [0.623, 2.294],
                      [1.201, 1.716],
                      [0.416, -6.588],
                      [0.208, -7.239],
                      [0, -7.456],
                      [-0.208, -7.239],
                      [-0.25, 0],
                      [-0.25, 0],
                      [-0.25, 0],
                      [-0.25, 0]
                    ],
                    v: [
                      [170.5, -308],
                      [168.883, -298.641],
                      [166.639, -289.576],
                      [164.011, -280.727],
                      [161.237, -272.016],
                      [158.558, -263.366],
                      [156.215, -254.698],
                      [154.449, -245.935],
                      [153.5, -237],
                      [156.322, -237.378],
                      [157.729, -239.171],
                      [158.526, -241.574],
                      [159.515, -243.785],
                      [161.5, -245],
                      [164.021, -238.771],
                      [165.73, -231.73],
                      [168.324, -225.574],
                      [173.5, -222],
                      [174.436, -242.849],
                      [174.748, -265],
                      [174.436, -287.151],
                      [173.5, -308],
                      [172.75, -308],
                      [172, -308],
                      [171.25, -308]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 29,
                s: [
                  {
                    i: [
                      [0.341, 0.75],
                      [0.106, -1.302],
                      [0.192, -1.215],
                      [0.255, -1.153],
                      [0.293, -1.114],
                      [0.308, -1.1],
                      [0.298, -1.109],
                      [0.264, -1.143],
                      [0.207, -1.201],
                      [0.125, -1.283],
                      [-0.478, 0.104],
                      [-0.348, -0.037],
                      [-0.253, -0.152],
                      [-0.195, -0.239],
                      [-0.173, -0.299],
                      [-0.187, -0.332],
                      [-0.238, -0.338],
                      [-0.324, -0.316],
                      [-0.249, 1.424],
                      [-0.131, 1.656],
                      [-0.024, 1.779],
                      [0.072, 1.792],
                      [0.156, 1.696],
                      [0.229, 1.49],
                      [0.291, 1.175]
                    ],
                    o: [
                      [0.005, 1.412],
                      [-0.106, 1.302],
                      [-0.192, 1.215],
                      [-0.255, 1.153],
                      [-0.293, 1.114],
                      [-0.308, 1.1],
                      [-0.298, 1.109],
                      [-0.264, 1.143],
                      [-0.207, 1.201],
                      [0.645, -0.273],
                      [0.478, -0.104],
                      [0.348, 0.037],
                      [0.253, 0.152],
                      [0.195, 0.239],
                      [0.173, 0.299],
                      [0.187, 0.332],
                      [0.238, 0.338],
                      [0.38, -1.082],
                      [0.249, -1.424],
                      [0.131, -1.656],
                      [0.024, -1.779],
                      [-0.072, -1.792],
                      [-0.156, -1.696],
                      [-0.229, -1.49],
                      [-0.291, -1.175]
                    ],
                    v: [
                      [168.5, -315],
                      [168.343, -310.935],
                      [167.891, -307.165],
                      [167.214, -303.619],
                      [166.386, -300.225],
                      [165.479, -296.91],
                      [164.564, -293.603],
                      [163.715, -290.23],
                      [163.003, -286.72],
                      [162.5, -283],
                      [164.176, -283.56],
                      [165.407, -283.653],
                      [166.299, -283.362],
                      [166.963, -282.769],
                      [167.506, -281.954],
                      [168.037, -280.999],
                      [168.666, -279.988],
                      [169.5, -279],
                      [170.441, -282.787],
                      [171.008, -287.435],
                      [171.237, -292.614],
                      [171.161, -297.998],
                      [170.815, -303.256],
                      [170.234, -308.062],
                      [169.45, -312.086]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 30,
                s: [
                  {
                    i: [
                      [0.224, -0.016],
                      [0.077, -0.63],
                      [0.107, -0.774],
                      [0.109, -0.872],
                      [0.082, -0.924],
                      [0.028, -0.93],
                      [-0.055, -0.891],
                      [-0.167, -0.805],
                      [-0.306, -0.673],
                      [-0.474, -0.496],
                      [-0.117, 0.841],
                      [-0.115, 0.843],
                      [-0.115, 0.843],
                      [-0.118, 0.84],
                      [-0.123, 0.835],
                      [-0.132, 0.827],
                      [-0.142, 0.816],
                      [-0.156, 0.803],
                      [0.193, 0.015],
                      [0.162, 0.047],
                      [0.142, 0.066],
                      [0.135, 0.073],
                      [0.139, 0.069],
                      [0.156, 0.053],
                      [0.184, 0.024]
                    ],
                    o: [
                      [-0.018, 0.439],
                      [-0.077, 0.63],
                      [-0.107, 0.774],
                      [-0.109, 0.872],
                      [-0.082, 0.924],
                      [-0.028, 0.93],
                      [0.055, 0.891],
                      [0.167, 0.805],
                      [0.306, 0.673],
                      [0.122, -0.836],
                      [0.117, -0.841],
                      [0.115, -0.843],
                      [0.115, -0.843],
                      [0.118, -0.84],
                      [0.123, -0.835],
                      [0.132, -0.827],
                      [0.142, -0.816],
                      [-0.236, 0.028],
                      [-0.193, -0.015],
                      [-0.162, -0.047],
                      [-0.142, -0.066],
                      [-0.135, -0.073],
                      [-0.139, -0.069],
                      [-0.156, -0.053],
                      [-0.184, -0.024]
                    ],
                    v: [
                      [167.5, -321],
                      [167.351, -319.385],
                      [167.069, -317.269],
                      [166.739, -314.789],
                      [166.445, -312.084],
                      [166.273, -309.291],
                      [166.308, -306.548],
                      [166.634, -303.993],
                      [167.337, -301.765],
                      [168.5, -300],
                      [168.859, -302.516],
                      [169.207, -305.043],
                      [169.551, -307.574],
                      [169.9, -310.1],
                      [170.261, -312.613],
                      [170.643, -315.106],
                      [171.054, -317.571],
                      [171.5, -320],
                      [170.859, -319.984],
                      [170.33, -320.08],
                      [169.876, -320.251],
                      [169.464, -320.464],
                      [169.055, -320.68],
                      [168.616, -320.866],
                      [168.109, -320.984]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 31,
                s: [
                  {
                    i: [
                      [-0.097, 1.67],
                      [0.435, -1.009],
                      [0.497, -0.982],
                      [0.545, -0.96],
                      [0.579, -0.944],
                      [0.601, -0.933],
                      [0.608, -0.927],
                      [0.602, -0.926],
                      [0.582, -0.931],
                      [0.549, -0.941],
                      [0.502, -0.957],
                      [0.442, -0.977],
                      [0.368, -1.003],
                      [0.281, -1.035],
                      [0.18, -1.071],
                      [0.065, -1.113],
                      [-0.835, 1.358],
                      [-0.885, 1.372],
                      [-0.903, 1.391],
                      [-0.887, 1.416],
                      [-0.838, 1.445],
                      [-0.756, 1.48],
                      [-0.641, 1.52],
                      [-0.493, 1.565],
                      [-0.311, 1.615]
                    ],
                    o: [
                      [-0.359, 1.041],
                      [-0.435, 1.009],
                      [-0.497, 0.982],
                      [-0.545, 0.96],
                      [-0.579, 0.944],
                      [-0.601, 0.933],
                      [-0.608, 0.927],
                      [-0.602, 0.926],
                      [-0.582, 0.931],
                      [-0.549, 0.941],
                      [-0.502, 0.957],
                      [-0.442, 0.977],
                      [-0.368, 1.003],
                      [-0.281, 1.035],
                      [-0.18, 1.071],
                      [0.752, -1.349],
                      [0.835, -1.358],
                      [0.885, -1.372],
                      [0.903, -1.391],
                      [0.887, -1.416],
                      [0.838, -1.445],
                      [0.756, -1.48],
                      [0.641, -1.52],
                      [0.493, -1.565],
                      [0.311, -1.615]
                    ],
                    v: [
                      [504.5, -328],
                      [503.306, -324.927],
                      [501.906, -321.943],
                      [500.34, -319.031],
                      [498.651, -316.177],
                      [496.877, -313.363],
                      [495.061, -310.575],
                      [493.242, -307.796],
                      [491.462, -305.011],
                      [489.761, -302.203],
                      [488.18, -299.357],
                      [486.76, -296.457],
                      [485.541, -293.488],
                      [484.564, -290.432],
                      [483.87, -287.275],
                      [483.5, -284],
                      [485.888, -288.059],
                      [488.477, -292.152],
                      [491.167, -296.296],
                      [493.86, -300.505],
                      [496.455, -304.795],
                      [498.854, -309.181],
                      [500.957, -313.68],
                      [502.666, -318.305],
                      [503.88, -323.074]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 32,
                s: [
                  {
                    i: [
                      [0.202, -0.035],
                      [0.251, -1.379],
                      [0.492, -1.138],
                      [0.663, -0.966],
                      [0.765, -0.864],
                      [0.798, -0.832],
                      [0.761, -0.869],
                      [0.654, -0.976],
                      [0.478, -1.152],
                      [0.232, -1.398],
                      [-1.067, 0.85],
                      [-0.967, 0.95],
                      [-0.864, 1.053],
                      [-0.758, 1.158],
                      [-0.649, 1.267],
                      [-0.537, 1.379],
                      [-0.422, 1.495],
                      [-0.304, 1.613],
                      [0.128, 0.038],
                      [0.107, 0.059],
                      [0.097, 0.07],
                      [0.097, 0.07],
                      [0.108, 0.059],
                      [0.129, 0.038],
                      [0.16, 0.007]
                    ],
                    o: [
                      [0.06, 1.689],
                      [-0.251, 1.379],
                      [-0.492, 1.138],
                      [-0.663, 0.966],
                      [-0.765, 0.864],
                      [-0.798, 0.832],
                      [-0.761, 0.869],
                      [-0.654, 0.976],
                      [-0.478, 1.152],
                      [1.163, -0.753],
                      [1.067, -0.85],
                      [0.967, -0.95],
                      [0.864, -1.053],
                      [0.758, -1.158],
                      [0.649, -1.267],
                      [0.537, -1.379],
                      [0.422, -1.495],
                      [-0.16, -0.007],
                      [-0.128, -0.038],
                      [-0.107, -0.059],
                      [-0.097, -0.07],
                      [-0.097, -0.07],
                      [-0.108, -0.059],
                      [-0.129, -0.038],
                      [-0.16, -0.007]
                    ],
                    v: [
                      [507.5, -336],
                      [507.196, -331.416],
                      [506.064, -327.659],
                      [504.313, -324.52],
                      [502.153, -321.792],
                      [499.791, -319.265],
                      [497.436, -316.731],
                      [495.297, -313.981],
                      [493.582, -310.807],
                      [492.5, -307],
                      [495.846, -309.404],
                      [498.897, -312.103],
                      [501.644, -315.106],
                      [504.078, -318.421],
                      [506.191, -322.06],
                      [507.971, -326.029],
                      [509.411, -330.34],
                      [510.5, -335],
                      [510.07, -335.07],
                      [509.719, -335.219],
                      [509.415, -335.415],
                      [509.126, -335.626],
                      [508.822, -335.822],
                      [508.47, -335.97],
                      [508.04, -336.04]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 33,
                s: [
                  {
                    i: [
                      [0.358, 0.571],
                      [0.354, -0.537],
                      [0.393, -0.571],
                      [0.402, -0.597],
                      [0.381, -0.615],
                      [0.331, -0.624],
                      [0.251, -0.625],
                      [0.14, -0.618],
                      [0.001, -0.603],
                      [-0.169, -0.579],
                      [0.005, 0.255],
                      [-0.095, 0.155],
                      [-0.172, 0.078],
                      [-0.226, 0.024],
                      [-0.258, -0.008],
                      [-0.268, -0.018],
                      [-0.256, -0.006],
                      [-0.221, 0.029],
                      [-0.16, 0.605],
                      [-0.254, 0.594],
                      [-0.292, 0.585],
                      [-0.274, 0.578],
                      [-0.2, 0.573],
                      [-0.07, 0.57],
                      [0.116, 0.569]
                    ],
                    o: [
                      [-0.285, 0.494],
                      [-0.354, 0.537],
                      [-0.393, 0.571],
                      [-0.402, 0.597],
                      [-0.381, 0.615],
                      [-0.331, 0.624],
                      [-0.251, 0.625],
                      [-0.14, 0.618],
                      [-0.001, 0.603],
                      [-0.126, -0.376],
                      [-0.005, -0.255],
                      [0.095, -0.155],
                      [0.172, -0.078],
                      [0.226, -0.024],
                      [0.258, 0.008],
                      [0.268, 0.018],
                      [0.256, 0.006],
                      [0.01, -0.618],
                      [0.16, -0.605],
                      [0.254, -0.594],
                      [0.292, -0.585],
                      [0.274, -0.578],
                      [0.2, -0.573],
                      [0.07, -0.57],
                      [-0.116, -0.569]
                    ],
                    v: [
                      [509.5, -338],
                      [508.533, -336.452],
                      [507.405, -334.787],
                      [506.206, -333.033],
                      [505.023, -331.212],
                      [503.948, -329.351],
                      [503.068, -327.474],
                      [502.474, -325.607],
                      [502.255, -323.774],
                      [502.5, -322],
                      [502.31, -322.94],
                      [502.451, -323.549],
                      [502.855, -323.895],
                      [503.457, -324.043],
                      [504.19, -324.06],
                      [504.986, -324.014],
                      [505.778, -323.972],
                      [506.5, -324],
                      [506.77, -325.834],
                      [507.407, -327.631],
                      [508.24, -329.399],
                      [509.104, -331.142],
                      [509.83, -332.868],
                      [510.25, -334.582],
                      [510.196, -336.291]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 34,
                s: [
                  {
                    i: [
                      [-0.107, 0.493],
                      [0.347, -0.053],
                      [0.302, -0.098],
                      [0.262, -0.138],
                      [0.226, -0.174],
                      [0.194, -0.206],
                      [0.168, -0.232],
                      [0.145, -0.255],
                      [0.127, -0.273],
                      [0.114, -0.286],
                      [0.105, -0.295],
                      [0.101, -0.299],
                      [0.101, -0.299],
                      [0.105, -0.295],
                      [0.114, -0.286],
                      [0.128, -0.272],
                      [-0.472, 0.128],
                      [-0.402, 0.198],
                      [-0.339, 0.261],
                      [-0.284, 0.316],
                      [-0.236, 0.364],
                      [-0.195, 0.405],
                      [-0.162, 0.438],
                      [-0.136, 0.464],
                      [-0.118, 0.482]
                    ],
                    o: [
                      [-0.396, 0.004],
                      [-0.347, 0.053],
                      [-0.302, 0.098],
                      [-0.262, 0.138],
                      [-0.226, 0.174],
                      [-0.194, 0.206],
                      [-0.168, 0.232],
                      [-0.145, 0.255],
                      [-0.127, 0.273],
                      [-0.114, 0.286],
                      [-0.105, 0.295],
                      [-0.101, 0.299],
                      [-0.101, 0.299],
                      [-0.105, 0.295],
                      [-0.114, 0.286],
                      [0.55, -0.05],
                      [0.472, -0.128],
                      [0.402, -0.198],
                      [0.339, -0.261],
                      [0.284, -0.316],
                      [0.236, -0.364],
                      [0.195, -0.405],
                      [0.162, -0.438],
                      [0.136, -0.464],
                      [0.118, -0.482]
                    ],
                    v: [
                      [512.5, -341],
                      [511.386, -340.914],
                      [510.414, -340.686],
                      [509.569, -340.331],
                      [508.839, -339.861],
                      [508.21, -339.29],
                      [507.668, -338.632],
                      [507.201, -337.899],
                      [506.793, -337.107],
                      [506.433, -336.267],
                      [506.106, -335.394],
                      [505.799, -334.501],
                      [505.498, -333.602],
                      [505.191, -332.709],
                      [504.862, -331.838],
                      [504.5, -331],
                      [506.031, -331.269],
                      [507.341, -331.76],
                      [508.45, -332.45],
                      [509.383, -333.317],
                      [510.161, -334.34],
                      [510.805, -335.495],
                      [511.339, -336.761],
                      [511.785, -338.115],
                      [512.164, -339.536]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 35,
                s: [
                  {
                    i: [
                      [0.273, 0.216],
                      [0.085, -0.138],
                      [0.087, -0.135],
                      [0.089, -0.134],
                      [0.09, -0.132],
                      [0.091, -0.131],
                      [0.092, -0.13],
                      [0.092, -0.13],
                      [0.092, -0.13],
                      [0.092, -0.13],
                      [0.091, -0.131],
                      [0.09, -0.132],
                      [0.089, -0.134],
                      [0.087, -0.135],
                      [0.085, -0.138],
                      [0.082, -0.14],
                      [-0.358, 0.004],
                      [-0.326, 0.097],
                      [-0.285, 0.173],
                      [-0.234, 0.232],
                      [-0.173, 0.273],
                      [-0.103, 0.297],
                      [-0.023, 0.303],
                      [0.066, 0.291],
                      [0.165, 0.262]
                    ],
                    o: [
                      [-0.082, 0.14],
                      [-0.085, 0.138],
                      [-0.087, 0.135],
                      [-0.089, 0.134],
                      [-0.09, 0.132],
                      [-0.091, 0.131],
                      [-0.092, 0.13],
                      [-0.092, 0.13],
                      [-0.092, 0.13],
                      [-0.092, 0.13],
                      [-0.091, 0.131],
                      [-0.09, 0.132],
                      [-0.089, 0.134],
                      [-0.087, 0.135],
                      [-0.085, 0.138],
                      [0.381, 0.107],
                      [0.358, -0.004],
                      [0.326, -0.097],
                      [0.285, -0.173],
                      [0.234, -0.232],
                      [0.173, -0.273],
                      [0.103, -0.297],
                      [0.023, -0.303],
                      [-0.066, -0.291],
                      [-0.165, -0.262]
                    ],
                    v: [
                      [511.5, -342],
                      [511.25, -341.584],
                      [510.992, -341.174],
                      [510.729, -340.771],
                      [510.461, -340.372],
                      [510.189, -339.978],
                      [509.915, -339.585],
                      [509.638, -339.195],
                      [509.362, -338.805],
                      [509.085, -338.415],
                      [508.811, -338.022],
                      [508.539, -337.628],
                      [508.271, -337.229],
                      [508.008, -336.826],
                      [507.75, -336.416],
                      [507.5, -336],
                      [508.611, -335.849],
                      [509.641, -336.005],
                      [510.56, -336.415],
                      [511.34, -337.027],
                      [511.952, -337.789],
                      [512.369, -338.648],
                      [512.561, -339.551],
                      [512.499, -340.445],
                      [512.155, -341.279]
                    ],
                    c: true
                  }
                ],
                h: 1
              }
            ],
            ix: 1
          },
          o: {
            a: 1,
            k: [
              { t: 21, s: [0], h: 1 },
              { t: 22, s: [100], h: 1 },
              { t: 36, s: [0], h: 1 }
            ],
            ix: 3
          },
          x: { a: 0, k: 0, ix: 4 },
          nm: 'Mask 2'
        },
        {
          inv: false,
          mode: 'f',
          pt: {
            a: 1,
            k: [
              {
                t: 23,
                s: [
                  {
                    i: [
                      [-0.012, -0.012],
                      [-0.016, -0.016],
                      [-0.013, -0.013],
                      [-0.011, -0.011],
                      [-0.009, -0.009],
                      [-0.006, -0.006],
                      [-0.004, -0.004],
                      [-0.003, -0.003],
                      [-0.001, -0.001],
                      [0.001, 0.001],
                      [0.002, 0.002],
                      [0.003, 0.003],
                      [0.004, 0.004],
                      [0.005, 0.005],
                      [0.006, 0.006],
                      [0.007, 0.007],
                      [0.007, 0.007],
                      [0.008, 0.008],
                      [0.008, 0.008],
                      [0.008, 0.008],
                      [0.008, 0.008],
                      [0.007, 0.007],
                      [0.007, 0.007],
                      [0.006, 0.006],
                      [0.006, 0.006],
                      [0.005, 0.005],
                      [0.004, 0.004],
                      [0.003, 0.003],
                      [0.001, 0.001],
                      [0, 0],
                      [-0.002, -0.002],
                      [-0.003, -0.003],
                      [-0.005, -0.005],
                      [-0.007, -0.007],
                      [-0.01, -0.01]
                    ],
                    o: [
                      [0.019, 0.019],
                      [0.016, 0.016],
                      [0.013, 0.013],
                      [0.011, 0.011],
                      [0.009, 0.009],
                      [0.006, 0.006],
                      [0.004, 0.004],
                      [0.003, 0.003],
                      [0.001, 0.001],
                      [-0.001, -0.001],
                      [-0.002, -0.002],
                      [-0.003, -0.003],
                      [-0.004, -0.004],
                      [-0.005, -0.005],
                      [-0.006, -0.006],
                      [-0.007, -0.007],
                      [-0.007, -0.007],
                      [-0.008, -0.008],
                      [-0.008, -0.008],
                      [-0.008, -0.008],
                      [-0.008, -0.008],
                      [-0.007, -0.007],
                      [-0.007, -0.007],
                      [-0.006, -0.006],
                      [-0.006, -0.006],
                      [-0.005, -0.005],
                      [-0.004, -0.004],
                      [-0.003, -0.003],
                      [-0.001, -0.001],
                      [0, 0],
                      [0.002, 0.002],
                      [0.003, 0.003],
                      [0.005, 0.005],
                      [0.007, 0.007],
                      [0.01, 0.01]
                    ],
                    v: [
                      [8.5, 302],
                      [8.552, 302.052],
                      [8.596, 302.096],
                      [8.632, 302.132],
                      [8.662, 302.162],
                      [8.684, 302.184],
                      [8.701, 302.201],
                      [8.711, 302.211],
                      [8.717, 302.217],
                      [8.717, 302.217],
                      [8.713, 302.213],
                      [8.705, 302.205],
                      [8.694, 302.194],
                      [8.679, 302.179],
                      [8.662, 302.162],
                      [8.643, 302.143],
                      [8.622, 302.122],
                      [8.6, 302.1],
                      [8.577, 302.077],
                      [8.554, 302.054],
                      [8.53, 302.03],
                      [8.508, 302.008],
                      [8.486, 301.986],
                      [8.466, 301.966],
                      [8.447, 301.947],
                      [8.431, 301.931],
                      [8.418, 301.918],
                      [8.408, 301.908],
                      [8.402, 301.902],
                      [8.4, 301.9],
                      [8.402, 301.902],
                      [8.41, 301.91],
                      [8.423, 301.923],
                      [8.442, 301.942],
                      [8.468, 301.968]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 24,
                s: [
                  {
                    i: [
                      [-14.164, -1.887],
                      [9.511, 30.068],
                      [12.358, 27.299],
                      [-0.81, -0.19],
                      [0.184, -0.851],
                      [-70.207, -43.894],
                      [-12.735, -0.14],
                      [7.432, 12.126],
                      [1.655, 17.767],
                      [-44.147, -2.245],
                      [-10.348, 5.595],
                      [7.134, 7.516],
                      [7.979, 6.978],
                      [13.13, 17.87],
                      [14.621, 16.379],
                      [17.421, 13.912],
                      [-5.418, -16.549],
                      [-17.06, -45.811],
                      [1.138, -35.985],
                      [36.267, 27.733],
                      [16.382, 15.618],
                      [8.618, 7.382],
                      [9.839, 1.896],
                      [-24.058, -49.942],
                      [22.434, -1.633],
                      [13.423, -58.293],
                      [-39.375, -37.598],
                      [-49.081, 5.348],
                      [-11.705, 6.342],
                      [10.004, 3.311],
                      [9.91, 2.608],
                      [3.799, 68.023],
                      [-4.891, 14.174],
                      [-8.525, 4.141],
                      [-36.878, -18.541]
                    ],
                    o: [
                      [-2.569, -33.708],
                      [-9.664, -30.55],
                      [1.003, -0.003],
                      [0.61, 0.057],
                      [68.972, 46.701],
                      [9.926, 6.205],
                      [0.436, -15.369],
                      [-7.57, -12.35],
                      [-4.339, -46.568],
                      [12.847, 0.653],
                      [-1.082, -10.527],
                      [-7.723, -8.136],
                      [-13.589, -17.411],
                      [-15.553, -15.447],
                      [-14.783, -16.55],
                      [0.013, 17.796],
                      [16.56, 50.578],
                      [-36.51, -2.676],
                      [-41.119, -22.88],
                      [-17.715, -14.285],
                      [-9.238, -6.762],
                      [-9.333, -5.718],
                      [17.131, 60.203],
                      [-15.259, -7.505],
                      [-63.154, 4.597],
                      [-15.654, 67.985],
                      [25.596, 24.083],
                      [15.128, -1.648],
                      [-7.445, -6.959],
                      [-10.265, -3.397],
                      [-50.017, -22.895],
                      [-1.078, -19.301],
                      [5.558, -7.109],
                      [49.228, -22.118],
                      [14.179, 7.128]
                    ],
                    v: [
                      [-329.5, 308],
                      [-350.5, 212],
                      [-383.5, 125],
                      [-381.5, 126],
                      [-380.5, 127],
                      [-169.5, 264],
                      [-137.5, 280],
                      [-155.5, 241],
                      [-172.5, 198],
                      [-103.5, 116],
                      [-70.5, 121],
                      [-89.5, 96],
                      [-112.5, 72],
                      [-155.5, 22],
                      [-198.5, -28],
                      [-241.5, -79],
                      [-228.5, -28],
                      [-175.5, 117],
                      [-240.5, 165],
                      [-355.5, 88],
                      [-407.5, 44],
                      [-432.5, 21],
                      [-457.5, -1],
                      [-415.5, 191],
                      [-470.5, 181],
                      [-590.5, 294],
                      [-544.5, 471],
                      [-419.5, 498],
                      [-376.5, 489],
                      [-405.5, 478],
                      [-435.5, 467],
                      [-546.5, 335],
                      [-538.5, 291],
                      [-519.5, 272],
                      [-369.5, 288]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 25,
                s: [
                  {
                    i: [
                      [-2.221, -2.779],
                      [0.139, 7.55],
                      [1.505, 6.614],
                      [2.644, 5.758],
                      [3.557, 4.983],
                      [4.244, 4.287],
                      [4.704, 3.671],
                      [4.938, 3.135],
                      [6.231, 2.853],
                      [6.803, 1.82],
                      [7.671, 0.682],
                      [8.835, -0.56],
                      [7.965, -4.149],
                      [5.897, -6.225],
                      [3.859, -7.839],
                      [1.849, -8.99],
                      [-2.075, 1.508],
                      [-1.778, 1.805],
                      [-1.814, 1.769],
                      [-2.183, 1.4],
                      [-2.431, 1.153],
                      [-2.503, 1.08],
                      [-2.571, 1.013],
                      [-2.633, 0.95],
                      [-9.939, -0.89],
                      [-8.767, -3.173],
                      [-7.819, -4.196],
                      [-7.095, -3.961],
                      [-2.562, -2.438],
                      [-2.483, -2.517],
                      [-2.304, -2.696],
                      [-2.027, -2.973],
                      [-1.69, -3.31],
                      [-1.515, -3.485],
                      [-1.692, -3.308]
                    ],
                    o: [
                      [1.453, -8.565],
                      [-0.139, -7.55],
                      [-1.505, -6.614],
                      [-2.644, -5.758],
                      [-3.557, -4.983],
                      [-4.244, -4.287],
                      [-4.704, -3.671],
                      [-5.954, -3.781],
                      [-6.231, -2.853],
                      [-6.803, -1.82],
                      [-7.671, -0.682],
                      [-10.061, 1.61],
                      [-7.965, 4.149],
                      [-5.897, 6.225],
                      [-3.859, 7.839],
                      [2.705, -0.879],
                      [2.075, -1.508],
                      [1.778, -1.805],
                      [1.814, -1.769],
                      [2.354, -1.23],
                      [2.431, -1.152],
                      [2.503, -1.08],
                      [2.571, -1.012],
                      [11.335, -2.652],
                      [9.939, 0.89],
                      [8.767, 3.173],
                      [7.819, 4.196],
                      [2.542, 2.458],
                      [2.562, 2.438],
                      [2.483, 2.517],
                      [2.304, 2.696],
                      [2.218, 2.782],
                      [1.69, 3.31],
                      [1.515, 3.485],
                      [1.692, 3.308]
                    ],
                    v: [
                      [212.5, 133],
                      [214.415, 108.847],
                      [211.893, 87.622],
                      [205.613, 69.083],
                      [196.254, 52.991],
                      [184.497, 39.107],
                      [171.019, 27.19],
                      [156.5, 17],
                      [138.296, 7.023],
                      [118.82, -0.011],
                      [97.184, -3.791],
                      [72.5, -4],
                      [45.468, 4.753],
                      [24.682, 20.43],
                      [10.055, 41.642],
                      [1.5, 67],
                      [8.586, 63.336],
                      [14.282, 58.282],
                      [19.587, 52.837],
                      [25.5, 48],
                      [32.678, 44.427],
                      [40.08, 41.08],
                      [47.693, 37.942],
                      [55.5, 35],
                      [87.355, 32.672],
                      [115.36, 39.08],
                      [140.184, 50.449],
                      [162.5, 63],
                      [170.181, 70.319],
                      [177.772, 77.728],
                      [184.978, 85.522],
                      [191.5, 94],
                      [197.274, 103.226],
                      [201.995, 113.505],
                      [206.718, 123.782]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 26,
                s: [
                  {
                    i: [
                      [-1.812, 13.023],
                      [7.114, -11.803],
                      [7.371, -11.546],
                      [7.072, -11.845],
                      [6.216, -12.701],
                      [7.578, -13.588],
                      [5.569, -15.598],
                      [4.456, -16.71],
                      [4.24, -16.927],
                      [-0.291, -0.074],
                      [-0.409, 0.52],
                      [-0.414, 0.584],
                      [-0.304, 0.117],
                      [-0.418, 0.415],
                      [-0.472, 0.361],
                      [-0.318, 0.515],
                      [0.044, 0.878],
                      [-0.866, 0.884],
                      [-0.769, 0.981],
                      [-0.769, 0.981],
                      [-0.864, 0.886],
                      [-2.561, 2.643],
                      [-2.663, 2.532],
                      [-2.771, 2.498],
                      [-2.882, 2.541],
                      [-3.009, 2.34],
                      [-3.02, 2.457],
                      [-2.626, 2.639],
                      [-1.827, 2.885],
                      [-1.695, 4.315],
                      [-1.495, 4.629],
                      [-1.583, 4.389],
                      [-6.872, 20.22],
                      [-7.188, 19.804],
                      [-4.032, 13.384]
                    ],
                    o: [
                      [-6.3, 12.616],
                      [-7.114, 11.803],
                      [-7.371, 11.546],
                      [-7.072, 11.845],
                      [-10.484, 10.683],
                      [-7.578, 13.588],
                      [-5.569, 15.598],
                      [-4.456, 16.71],
                      [0.059, 1.198],
                      [0.291, 0.074],
                      [0.409, -0.52],
                      [0.414, -0.584],
                      [0.156, -0.678],
                      [0.418, -0.415],
                      [0.472, -0.361],
                      [0.318, -0.515],
                      [1.058, -0.691],
                      [0.866, -0.884],
                      [0.769, -0.981],
                      [0.769, -0.981],
                      [2.464, -2.831],
                      [2.561, -2.643],
                      [2.663, -2.532],
                      [2.771, -2.498],
                      [2.594, -2.287],
                      [3.009, -2.34],
                      [3.02, -2.457],
                      [2.626, -2.639],
                      [2.181, -3.445],
                      [1.695, -4.315],
                      [1.495, -4.629],
                      [7.326, -20.314],
                      [6.871, -20.22],
                      [0.06, -16.44],
                      [4.032, -13.384]
                    ],
                    v: [
                      [470.5, -255],
                      [450.239, -218.511],
                      [428.373, -183.628],
                      [406.57, -148.68],
                      [386.5, -112],
                      [359.631, -75.369],
                      [340.134, -31.366],
                      [325.32, 17.32],
                      [312.5, 68],
                      [313.054, 69.775],
                      [314.133, 68.973],
                      [315.395, 67.184],
                      [316.5, 66],
                      [317.412, 64.413],
                      [318.8, 63.3],
                      [320.038, 62.037],
                      [320.5, 60],
                      [323.363, 57.613],
                      [325.791, 54.791],
                      [328.074, 51.824],
                      [330.5, 49],
                      [338.036, 40.808],
                      [345.872, 33.065],
                      [354.022, 25.54],
                      [362.5, 18],
                      [371.006, 11.075],
                      [380.151, 3.897],
                      [388.72, -3.73],
                      [395.5, -12],
                      [401.242, -23.779],
                      [405.955, -37.333],
                      [410.5, -51],
                      [431.604, -111.883],
                      [452.5, -172],
                      [460.187, -216.063]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 27,
                s: [
                  {
                    i: [
                      [-10.438, 33.562],
                      [0.25, 0],
                      [0.25, 0],
                      [0.25, 0],
                      [0.25, 0],
                      [0.118, -0.215],
                      [0.139, -0.194],
                      [0.076, -0.257],
                      [-0.07, -0.404],
                      [4.786, -8.976],
                      [5.055, -8.727],
                      [5.241, -8.638],
                      [5.345, -8.707],
                      [3.85, -6.575],
                      [3.848, -6.714],
                      [3.824, -6.657],
                      [3.776, -6.403],
                      [1.481, -2.183],
                      [1.472, -2.178],
                      [1.255, -2.156],
                      [0.829, -2.117],
                      [0.801, -3.547],
                      [0.537, -3.7],
                      [0.467, -3.678],
                      [0.592, -3.48],
                      [0.527, -3.699],
                      [0.521, -3.736],
                      [0.609, -3.794],
                      [0.79, -3.873],
                      [-1.99, 2.99],
                      [-1.371, 3.422],
                      [-1.586, 3.029],
                      [-9.721, 12.876],
                      [-11.377, 10.71],
                      [-11.155, 32.845]
                    ],
                    o: [
                      [-0.25, 0],
                      [-0.25, 0],
                      [-0.25, 0],
                      [-0.25, 0],
                      [-0.014, 0.32],
                      [-0.118, 0.215],
                      [-0.139, 0.194],
                      [-0.076, 0.257],
                      [-4.434, 9.384],
                      [-4.786, 8.976],
                      [-5.055, 8.727],
                      [-5.241, 8.638],
                      [-3.83, 6.24],
                      [-3.85, 6.575],
                      [-3.848, 6.714],
                      [-3.824, 6.657],
                      [-1.281, 2.172],
                      [-1.481, 2.183],
                      [-1.472, 2.178],
                      [-1.255, 2.156],
                      [-1.26, 3.219],
                      [-0.801, 3.547],
                      [-0.537, 3.7],
                      [-0.467, 3.678],
                      [-0.626, 3.683],
                      [-0.527, 3.699],
                      [-0.521, 3.736],
                      [-0.609, 3.794],
                      [3.442, -1.733],
                      [1.99, -2.99],
                      [1.371, -3.422],
                      [7.74, -14.787],
                      [9.721, -12.876],
                      [10.942, -33.058],
                      [11.155, -32.845]
                    ],
                    v: [
                      [488.5, -289],
                      [487.75, -289],
                      [487, -289],
                      [486.25, -289],
                      [485.5, -289],
                      [485.281, -288.219],
                      [484.874, -287.626],
                      [484.53, -286.97],
                      [484.5, -286],
                      [470.649, -258.5],
                      [455.866, -231.985],
                      [440.4, -205.978],
                      [424.5, -180],
                      [412.974, -160.728],
                      [401.42, -140.745],
                      [389.906, -120.639],
                      [378.5, -101],
                      [374.304, -94.462],
                      [369.822, -87.917],
                      [365.679, -81.413],
                      [362.5, -75],
                      [359.456, -64.807],
                      [357.497, -53.891],
                      [356.04, -42.78],
                      [354.5, -32],
                      [352.793, -20.933],
                      [351.245, -9.786],
                      [349.574, 1.504],
                      [347.5, 13],
                      [355.44, 5.709],
                      [360.273, -4.116],
                      [364.5, -14],
                      [390.772, -55.557],
                      [422.5, -91],
                      [455.878, -189.622]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 28,
                s: [
                  {
                    i: [
                      [-2.397, 11.938],
                      [0.208, 0],
                      [0.208, 0],
                      [0.208, 0],
                      [0.208, 0],
                      [0.208, 0],
                      [0.208, 0],
                      [0.208, 0],
                      [0.208, 0],
                      [4.127, -8.137],
                      [4.42, -7.929],
                      [4.505, -7.873],
                      [4.385, -7.969],
                      [4.057, -8.218],
                      [3.523, -8.618],
                      [2.782, -9.17],
                      [1.834, -9.874],
                      [-1.185, 1.339],
                      [-0.99, 1.534],
                      [-0.86, 1.664],
                      [-0.796, 1.728],
                      [-0.796, 1.728],
                      [-0.861, 1.663],
                      [-0.991, 1.533],
                      [-1.83, 2.586],
                      [-1.807, 2.61],
                      [-1.823, 2.593],
                      [-1.88, 2.537],
                      [-0.68, -0.013],
                      [-0.628, 0.038],
                      [-0.509, 0.158],
                      [-0.322, 0.344],
                      [-4.317, 11.297],
                      [-4.646, 10.978],
                      [-4.006, 11.192]
                    ],
                    o: [
                      [-0.208, 0],
                      [-0.208, 0],
                      [-0.208, 0],
                      [-0.208, 0],
                      [-0.208, 0],
                      [-0.208, 0],
                      [-0.208, 0],
                      [-0.208, 0],
                      [-3.628, 8.498],
                      [-4.127, 8.137],
                      [-4.42, 7.929],
                      [-4.505, 7.873],
                      [-4.385, 7.969],
                      [-4.057, 8.218],
                      [-3.523, 8.618],
                      [-2.782, 9.17],
                      [1.444, -1.08],
                      [1.185, -1.339],
                      [0.99, -1.534],
                      [0.86, -1.664],
                      [0.796, -1.728],
                      [0.796, -1.728],
                      [0.861, -1.663],
                      [1.893, -2.523],
                      [1.83, -2.586],
                      [1.807, -2.61],
                      [1.823, -2.593],
                      [0.664, -0.002],
                      [0.68, 0.013],
                      [0.628, -0.038],
                      [0.509, -0.158],
                      [3.018, -12.149],
                      [4.317, -11.297],
                      [4.646, -10.978],
                      [4.006, -11.192]
                    ],
                    v: [
                      [494.5, -300],
                      [493.875, -300],
                      [493.25, -300],
                      [492.625, -300],
                      [492, -300],
                      [491.375, -300],
                      [490.75, -300],
                      [490.125, -300],
                      [489.5, -300],
                      [477.816, -275.085],
                      [464.945, -251.023],
                      [451.506, -227.357],
                      [438.12, -203.631],
                      [425.405, -179.388],
                      [413.984, -154.173],
                      [404.476, -127.529],
                      [397.5, -99],
                      [401.427, -102.645],
                      [404.672, -106.971],
                      [407.431, -111.783],
                      [409.899, -116.887],
                      [412.27, -122.088],
                      [414.738, -127.19],
                      [417.5, -132],
                      [423.075, -139.675],
                      [428.521, -147.479],
                      [433.956, -155.294],
                      [439.5, -163],
                      [441.533, -162.967],
                      [443.513, -162.987],
                      [445.236, -163.264],
                      [446.5, -164],
                      [457.745, -199.037],
                      [471.432, -232.317],
                      [484.654, -265.439]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 29,
                s: [
                  {
                    i: [
                      [-1.351, 7.232],
                      [2.378, -4.372],
                      [2.307, -4.443],
                      [2.25, -4.5],
                      [2.209, -4.541],
                      [2.184, -4.566],
                      [2.173, -4.577],
                      [2.178, -4.572],
                      [2.197, -4.553],
                      [-0.013, -0.654],
                      [0.028, -0.695],
                      [0.042, -0.709],
                      [0.03, -0.696],
                      [-0.009, -0.657],
                      [-0.076, -0.591],
                      [-0.169, -0.498],
                      [-0.288, -0.378],
                      [-0.785, 1.59],
                      [-0.799, 1.576],
                      [-0.814, 1.561],
                      [-0.83, 1.545],
                      [-0.847, 1.528],
                      [-0.866, 1.509],
                      [-0.887, 1.488],
                      [-0.908, 1.467],
                      [-0.239, 0.12],
                      [-0.179, -0.046],
                      [-0.158, -0.149],
                      [-0.173, -0.188],
                      [-0.227, -0.165],
                      [-0.319, -0.079],
                      [-0.449, 0.07],
                      [-2.573, 7.292],
                      [-2.669, 7.206],
                      [-2.262, 7.186]
                    ],
                    o: [
                      [-2.465, 4.285],
                      [-2.378, 4.372],
                      [-2.307, 4.443],
                      [-2.25, 4.5],
                      [-2.209, 4.541],
                      [-2.184, 4.566],
                      [-2.173, 4.577],
                      [-2.178, 4.572],
                      [0.081, 0.586],
                      [0.013, 0.654],
                      [-0.028, 0.695],
                      [-0.042, 0.709],
                      [-0.03, 0.697],
                      [0.009, 0.657],
                      [0.076, 0.591],
                      [0.169, 0.498],
                      [0.773, -1.602],
                      [0.785, -1.59],
                      [0.799, -1.576],
                      [0.814, -1.561],
                      [0.83, -1.545],
                      [0.847, -1.528],
                      [0.866, -1.509],
                      [0.887, -1.488],
                      [0.337, -0.35],
                      [0.239, -0.12],
                      [0.179, 0.046],
                      [0.158, 0.149],
                      [0.173, 0.188],
                      [0.227, 0.165],
                      [0.319, 0.079],
                      [1.972, -7.445],
                      [2.573, -7.292],
                      [2.669, -7.206],
                      [2.262, -7.186]
                    ],
                    v: [
                      [497.5, -314],
                      [490.239, -301.011],
                      [483.216, -287.784],
                      [476.384, -274.366],
                      [469.698, -260.802],
                      [463.112, -247.138],
                      [456.581, -233.419],
                      [450.059, -219.691],
                      [443.5, -206],
                      [443.634, -204.134],
                      [443.605, -202.105],
                      [443.493, -199.992],
                      [443.378, -197.877],
                      [443.34, -195.84],
                      [443.461, -193.961],
                      [443.821, -192.321],
                      [444.5, -191],
                      [446.836, -195.789],
                      [449.211, -200.539],
                      [451.629, -205.246],
                      [454.094, -209.906],
                      [456.609, -214.516],
                      [459.179, -219.071],
                      [461.808, -223.567],
                      [464.5, -228],
                      [465.355, -228.689],
                      [465.973, -228.786],
                      [466.469, -228.478],
                      [466.956, -227.957],
                      [467.548, -227.411],
                      [468.358, -227.029],
                      [469.5, -227],
                      [476.443, -249.089],
                      [484.432, -270.819],
                      [491.954, -292.389]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 30,
                s: [
                  {
                    i: [
                      [0.213, -0.046],
                      [0.756, -1.489],
                      [0.773, -1.471],
                      [0.789, -1.456],
                      [0.801, -1.443],
                      [0.811, -1.433],
                      [0.818, -1.426],
                      [0.823, -1.421],
                      [0.825, -1.419],
                      [0.825, -1.42],
                      [0.822, -1.423],
                      [0.816, -1.429],
                      [0.808, -1.437],
                      [0.797, -1.448],
                      [0.783, -1.461],
                      [0.767, -1.478],
                      [-1.381, 1.425],
                      [-1.275, 1.53],
                      [-1.182, 1.624],
                      [-1.102, 1.704],
                      [-1.034, 1.772],
                      [-0.979, 1.827],
                      [-0.937, 1.869],
                      [-0.908, 1.898],
                      [-0.891, 1.915],
                      [-0.887, 1.919],
                      [-0.896, 1.91],
                      [-0.918, 1.888],
                      [0.019, 0.147],
                      [0.03, 0.137],
                      [0.046, 0.12],
                      [0.068, 0.098],
                      [0.096, 0.071],
                      [0.129, 0.037],
                      [0.168, -0.001]
                    ],
                    o: [
                      [-0.735, 1.509],
                      [-0.756, 1.489],
                      [-0.773, 1.471],
                      [-0.789, 1.456],
                      [-0.801, 1.443],
                      [-0.811, 1.433],
                      [-0.818, 1.426],
                      [-0.823, 1.421],
                      [-0.825, 1.419],
                      [-0.825, 1.42],
                      [-0.822, 1.423],
                      [-0.816, 1.429],
                      [-0.808, 1.437],
                      [-0.797, 1.448],
                      [-0.783, 1.461],
                      [1.5, -1.306],
                      [1.381, -1.425],
                      [1.275, -1.53],
                      [1.182, -1.624],
                      [1.102, -1.704],
                      [1.034, -1.772],
                      [0.979, -1.827],
                      [0.937, -1.869],
                      [0.908, -1.898],
                      [0.891, -1.915],
                      [0.887, -1.919],
                      [0.896, -1.91],
                      [-0.014, -0.153],
                      [-0.019, -0.147],
                      [-0.03, -0.137],
                      [-0.046, -0.12],
                      [-0.068, -0.098],
                      [-0.096, -0.071],
                      [-0.129, -0.037],
                      [-0.168, 0.001]
                    ],
                    v: [
                      [502.5, -320],
                      [500.263, -315.504],
                      [497.968, -311.065],
                      [495.625, -306.675],
                      [493.24, -302.327],
                      [490.821, -298.012],
                      [488.376, -293.724],
                      [485.913, -289.453],
                      [483.44, -285.193],
                      [480.965, -280.935],
                      [478.495, -276.672],
                      [476.038, -272.395],
                      [473.602, -268.097],
                      [471.196, -263.771],
                      [468.825, -259.408],
                      [466.5, -255],
                      [470.818, -259.099],
                      [474.799, -263.534],
                      [478.481, -268.269],
                      [481.903, -273.263],
                      [485.103, -278.48],
                      [488.119, -283.881],
                      [490.99, -289.427],
                      [493.753, -295.08],
                      [496.448, -300.802],
                      [499.112, -306.555],
                      [501.783, -312.301],
                      [504.5, -318],
                      [504.451, -318.451],
                      [504.379, -318.879],
                      [504.266, -319.266],
                      [504.095, -319.596],
                      [503.851, -319.851],
                      [503.514, -320.014],
                      [503.07, -320.07]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 31,
                s: [
                  {
                    i: [
                      [0.213, 0.086],
                      [0.038, -0.254],
                      [0.04, -0.251],
                      [0.041, -0.249],
                      [0.042, -0.246],
                      [0.042, -0.244],
                      [0.041, -0.241],
                      [0.039, -0.239],
                      [0.037, -0.236],
                      [0.034, -0.234],
                      [0.029, -0.232],
                      [0.025, -0.23],
                      [0.019, -0.228],
                      [0.013, -0.226],
                      [0.006, -0.224],
                      [-0.002, -0.222],
                      [-0.011, -0.221],
                      [-0.02, -0.219],
                      [-0.03, -0.218],
                      [-0.041, -0.216],
                      [-0.053, -0.215],
                      [-0.255, 0.106],
                      [-0.215, 0.197],
                      [-0.176, 0.275],
                      [-0.138, 0.337],
                      [-0.101, 0.386],
                      [-0.064, 0.42],
                      [-0.029, 0.441],
                      [0.005, 0.446],
                      [0.037, 0.438],
                      [0.069, 0.415],
                      [0.1, 0.378],
                      [0.13, 0.326],
                      [0.158, 0.261],
                      [0.186, 0.18]
                    ],
                    o: [
                      [-0.035, 0.257],
                      [-0.038, 0.254],
                      [-0.04, 0.251],
                      [-0.041, 0.249],
                      [-0.042, 0.246],
                      [-0.042, 0.244],
                      [-0.041, 0.241],
                      [-0.039, 0.239],
                      [-0.037, 0.236],
                      [-0.034, 0.234],
                      [-0.029, 0.232],
                      [-0.025, 0.23],
                      [-0.019, 0.228],
                      [-0.013, 0.226],
                      [-0.006, 0.224],
                      [0.002, 0.222],
                      [0.011, 0.221],
                      [0.02, 0.219],
                      [0.03, 0.218],
                      [0.041, 0.216],
                      [0.297, 0],
                      [0.255, -0.106],
                      [0.215, -0.197],
                      [0.176, -0.275],
                      [0.138, -0.337],
                      [0.101, -0.386],
                      [0.064, -0.42],
                      [0.029, -0.441],
                      [-0.005, -0.446],
                      [-0.037, -0.438],
                      [-0.069, -0.415],
                      [-0.1, -0.378],
                      [-0.13, -0.326],
                      [-0.158, -0.261],
                      [-0.186, -0.18]
                    ],
                    v: [
                      [169.5, -324],
                      [169.39, -323.233],
                      [169.273, -322.475],
                      [169.151, -321.725],
                      [169.026, -320.982],
                      [168.9, -320.248],
                      [168.775, -319.521],
                      [168.655, -318.801],
                      [168.541, -318.088],
                      [168.435, -317.382],
                      [168.34, -316.683],
                      [168.259, -315.99],
                      [168.193, -315.303],
                      [168.145, -314.622],
                      [168.117, -313.946],
                      [168.111, -313.276],
                      [168.13, -312.611],
                      [168.176, -311.952],
                      [168.252, -311.297],
                      [168.359, -310.646],
                      [168.5, -310],
                      [169.328, -310.162],
                      [170.033, -310.62],
                      [170.619, -311.331],
                      [171.089, -312.252],
                      [171.446, -313.341],
                      [171.693, -314.555],
                      [171.834, -315.85],
                      [171.871, -317.184],
                      [171.808, -318.513],
                      [171.647, -319.796],
                      [171.393, -320.988],
                      [171.048, -322.048],
                      [170.616, -322.932],
                      [170.098, -323.597]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 32,
                s: [
                  {
                    i: [
                      [0.092, 0.033],
                      [0.067, -0.083],
                      [0.057, -0.093],
                      [0.048, -0.102],
                      [0.039, -0.111],
                      [0.031, -0.119],
                      [0.024, -0.126],
                      [0.018, -0.132],
                      [0.013, -0.137],
                      [0.008, -0.142],
                      [0.004, -0.146],
                      [0.001, -0.149],
                      [-0.001, -0.151],
                      [-0.003, -0.153],
                      [-0.004, -0.154],
                      [-0.004, -0.154],
                      [-0.004, -0.154],
                      [-0.002, -0.152],
                      [0, -0.15],
                      [0.002, -0.148],
                      [0.006, -0.144],
                      [-0.19, 0.012],
                      [-0.154, 0.081],
                      [-0.12, 0.14],
                      [-0.089, 0.188],
                      [-0.06, 0.226],
                      [-0.034, 0.254],
                      [-0.01, 0.271],
                      [0.011, 0.277],
                      [0.03, 0.274],
                      [0.047, 0.26],
                      [0.061, 0.235],
                      [0.072, 0.2],
                      [0.081, 0.155],
                      [0.088, 0.099]
                    ],
                    o: [
                      [-0.077, 0.073],
                      [-0.067, 0.083],
                      [-0.057, 0.093],
                      [-0.048, 0.102],
                      [-0.039, 0.111],
                      [-0.031, 0.119],
                      [-0.024, 0.126],
                      [-0.018, 0.132],
                      [-0.013, 0.137],
                      [-0.008, 0.142],
                      [-0.004, 0.146],
                      [-0.001, 0.149],
                      [0.001, 0.151],
                      [0.003, 0.153],
                      [0.004, 0.154],
                      [0.004, 0.154],
                      [0.004, 0.154],
                      [0.002, 0.152],
                      [0, 0.15],
                      [-0.002, 0.148],
                      [0.228, 0.068],
                      [0.19, -0.012],
                      [0.154, -0.081],
                      [0.12, -0.14],
                      [0.089, -0.188],
                      [0.06, -0.226],
                      [0.034, -0.254],
                      [0.01, -0.271],
                      [-0.011, -0.277],
                      [-0.03, -0.274],
                      [-0.047, -0.26],
                      [-0.061, -0.235],
                      [-0.072, -0.2],
                      [-0.081, -0.155],
                      [-0.088, -0.099]
                    ],
                    v: [
                      [169.5, -324],
                      [169.284, -323.766],
                      [169.1, -323.5],
                      [168.943, -323.207],
                      [168.814, -322.886],
                      [168.708, -322.542],
                      [168.625, -322.175],
                      [168.561, -321.789],
                      [168.514, -321.386],
                      [168.483, -320.967],
                      [168.465, -320.535],
                      [168.457, -320.093],
                      [168.458, -319.642],
                      [168.465, -319.185],
                      [168.476, -318.724],
                      [168.489, -318.261],
                      [168.501, -317.799],
                      [168.511, -317.339],
                      [168.515, -316.885],
                      [168.512, -316.438],
                      [168.5, -316],
                      [169.127, -315.919],
                      [169.642, -316.061],
                      [170.053, -316.395],
                      [170.366, -316.89],
                      [170.589, -317.515],
                      [170.73, -318.237],
                      [170.796, -319.026],
                      [170.794, -319.851],
                      [170.732, -320.681],
                      [170.617, -321.483],
                      [170.455, -322.228],
                      [170.256, -322.883],
                      [170.025, -323.418],
                      [169.771, -323.8]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 33,
                s: [
                  {
                    i: [
                      [0.004, -0.005],
                      [0.169, -0.21],
                      [0.109, -0.155],
                      [0.055, -0.104],
                      [0.009, -0.059],
                      [-0.031, -0.019],
                      [-0.063, 0.016],
                      [-0.088, 0.046],
                      [-0.105, 0.07],
                      [-0.116, 0.089],
                      [-0.119, 0.103],
                      [-0.116, 0.112],
                      [-0.105, 0.116],
                      [-0.087, 0.114],
                      [-0.062, 0.108],
                      [-0.029, 0.096],
                      [0.01, 0.078],
                      [0.057, 0.056],
                      [0.111, 0.028],
                      [0.172, -0.004],
                      [0.24, -0.042],
                      [0.003, -0.008],
                      [0.002, -0.015],
                      [0, -0.02],
                      [-0.001, -0.025],
                      [-0.001, -0.028],
                      [-0.002, -0.03],
                      [-0.002, -0.032],
                      [-0.002, -0.032],
                      [-0.002, -0.031],
                      [-0.002, -0.029],
                      [-0.001, -0.027],
                      [0, -0.023],
                      [0.001, -0.018],
                      [0.003, -0.012]
                    ],
                    o: [
                      [-0.237, 0.271],
                      [-0.169, 0.21],
                      [-0.109, 0.155],
                      [-0.055, 0.104],
                      [-0.009, 0.059],
                      [0.031, 0.019],
                      [0.063, -0.016],
                      [0.088, -0.046],
                      [0.105, -0.07],
                      [0.116, -0.089],
                      [0.119, -0.103],
                      [0.116, -0.112],
                      [0.105, -0.116],
                      [0.087, -0.114],
                      [0.062, -0.108],
                      [0.029, -0.096],
                      [-0.01, -0.078],
                      [-0.057, -0.056],
                      [-0.111, -0.028],
                      [-0.172, 0.004],
                      [-0.005, 0.001],
                      [-0.003, 0.008],
                      [-0.002, 0.015],
                      [0, 0.02],
                      [0.001, 0.025],
                      [0.001, 0.028],
                      [0.002, 0.03],
                      [0.002, 0.032],
                      [0.002, 0.032],
                      [0.002, 0.031],
                      [0.002, 0.029],
                      [0.001, 0.027],
                      [0, 0.023],
                      [-0.001, 0.018],
                      [-0.003, 0.012]
                    ],
                    v: [
                      [169.5, -324],
                      [168.891, -323.279],
                      [168.476, -322.732],
                      [168.232, -322.345],
                      [168.138, -322.101],
                      [168.172, -321.986],
                      [168.314, -321.983],
                      [168.541, -322.077],
                      [168.832, -322.251],
                      [169.166, -322.492],
                      [169.52, -322.783],
                      [169.875, -323.107],
                      [170.207, -323.451],
                      [170.496, -323.798],
                      [170.72, -324.132],
                      [170.858, -324.438],
                      [170.888, -324.7],
                      [170.789, -324.903],
                      [170.539, -325.031],
                      [170.116, -325.069],
                      [169.5, -325],
                      [169.489, -324.986],
                      [169.482, -324.951],
                      [169.479, -324.898],
                      [169.48, -324.831],
                      [169.483, -324.751],
                      [169.488, -324.663],
                      [169.494, -324.57],
                      [169.501, -324.474],
                      [169.507, -324.379],
                      [169.512, -324.288],
                      [169.516, -324.204],
                      [169.517, -324.13],
                      [169.515, -324.069],
                      [169.51, -324.025]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 34,
                s: [
                  {
                    i: [
                      [0.185, 0.023],
                      [-0.032, -0.118],
                      [-0.033, -0.117],
                      [-0.035, -0.115],
                      [-0.037, -0.113],
                      [-0.039, -0.111],
                      [-0.042, -0.108],
                      [-0.046, -0.104],
                      [-0.05, -0.1],
                      [-0.054, -0.096],
                      [-0.059, -0.091],
                      [-0.064, -0.086],
                      [-0.07, -0.08],
                      [-0.076, -0.074],
                      [-0.083, -0.067],
                      [-0.09, -0.06],
                      [-0.098, -0.052],
                      [-0.106, -0.044],
                      [-0.114, -0.036],
                      [-0.123, -0.027],
                      [-0.133, -0.017],
                      [-0.083, 0.138],
                      [-0.043, 0.142],
                      [-0.007, 0.143],
                      [0.027, 0.142],
                      [0.057, 0.14],
                      [0.084, 0.136],
                      [0.108, 0.131],
                      [0.129, 0.123],
                      [0.146, 0.114],
                      [0.16, 0.104],
                      [0.172, 0.091],
                      [0.18, 0.077],
                      [0.185, 0.061],
                      [0.186, 0.043]
                    ],
                    o: [
                      [0.031, 0.119],
                      [0.032, 0.118],
                      [0.033, 0.117],
                      [0.035, 0.115],
                      [0.037, 0.113],
                      [0.039, 0.111],
                      [0.042, 0.108],
                      [0.046, 0.104],
                      [0.05, 0.1],
                      [0.054, 0.096],
                      [0.059, 0.091],
                      [0.064, 0.086],
                      [0.07, 0.08],
                      [0.076, 0.074],
                      [0.083, 0.067],
                      [0.09, 0.06],
                      [0.098, 0.052],
                      [0.106, 0.044],
                      [0.114, 0.036],
                      [0.123, 0.027],
                      [0.126, -0.134],
                      [0.083, -0.138],
                      [0.043, -0.142],
                      [0.007, -0.143],
                      [-0.027, -0.142],
                      [-0.057, -0.14],
                      [-0.084, -0.136],
                      [-0.108, -0.131],
                      [-0.129, -0.123],
                      [-0.146, -0.114],
                      [-0.16, -0.104],
                      [-0.172, -0.091],
                      [-0.18, -0.077],
                      [-0.185, -0.061],
                      [-0.186, -0.043]
                    ],
                    v: [
                      [-310.5, -204],
                      [-310.405, -203.645],
                      [-310.308, -203.292],
                      [-310.206, -202.944],
                      [-310.099, -202.601],
                      [-309.985, -202.265],
                      [-309.862, -201.938],
                      [-309.73, -201.62],
                      [-309.587, -201.313],
                      [-309.431, -201.019],
                      [-309.261, -200.739],
                      [-309.076, -200.474],
                      [-308.875, -200.225],
                      [-308.656, -199.994],
                      [-308.417, -199.783],
                      [-308.157, -199.593],
                      [-307.876, -199.424],
                      [-307.57, -199.28],
                      [-307.24, -199.16],
                      [-306.884, -199.066],
                      [-306.5, -199],
                      [-306.188, -199.409],
                      [-306, -199.829],
                      [-305.926, -200.256],
                      [-305.957, -200.685],
                      [-306.083, -201.109],
                      [-306.295, -201.525],
                      [-306.583, -201.926],
                      [-306.939, -202.308],
                      [-307.351, -202.665],
                      [-307.812, -202.993],
                      [-308.311, -203.285],
                      [-308.838, -203.537],
                      [-309.386, -203.744],
                      [-309.943, -203.9]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 35,
                s: [
                  {
                    i: [
                      [0.042, -0.095],
                      [-0.065, -0.094],
                      [-0.06, -0.077],
                      [-0.055, -0.062],
                      [-0.049, -0.047],
                      [-0.044, -0.033],
                      [-0.039, -0.021],
                      [-0.034, -0.009],
                      [-0.03, 0.001],
                      [-0.025, 0.011],
                      [-0.021, 0.02],
                      [-0.017, 0.027],
                      [-0.013, 0.034],
                      [-0.009, 0.039],
                      [-0.005, 0.044],
                      [-0.001, 0.047],
                      [0.002, 0.05],
                      [0.006, 0.051],
                      [0.009, 0.052],
                      [0.012, 0.051],
                      [0.015, 0.05],
                      [0.018, 0.047],
                      [0.02, 0.043],
                      [0.023, 0.039],
                      [0.025, 0.033],
                      [0.027, 0.027],
                      [0.03, 0.019],
                      [0.031, 0.011],
                      [0.033, 0.001],
                      [0.035, -0.01],
                      [0.036, -0.021],
                      [0.038, -0.034],
                      [0.039, -0.048],
                      [0.04, -0.062],
                      [0.041, -0.078]
                    ],
                    o: [
                      [0.071, 0.111],
                      [0.065, 0.094],
                      [0.06, 0.077],
                      [0.055, 0.062],
                      [0.049, 0.047],
                      [0.044, 0.033],
                      [0.039, 0.021],
                      [0.034, 0.009],
                      [0.03, -0.001],
                      [0.025, -0.011],
                      [0.021, -0.02],
                      [0.017, -0.027],
                      [0.013, -0.034],
                      [0.009, -0.039],
                      [0.005, -0.044],
                      [0.001, -0.047],
                      [-0.002, -0.05],
                      [-0.006, -0.051],
                      [-0.009, -0.052],
                      [-0.012, -0.051],
                      [-0.015, -0.05],
                      [-0.018, -0.047],
                      [-0.02, -0.043],
                      [-0.023, -0.039],
                      [-0.025, -0.033],
                      [-0.027, -0.027],
                      [-0.03, -0.019],
                      [-0.031, -0.011],
                      [-0.033, -0.001],
                      [-0.035, 0.01],
                      [-0.036, 0.021],
                      [-0.038, 0.034],
                      [-0.039, 0.048],
                      [-0.04, 0.062],
                      [-0.041, 0.078]
                    ],
                    v: [
                      [-312.5, -204],
                      [-312.295, -203.692],
                      [-312.107, -203.436],
                      [-311.936, -203.228],
                      [-311.78, -203.066],
                      [-311.64, -202.946],
                      [-311.515, -202.865],
                      [-311.405, -202.821],
                      [-311.308, -202.809],
                      [-311.226, -202.828],
                      [-311.157, -202.874],
                      [-311.101, -202.944],
                      [-311.057, -203.036],
                      [-311.025, -203.145],
                      [-311.005, -203.27],
                      [-310.996, -203.406],
                      [-310.998, -203.552],
                      [-311.01, -203.703],
                      [-311.032, -203.858],
                      [-311.064, -204.012],
                      [-311.105, -204.163],
                      [-311.154, -204.308],
                      [-311.211, -204.444],
                      [-311.276, -204.568],
                      [-311.348, -204.677],
                      [-311.427, -204.767],
                      [-311.513, -204.836],
                      [-311.604, -204.881],
                      [-311.701, -204.898],
                      [-311.803, -204.885],
                      [-311.91, -204.839],
                      [-312.021, -204.756],
                      [-312.136, -204.634],
                      [-312.255, -204.469],
                      [-312.376, -204.259]
                    ],
                    c: true
                  }
                ],
                h: 1
              }
            ],
            ix: 1
          },
          o: {
            a: 1,
            k: [
              { t: 22, s: [0], h: 1 },
              { t: 23, s: [100], h: 1 },
              { t: 36, s: [0], h: 1 }
            ],
            ix: 3
          },
          x: { a: 0, k: 0, ix: 4 },
          nm: 'Mask 3'
        },
        {
          inv: false,
          mode: 'f',
          pt: {
            a: 1,
            k: [
              {
                t: 23,
                s: [
                  {
                    i: [
                      [-10.164, 13.716],
                      [18.068, 21.937],
                      [-13.515, 21.536],
                      [-44.784, -15.725],
                      [12.622, 42.712],
                      [-72.016, -50.985],
                      [-6.042, -2.625],
                      [-3.013, 3.113],
                      [3.197, 26.408],
                      [-3.63, 17.531],
                      [-10.698, 3.968],
                      [-17.134, -34.893],
                      [-37.467, 16.532],
                      [-25.941, -2.19],
                      [-11.44, -11.56],
                      [-2.526, -35.26],
                      [4.1, -15.53],
                      [-59.812, 27.83],
                      [-5.433, -53.046],
                      [26.187, -23.281],
                      [3.385, -3.615],
                      [-3.794, -2.139],
                      [-22.98, -3.877],
                      [-12.64, -15.026],
                      [25.621, -11.075],
                      [13.774, -0.505],
                      [-27.123, -0.543],
                      [35.319, 30.086],
                      [13.694, 4.639],
                      [-11.667, 11.667],
                      [-7.803, 15.262],
                      [24.709, -3.64],
                      [97.866, -34.466],
                      [-18.189, 85.146],
                      [31.925, -55.386],
                      [34.719, -54.291],
                      [12.247, 23.089],
                      [45.01, 10.559],
                      [17.764, -27.779],
                      [8.552, -29.191],
                      [14.43, 1.992],
                      [-5.607, -50.705],
                      [64.854, 45.478],
                      [11.619, 6.715],
                      [15.472, 3.195],
                      [-7.425, -29.789],
                      [-8.713, -29.472],
                      [16.376, -16.158],
                      [0.072, -53.572],
                      [-67.619, 5.209]
                    ],
                    o: [
                      [-37.894, 16.385],
                      [-18.825, -17.2],
                      [17.686, -29.941],
                      [-6.996, -48.337],
                      [72.455, 50.546],
                      [6.494, 2.173],
                      [4.468, 2.037],
                      [-7.664, -20.82],
                      [-2.033, -16.79],
                      [6.222, -8.444],
                      [50.026, -1.323],
                      [-9.194, -44.683],
                      [18.432, -8.133],
                      [17.481, 5.518],
                      [14.932, 21.477],
                      [1.166, 16.279],
                      [42.071, -47.045],
                      [60.706, -16.429],
                      [4.997, 48.789],
                      [-2.651, 4.349],
                      [-2.457, 2.463],
                      [25.167, -3.242],
                      [22.352, 3.771],
                      [21.038, 25.009],
                      [-10.9, 4.712],
                      [19.901, 7.765],
                      [53.933, -8.841],
                      [-10.395, -7.938],
                      [10.937, -12.396],
                      [11.643, -11.643],
                      [-24.204, 0.817],
                      [19.962, -104.383],
                      [20.219, -86.45],
                      [-31.211, 54.791],
                      [-32.047, 55.598],
                      [-7.632, -24.329],
                      [-20.223, -38.124],
                      [-47.907, -11.238],
                      [-17.889, 27.975],
                      [-3.111, -15.822],
                      [-48.936, -6.757],
                      [-62.09, -48.241],
                      [-11.619, -6.715],
                      [-11.877, -6.79],
                      [-1.545, 32.803],
                      [7.416, 29.753],
                      [-26.218, 2.804],
                      [-28.676, 28.295],
                      [9.378, 55.807],
                      [23.372, -1.801]
                    ],
                    v: [
                      [-305.5, 474],
                      [-403.5, 450],
                      [-406.5, 365],
                      [-293.5, 333],
                      [-332.5, 206],
                      [-117.5, 360],
                      [-100.5, 369],
                      [-84.5, 376],
                      [-109.5, 307],
                      [-103.5, 256],
                      [-82.5, 233],
                      [8.5, 300],
                      [60.5, 187],
                      [127.5, 181],
                      [168.5, 209],
                      [208.5, 286],
                      [203.5, 336],
                      [339.5, 197],
                      [454.5, 264],
                      [405.5, 366],
                      [394.5, 376],
                      [385.5, 385],
                      [458.5, 390],
                      [515.5, 412],
                      [493.5, 489],
                      [453.5, 492],
                      [528.5, 500],
                      [548.5, 373],
                      [513.5, 353],
                      [548.5, 318],
                      [581.5, 280],
                      [511.5, 297],
                      [353.5, 127],
                      [414.5, -134],
                      [317.5, 30],
                      [226.5, 200],
                      [196.5, 126],
                      [111.5, 51],
                      [14.5, 111],
                      [-26.5, 199],
                      [-56.5, 172],
                      [-149.5, 250],
                      [-341.5, 111],
                      [-374.5, 89],
                      [-409.5, 68],
                      [-395.5, 162],
                      [-374.5, 252],
                      [-440.5, 284],
                      [-491.5, 413],
                      [-361.5, 499]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 24,
                s: [
                  {
                    i: [
                      [2.883, 1.411],
                      [1.703, -1.505],
                      [1.531, -1.678],
                      [1.384, -1.824],
                      [1.263, -1.945],
                      [1.169, -2.039],
                      [1.101, -2.107],
                      [1.059, -2.149],
                      [1.044, -2.165],
                      [-0.219, -0.281],
                      [-0.252, -0.248],
                      [-0.266, -0.234],
                      [-0.26, -0.24],
                      [-0.235, -0.265],
                      [-0.19, -0.31],
                      [-0.127, -0.373],
                      [-0.044, -0.456],
                      [-0.499, -1.626],
                      [-0.556, -1.569],
                      [-0.598, -1.527],
                      [-0.624, -1.501],
                      [-0.635, -1.49],
                      [-0.631, -1.494],
                      [-0.611, -1.514],
                      [-0.576, -1.549],
                      [-0.746, -1.421],
                      [-0.675, -1.492],
                      [-0.65, -1.517],
                      [-0.671, -1.496],
                      [-0.736, -1.43],
                      [-0.848, -1.319],
                      [-1.004, -1.162],
                      [-1.207, -0.96],
                      [-0.323, 0.672],
                      [-0.125, 0.776],
                      [0.026, 0.845],
                      [0.128, 0.881],
                      [0.182, 0.884],
                      [0.189, 0.853],
                      [0.147, 0.788],
                      [0.057, 0.69],
                      [-0.01, 3.562],
                      [-0.043, 3.613],
                      [-0.046, 3.576],
                      [-0.018, 3.451],
                      [0.04, 3.239],
                      [0.129, 2.939],
                      [-0.307, 4.121],
                      [-0.013, 3.814],
                      [1.05, 2.911]
                    ],
                    o: [
                      [-1.902, 1.306],
                      [-1.703, 1.505],
                      [-1.531, 1.678],
                      [-1.384, 1.824],
                      [-1.263, 1.945],
                      [-1.169, 2.039],
                      [-1.101, 2.107],
                      [-1.059, 2.149],
                      [0.167, 0.333],
                      [0.219, 0.281],
                      [0.252, 0.248],
                      [0.266, 0.234],
                      [0.26, 0.24],
                      [0.235, 0.265],
                      [0.19, 0.31],
                      [0.127, 0.373],
                      [0.427, 1.698],
                      [0.499, 1.626],
                      [0.556, 1.569],
                      [0.598, 1.527],
                      [0.624, 1.501],
                      [0.635, 1.49],
                      [0.631, 1.494],
                      [0.611, 1.514],
                      [0.861, 1.305],
                      [0.746, 1.421],
                      [0.675, 1.492],
                      [0.65, 1.517],
                      [0.671, 1.496],
                      [0.736, 1.43],
                      [0.848, 1.319],
                      [1.004, 1.162],
                      [0.569, -0.535],
                      [0.323, -0.672],
                      [0.125, -0.776],
                      [-0.026, -0.845],
                      [-0.128, -0.881],
                      [-0.182, -0.884],
                      [-0.189, -0.853],
                      [-0.147, -0.788],
                      [-0.054, -3.424],
                      [0.01, -3.562],
                      [0.043, -3.613],
                      [0.046, -3.576],
                      [0.018, -3.451],
                      [-0.04, -3.239],
                      [-0.168, -3.833],
                      [0.307, -4.121],
                      [0.013, -3.814],
                      [-1.05, -2.911]
                    ],
                    v: [
                      [-15.5, 102],
                      [-20.902, 106.223],
                      [-25.746, 111.004],
                      [-30.111, 116.264],
                      [-34.076, 121.924],
                      [-37.718, 127.907],
                      [-41.118, 134.132],
                      [-44.352, 140.523],
                      [-47.5, 147],
                      [-46.916, 147.916],
                      [-46.205, 148.705],
                      [-45.424, 149.424],
                      [-44.631, 150.131],
                      [-43.884, 150.884],
                      [-43.242, 151.742],
                      [-42.761, 152.761],
                      [-42.5, 154],
                      [-41.107, 158.982],
                      [-39.52, 163.77],
                      [-37.785, 168.41],
                      [-35.947, 172.947],
                      [-34.054, 177.429],
                      [-32.151, 181.901],
                      [-30.284, 186.409],
                      [-28.5, 191],
                      [-26.101, 195.101],
                      [-23.981, 199.482],
                      [-22.005, 204.005],
                      [-20.035, 208.536],
                      [-17.936, 212.937],
                      [-15.572, 217.072],
                      [-12.805, 220.805],
                      [-9.5, 224],
                      [-8.173, 222.18],
                      [-7.514, 220],
                      [-7.377, 217.561],
                      [-7.62, 214.962],
                      [-8.097, 212.306],
                      [-8.666, 209.694],
                      [-9.182, 207.224],
                      [-9.5, 205],
                      [-9.559, 194.498],
                      [-9.473, 183.713],
                      [-9.332, 172.908],
                      [-9.228, 162.346],
                      [-9.254, 152.289],
                      [-9.5, 143],
                      [-9.1, 130.919],
                      [-8.429, 118.867],
                      [-9.793, 108.632]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 25,
                s: [
                  {
                    i: [
                      [-0.492, 2.313],
                      [0.076, 0.013],
                      [0.068, 0.021],
                      [0.061, 0.028],
                      [0.056, 0.033],
                      [0.053, 0.036],
                      [0.051, 0.038],
                      [0.051, 0.038],
                      [0.053, 0.036],
                      [0.056, 0.033],
                      [0.06, 0.028],
                      [0.067, 0.022],
                      [0.075, 0.014],
                      [0.084, 0.005],
                      [0.095, -0.006],
                      [0.108, -0.019],
                      [0.695, -0.954],
                      [0.842, -1.035],
                      [0.942, -1.098],
                      [0.997, -1.144],
                      [1.005, -1.172],
                      [0.966, -1.182],
                      [0.882, -1.175],
                      [0.75, -1.151],
                      [0.573, -1.109],
                      [0.349, -1.05],
                      [0.079, -0.973],
                      [-0.133, -0.939],
                      [-0.301, -0.945],
                      [-0.431, -0.944],
                      [-0.521, -0.936],
                      [-0.572, -0.921],
                      [-0.584, -0.898],
                      [-0.557, -0.868],
                      [-0.491, -0.83],
                      [-0.483, -0.927],
                      [-0.483, -0.952],
                      [-0.508, -0.933],
                      [-0.559, -0.869],
                      [-0.634, -0.76],
                      [-0.734, -0.607],
                      [-0.859, -0.409],
                      [-1.009, -0.167],
                      [0.33, 3.256],
                      [0.12, 3.367],
                      [-0.059, 3.396],
                      [-0.207, 3.344],
                      [-0.325, 3.209],
                      [-0.411, 2.992],
                      [-0.467, 2.694]
                    ],
                    o: [
                      [-0.085, -0.004],
                      [-0.076, -0.013],
                      [-0.068, -0.021],
                      [-0.061, -0.028],
                      [-0.056, -0.033],
                      [-0.053, -0.036],
                      [-0.051, -0.038],
                      [-0.051, -0.038],
                      [-0.053, -0.036],
                      [-0.056, -0.033],
                      [-0.06, -0.028],
                      [-0.067, -0.022],
                      [-0.075, -0.014],
                      [-0.084, -0.005],
                      [-0.095, 0.006],
                      [-0.501, 0.856],
                      [-0.695, 0.954],
                      [-0.842, 1.035],
                      [-0.942, 1.098],
                      [-0.997, 1.144],
                      [-1.005, 1.172],
                      [-0.966, 1.182],
                      [-0.882, 1.175],
                      [-0.75, 1.151],
                      [-0.573, 1.109],
                      [-0.349, 1.05],
                      [-0.075, 0.925],
                      [0.133, 0.939],
                      [0.301, 0.945],
                      [0.431, 0.944],
                      [0.521, 0.936],
                      [0.572, 0.921],
                      [0.584, 0.898],
                      [0.557, 0.868],
                      [0.507, 0.857],
                      [0.483, 0.927],
                      [0.483, 0.952],
                      [0.508, 0.933],
                      [0.559, 0.869],
                      [0.634, 0.76],
                      [0.734, 0.607],
                      [0.859, 0.409],
                      [-0.571, -3.063],
                      [-0.33, -3.256],
                      [-0.12, -3.367],
                      [0.059, -3.396],
                      [0.207, -3.344],
                      [0.325, -3.209],
                      [0.411, -2.992],
                      [0.467, -2.694]
                    ],
                    v: [
                      [-24.5, 67],
                      [-24.741, 66.974],
                      [-24.955, 66.922],
                      [-25.148, 66.848],
                      [-25.324, 66.757],
                      [-25.487, 66.654],
                      [-25.643, 66.543],
                      [-25.797, 66.43],
                      [-25.952, 66.319],
                      [-26.115, 66.214],
                      [-26.289, 66.122],
                      [-26.479, 66.045],
                      [-26.691, 65.99],
                      [-26.928, 65.961],
                      [-27.196, 65.963],
                      [-27.5, 66],
                      [-29.306, 68.719],
                      [-31.622, 71.707],
                      [-34.31, 74.91],
                      [-37.23, 78.277],
                      [-40.244, 81.754],
                      [-43.212, 85.289],
                      [-45.995, 88.831],
                      [-48.455, 92.325],
                      [-50.452, 95.719],
                      [-51.846, 98.962],
                      [-52.5, 102],
                      [-52.404, 104.798],
                      [-51.743, 107.626],
                      [-50.635, 110.463],
                      [-49.198, 113.285],
                      [-47.548, 116.072],
                      [-45.804, 118.802],
                      [-44.082, 121.452],
                      [-42.5, 124],
                      [-41.022, 126.687],
                      [-39.58, 129.518],
                      [-38.099, 132.357],
                      [-36.505, 135.072],
                      [-34.723, 137.527],
                      [-32.678, 139.589],
                      [-30.295, 141.125],
                      [-27.5, 142],
                      [-28.844, 132.502],
                      [-29.512, 122.547],
                      [-29.597, 112.382],
                      [-29.19, 102.252],
                      [-28.384, 92.402],
                      [-27.272, 83.08],
                      [-25.947, 74.531]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 26,
                s: [
                  {
                    i: [
                      [0.808, -0.141],
                      [0.118, -0.215],
                      [0.139, -0.194],
                      [0.076, -0.257],
                      [-0.071, -0.404],
                      [-0.986, -3.514],
                      [-1.069, -3.431],
                      [-1.048, -3.452],
                      [-0.924, -3.576],
                      [-2.438, -5.331],
                      [-1.988, -5.859],
                      [-1.556, -6.099],
                      [-1.142, -6.049],
                      [-0.19, -2.192],
                      [-0.352, -2.158],
                      [-0.861, -1.887],
                      [-1.717, -1.379],
                      [-0.82, 1.982],
                      [-0.484, 2.081],
                      [-1.047, 1.558],
                      [-2.508, 0.414],
                      [-1.929, -0.905],
                      [-2.053, -1.579],
                      [-1.973, -1.76],
                      [-1.687, -1.446],
                      [-1.882, -1.815],
                      [-1.715, -1.632],
                      [-1.601, -1.251],
                      [-1.539, -0.673],
                      [-1.566, -1.55],
                      [-1.58, -1.602],
                      [-1.599, -1.626],
                      [-1.622, -1.622],
                      [-1.607, -1.755],
                      [-1.789, -1.557],
                      [-2.037, -0.899],
                      [-2.353, 0.222],
                      [2.503, 3.861],
                      [3.152, 3.637],
                      [3.198, 3.582],
                      [2.641, 3.697],
                      [3.036, 3.714],
                      [3.049, 3.701],
                      [3.114, 3.636],
                      [3.23, 3.52],
                      [5.803, 7.614],
                      [5.713, 7.703],
                      [5.429, 7.988],
                      [4.948, 8.468],
                      [0.388, 0.278]
                    ],
                    o: [
                      [-0.014, 0.32],
                      [-0.118, 0.215],
                      [-0.139, 0.194],
                      [-0.076, 0.257],
                      [0.799, 3.701],
                      [0.986, 3.514],
                      [1.069, 3.431],
                      [1.048, 3.452],
                      [2.905, 4.513],
                      [2.438, 5.331],
                      [1.988, 5.859],
                      [1.556, 6.099],
                      [0.375, 1.989],
                      [0.19, 2.192],
                      [0.352, 2.158],
                      [0.861, 1.887],
                      [2.055, -1.262],
                      [0.82, -1.982],
                      [0.484, -2.081],
                      [1.047, -1.558],
                      [1.6, -0.264],
                      [1.929, 0.905],
                      [2.053, 1.58],
                      [1.973, 1.76],
                      [2.101, 1.801],
                      [1.882, 1.815],
                      [1.715, 1.632],
                      [1.601, 1.251],
                      [1.557, 1.47],
                      [1.566, 1.55],
                      [1.58, 1.602],
                      [1.599, 1.626],
                      [1.492, 1.492],
                      [1.607, 1.755],
                      [1.789, 1.557],
                      [2.037, 0.899],
                      [-1.252, -4.255],
                      [-2.503, -3.861],
                      [-3.152, -3.637],
                      [-3.198, -3.582],
                      [-3.074, -3.676],
                      [-3.036, -3.714],
                      [-3.049, -3.701],
                      [-3.114, -3.636],
                      [-5.698, -7.719],
                      [-5.803, -7.614],
                      [-5.713, -7.703],
                      [-5.429, -7.988],
                      [-0.639, -0.028],
                      [-0.388, -0.278]
                    ],
                    v: [
                      [-272.5, -142],
                      [-272.719, -141.219],
                      [-273.126, -140.626],
                      [-273.47, -139.97],
                      [-273.5, -139],
                      [-270.796, -128.204],
                      [-267.687, -117.813],
                      [-264.485, -107.515],
                      [-261.5, -97],
                      [-253.49, -82.161],
                      [-246.855, -65.303],
                      [-241.542, -47.294],
                      [-237.5, -29],
                      [-236.739, -22.67],
                      [-236.012, -16.086],
                      [-234.28, -9.959],
                      [-230.5, -5],
                      [-226.412, -10.022],
                      [-224.679, -16.272],
                      [-222.608, -21.886],
                      [-217.5, -25],
                      [-212.156, -23.916],
                      [-206.132, -20.066],
                      [-200.041, -14.933],
                      [-194.5, -10],
                      [-188.539, -4.527],
                      [-183.157, 0.692],
                      [-178.197, 5.065],
                      [-173.5, 8],
                      [-168.816, 12.537],
                      [-164.097, 17.272],
                      [-159.33, 22.121],
                      [-154.5, 27],
                      [-149.868, 31.986],
                      [-144.791, 37.07],
                      [-139.068, 40.869],
                      [-132.5, 42],
                      [-138.283, 29.869],
                      [-146.917, 18.664],
                      [-156.591, 7.877],
                      [-165.5, -3],
                      [-174.652, -14.098],
                      [-183.766, -25.234],
                      [-192.997, -36.253],
                      [-202.5, -47],
                      [-219.8, -69.95],
                      [-237.124, -92.876],
                      [-253.886, -116.365],
                      [-269.5, -141],
                      [-270.874, -141.626]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 27,
                s: [
                  {
                    i: [
                      [2.552, 3.181],
                      [-0.636, -1.864],
                      [-0.645, -1.855],
                      [-0.655, -1.845],
                      [-0.666, -1.834],
                      [-0.678, -1.822],
                      [-0.692, -1.808],
                      [-0.707, -1.793],
                      [-0.724, -1.776],
                      [-0.741, -1.759],
                      [-0.761, -1.739],
                      [-0.781, -1.719],
                      [-0.803, -1.697],
                      [-0.827, -1.673],
                      [-0.851, -1.649],
                      [-0.877, -1.623],
                      [-0.905, -1.595],
                      [-0.933, -1.567],
                      [-0.964, -1.536],
                      [-0.995, -1.505],
                      [-1.028, -1.472],
                      [-1.789, 0.668],
                      [-1.667, 0.065],
                      [-1.562, -0.452],
                      [-1.475, -0.885],
                      [-1.405, -1.233],
                      [-1.352, -1.495],
                      [-1.316, -1.673],
                      [-1.298, -1.766],
                      [-1.297, -1.773],
                      [-1.313, -1.696],
                      [-1.346, -1.534],
                      [-1.397, -1.287],
                      [-1.465, -0.955],
                      [-1.55, -0.538],
                      [-1.653, -0.036],
                      [2.338, 3.395],
                      [2.385, 3.348],
                      [2.428, 3.306],
                      [2.465, 3.268],
                      [2.497, 3.236],
                      [2.525, 3.208],
                      [2.548, 3.186],
                      [2.565, 3.168],
                      [2.578, 3.155],
                      [2.586, 3.147],
                      [2.589, 3.144],
                      [2.587, 3.146],
                      [2.58, 3.153],
                      [2.569, 3.165]
                    ],
                    o: [
                      [0.629, 1.871],
                      [0.636, 1.864],
                      [0.645, 1.855],
                      [0.655, 1.845],
                      [0.666, 1.834],
                      [0.678, 1.822],
                      [0.692, 1.808],
                      [0.707, 1.793],
                      [0.724, 1.776],
                      [0.741, 1.759],
                      [0.761, 1.739],
                      [0.781, 1.719],
                      [0.803, 1.697],
                      [0.827, 1.673],
                      [0.851, 1.649],
                      [0.877, 1.623],
                      [0.905, 1.595],
                      [0.933, 1.567],
                      [0.964, 1.536],
                      [0.995, 1.505],
                      [1.928, -1.355],
                      [1.789, -0.668],
                      [1.667, -0.065],
                      [1.562, 0.452],
                      [1.475, 0.885],
                      [1.405, 1.233],
                      [1.352, 1.495],
                      [1.316, 1.673],
                      [1.298, 1.766],
                      [1.297, 1.773],
                      [1.313, 1.696],
                      [1.346, 1.534],
                      [1.397, 1.287],
                      [1.465, 0.955],
                      [1.55, 0.538],
                      [-2.286, -3.447],
                      [-2.338, -3.395],
                      [-2.385, -3.348],
                      [-2.428, -3.306],
                      [-2.465, -3.268],
                      [-2.497, -3.236],
                      [-2.525, -3.208],
                      [-2.548, -3.186],
                      [-2.565, -3.168],
                      [-2.578, -3.155],
                      [-2.586, -3.147],
                      [-2.589, -3.144],
                      [-2.587, -3.146],
                      [-2.58, -3.153],
                      [-2.569, -3.165]
                    ],
                    v: [
                      [-288.5, -164],
                      [-286.602, -158.398],
                      [-284.68, -152.82],
                      [-282.731, -147.269],
                      [-280.752, -141.748],
                      [-278.737, -136.263],
                      [-276.682, -130.818],
                      [-274.584, -125.416],
                      [-272.439, -120.061],
                      [-270.242, -114.758],
                      [-267.989, -109.511],
                      [-265.677, -104.323],
                      [-263.3, -99.2],
                      [-260.856, -94.144],
                      [-258.34, -89.16],
                      [-255.747, -84.253],
                      [-253.074, -79.426],
                      [-250.318, -74.682],
                      [-247.472, -70.028],
                      [-244.534, -65.466],
                      [-241.5, -61],
                      [-235.929, -64.014],
                      [-230.75, -65.093],
                      [-225.911, -64.491],
                      [-221.36, -62.464],
                      [-217.045, -59.267],
                      [-212.915, -55.154],
                      [-208.917, -50.38],
                      [-205.001, -45.201],
                      [-201.113, -39.871],
                      [-197.203, -34.645],
                      [-193.218, -29.779],
                      [-189.108, -25.526],
                      [-184.819, -22.142],
                      [-180.3, -19.882],
                      [-175.5, -19],
                      [-182.437, -29.263],
                      [-189.524, -39.376],
                      [-196.744, -49.356],
                      [-204.085, -59.215],
                      [-211.529, -68.971],
                      [-219.064, -78.636],
                      [-226.675, -88.225],
                      [-234.345, -97.755],
                      [-242.062, -107.238],
                      [-249.81, -116.69],
                      [-257.574, -126.126],
                      [-265.34, -135.56],
                      [-273.093, -145.007],
                      [-280.818, -154.482]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 28,
                s: [
                  {
                    i: [
                      [0.25, 0],
                      [0.06, -0.149],
                      [0.071, -0.138],
                      [0.073, -0.135],
                      [0.066, -0.142],
                      [0.05, -0.159],
                      [0.025, -0.184],
                      [-0.01, -0.218],
                      [-0.053, -0.261],
                      [-0.335, -0.665],
                      [-0.391, -0.609],
                      [-0.425, -0.575],
                      [-0.435, -0.565],
                      [-0.423, -0.577],
                      [-0.389, -0.611],
                      [-0.332, -0.668],
                      [-0.251, -0.748],
                      [-0.938, -2.895],
                      [-1.15, -2.683],
                      [-1.297, -2.536],
                      [-1.38, -2.453],
                      [-1.399, -2.435],
                      [-1.352, -2.481],
                      [-1.242, -2.592],
                      [-1.067, -2.767],
                      [-1.697, 0.162],
                      [-1.534, -0.38],
                      [-1.422, -0.737],
                      [-1.362, -0.909],
                      [-1.354, -0.897],
                      [-1.397, -0.7],
                      [-1.492, -0.318],
                      [-1.639, 0.249],
                      [0.786, 1.405],
                      [0.934, 1.305],
                      [1.027, 1.235],
                      [1.065, 1.193],
                      [1.049, 1.179],
                      [0.977, 1.195],
                      [1.462, 1.935],
                      [1.454, 1.945],
                      [1.515, 1.912],
                      [1.644, 1.836],
                      [2.938, 3.812],
                      [2.903, 3.847],
                      [2.888, 3.862],
                      [2.894, 3.856],
                      [0.25, 0],
                      [0.25, 0],
                      [0.25, 0]
                    ],
                    o: [
                      [-0.039, 0.169],
                      [-0.06, 0.149],
                      [-0.071, 0.138],
                      [-0.073, 0.135],
                      [-0.066, 0.142],
                      [-0.05, 0.159],
                      [-0.025, 0.184],
                      [0.01, 0.218],
                      [0.257, 0.743],
                      [0.335, 0.665],
                      [0.391, 0.609],
                      [0.425, 0.575],
                      [0.435, 0.565],
                      [0.423, 0.577],
                      [0.389, 0.611],
                      [0.332, 0.668],
                      [0.662, 3.171],
                      [0.938, 2.895],
                      [1.15, 2.683],
                      [1.297, 2.536],
                      [1.38, 2.453],
                      [1.399, 2.435],
                      [1.352, 2.481],
                      [1.242, 2.592],
                      [1.913, -0.888],
                      [1.697, -0.162],
                      [1.534, 0.38],
                      [1.422, 0.737],
                      [1.362, 0.909],
                      [1.354, 0.897],
                      [1.397, 0.7],
                      [1.492, 0.318],
                      [-0.583, -1.533],
                      [-0.786, -1.405],
                      [-0.934, -1.305],
                      [-1.027, -1.235],
                      [-1.065, -1.193],
                      [-1.049, -1.179],
                      [-1.539, -1.882],
                      [-1.462, -1.935],
                      [-1.454, -1.945],
                      [-1.515, -1.912],
                      [-2.994, -3.756],
                      [-2.938, -3.812],
                      [-2.903, -3.847],
                      [-2.888, -3.862],
                      [-0.25, 0],
                      [-0.25, 0],
                      [-0.25, 0],
                      [-0.25, 0]
                    ],
                    v: [
                      [-297.5, -179],
                      [-297.651, -178.526],
                      [-297.849, -178.099],
                      [-298.067, -177.691],
                      [-298.277, -177.277],
                      [-298.453, -176.828],
                      [-298.567, -176.317],
                      [-298.592, -175.716],
                      [-298.5, -175],
                      [-297.606, -172.894],
                      [-296.511, -170.989],
                      [-295.281, -169.219],
                      [-293.985, -167.515],
                      [-292.691, -165.809],
                      [-291.466, -164.034],
                      [-290.38, -162.12],
                      [-289.5, -160],
                      [-287.083, -150.917],
                      [-283.934, -142.567],
                      [-280.246, -134.754],
                      [-276.213, -127.287],
                      [-272.029, -119.971],
                      [-267.887, -112.614],
                      [-263.979, -105.021],
                      [-260.5, -97],
                      [-255.098, -98.528],
                      [-250.263, -98.154],
                      [-245.842, -96.432],
                      [-241.679, -93.917],
                      [-237.618, -91.161],
                      [-233.505, -88.721],
                      [-229.184, -87.149],
                      [-224.5, -87],
                      [-226.566, -91.4],
                      [-229.158, -95.458],
                      [-232.112, -99.26],
                      [-235.264, -102.894],
                      [-238.448, -106.445],
                      [-241.5, -110],
                      [-245.985, -115.738],
                      [-250.343, -121.57],
                      [-254.779, -127.367],
                      [-259.5, -133],
                      [-268.393, -144.357],
                      [-277.149, -155.851],
                      [-285.831, -167.419],
                      [-294.5, -179],
                      [-295.25, -179],
                      [-296, -179],
                      [-296.75, -179]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 29,
                s: [
                  {
                    i: [
                      [0.167, 0],
                      [0, -0.125],
                      [0, -0.125],
                      [0, -0.125],
                      [0, -0.125],
                      [0, -0.125],
                      [0, -0.125],
                      [0, -0.125],
                      [0, -0.125],
                      [-0.917, -1.854],
                      [-0.953, -1.83],
                      [-0.977, -1.815],
                      [-0.989, -1.809],
                      [-0.99, -1.812],
                      [-0.979, -1.825],
                      [-0.957, -1.846],
                      [-0.923, -1.877],
                      [-0.812, -1.933],
                      [-0.733, -1.964],
                      [-0.692, -1.983],
                      [-0.69, -1.99],
                      [-0.725, -1.985],
                      [-0.799, -1.968],
                      [-0.911, -1.94],
                      [-1.061, -1.9],
                      [0.243, 1.716],
                      [0.333, 1.625],
                      [0.377, 1.581],
                      [0.374, 1.584],
                      [0.325, 1.633],
                      [0.229, 1.729],
                      [0.086, 1.872],
                      [-0.103, 2.061],
                      [-0.407, 0.334],
                      [-0.499, 0.189],
                      [-0.558, 0.084],
                      [-0.586, 0.019],
                      [-0.581, -0.006],
                      [-0.544, 0.008],
                      [-0.475, 0.062],
                      [-0.374, 0.155],
                      [2.669, 2.664],
                      [2.497, 2.836],
                      [2.417, 2.916],
                      [2.43, 2.904],
                      [2.534, 2.799],
                      [2.731, 2.602],
                      [0.167, 0],
                      [0.167, 0],
                      [0.167, 0]
                    ],
                    o: [
                      [0, 0.125],
                      [0, 0.125],
                      [0, 0.125],
                      [0, 0.125],
                      [0, 0.125],
                      [0, 0.125],
                      [0, 0.125],
                      [0, 0.125],
                      [0.87, 1.887],
                      [0.917, 1.854],
                      [0.953, 1.83],
                      [0.977, 1.815],
                      [0.989, 1.809],
                      [0.99, 1.812],
                      [0.979, 1.825],
                      [0.957, 1.846],
                      [0.929, 1.891],
                      [0.812, 1.933],
                      [0.733, 1.964],
                      [0.692, 1.983],
                      [0.69, 1.99],
                      [0.725, 1.985],
                      [0.799, 1.968],
                      [0.911, 1.94],
                      [-0.106, -1.853],
                      [-0.243, -1.716],
                      [-0.333, -1.625],
                      [-0.377, -1.581],
                      [-0.374, -1.584],
                      [-0.325, -1.633],
                      [-0.229, -1.729],
                      [-0.086, -1.872],
                      [0.283, -0.519],
                      [0.407, -0.334],
                      [0.499, -0.189],
                      [0.558, -0.084],
                      [0.586, -0.019],
                      [0.581, 0.006],
                      [0.544, -0.008],
                      [0.475, -0.062],
                      [-2.933, -2.4],
                      [-2.669, -2.664],
                      [-2.497, -2.836],
                      [-2.417, -2.916],
                      [-2.43, -2.904],
                      [-2.534, -2.799],
                      [-0.167, 0],
                      [-0.167, 0],
                      [-0.167, 0],
                      [-0.167, 0]
                    ],
                    v: [
                      [-551.5, -139],
                      [-551.5, -138.625],
                      [-551.5, -138.25],
                      [-551.5, -137.875],
                      [-551.5, -137.5],
                      [-551.5, -137.125],
                      [-551.5, -136.75],
                      [-551.5, -136.375],
                      [-551.5, -136],
                      [-548.817, -130.391],
                      [-546.01, -124.868],
                      [-543.112, -119.403],
                      [-540.16, -113.97],
                      [-537.187, -108.541],
                      [-534.23, -103.088],
                      [-531.322, -97.583],
                      [-528.5, -92],
                      [-525.897, -86.261],
                      [-523.588, -80.412],
                      [-521.459, -74.489],
                      [-519.395, -68.528],
                      [-517.282, -62.563],
                      [-515.004, -56.63],
                      [-512.449, -50.764],
                      [-509.5, -45],
                      [-510.034, -50.341],
                      [-510.909, -55.341],
                      [-511.986, -60.139],
                      [-513.125, -64.875],
                      [-514.186, -69.689],
                      [-515.028, -74.722],
                      [-515.513, -80.112],
                      [-515.5, -86],
                      [-514.457, -87.269],
                      [-513.09, -88.044],
                      [-511.496, -88.445],
                      [-509.772, -88.59],
                      [-508.013, -88.599],
                      [-506.317, -88.591],
                      [-504.781, -88.685],
                      [-503.5, -89],
                      [-511.88, -96.62],
                      [-519.605, -104.895],
                      [-526.954, -113.546],
                      [-534.202, -122.298],
                      [-541.625, -130.875],
                      [-549.5, -139],
                      [-550, -139],
                      [-550.5, -139],
                      [-551, -139]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 30,
                s: [
                  {
                    i: [
                      [0.895, 0.238],
                      [-0.286, -0.564],
                      [-0.284, -0.566],
                      [-0.283, -0.567],
                      [-0.283, -0.567],
                      [-0.284, -0.566],
                      [-0.286, -0.564],
                      [-0.289, -0.561],
                      [-0.292, -0.558],
                      [-0.297, -0.553],
                      [-0.302, -0.548],
                      [-0.308, -0.542],
                      [-0.315, -0.535],
                      [-0.323, -0.527],
                      [-0.332, -0.518],
                      [-0.342, -0.508],
                      [-0.352, -0.498],
                      [-0.364, -0.486],
                      [-0.376, -0.474],
                      [-0.389, -0.461],
                      [-0.404, -0.446],
                      [-0.205, 0.062],
                      [-0.194, 0.073],
                      [-0.183, 0.084],
                      [-0.172, 0.095],
                      [-0.161, 0.106],
                      [-0.15, 0.117],
                      [-0.139, 0.128],
                      [-0.128, 0.139],
                      [-0.117, 0.15],
                      [-0.106, 0.161],
                      [-0.095, 0.172],
                      [-0.084, 0.183],
                      [-0.073, 0.194],
                      [-0.062, 0.205],
                      [-0.051, 0.216],
                      [0.516, 0.617],
                      [0.484, 0.649],
                      [0.461, 0.672],
                      [0.447, 0.686],
                      [0.442, 0.691],
                      [0.447, 0.687],
                      [0.46, 0.673],
                      [0.482, 0.651],
                      [0.514, 0.619],
                      [0.555, 0.578],
                      [0.605, 0.529],
                      [0.664, 0.47],
                      [0.732, 0.401],
                      [0.809, 0.324]
                    ],
                    o: [
                      [0.288, 0.562],
                      [0.286, 0.564],
                      [0.284, 0.566],
                      [0.283, 0.567],
                      [0.283, 0.567],
                      [0.284, 0.566],
                      [0.286, 0.564],
                      [0.289, 0.561],
                      [0.292, 0.558],
                      [0.297, 0.553],
                      [0.302, 0.548],
                      [0.308, 0.542],
                      [0.315, 0.535],
                      [0.323, 0.527],
                      [0.332, 0.518],
                      [0.342, 0.508],
                      [0.352, 0.498],
                      [0.364, 0.486],
                      [0.376, 0.474],
                      [0.389, 0.461],
                      [0.216, -0.051],
                      [0.205, -0.062],
                      [0.194, -0.073],
                      [0.183, -0.084],
                      [0.172, -0.095],
                      [0.161, -0.106],
                      [0.15, -0.117],
                      [0.139, -0.128],
                      [0.128, -0.139],
                      [0.117, -0.15],
                      [0.106, -0.161],
                      [0.095, -0.172],
                      [0.084, -0.183],
                      [0.073, -0.194],
                      [0.062, -0.205],
                      [-0.558, -0.576],
                      [-0.516, -0.617],
                      [-0.484, -0.649],
                      [-0.461, -0.672],
                      [-0.447, -0.686],
                      [-0.442, -0.691],
                      [-0.447, -0.687],
                      [-0.46, -0.673],
                      [-0.482, -0.651],
                      [-0.514, -0.619],
                      [-0.555, -0.578],
                      [-0.605, -0.529],
                      [-0.664, -0.47],
                      [-0.732, -0.401],
                      [-0.809, -0.324]
                    ],
                    v: [
                      [-308.5, -194],
                      [-307.639, -192.311],
                      [-306.785, -190.615],
                      [-305.935, -188.915],
                      [-305.086, -187.214],
                      [-304.235, -185.515],
                      [-303.38, -183.82],
                      [-302.518, -182.132],
                      [-301.647, -180.453],
                      [-300.763, -178.787],
                      [-299.866, -177.134],
                      [-298.95, -175.5],
                      [-298.015, -173.885],
                      [-297.057, -172.293],
                      [-296.074, -170.726],
                      [-295.064, -169.186],
                      [-294.022, -167.678],
                      [-292.948, -166.202],
                      [-291.838, -164.762],
                      [-290.689, -163.361],
                      [-289.5, -162],
                      [-288.87, -162.17],
                      [-288.273, -162.373],
                      [-287.708, -162.608],
                      [-287.177, -162.877],
                      [-286.678, -163.178],
                      [-286.212, -163.512],
                      [-285.779, -163.879],
                      [-285.379, -164.279],
                      [-285.012, -164.712],
                      [-284.678, -165.178],
                      [-284.377, -165.677],
                      [-284.108, -166.208],
                      [-283.873, -166.773],
                      [-283.67, -167.37],
                      [-283.5, -168],
                      [-285.109, -169.791],
                      [-286.607, -171.693],
                      [-288.022, -173.678],
                      [-289.382, -175.718],
                      [-290.714, -177.786],
                      [-292.045, -179.855],
                      [-293.403, -181.897],
                      [-294.814, -183.886],
                      [-296.307, -185.793],
                      [-297.908, -187.592],
                      [-299.645, -189.255],
                      [-301.545, -190.755],
                      [-303.637, -192.063],
                      [-305.946, -193.154]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 31,
                s: [
                  {
                    i: [
                      [0.247, 0.175],
                      [-0.118, -0.432],
                      [-0.124, -0.426],
                      [-0.129, -0.421],
                      [-0.135, -0.415],
                      [-0.141, -0.409],
                      [-0.148, -0.402],
                      [-0.155, -0.395],
                      [-0.162, -0.388],
                      [-0.17, -0.38],
                      [-0.178, -0.372],
                      [-0.186, -0.364],
                      [-0.195, -0.355],
                      [-0.204, -0.346],
                      [-0.213, -0.337],
                      [-0.222, -0.328],
                      [-0.232, -0.317],
                      [-0.243, -0.307],
                      [-0.254, -0.296],
                      [-0.265, -0.285],
                      [-0.276, -0.274],
                      [0.014, 0.334],
                      [0.03, 0.329],
                      [0.041, 0.325],
                      [0.048, 0.321],
                      [0.051, 0.318],
                      [0.049, 0.314],
                      [0.044, 0.311],
                      [0.034, 0.308],
                      [0.02, 0.305],
                      [0.002, 0.302],
                      [-0.021, 0.3],
                      [-0.047, 0.297],
                      [-0.078, 0.295],
                      [-0.113, 0.294],
                      [-0.152, 0.292],
                      [0.257, 0.165],
                      [0.253, 0.169],
                      [0.249, 0.173],
                      [0.246, 0.176],
                      [0.244, 0.179],
                      [0.242, 0.181],
                      [0.24, 0.182],
                      [0.239, 0.183],
                      [0.238, 0.184],
                      [0.239, 0.184],
                      [0.239, 0.183],
                      [0.24, 0.182],
                      [0.242, 0.18],
                      [0.244, 0.178]
                    ],
                    o: [
                      [0.114, 0.436],
                      [0.118, 0.432],
                      [0.124, 0.426],
                      [0.129, 0.421],
                      [0.135, 0.415],
                      [0.141, 0.409],
                      [0.148, 0.402],
                      [0.155, 0.395],
                      [0.162, 0.388],
                      [0.17, 0.38],
                      [0.178, 0.372],
                      [0.186, 0.364],
                      [0.195, 0.355],
                      [0.204, 0.346],
                      [0.213, 0.337],
                      [0.222, 0.328],
                      [0.232, 0.317],
                      [0.243, 0.307],
                      [0.254, 0.296],
                      [0.265, 0.285],
                      [0.005, -0.338],
                      [-0.014, -0.334],
                      [-0.03, -0.329],
                      [-0.041, -0.325],
                      [-0.048, -0.321],
                      [-0.051, -0.318],
                      [-0.049, -0.314],
                      [-0.044, -0.311],
                      [-0.034, -0.308],
                      [-0.02, -0.305],
                      [-0.002, -0.302],
                      [0.021, -0.3],
                      [0.047, -0.297],
                      [0.078, -0.295],
                      [0.113, -0.294],
                      [-0.262, -0.16],
                      [-0.257, -0.165],
                      [-0.253, -0.169],
                      [-0.249, -0.173],
                      [-0.246, -0.176],
                      [-0.244, -0.179],
                      [-0.242, -0.181],
                      [-0.24, -0.182],
                      [-0.239, -0.183],
                      [-0.238, -0.184],
                      [-0.239, -0.184],
                      [-0.239, -0.183],
                      [-0.24, -0.182],
                      [-0.242, -0.18],
                      [-0.244, -0.178]
                    ],
                    v: [
                      [-555.5, -145],
                      [-555.152, -143.698],
                      [-554.789, -142.411],
                      [-554.41, -141.14],
                      [-554.013, -139.887],
                      [-553.599, -138.651],
                      [-553.165, -137.435],
                      [-552.711, -136.239],
                      [-552.236, -135.064],
                      [-551.738, -133.911],
                      [-551.218, -132.782],
                      [-550.672, -131.677],
                      [-550.102, -130.598],
                      [-549.505, -129.545],
                      [-548.88, -128.519],
                      [-548.228, -127.522],
                      [-547.545, -126.554],
                      [-546.832, -125.617],
                      [-546.088, -124.712],
                      [-545.311, -123.839],
                      [-544.5, -123],
                      [-544.515, -124.007],
                      [-544.583, -125.001],
                      [-544.69, -125.983],
                      [-544.824, -126.952],
                      [-544.974, -127.911],
                      [-545.125, -128.858],
                      [-545.265, -129.795],
                      [-545.383, -130.723],
                      [-545.464, -131.641],
                      [-545.498, -132.551],
                      [-545.47, -133.454],
                      [-545.369, -134.35],
                      [-545.182, -135.239],
                      [-544.897, -136.122],
                      [-544.5, -137],
                      [-545.279, -137.488],
                      [-546.045, -137.989],
                      [-546.798, -138.502],
                      [-547.542, -139.025],
                      [-548.276, -139.557],
                      [-549.004, -140.096],
                      [-549.726, -140.641],
                      [-550.444, -141.189],
                      [-551.16, -141.74],
                      [-551.876, -142.291],
                      [-552.592, -142.841],
                      [-553.311, -143.389],
                      [-554.034, -143.932],
                      [-554.763, -144.47]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 32,
                s: [
                  {
                    i: [
                      [0.215, 0.102],
                      [-0.051, -0.179],
                      [-0.051, -0.179],
                      [-0.051, -0.179],
                      [-0.052, -0.178],
                      [-0.054, -0.176],
                      [-0.055, -0.174],
                      [-0.058, -0.172],
                      [-0.06, -0.17],
                      [-0.063, -0.167],
                      [-0.067, -0.163],
                      [-0.071, -0.159],
                      [-0.075, -0.155],
                      [-0.08, -0.15],
                      [-0.085, -0.145],
                      [-0.091, -0.139],
                      [-0.097, -0.133],
                      [-0.104, -0.126],
                      [-0.111, -0.119],
                      [-0.118, -0.112],
                      [-0.126, -0.104],
                      [-0.134, -0.095],
                      [-0.143, -0.087],
                      [-0.152, -0.077],
                      [-0.162, -0.068],
                      [-0.172, -0.058],
                      [-0.183, -0.047],
                      [-0.194, -0.036],
                      [-0.205, -0.025],
                      [-0.217, -0.013],
                      [0.098, 0.219],
                      [0.101, 0.217],
                      [0.104, 0.214],
                      [0.107, 0.211],
                      [0.11, 0.207],
                      [0.114, 0.203],
                      [0.119, 0.199],
                      [0.123, 0.194],
                      [0.128, 0.189],
                      [0.134, 0.184],
                      [0.139, 0.178],
                      [0.145, 0.172],
                      [0.152, 0.166],
                      [0.159, 0.159],
                      [0.166, 0.152],
                      [0.173, 0.144],
                      [0.181, 0.137],
                      [0.189, 0.129],
                      [0.197, 0.12],
                      [0.206, 0.111]
                    ],
                    o: [
                      [0.051, 0.179],
                      [0.051, 0.179],
                      [0.051, 0.179],
                      [0.051, 0.179],
                      [0.052, 0.178],
                      [0.054, 0.176],
                      [0.055, 0.174],
                      [0.058, 0.172],
                      [0.06, 0.17],
                      [0.063, 0.167],
                      [0.067, 0.163],
                      [0.071, 0.159],
                      [0.075, 0.155],
                      [0.08, 0.15],
                      [0.085, 0.145],
                      [0.091, 0.139],
                      [0.097, 0.133],
                      [0.104, 0.126],
                      [0.111, 0.119],
                      [0.118, 0.112],
                      [0.126, 0.104],
                      [0.134, 0.095],
                      [0.143, 0.087],
                      [0.152, 0.077],
                      [0.162, 0.068],
                      [0.172, 0.058],
                      [0.183, 0.047],
                      [0.194, 0.036],
                      [0.205, 0.025],
                      [-0.096, -0.222],
                      [-0.098, -0.219],
                      [-0.101, -0.217],
                      [-0.104, -0.214],
                      [-0.107, -0.211],
                      [-0.11, -0.207],
                      [-0.114, -0.203],
                      [-0.119, -0.199],
                      [-0.123, -0.194],
                      [-0.128, -0.189],
                      [-0.134, -0.184],
                      [-0.139, -0.178],
                      [-0.145, -0.172],
                      [-0.152, -0.166],
                      [-0.159, -0.159],
                      [-0.166, -0.152],
                      [-0.173, -0.144],
                      [-0.181, -0.137],
                      [-0.189, -0.129],
                      [-0.197, -0.12],
                      [-0.206, -0.111]
                    ],
                    v: [
                      [-310.5, -200],
                      [-310.347, -199.463],
                      [-310.195, -198.926],
                      [-310.041, -198.39],
                      [-309.886, -197.856],
                      [-309.727, -197.325],
                      [-309.564, -196.798],
                      [-309.395, -196.278],
                      [-309.218, -195.765],
                      [-309.033, -195.261],
                      [-308.838, -194.766],
                      [-308.631, -194.283],
                      [-308.412, -193.812],
                      [-308.179, -193.356],
                      [-307.931, -192.914],
                      [-307.667, -192.489],
                      [-307.384, -192.081],
                      [-307.083, -191.693],
                      [-306.761, -191.325],
                      [-306.418, -190.979],
                      [-306.051, -190.656],
                      [-305.66, -190.357],
                      [-305.244, -190.084],
                      [-304.8, -189.838],
                      [-304.329, -189.62],
                      [-303.827, -189.431],
                      [-303.295, -189.274],
                      [-302.731, -189.149],
                      [-302.133, -189.057],
                      [-301.5, -189],
                      [-301.791, -189.661],
                      [-302.089, -190.316],
                      [-302.395, -190.962],
                      [-302.711, -191.598],
                      [-303.037, -192.225],
                      [-303.374, -192.84],
                      [-303.724, -193.443],
                      [-304.087, -194.032],
                      [-304.464, -194.607],
                      [-304.857, -195.167],
                      [-305.266, -195.709],
                      [-305.693, -196.235],
                      [-306.139, -196.742],
                      [-306.604, -197.229],
                      [-307.09, -197.695],
                      [-307.598, -198.14],
                      [-308.129, -198.561],
                      [-308.683, -198.959],
                      [-309.263, -199.333],
                      [-309.868, -199.68]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 33,
                s: [
                  {
                    i: [
                      [0.172, 0.095],
                      [0.008, -0.108],
                      [-0.003, -0.097],
                      [-0.013, -0.087],
                      [-0.021, -0.079],
                      [-0.028, -0.072],
                      [-0.033, -0.067],
                      [-0.037, -0.063],
                      [-0.04, -0.06],
                      [-0.041, -0.059],
                      [-0.04, -0.06],
                      [-0.039, -0.061],
                      [-0.036, -0.065],
                      [-0.031, -0.069],
                      [-0.025, -0.075],
                      [-0.017, -0.083],
                      [-0.009, -0.091],
                      [0.002, -0.102],
                      [0.014, -0.114],
                      [0.027, -0.127],
                      [0.041, -0.141],
                      [-0.163, 0.03],
                      [-0.141, 0.007],
                      [-0.122, -0.011],
                      [-0.107, -0.027],
                      [-0.095, -0.039],
                      [-0.086, -0.047],
                      [-0.081, -0.053],
                      [-0.079, -0.054],
                      [-0.08, -0.053],
                      [-0.085, -0.048],
                      [-0.094, -0.04],
                      [-0.105, -0.028],
                      [-0.12, -0.013],
                      [-0.139, 0.005],
                      [-0.161, 0.027],
                      [0.046, 0.22],
                      [0.068, 0.199],
                      [0.087, 0.179],
                      [0.105, 0.162],
                      [0.121, 0.146],
                      [0.134, 0.132],
                      [0.146, 0.121],
                      [0.156, 0.111],
                      [0.164, 0.103],
                      [0.17, 0.096],
                      [0.174, 0.092],
                      [0.177, 0.09],
                      [0.177, 0.09],
                      [0.175, 0.091]
                    ],
                    o: [
                      [-0.02, 0.12],
                      [-0.008, 0.108],
                      [0.003, 0.097],
                      [0.013, 0.087],
                      [0.021, 0.079],
                      [0.028, 0.072],
                      [0.033, 0.067],
                      [0.037, 0.063],
                      [0.04, 0.06],
                      [0.041, 0.059],
                      [0.04, 0.06],
                      [0.039, 0.061],
                      [0.036, 0.065],
                      [0.031, 0.069],
                      [0.025, 0.075],
                      [0.017, 0.083],
                      [0.009, 0.091],
                      [-0.002, 0.102],
                      [-0.014, 0.114],
                      [-0.027, 0.127],
                      [0.188, -0.055],
                      [0.163, -0.03],
                      [0.141, -0.007],
                      [0.122, 0.011],
                      [0.107, 0.027],
                      [0.095, 0.039],
                      [0.086, 0.047],
                      [0.081, 0.053],
                      [0.079, 0.054],
                      [0.08, 0.053],
                      [0.085, 0.048],
                      [0.094, 0.04],
                      [0.105, 0.028],
                      [0.12, 0.013],
                      [0.139, -0.005],
                      [-0.023, -0.244],
                      [-0.046, -0.22],
                      [-0.068, -0.199],
                      [-0.087, -0.179],
                      [-0.105, -0.162],
                      [-0.121, -0.146],
                      [-0.134, -0.132],
                      [-0.146, -0.121],
                      [-0.156, -0.111],
                      [-0.164, -0.103],
                      [-0.17, -0.096],
                      [-0.174, -0.092],
                      [-0.177, -0.09],
                      [-0.177, -0.09],
                      [-0.175, -0.091]
                    ],
                    v: [
                      [-310.5, -202],
                      [-310.542, -201.658],
                      [-310.549, -201.351],
                      [-310.525, -201.075],
                      [-310.474, -200.826],
                      [-310.4, -200.6],
                      [-310.308, -200.392],
                      [-310.202, -200.198],
                      [-310.087, -200.013],
                      [-309.966, -199.834],
                      [-309.844, -199.656],
                      [-309.725, -199.475],
                      [-309.613, -199.287],
                      [-309.513, -199.087],
                      [-309.429, -198.871],
                      [-309.365, -198.635],
                      [-309.326, -198.374],
                      [-309.316, -198.084],
                      [-309.338, -197.762],
                      [-309.398, -197.402],
                      [-309.5, -197],
                      [-308.974, -197.126],
                      [-308.519, -197.181],
                      [-308.126, -197.174],
                      [-307.784, -197.116],
                      [-307.483, -197.017],
                      [-307.213, -196.887],
                      [-306.964, -196.736],
                      [-306.725, -196.575],
                      [-306.487, -196.413],
                      [-306.239, -196.261],
                      [-305.971, -196.129],
                      [-305.674, -196.026],
                      [-305.336, -195.964],
                      [-304.948, -195.952],
                      [-304.5, -196],
                      [-304.604, -196.696],
                      [-304.775, -197.325],
                      [-305.008, -197.892],
                      [-305.297, -198.403],
                      [-305.635, -198.865],
                      [-306.018, -199.282],
                      [-306.439, -199.661],
                      [-306.893, -200.007],
                      [-307.374, -200.326],
                      [-307.876, -200.624],
                      [-308.393, -200.907],
                      [-308.92, -201.18],
                      [-309.45, -201.45],
                      [-309.979, -201.721]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 34,
                s: [
                  {
                    i: [
                      [0.17, -0.011],
                      [-0.02, -0.095],
                      [-0.02, -0.095],
                      [-0.02, -0.095],
                      [-0.021, -0.094],
                      [-0.022, -0.093],
                      [-0.024, -0.091],
                      [-0.025, -0.09],
                      [-0.027, -0.088],
                      [-0.03, -0.085],
                      [-0.032, -0.083],
                      [-0.035, -0.08],
                      [-0.038, -0.077],
                      [-0.042, -0.073],
                      [-0.045, -0.07],
                      [-0.049, -0.066],
                      [-0.054, -0.061],
                      [-0.058, -0.057],
                      [-0.063, -0.052],
                      [-0.068, -0.047],
                      [-0.074, -0.041],
                      [-0.079, -0.035],
                      [-0.086, -0.029],
                      [-0.092, -0.023],
                      [-0.098, -0.016],
                      [-0.105, -0.01],
                      [-0.113, -0.002],
                      [-0.12, 0.005],
                      [-0.128, 0.013],
                      [-0.136, 0.021],
                      [-0.002, 0.161],
                      [0.006, 0.152],
                      [0.015, 0.144],
                      [0.023, 0.135],
                      [0.032, 0.127],
                      [0.041, 0.118],
                      [0.049, 0.109],
                      [0.058, 0.101],
                      [0.066, 0.092],
                      [0.075, 0.084],
                      [0.084, 0.075],
                      [0.092, 0.066],
                      [0.101, 0.058],
                      [0.109, 0.049],
                      [0.118, 0.041],
                      [0.127, 0.032],
                      [0.135, 0.023],
                      [0.144, 0.015],
                      [0.152, 0.006],
                      [0.161, -0.002]
                    ],
                    o: [
                      [0.02, 0.095],
                      [0.02, 0.095],
                      [0.02, 0.095],
                      [0.02, 0.095],
                      [0.021, 0.094],
                      [0.022, 0.093],
                      [0.024, 0.091],
                      [0.025, 0.09],
                      [0.027, 0.088],
                      [0.03, 0.085],
                      [0.032, 0.083],
                      [0.035, 0.08],
                      [0.038, 0.077],
                      [0.042, 0.073],
                      [0.045, 0.07],
                      [0.049, 0.066],
                      [0.054, 0.061],
                      [0.058, 0.057],
                      [0.063, 0.052],
                      [0.068, 0.047],
                      [0.074, 0.041],
                      [0.079, 0.035],
                      [0.086, 0.029],
                      [0.092, 0.023],
                      [0.098, 0.016],
                      [0.105, 0.01],
                      [0.113, 0.002],
                      [0.12, -0.005],
                      [0.128, -0.013],
                      [0.011, -0.17],
                      [0.002, -0.161],
                      [-0.006, -0.152],
                      [-0.015, -0.144],
                      [-0.023, -0.135],
                      [-0.032, -0.127],
                      [-0.041, -0.118],
                      [-0.049, -0.109],
                      [-0.058, -0.101],
                      [-0.066, -0.092],
                      [-0.075, -0.084],
                      [-0.084, -0.075],
                      [-0.092, -0.066],
                      [-0.101, -0.058],
                      [-0.109, -0.049],
                      [-0.118, -0.041],
                      [-0.127, -0.032],
                      [-0.135, -0.023],
                      [-0.144, -0.015],
                      [-0.152, -0.006],
                      [-0.161, 0.002]
                    ],
                    v: [
                      [-560.5, -152],
                      [-560.441, -151.714],
                      [-560.382, -151.428],
                      [-560.322, -151.144],
                      [-560.259, -150.861],
                      [-560.194, -150.582],
                      [-560.125, -150.306],
                      [-560.051, -150.035],
                      [-559.972, -149.769],
                      [-559.887, -149.51],
                      [-559.794, -149.257],
                      [-559.693, -149.013],
                      [-559.583, -148.778],
                      [-559.464, -148.553],
                      [-559.333, -148.339],
                      [-559.191, -148.136],
                      [-559.037, -147.946],
                      [-558.869, -147.768],
                      [-558.687, -147.606],
                      [-558.49, -147.458],
                      [-558.277, -147.326],
                      [-558.047, -147.211],
                      [-557.8, -147.114],
                      [-557.534, -147.035],
                      [-557.249, -146.975],
                      [-556.943, -146.936],
                      [-556.616, -146.918],
                      [-556.267, -146.922],
                      [-555.896, -146.949],
                      [-555.5, -147],
                      [-555.48, -147.496],
                      [-555.486, -147.966],
                      [-555.518, -148.411],
                      [-555.576, -148.829],
                      [-555.659, -149.222],
                      [-555.768, -149.589],
                      [-555.903, -149.93],
                      [-556.064, -150.246],
                      [-556.25, -150.535],
                      [-556.463, -150.799],
                      [-556.701, -151.037],
                      [-556.965, -151.25],
                      [-557.254, -151.436],
                      [-557.57, -151.597],
                      [-557.911, -151.732],
                      [-558.278, -151.841],
                      [-558.671, -151.924],
                      [-559.089, -151.982],
                      [-559.534, -152.014],
                      [-560.004, -152.02]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 35,
                s: [
                  {
                    i: [
                      [0.07, 0.025],
                      [0.029, -0.13],
                      [0.016, -0.124],
                      [0.004, -0.117],
                      [-0.008, -0.111],
                      [-0.018, -0.104],
                      [-0.028, -0.097],
                      [-0.036, -0.09],
                      [-0.044, -0.082],
                      [-0.051, -0.075],
                      [-0.058, -0.067],
                      [-0.063, -0.059],
                      [-0.068, -0.051],
                      [-0.071, -0.043],
                      [-0.074, -0.034],
                      [-0.076, -0.025],
                      [-0.077, -0.016],
                      [-0.078, -0.007],
                      [-0.077, 0.002],
                      [-0.076, 0.011],
                      [-0.074, 0.021],
                      [-0.071, 0.031],
                      [-0.067, 0.041],
                      [-0.062, 0.051],
                      [-0.057, 0.062],
                      [-0.051, 0.072],
                      [-0.043, 0.083],
                      [-0.035, 0.094],
                      [-0.027, 0.105],
                      [-0.017, 0.117],
                      [0.069, 0.027],
                      [0.067, 0.028],
                      [0.065, 0.03],
                      [0.064, 0.031],
                      [0.063, 0.032],
                      [0.062, 0.033],
                      [0.061, 0.034],
                      [0.061, 0.035],
                      [0.06, 0.035],
                      [0.06, 0.035],
                      [0.06, 0.035],
                      [0.06, 0.035],
                      [0.061, 0.035],
                      [0.061, 0.034],
                      [0.062, 0.033],
                      [0.063, 0.032],
                      [0.064, 0.031],
                      [0.065, 0.03],
                      [0.067, 0.028],
                      [0.069, 0.027]
                    ],
                    o: [
                      [-0.042, 0.136],
                      [-0.029, 0.13],
                      [-0.016, 0.124],
                      [-0.004, 0.117],
                      [0.008, 0.111],
                      [0.018, 0.104],
                      [0.028, 0.097],
                      [0.036, 0.09],
                      [0.044, 0.082],
                      [0.051, 0.075],
                      [0.058, 0.067],
                      [0.063, 0.059],
                      [0.068, 0.051],
                      [0.071, 0.043],
                      [0.074, 0.034],
                      [0.076, 0.025],
                      [0.077, 0.016],
                      [0.078, 0.007],
                      [0.077, -0.002],
                      [0.076, -0.011],
                      [0.074, -0.021],
                      [0.071, -0.031],
                      [0.067, -0.041],
                      [0.062, -0.051],
                      [0.057, -0.062],
                      [0.051, -0.072],
                      [0.043, -0.083],
                      [0.035, -0.094],
                      [0.027, -0.105],
                      [-0.07, -0.025],
                      [-0.069, -0.027],
                      [-0.067, -0.028],
                      [-0.065, -0.03],
                      [-0.064, -0.031],
                      [-0.063, -0.032],
                      [-0.062, -0.033],
                      [-0.061, -0.034],
                      [-0.061, -0.035],
                      [-0.06, -0.035],
                      [-0.06, -0.035],
                      [-0.06, -0.035],
                      [-0.06, -0.035],
                      [-0.061, -0.035],
                      [-0.061, -0.034],
                      [-0.062, -0.033],
                      [-0.063, -0.032],
                      [-0.064, -0.031],
                      [-0.065, -0.03],
                      [-0.067, -0.028],
                      [-0.069, -0.027]
                    ],
                    v: [
                      [-561.5, -154],
                      [-561.606, -153.601],
                      [-561.672, -153.221],
                      [-561.701, -152.86],
                      [-561.694, -152.518],
                      [-561.655, -152.197],
                      [-561.586, -151.896],
                      [-561.49, -151.616],
                      [-561.369, -151.359],
                      [-561.225, -151.123],
                      [-561.061, -150.911],
                      [-560.88, -150.722],
                      [-560.684, -150.557],
                      [-560.476, -150.417],
                      [-560.257, -150.302],
                      [-560.031, -150.213],
                      [-559.801, -150.151],
                      [-559.568, -150.115],
                      [-559.335, -150.107],
                      [-559.105, -150.127],
                      [-558.879, -150.176],
                      [-558.662, -150.254],
                      [-558.455, -150.362],
                      [-558.26, -150.5],
                      [-558.081, -150.669],
                      [-557.919, -150.87],
                      [-557.778, -151.103],
                      [-557.659, -151.369],
                      [-557.566, -151.667],
                      [-557.5, -152],
                      [-557.709, -152.077],
                      [-557.912, -152.16],
                      [-558.11, -152.247],
                      [-558.304, -152.339],
                      [-558.494, -152.434],
                      [-558.681, -152.533],
                      [-558.866, -152.634],
                      [-559.049, -152.737],
                      [-559.23, -152.842],
                      [-559.41, -152.947],
                      [-559.59, -153.053],
                      [-559.77, -153.158],
                      [-559.951, -153.263],
                      [-560.134, -153.366],
                      [-560.319, -153.467],
                      [-560.506, -153.566],
                      [-560.696, -153.661],
                      [-560.89, -153.753],
                      [-561.088, -153.84],
                      [-561.291, -153.923]
                    ],
                    c: true
                  }
                ],
                h: 1
              }
            ],
            ix: 1
          },
          o: {
            a: 1,
            k: [
              { t: 22, s: [0], h: 1 },
              { t: 23, s: [100], h: 1 },
              { t: 36, s: [0], h: 1 }
            ],
            ix: 3
          },
          x: { a: 0, k: 0, ix: 4 },
          nm: 'Mask 4'
        },
        {
          inv: false,
          mode: 'f',
          pt: {
            a: 1,
            k: [
              {
                t: 24,
                s: [
                  {
                    i: [
                      [-0.276, -12.609],
                      [-9.577, 3.923],
                      [-9.791, 3.709],
                      [4.051, -2.616],
                      [1.976, -4.691],
                      [-6.131, 1.621],
                      [-6.462, 0.556],
                      [-11.668, -6.598],
                      [-3.619, -14.133],
                      [6.363, -13.137],
                      [10.822, -8.678],
                      [7.711, -5.253],
                      [8.291, -4.2],
                      [2.912, -1.718],
                      [-0.074, -3.899],
                      [-18.744, 7.341],
                      [-15.524, 12.695],
                      [-9.102, 14.503],
                      [-0.676, 17.426],
                      [4.869, 9.992],
                      [6.95, 6.096],
                      [13.958, 2.585],
                      [16.099, -2.416],
                      [-8.667, 5.667],
                      [-8.413, 5.921],
                      [-4.056, 3.111],
                      [-3.837, 3.33],
                      [-6.375, 4.625],
                      [4.458, 0.015],
                      [39.595, -18.405],
                      [25.077, -10.59]
                    ],
                    o: [
                      [10.901, -2.599],
                      [9.577, -3.923],
                      [-2.821, 3.846],
                      [-4.051, 2.616],
                      [6.015, 2.038],
                      [6.131, -1.621],
                      [17.712, -1.524],
                      [11.667, 6.598],
                      [0.274, 19.774],
                      [-6.363, 13.137],
                      [-6.866, 5.789],
                      [-7.711, 5.253],
                      [-2.426, 1.229],
                      [-2.912, 1.718],
                      [23.5, 1.06],
                      [18.744, -7.341],
                      [12.914, -10.56],
                      [9.103, -14.503],
                      [0.425, -10.938],
                      [-4.869, -9.992],
                      [-8.067, -7.076],
                      [-13.958, -2.585],
                      [7.92, -6.413],
                      [8.667, -5.666],
                      [3.939, -3.227],
                      [4.056, -3.111],
                      [6.61, -4.39],
                      [0.712, -1.157],
                      [-48.351, 9.65],
                      [-18.431, 17.235],
                      [-0.937, 11.397]
                    ],
                    v: [
                      [487.5, 342],
                      [517.833, 331.833],
                      [546.5, 320],
                      [535.366, 328.866],
                      [525.5, 339],
                      [543.664, 338.446],
                      [562.5, 334],
                      [607.07, 341.758],
                      [630.5, 373],
                      [620.822, 421.822],
                      [594.5, 454],
                      [572.568, 470.692],
                      [548.5, 485],
                      [539.625, 488.998],
                      [534.5, 497],
                      [597.483, 486.815],
                      [648.5, 456],
                      [682.678, 418.149],
                      [698.5, 370],
                      [691.031, 337.868],
                      [672.5, 313],
                      [638.523, 298.381],
                      [592.5, 298],
                      [617.631, 280.131],
                      [643.5, 263],
                      [655.577, 253.577],
                      [667.5, 244],
                      [686.5, 230],
                      [689.5, 226],
                      [562.5, 273],
                      [504.5, 322]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 25,
                s: [
                  {
                    i: [
                      [1.781, 0.733],
                      [26.15, -12.183],
                      [25.57, -12.763],
                      [5.704, -5.13],
                      [5.862, -4.971],
                      [2.866, -2.467],
                      [2.901, -2.433],
                      [1.493, -1.173],
                      [0.869, -1.798],
                      [1.624, -1.589],
                      [-1.875, -0.322],
                      [-17.247, 6.753],
                      [-17.333, 6.667],
                      [2.567, -4.266],
                      [2.382, -4.451],
                      [-29.426, 4.081],
                      [-17.798, -19.004],
                      [-2.8, -4.439],
                      [-1.849, -5.235],
                      [11.849, -23.022],
                      [11.087, -11.894],
                      [6.783, -5.717],
                      [6.88, -5.62],
                      [8.747, -3.92],
                      [7.566, -5.101],
                      [-20.113, 8.074],
                      [-21.185, 38.897],
                      [38.791, 35.932],
                      [28.078, -1.46],
                      [24.679, -6.199],
                      [-29.459, 28.541]
                    ],
                    o: [
                      [-24.829, 13.505],
                      [-26.15, 12.183],
                      [-5.323, 5.51],
                      [-5.704, 5.13],
                      [-2.635, 2.699],
                      [-2.866, 2.467],
                      [-1.157, 1.51],
                      [-1.494, 1.173],
                      [-1.381, 0.322],
                      [-1.624, 1.589],
                      [17.679, -6.321],
                      [17.247, -6.753],
                      [-2.348, 4.486],
                      [-2.567, 4.266],
                      [21.496, -5.679],
                      [29.426, -4.081],
                      [1.952, 6.01],
                      [2.8, 4.439],
                      [8.484, 24.017],
                      [-11.849, 23.022],
                      [-5.99, 6.51],
                      [-6.783, 5.717],
                      [-7.447, 5.22],
                      [-8.747, 3.92],
                      [21.726, -4.551],
                      [40.52, -16.265],
                      [22.991, -42.212],
                      [-16.005, -15.184],
                      [-34.309, 1.784],
                      [30, -28],
                      [0.144, -1.182]
                    ],
                    v: [
                      [787.5, 158],
                      [710.556, 196.056],
                      [632.5, 233],
                      [615.904, 248.904],
                      [598.5, 264],
                      [590.2, 271.7],
                      [581.5, 279],
                      [577.284, 282.784],
                      [573.5, 287],
                      [568.058, 290.5],
                      [567.5, 294],
                      [619.759, 274.259],
                      [671.5, 254],
                      [664.026, 267.026],
                      [656.5, 280],
                      [737.774, 259.988],
                      [813.5, 277],
                      [821.078, 292.082],
                      [828.5, 306],
                      [818.178, 379.092],
                      [778.5, 434],
                      [759.167, 452.167],
                      [738.5, 469],
                      [713.589, 482.089],
                      [688.5, 495],
                      [755.5, 481],
                      [857.5, 386],
                      [848.5, 238],
                      [783.5, 215],
                      [694.5, 242],
                      [787.5, 161]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 26,
                s: [
                  {
                    i: [
                      [19.466, 9.388],
                      [-22.845, -75.154],
                      [37.2, 7.464],
                      [34.056, -54.013],
                      [2.235, -40.353],
                      [1.357, -13.909],
                      [-0.071, -28.178],
                      [-94.631, 15.287],
                      [-0.172, 1.218],
                      [-6.101, 9.233],
                      [11.299, 2.477],
                      [13.324, 32.185],
                      [-11.364, 20.442],
                      [0.007, 7.304],
                      [2.014, 5.712],
                      [-18.959, 29.278],
                      [-32.84, 10.274],
                      [-49.61, -14.814],
                      [-16.805, -2.585],
                      [12.337, 43.199],
                      [-36.214, -29.119],
                      [-16.861, -15.806],
                      [-7.426, -8.863],
                      [-13.517, -1.739],
                      [1.552, 30.85],
                      [-22.828, 8.204],
                      [-11.066, 10.907],
                      [15.583, -19.872],
                      [-2.047, -17.953],
                      [42.229, 26.105],
                      [18.058, 17.962]
                    ],
                    o: [
                      [22.266, 75.733],
                      [-27.66, -21.773],
                      [-88.395, -17.736],
                      [-17.624, 27.952],
                      [-0.763, 13.779],
                      [-2.513, 25.755],
                      [0.213, 84.144],
                      [-3.947, -0.42],
                      [13.965, -1.369],
                      [-9.263, -7.149],
                      [-38.582, -8.459],
                      [-10.863, -22.245],
                      [3.396, -6.11],
                      [-0.007, -7.19],
                      [-15.004, -42.55],
                      [17.927, -27.683],
                      [52.237, -16.343],
                      [16.991, 5.074],
                      [4.488, -58.319],
                      [33.838, 31.495],
                      [17.284, 15.383],
                      [7.186, 8.97],
                      [7.166, 8.553],
                      [-8.018, -20.755],
                      [-1.421, -28.244],
                      [14.293, -5.137],
                      [-22.988, -20.098],
                      [-9.911, 12.639],
                      [-38.143, -30.191],
                      [-17.467, -14.312],
                      [-17.56, -17.466]
                    ],
                    v: [
                      [-520.5, -88],
                      [-446.5, 132],
                      [-540.5, 85],
                      [-745.5, 154],
                      [-781.5, 259],
                      [-781.5, 300],
                      [-797.5, 371],
                      [-668.5, 497],
                      [-680.5, 494],
                      [-641.5, 487],
                      [-675.5, 477],
                      [-759.5, 426],
                      [-756.5, 355],
                      [-744.5, 336],
                      [-753.5, 317],
                      [-728.5, 190],
                      [-652.5, 135],
                      [-481.5, 136],
                      [-432.5, 153],
                      [-457.5, 7],
                      [-340.5, 86],
                      [-288.5, 132],
                      [-266.5, 159],
                      [-238.5, 181],
                      [-267.5, 109],
                      [-233.5, 46],
                      [-188.5, 32],
                      [-288.5, 48],
                      [-294.5, 99],
                      [-410.5, 10],
                      [-463.5, -40]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 27,
                s: [
                  {
                    i: [
                      [0.333, 0],
                      [-0.01, -0.324],
                      [-0.042, -0.291],
                      [-0.136, -0.197],
                      [-0.293, -0.04],
                      [-3.381, -9.619],
                      [-3.339, -9.661],
                      [-3.164, -9.836],
                      [-2.855, -10.145],
                      [-2.347, -7.565],
                      [-2.299, -7.548],
                      [-2.428, -7.209],
                      [-2.733, -6.549],
                      [-1.336, -3.159],
                      [-1.81, -2.716],
                      [-2.66, -1.633],
                      [4.123, 15.71],
                      [1.94, 17.894],
                      [2.809, 6.858],
                      [-0.688, 10.355],
                      [-10.356, -9.349],
                      [-3.161, -5.931],
                      [-7.85, -4.893],
                      [-9.942, -0.239],
                      [6.819, 5.181],
                      [5.09, 6.91],
                      [5.695, 6.305],
                      [5.317, 6.683],
                      [12.592, 11.574],
                      [14.525, 9.642],
                      [0.333, 0]
                    ],
                    o: [
                      [0.039, 0.294],
                      [0.01, 0.324],
                      [0.042, 0.291],
                      [0.136, 0.197],
                      [3.288, 9.712],
                      [3.381, 9.619],
                      [3.339, 9.661],
                      [3.164, 9.836],
                      [2.57, 7.26],
                      [2.347, 7.565],
                      [2.299, 7.548],
                      [2.428, 7.209],
                      [1.237, 2.964],
                      [1.336, 3.159],
                      [1.81, 2.716],
                      [-1.567, -18.266],
                      [-4.123, -15.71],
                      [-4.452, -5.214],
                      [-2.809, -6.858],
                      [5.414, -15.326],
                      [10.356, 9.349],
                      [10.656, 1.188],
                      [7.85, 4.893],
                      [-4.635, -7.366],
                      [-6.819, -5.181],
                      [-6.902, -5.098],
                      [-5.695, -6.305],
                      [-12.106, -12.061],
                      [-12.592, -11.574],
                      [-0.333, 0],
                      [-0.333, 0]
                    ],
                    v: [
                      [-532.5, -108],
                      [-532.443, -107.057],
                      [-532.381, -106.119],
                      [-532.129, -105.371],
                      [-531.5, -105],
                      [-521.464, -76.036],
                      [-511.351, -47.149],
                      [-501.562, -17.938],
                      [-492.5, 12],
                      [-485.169, 34.317],
                      [-478.245, 57.066],
                      [-471.198, 79.283],
                      [-463.5, 100],
                      [-459.735, 109.344],
                      [-455.11, 118.317],
                      [-448.5, 125],
                      [-458.221, 75.221],
                      [-468.5, 26],
                      [-479.856, 8.356],
                      [-483.5, -17],
                      [-456.81, -18.943],
                      [-433.5, 11],
                      [-406.964, 22.211],
                      [-381.5, 32],
                      [-399.658, 14.158],
                      [-418.5, -3],
                      [-437.188, -20.312],
                      [-453.5, -40],
                      [-490.185, -75.815],
                      [-530.5, -108],
                      [-531.5, -108]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 28,
                s: [
                  {
                    i: [
                      [-4.78, -0.053],
                      [1.155, 1.873],
                      [1.912, 1.517],
                      [1.926, 1.51],
                      [1.197, 1.852],
                      [4.471, 4.862],
                      [4.677, 4.657],
                      [5.117, 4.216],
                      [5.793, 3.54],
                      [0.167, 0],
                      [0.167, 0],
                      [0.167, 0],
                      [0.167, 0],
                      [0.245, -0.255],
                      [0.295, -0.205],
                      [0.152, -0.348],
                      [-0.182, -0.682],
                      [-4.415, -9.335],
                      [-3.713, -10.037],
                      [-3.067, -10.683],
                      [-2.476, -11.274],
                      [-0.642, -2.476],
                      [-0.705, -2.508],
                      [-0.74, -2.348],
                      [-1.361, -3.457],
                      [-3.522, -0.895],
                      [1.83, 10.286],
                      [0.495, 11.265],
                      [-0.123, 5.522],
                      [-0.745, 5.495],
                      [-3.575, -1.258]
                    ],
                    o: [
                      [0.344, -2.579],
                      [-1.155, -1.873],
                      [-1.912, -1.517],
                      [-1.926, -1.51],
                      [-4.5, -4.833],
                      [-4.471, -4.862],
                      [-4.677, -4.657],
                      [-5.117, -4.216],
                      [-0.167, 0],
                      [-0.167, 0],
                      [-0.167, 0],
                      [-0.167, 0],
                      [-0.003, 0.497],
                      [-0.245, 0.255],
                      [-0.295, 0.205],
                      [-0.152, 0.348],
                      [5.173, 8.577],
                      [4.415, 9.335],
                      [3.713, 10.037],
                      [3.067, 10.683],
                      [0.55, 2.254],
                      [0.642, 2.476],
                      [0.705, 2.508],
                      [1.033, 3.276],
                      [1.361, 3.457],
                      [0.815, -8.593],
                      [-1.83, -10.286],
                      [-0.238, -5.416],
                      [0.123, -5.522],
                      [4.919, -0.086],
                      [3.575, 1.258]
                    ],
                    v: [
                      [-469.5, -55],
                      [-470.902, -61.592],
                      [-475.687, -66.59],
                      [-481.629, -71.044],
                      [-486.5, -76],
                      [-499.898, -90.602],
                      [-513.56, -104.939],
                      [-528.193, -118.307],
                      [-544.5, -130],
                      [-545, -130],
                      [-545.5, -130],
                      [-546, -130],
                      [-546.5, -130],
                      [-546.92, -128.92],
                      [-547.778, -128.278],
                      [-548.496, -127.496],
                      [-548.5, -126],
                      [-534.132, -99.118],
                      [-521.955, -70.045],
                      [-511.8, -38.95],
                      [-503.5, -6],
                      [-501.704, 1.144],
                      [-499.675, 8.668],
                      [-497.5, 16],
                      [-494.367, 26.786],
                      [-487.5, 34],
                      [-490.017, 5.504],
                      [-494.5, -27],
                      [-494.737, -43.44],
                      [-493.5, -60],
                      [-481.395, -57.604]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 29,
                s: [
                  {
                    i: [
                      [-0.157, 1.51],
                      [0.906, -1.604],
                      [0.949, -1.596],
                      [0.985, -1.587],
                      [1.013, -1.577],
                      [1.033, -1.567],
                      [1.045, -1.556],
                      [1.05, -1.545],
                      [1.047, -1.533],
                      [1.037, -1.52],
                      [1.019, -1.507],
                      [0.993, -1.493],
                      [0.959, -1.479],
                      [0.918, -1.464],
                      [0.869, -1.448],
                      [0.813, -1.432],
                      [-1.214, 1.387],
                      [-1.188, 1.492],
                      [-1.211, 1.496],
                      [-1.284, 1.397],
                      [-1.407, 1.197],
                      [-1.579, 0.894],
                      [-1.802, 0.489],
                      [-2.074, -0.018],
                      [-0.404, 1.819],
                      [-0.475, 1.832],
                      [-0.511, 1.826],
                      [-0.512, 1.801],
                      [-0.476, 1.756],
                      [-0.405, 1.693],
                      [-0.299, 1.611]
                    ],
                    o: [
                      [-0.855, 1.612],
                      [-0.906, 1.604],
                      [-0.949, 1.596],
                      [-0.985, 1.587],
                      [-1.013, 1.577],
                      [-1.033, 1.567],
                      [-1.045, 1.556],
                      [-1.05, 1.545],
                      [-1.047, 1.533],
                      [-1.037, 1.52],
                      [-1.019, 1.507],
                      [-0.993, 1.493],
                      [-0.959, 1.479],
                      [-0.918, 1.464],
                      [-0.869, 1.448],
                      [1.29, -1.179],
                      [1.214, -1.387],
                      [1.188, -1.492],
                      [1.211, -1.496],
                      [1.284, -1.397],
                      [1.407, -1.197],
                      [1.579, -0.894],
                      [1.802, -0.489],
                      [0.297, -1.787],
                      [0.404, -1.819],
                      [0.475, -1.832],
                      [0.511, -1.826],
                      [0.512, -1.801],
                      [0.476, -1.756],
                      [0.405, -1.693],
                      [0.299, -1.611]
                    ],
                    v: [
                      [485.5, -132],
                      [482.856, -127.177],
                      [480.072, -122.377],
                      [477.169, -117.603],
                      [474.171, -112.857],
                      [471.101, -108.14],
                      [467.982, -103.454],
                      [464.837, -98.802],
                      [461.688, -94.184],
                      [458.56, -89.604],
                      [455.475, -85.062],
                      [452.456, -80.561],
                      [449.526, -76.102],
                      [446.708, -71.688],
                      [444.025, -67.32],
                      [441.5, -63],
                      [445.244, -66.873],
                      [448.834, -71.217],
                      [452.419, -75.724],
                      [456.149, -80.09],
                      [460.173, -84.006],
                      [464.64, -87.167],
                      [469.699, -89.267],
                      [475.5, -90],
                      [476.56, -95.414],
                      [477.887, -100.895],
                      [479.376, -106.386],
                      [480.92, -111.83],
                      [482.411, -117.171],
                      [483.742, -122.35],
                      [484.808, -127.312]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 30,
                s: [
                  {
                    i: [
                      [2.147, 2.103],
                      [0.266, 0.016],
                      [0.243, -0.007],
                      [0.223, -0.027],
                      [0.204, -0.046],
                      [0.187, -0.063],
                      [0.171, -0.079],
                      [0.157, -0.093],
                      [0.145, -0.105],
                      [0, -0.143],
                      [0, -0.143],
                      [0, -0.143],
                      [0, -0.143],
                      [0, -0.143],
                      [0, -0.143],
                      [0, -0.143],
                      [-1.258, -1.409],
                      [-1.242, -1.424],
                      [-1.238, -1.429],
                      [-1.245, -1.421],
                      [-0.644, -2.106],
                      [-0.717, -2.033],
                      [-1.339, -1.411],
                      [-2.51, -0.24],
                      [0.105, 1.262],
                      [0.076, 1.24],
                      [-0.317, 1.233],
                      [-1.075, 1.239],
                      [2.062, 2.188],
                      [2.069, 2.181],
                      [2.097, 2.153]
                    ],
                    o: [
                      [-0.29, -0.04],
                      [-0.266, -0.016],
                      [-0.243, 0.007],
                      [-0.223, 0.027],
                      [-0.204, 0.046],
                      [-0.187, 0.063],
                      [-0.171, 0.079],
                      [-0.157, 0.093],
                      [0, 0.143],
                      [0, 0.143],
                      [0, 0.143],
                      [0, 0.143],
                      [0, 0.143],
                      [0, 0.143],
                      [0, 0.143],
                      [1.285, 1.381],
                      [1.258, 1.409],
                      [1.242, 1.424],
                      [1.238, 1.429],
                      [1.12, 1.63],
                      [0.644, 2.106],
                      [0.717, 2.033],
                      [1.339, 1.411],
                      [0.23, -1.299],
                      [-0.105, -1.262],
                      [-0.076, -1.24],
                      [0.317, -1.233],
                      [-2.077, -2.172],
                      [-2.062, -2.188],
                      [-2.069, -2.181],
                      [-2.097, -2.153]
                    ],
                    v: [
                      [-552.5, -140],
                      [-553.333, -140.083],
                      [-554.096, -140.096],
                      [-554.795, -140.045],
                      [-555.434, -139.934],
                      [-556.019, -139.769],
                      [-556.555, -139.555],
                      [-557.047, -139.297],
                      [-557.5, -139],
                      [-557.5, -138.571],
                      [-557.5, -138.143],
                      [-557.5, -137.714],
                      [-557.5, -137.286],
                      [-557.5, -136.857],
                      [-557.5, -136.429],
                      [-557.5, -136],
                      [-553.688, -131.812],
                      [-549.94, -127.56],
                      [-546.223, -123.278],
                      [-542.5, -119],
                      [-539.99, -113.26],
                      [-538.085, -106.915],
                      [-535.137, -101.613],
                      [-529.5, -99],
                      [-529.405, -102.839],
                      [-529.768, -106.589],
                      [-529.498, -110.295],
                      [-527.5, -114],
                      [-533.705, -120.545],
                      [-539.896, -127.103],
                      [-546.14, -133.61]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 31,
                s: [
                  {
                    i: [
                      [-0.94, -0.567],
                      [0.572, 1.045],
                      [0.816, 0.96],
                      [1.024, 0.865],
                      [1.198, 0.759],
                      [1.336, 0.644],
                      [1.439, 0.518],
                      [1.507, 0.381],
                      [1.54, 0.235],
                      [1.539, 0.078],
                      [1.502, -0.089],
                      [1.43, -0.267],
                      [1.323, -0.455],
                      [1.181, -0.653],
                      [1.004, -0.861],
                      [0.791, -1.079],
                      [0.544, -1.308],
                      [-1.351, 0.284],
                      [-1.358, 0.213],
                      [-1.36, 0.145],
                      [-1.356, 0.077],
                      [-1.346, 0.011],
                      [-1.331, -0.053],
                      [-1.31, -0.116],
                      [-1.283, -0.178],
                      [-1.251, -0.238],
                      [-1.213, -0.296],
                      [-1.17, -0.353],
                      [-1.121, -0.409],
                      [-1.066, -0.463],
                      [-1.006, -0.516]
                    ],
                    o: [
                      [-0.294, -1.12],
                      [-0.572, -1.045],
                      [-0.816, -0.96],
                      [-1.024, -0.865],
                      [-1.198, -0.759],
                      [-1.336, -0.644],
                      [-1.439, -0.518],
                      [-1.507, -0.381],
                      [-1.54, -0.235],
                      [-1.539, -0.078],
                      [-1.502, 0.089],
                      [-1.43, 0.267],
                      [-1.323, 0.455],
                      [-1.181, 0.653],
                      [-1.004, 0.861],
                      [-0.791, 1.079],
                      [1.338, -0.355],
                      [1.351, -0.284],
                      [1.358, -0.213],
                      [1.36, -0.145],
                      [1.356, -0.077],
                      [1.346, -0.011],
                      [1.331, 0.053],
                      [1.31, 0.116],
                      [1.283, 0.178],
                      [1.251, 0.238],
                      [1.213, 0.296],
                      [1.17, 0.353],
                      [1.121, 0.409],
                      [1.066, 0.463],
                      [1.006, 0.516]
                    ],
                    v: [
                      [137.5, -29],
                      [136.192, -32.25],
                      [134.101, -35.261],
                      [131.332, -38.001],
                      [127.99, -40.44],
                      [124.181, -42.548],
                      [120.01, -44.292],
                      [115.582, -45.643],
                      [111.001, -46.569],
                      [106.374, -47.04],
                      [101.805, -47.025],
                      [97.399, -46.493],
                      [93.262, -45.413],
                      [89.498, -43.755],
                      [86.213, -41.487],
                      [83.512, -38.579],
                      [81.5, -35],
                      [85.535, -35.958],
                      [89.599, -36.703],
                      [93.677, -37.239],
                      [97.751, -37.571],
                      [101.805, -37.704],
                      [105.822, -37.641],
                      [109.784, -37.387],
                      [113.675, -36.947],
                      [117.478, -36.324],
                      [121.175, -35.523],
                      [124.752, -34.548],
                      [128.189, -33.404],
                      [131.471, -32.095],
                      [134.58, -30.626]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 32,
                s: [
                  {
                    i: [
                      [0.279, 0.037],
                      [-0.026, -0.255],
                      [0.002, -0.227],
                      [0.026, -0.203],
                      [0.046, -0.183],
                      [0.062, -0.168],
                      [0.073, -0.156],
                      [0.081, -0.148],
                      [0.085, -0.144],
                      [0.084, -0.145],
                      [0.08, -0.149],
                      [0.072, -0.158],
                      [0.059, -0.17],
                      [0.042, -0.187],
                      [0.022, -0.207],
                      [-0.003, -0.232],
                      [-0.031, -0.26],
                      [-0.257, 0.005],
                      [-0.234, 0.085],
                      [-0.209, 0.154],
                      [-0.182, 0.21],
                      [-0.152, 0.254],
                      [-0.12, 0.287],
                      [-0.085, 0.307],
                      [-0.048, 0.315],
                      [-0.009, 0.312],
                      [0.033, 0.296],
                      [0.077, 0.268],
                      [0.124, 0.228],
                      [0.173, 0.176],
                      [0.225, 0.112]
                    ],
                    o: [
                      [0.058, 0.287],
                      [0.026, 0.255],
                      [-0.002, 0.227],
                      [-0.026, 0.203],
                      [-0.046, 0.183],
                      [-0.062, 0.168],
                      [-0.073, 0.156],
                      [-0.081, 0.148],
                      [-0.085, 0.144],
                      [-0.084, 0.145],
                      [-0.08, 0.149],
                      [-0.072, 0.158],
                      [-0.059, 0.17],
                      [-0.042, 0.187],
                      [-0.022, 0.207],
                      [0.003, 0.232],
                      [0.277, 0.087],
                      [0.257, -0.005],
                      [0.234, -0.085],
                      [0.209, -0.154],
                      [0.182, -0.21],
                      [0.152, -0.254],
                      [0.12, -0.287],
                      [0.085, -0.307],
                      [0.048, -0.315],
                      [0.009, -0.312],
                      [-0.033, -0.296],
                      [-0.077, -0.268],
                      [-0.124, -0.228],
                      [-0.173, -0.176],
                      [-0.225, -0.112]
                    ],
                    v: [
                      [489.5, -150],
                      [489.624, -149.188],
                      [489.658, -148.467],
                      [489.615, -147.823],
                      [489.506, -147.244],
                      [489.344, -146.719],
                      [489.14, -146.235],
                      [488.908, -145.78],
                      [488.658, -145.342],
                      [488.403, -144.909],
                      [488.156, -144.469],
                      [487.927, -144.01],
                      [487.731, -143.519],
                      [487.577, -142.985],
                      [487.48, -142.395],
                      [487.45, -141.738],
                      [487.5, -141],
                      [488.301, -140.879],
                      [489.038, -141.018],
                      [489.704, -141.379],
                      [490.291, -141.928],
                      [490.793, -142.628],
                      [491.202, -143.443],
                      [491.51, -144.337],
                      [491.711, -145.274],
                      [491.798, -146.217],
                      [491.762, -147.131],
                      [491.597, -147.98],
                      [491.296, -148.727],
                      [490.851, -149.337],
                      [490.254, -149.773]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 33,
                s: [
                  {
                    i: [
                      [0.272, -0.176],
                      [-0.186, -0.106],
                      [-0.158, -0.133],
                      [-0.136, -0.156],
                      [-0.118, -0.174],
                      [-0.105, -0.187],
                      [-0.097, -0.195],
                      [-0.093, -0.199],
                      [-0.095, -0.197],
                      [-0.101, -0.191],
                      [-0.112, -0.18],
                      [-0.128, -0.164],
                      [-0.148, -0.143],
                      [-0.174, -0.118],
                      [-0.204, -0.088],
                      [-0.239, -0.053],
                      [-0.279, -0.013],
                      [-0.09, 0.298],
                      [-0.031, 0.298],
                      [0.024, 0.293],
                      [0.073, 0.282],
                      [0.117, 0.266],
                      [0.156, 0.245],
                      [0.189, 0.219],
                      [0.218, 0.188],
                      [0.241, 0.151],
                      [0.259, 0.11],
                      [0.272, 0.063],
                      [0.28, 0.011],
                      [0.282, -0.046],
                      [0.28, -0.109]
                    ],
                    o: [
                      [0.218, 0.074],
                      [0.186, 0.106],
                      [0.158, 0.133],
                      [0.136, 0.156],
                      [0.118, 0.174],
                      [0.105, 0.187],
                      [0.097, 0.195],
                      [0.093, 0.199],
                      [0.095, 0.197],
                      [0.101, 0.191],
                      [0.112, 0.18],
                      [0.128, 0.164],
                      [0.148, 0.143],
                      [0.174, 0.118],
                      [0.204, 0.088],
                      [0.239, 0.053],
                      [0.155, -0.293],
                      [0.09, -0.298],
                      [0.031, -0.298],
                      [-0.024, -0.293],
                      [-0.073, -0.282],
                      [-0.117, -0.266],
                      [-0.156, -0.245],
                      [-0.189, -0.219],
                      [-0.218, -0.188],
                      [-0.241, -0.151],
                      [-0.259, -0.11],
                      [-0.272, -0.063],
                      [-0.28, -0.011],
                      [-0.282, 0.046],
                      [-0.28, 0.109]
                    ],
                    v: [
                      [-560.5, -148],
                      [-559.896, -147.729],
                      [-559.381, -147.369],
                      [-558.941, -146.934],
                      [-558.562, -146.438],
                      [-558.229, -145.896],
                      [-557.929, -145.321],
                      [-557.645, -144.73],
                      [-557.365, -144.135],
                      [-557.073, -143.552],
                      [-556.756, -142.995],
                      [-556.398, -142.477],
                      [-555.985, -142.015],
                      [-555.504, -141.621],
                      [-554.939, -141.312],
                      [-554.275, -141.1],
                      [-553.5, -141],
                      [-553.133, -141.889],
                      [-552.953, -142.784],
                      [-552.944, -143.671],
                      [-553.09, -144.535],
                      [-553.376, -145.358],
                      [-553.786, -146.127],
                      [-554.304, -146.825],
                      [-554.916, -147.436],
                      [-555.606, -147.946],
                      [-556.357, -148.339],
                      [-557.155, -148.599],
                      [-557.984, -148.711],
                      [-558.828, -148.658],
                      [-559.672, -148.427]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 34,
                s: [
                  {
                    i: [
                      [-0.02, 0.188],
                      [0.181, 0.085],
                      [0.213, 0.073],
                      [0.241, 0.061],
                      [0.263, 0.049],
                      [0.281, 0.037],
                      [0.293, 0.024],
                      [0.301, 0.012],
                      [0.303, 0],
                      [0.301, -0.012],
                      [0.293, -0.024],
                      [0.281, -0.037],
                      [0.263, -0.049],
                      [0.241, -0.061],
                      [0.213, -0.073],
                      [0.181, -0.085],
                      [0.143, -0.098],
                      [-0.105, -0.199],
                      [-0.186, -0.163],
                      [-0.254, -0.128],
                      [-0.308, -0.095],
                      [-0.349, -0.063],
                      [-0.376, -0.032],
                      [-0.39, -0.002],
                      [-0.391, 0.026],
                      [-0.378, 0.053],
                      [-0.352, 0.079],
                      [-0.312, 0.103],
                      [-0.259, 0.126],
                      [-0.193, 0.148],
                      [-0.113, 0.169]
                    ],
                    o: [
                      [-0.143, -0.098],
                      [-0.181, -0.085],
                      [-0.213, -0.073],
                      [-0.241, -0.061],
                      [-0.263, -0.049],
                      [-0.281, -0.037],
                      [-0.293, -0.024],
                      [-0.301, -0.012],
                      [-0.303, 0],
                      [-0.301, 0.012],
                      [-0.293, 0.024],
                      [-0.281, 0.037],
                      [-0.263, 0.049],
                      [-0.241, 0.061],
                      [-0.213, 0.073],
                      [-0.181, 0.085],
                      [0.011, 0.236],
                      [0.105, 0.199],
                      [0.186, 0.163],
                      [0.254, 0.128],
                      [0.308, 0.095],
                      [0.349, 0.063],
                      [0.376, 0.032],
                      [0.39, 0.002],
                      [0.391, -0.026],
                      [0.378, -0.053],
                      [0.352, -0.079],
                      [0.312, -0.103],
                      [0.259, -0.126],
                      [0.193, -0.148],
                      [0.113, -0.169]
                    ],
                    v: [
                      [114.5, -53],
                      [114.012, -53.275],
                      [113.42, -53.513],
                      [112.737, -53.714],
                      [111.98, -53.879],
                      [111.162, -54.007],
                      [110.3, -54.099],
                      [109.407, -54.154],
                      [108.5, -54.172],
                      [107.593, -54.154],
                      [106.7, -54.099],
                      [105.838, -54.007],
                      [105.02, -53.879],
                      [104.263, -53.714],
                      [103.58, -53.513],
                      [102.988, -53.275],
                      [102.5, -53],
                      [102.677, -52.349],
                      [103.118, -51.807],
                      [103.782, -51.371],
                      [104.628, -51.037],
                      [105.617, -50.801],
                      [106.708, -50.659],
                      [107.861, -50.608],
                      [109.036, -50.644],
                      [110.193, -50.763],
                      [111.292, -50.96],
                      [112.292, -51.233],
                      [113.153, -51.577],
                      [113.835, -51.989],
                      [114.297, -52.465]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 35,
                s: [
                  {
                    i: [
                      [0.167, 0],
                      [0, -0.044],
                      [0, -0.044],
                      [0, -0.044],
                      [0, -0.044],
                      [0, -0.044],
                      [0, -0.044],
                      [0, -0.044],
                      [0, -0.044],
                      [0, -0.044],
                      [0, -0.044],
                      [0, -0.044],
                      [0, -0.044],
                      [0, -0.044],
                      [0, -0.044],
                      [0, -0.044],
                      [-0.256, -0.006],
                      [-0.268, -0.018],
                      [-0.258, -0.008],
                      [-0.226, 0.024],
                      [-0.172, 0.078],
                      [-0.095, 0.155],
                      [0.005, 0.255],
                      [0.126, 0.376],
                      [0.167, 0],
                      [0.167, 0],
                      [0.167, 0],
                      [0.167, 0],
                      [0.167, 0],
                      [0.167, 0],
                      [0.167, 0]
                    ],
                    o: [
                      [0, 0.044],
                      [0, 0.044],
                      [0, 0.044],
                      [0, 0.044],
                      [0, 0.044],
                      [0, 0.044],
                      [0, 0.044],
                      [0, 0.044],
                      [0, 0.044],
                      [0, 0.044],
                      [0, 0.044],
                      [0, 0.044],
                      [0, 0.044],
                      [0, 0.044],
                      [0, 0.044],
                      [0.222, -0.029],
                      [0.256, 0.006],
                      [0.268, 0.018],
                      [0.258, 0.008],
                      [0.226, -0.024],
                      [0.172, -0.078],
                      [0.095, -0.155],
                      [-0.005, -0.255],
                      [-0.167, 0],
                      [-0.167, 0],
                      [-0.167, 0],
                      [-0.167, 0],
                      [-0.167, 0],
                      [-0.167, 0],
                      [-0.167, 0],
                      [-0.167, 0]
                    ],
                    v: [
                      [107.5, -53],
                      [107.5, -52.867],
                      [107.5, -52.733],
                      [107.5, -52.6],
                      [107.5, -52.467],
                      [107.5, -52.333],
                      [107.5, -52.2],
                      [107.5, -52.067],
                      [107.5, -51.933],
                      [107.5, -51.8],
                      [107.5, -51.667],
                      [107.5, -51.533],
                      [107.5, -51.4],
                      [107.5, -51.267],
                      [107.5, -51.133],
                      [107.5, -51],
                      [108.222, -51.028],
                      [109.014, -50.986],
                      [109.81, -50.94],
                      [110.542, -50.957],
                      [111.145, -51.105],
                      [111.549, -51.451],
                      [111.69, -52.06],
                      [111.5, -53],
                      [111, -53],
                      [110.5, -53],
                      [110, -53],
                      [109.5, -53],
                      [109, -53],
                      [108.5, -53],
                      [108, -53]
                    ],
                    c: true
                  }
                ],
                h: 1
              }
            ],
            ix: 1
          },
          o: {
            a: 1,
            k: [
              { t: 23, s: [0], h: 1 },
              { t: 24, s: [100], h: 1 },
              { t: 36, s: [0], h: 1 }
            ],
            ix: 3
          },
          x: { a: 0, k: 0, ix: 4 },
          nm: 'Mask 5'
        },
        {
          inv: false,
          mode: 'f',
          pt: {
            a: 1,
            k: [
              {
                t: 25,
                s: [
                  {
                    i: [
                      [-12.425, -61.908],
                      [22.5, 12.5],
                      [16.433, 0.996],
                      [25.826, -50.772],
                      [3.159, -57.042],
                      [-33.3, -32.857],
                      [-84.346, 16.393],
                      [2.225, 0.005],
                      [9.786, 5.79],
                      [4.88, 88.113],
                      [-34.666, 27.442],
                      [-53.787, -6.389],
                      [-28.281, -22.719],
                      [-20.927, -14.406],
                      [9.848, 75.152],
                      [-36.933, -25.008],
                      [-12.638, -9.156],
                      [-12.615, -8.135],
                      [-13.503, -8.711],
                      [0.167, 2.952],
                      [0.788, 27.072],
                      [-9.136, 17.494],
                      [-9.985, 3.681],
                      [-16.13, -5.512],
                      [-5.526, 2.049],
                      [14.074, 16.069],
                      [43.094, 48.594],
                      [-11.537, -54.463],
                      [10.469, -39.197],
                      [41.343, 26.99],
                      [13.79, 12.877],
                      [6.551, 6.901],
                      [11.028, 1.214],
                      [1.688, -10.291]
                    ],
                    o: [
                      [-17.946, -17.054],
                      [-14.797, -4.282],
                      [-80.192, -4.861],
                      [-25.886, 38.134],
                      [-3.245, 58.589],
                      [43.633, 43.054],
                      [4.095, -2.763],
                      [-18.315, -0.038],
                      [-82.273, -6.032],
                      [-3.484, -62.92],
                      [31.006, -24.545],
                      [39.287, 11.713],
                      [21.189, 14.144],
                      [-23.63, -61.37],
                      [37.163, 26.597],
                      [12.94, 8.762],
                      [11.875, 8.603],
                      [13.416, 8.651],
                      [4.218, -0.843],
                      [-14.61, -14.841],
                      [-0.732, -25.125],
                      [7.247, -6.42],
                      [20.702, -6.148],
                      [6.713, 2.294],
                      [-6.974, -21.518],
                      [-42.939, -49.024],
                      [15.635, 50.365],
                      [-44.836, 4.831],
                      [-32.649, -35.684],
                      [-12.013, -14.653],
                      [-7.086, -6.4],
                      [-6.216, -6.549],
                      [-2.847, 10.771],
                      [20.703, 53.63]
                    ],
                    v: [
                      [-450.5, 146],
                      [-516.5, 107],
                      [-560.5, 98],
                      [-721.5, 168],
                      [-770.5, 304],
                      [-721.5, 447],
                      [-518.5, 491],
                      [-520.5, 490],
                      [-571.5, 483],
                      [-713.5, 322],
                      [-664.5, 181],
                      [-527.5, 156],
                      [-429.5, 211],
                      [-375.5, 263],
                      [-426.5, 59],
                      [-316.5, 140],
                      [-276.5, 164],
                      [-243.5, 193],
                      [-203.5, 216],
                      [-207.5, 208],
                      [-237.5, 149],
                      [-215.5, 89],
                      [-191.5, 72],
                      [-123.5, 76],
                      [-104.5, 81],
                      [-143.5, 29],
                      [-264.5, -123],
                      [-217.5, 28],
                      [-294.5, 100],
                      [-413.5, 14],
                      [-456.5, -23],
                      [-476.5, -43],
                      [-499.5, -61],
                      [-495.5, -32]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 26,
                s: [
                  {
                    i: [
                      [-3.633, 20.701],
                      [0.167, 0],
                      [0.167, 0],
                      [0.167, 0],
                      [0.167, 0],
                      [0.279, -0.137],
                      [0.255, -0.161],
                      [0.223, -0.193],
                      [0.183, -0.233],
                      [5.024, -7.643],
                      [5.793, -6.873],
                      [6.373, -6.294],
                      [6.762, -5.905],
                      [4.59, -3.91],
                      [2.632, -5.867],
                      [-6.561, 1.085],
                      [-3.698, 0.505],
                      [1.073, -8.427],
                      [0.529, -8.971],
                      [-6.314, 0.221],
                      [-5.185, -0.641],
                      [-12.987, -8.493],
                      [-10.274, -7.077],
                      [-3.487, -6.134],
                      [-1.735, -7.278],
                      [-0.532, -2.728],
                      [-3.434, -0.25],
                      [1.095, 5.669],
                      [1.321, 5.144],
                      [7.594, 13.736],
                      [10.39, 9.561],
                      [9.154, 2.679],
                      [7.069, 4.764],
                      [-4.874, 19.46]
                    ],
                    o: [
                      [-0.167, 0],
                      [-0.167, 0],
                      [-0.167, 0],
                      [-0.167, 0],
                      [-0.296, 0.121],
                      [-0.279, 0.137],
                      [-0.255, 0.161],
                      [-0.223, 0.193],
                      [-4.065, 8.602],
                      [-5.024, 7.643],
                      [-5.793, 6.873],
                      [-6.373, 6.294],
                      [-4.009, 4.492],
                      [-4.59, 3.91],
                      [6.06, 1.846],
                      [6.56, -1.085],
                      [-0.18, 9.32],
                      [-1.073, 8.427],
                      [5.558, 2.244],
                      [6.314, -0.221],
                      [15.779, 1.95],
                      [12.987, 8.493],
                      [4.319, 5.186],
                      [3.486, 6.134],
                      [0.438, 1.837],
                      [0.532, 2.728],
                      [2.698, -5.18],
                      [-1.094, -5.669],
                      [-4.234, -16.496],
                      [-7.594, -13.736],
                      [-6.315, -5.518],
                      [-9.154, -2.679],
                      [1.872, -22.461],
                      [4.874, -19.46]
                    ],
                    v: [
                      [455.5, -64],
                      [455, -64],
                      [454.5, -64],
                      [454, -64],
                      [453.5, -64],
                      [452.636, -63.614],
                      [451.832, -63.168],
                      [451.112, -62.638],
                      [450.5, -62],
                      [436.819, -37.681],
                      [420.546, -15.955],
                      [402.249, 3.749],
                      [382.5, 22],
                      [368.968, 33.968],
                      [357.5, 48],
                      [377.271, 48.263],
                      [393.5, 45],
                      [391.262, 71.262],
                      [388.5, 97],
                      [406.78, 99.203],
                      [424.5, 99],
                      [467.629, 116.655],
                      [502.5, 142],
                      [514.438, 158.931],
                      [522.5, 179],
                      [523.253, 186.69],
                      [528.5, 192],
                      [530.014, 175.474],
                      [525.5, 159],
                      [507.617, 113.298],
                      [480.5, 78],
                      [456.065, 66.935],
                      [430.5, 57],
                      [441.679, -4.82]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 27,
                s: [
                  {
                    i: [
                      [6.049, -9.618],
                      [-1.252, 0.457],
                      [-1.189, 0.519],
                      [-1.145, 0.563],
                      [-1.118, 0.59],
                      [-1.11, 0.598],
                      [-1.12, 0.588],
                      [-1.148, 0.561],
                      [-1.194, 0.515],
                      [0.765, -3.402],
                      [0.73, -3.436],
                      [0.697, -3.47],
                      [0.664, -3.502],
                      [0.633, -3.534],
                      [0.602, -3.565],
                      [0.572, -3.595],
                      [0.543, -3.624],
                      [-8.587, -2.079],
                      [-7.418, -3.248],
                      [-6.372, -4.295],
                      [-5.448, -5.219],
                      [-4.646, -6.02],
                      [-3.967, -6.699],
                      [4.755, 11.027],
                      [7.769, 8.439],
                      [10.13, 6.068],
                      [11.837, 3.914],
                      [-3.08, 12.868],
                      [-3.157, 12.802],
                      [-2.748, 12.783],
                      [-1.855, 12.812],
                      [6.305, -9.362],
                      [6.561, -9.106],
                      [6.476, -9.191]
                    ],
                    o: [
                      [1.332, -0.376],
                      [1.252, -0.457],
                      [1.189, -0.519],
                      [1.145, -0.563],
                      [1.118, -0.59],
                      [1.11, -0.598],
                      [1.12, -0.588],
                      [1.148, -0.561],
                      [-0.8, 3.367],
                      [-0.765, 3.402],
                      [-0.73, 3.436],
                      [-0.697, 3.47],
                      [-0.664, 3.502],
                      [-0.633, 3.534],
                      [-0.602, 3.565],
                      [-0.572, 3.595],
                      [9.879, 0.788],
                      [8.587, 2.079],
                      [7.418, 3.248],
                      [6.372, 4.295],
                      [5.448, 5.219],
                      [4.646, 6.02],
                      [-1.086, -13.832],
                      [-4.755, -11.027],
                      [-7.769, -8.439],
                      [-10.13, -6.068],
                      [2.519, -12.981],
                      [3.08, -12.868],
                      [3.157, -12.802],
                      [2.748, -12.783],
                      [-5.707, 9.96],
                      [-6.305, 9.362],
                      [-6.561, 9.106],
                      [-6.476, 9.191]
                    ],
                    v: [
                      [396.5, 6],
                      [400.372, 4.747],
                      [404.029, 3.279],
                      [407.525, 1.65],
                      [410.916, -0.084],
                      [414.254, -1.871],
                      [417.595, -3.655],
                      [420.992, -5.383],
                      [424.5, -7],
                      [422.153, 3.153],
                      [419.91, 13.41],
                      [417.77, 23.77],
                      [415.728, 34.228],
                      [413.783, 44.783],
                      [411.931, 55.431],
                      [410.171, 66.171],
                      [408.5, 77],
                      [436.169, 81.331],
                      [460.146, 89.353],
                      [480.8, 100.699],
                      [498.499, 115.001],
                      [513.61, 131.89],
                      [526.5, 151],
                      [517.575, 113.767],
                      [498.626, 84.623],
                      [471.614, 62.918],
                      [438.5, 48],
                      [447.02, 9.239],
                      [456.496, -29.253],
                      [465.475, -67.619],
                      [472.5, -106],
                      [454.397, -77.103],
                      [435.013, -49.487],
                      [415.372, -22.128]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 28,
                s: [
                  {
                    i: [
                      [0.818, 3.327],
                      [0.083, 0],
                      [0.083, 0],
                      [0.083, 0],
                      [0.083, 0],
                      [0.083, 0],
                      [0.083, 0],
                      [0.083, 0],
                      [0.083, 0],
                      [2.053, -3.739],
                      [2.391, -3.401],
                      [2.607, -3.185],
                      [2.701, -3.091],
                      [2.673, -3.119],
                      [2.522, -3.27],
                      [2.249, -3.543],
                      [1.854, -3.938],
                      [-1.644, 0.921],
                      [-1.508, 1.159],
                      [-1.401, 1.238],
                      [-1.323, 1.158],
                      [-1.274, 0.921],
                      [-1.255, 0.524],
                      [-0.295, -1.122],
                      [-0.242, -1.174],
                      [-0.201, -1.215],
                      [-0.172, -1.245],
                      [-1.927, 2.769],
                      [-1.379, 3.378],
                      [-1.137, 3.545],
                      [-1.202, 3.272],
                      [-1.517, 3.43],
                      [-1.273, 3.459],
                      [-0.495, 3.425]
                    ],
                    o: [
                      [-0.083, 0],
                      [-0.083, 0],
                      [-0.083, 0],
                      [-0.083, 0],
                      [-0.083, 0],
                      [-0.083, 0],
                      [-0.083, 0],
                      [-0.083, 0],
                      [-1.592, 4.2],
                      [-2.053, 3.739],
                      [-2.391, 3.401],
                      [-2.607, 3.185],
                      [-2.701, 3.091],
                      [-2.673, 3.119],
                      [-2.522, 3.27],
                      [-2.249, 3.543],
                      [1.81, -0.525],
                      [1.644, -0.921],
                      [1.508, -1.159],
                      [1.401, -1.238],
                      [1.323, -1.159],
                      [1.274, -0.921],
                      [0.359, 1.058],
                      [0.295, 1.122],
                      [0.242, 1.174],
                      [0.201, 1.215],
                      [2.782, -1.718],
                      [1.927, -2.769],
                      [1.379, -3.378],
                      [1.137, -3.545],
                      [1.226, -3.336],
                      [1.517, -3.43],
                      [1.273, -3.459],
                      [0.495, -3.425]
                    ],
                    v: [
                      [482.5, -116],
                      [482.25, -116],
                      [482, -116],
                      [481.75, -116],
                      [481.5, -116],
                      [481.25, -116],
                      [481, -116],
                      [480.75, -116],
                      [480.5, -116],
                      [475.002, -104.123],
                      [468.306, -93.444],
                      [460.778, -83.597],
                      [452.786, -74.215],
                      [444.695, -64.931],
                      [436.872, -55.378],
                      [429.685, -45.19],
                      [423.5, -34],
                      [428.673, -36.208],
                      [433.393, -39.367],
                      [437.748, -43.001],
                      [441.826, -46.635],
                      [445.714, -49.794],
                      [449.5, -52],
                      [450.478, -48.728],
                      [451.281, -45.281],
                      [451.944, -41.694],
                      [452.5, -38],
                      [459.488, -44.84],
                      [464.37, -54.17],
                      [468.068, -64.664],
                      [471.5, -75],
                      [475.748, -85.165],
                      [480.067, -95.515],
                      [482.852, -105.857]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 29,
                s: [
                  {
                    i: [
                      [-3.505, -1.306],
                      [1.46, 1.72],
                      [1.666, 1.707],
                      [1.866, 1.633],
                      [2.059, 1.497],
                      [2.246, 1.3],
                      [2.426, 1.041],
                      [2.599, 0.721],
                      [2.766, 0.339],
                      [2.393, -0.012],
                      [2.294, -0.283],
                      [2.171, -0.512],
                      [2.023, -0.698],
                      [1.851, -0.841],
                      [1.655, -0.941],
                      [1.435, -0.998],
                      [1.19, -1.012],
                      [0.403, -0.36],
                      [0.419, -0.402],
                      [0.407, -0.432],
                      [0.366, -0.449],
                      [0.298, -0.455],
                      [0.202, -0.448],
                      [0.077, -0.428],
                      [-0.075, -0.396],
                      [-2.703, 0.836],
                      [-2.769, 0.852],
                      [-2.835, 0.766],
                      [-2.899, 0.576],
                      [-2.963, 0.283],
                      [-3.025, -0.112],
                      [-4.798, -1.411],
                      [-4.266, -1.998],
                      [-3.835, -1.963]
                    ],
                    o: [
                      [-1.247, -1.672],
                      [-1.46, -1.72],
                      [-1.666, -1.707],
                      [-1.866, -1.633],
                      [-2.059, -1.497],
                      [-2.246, -1.3],
                      [-2.426, -1.041],
                      [-2.599, -0.721],
                      [-2.467, -0.303],
                      [-2.393, 0.012],
                      [-2.294, 0.283],
                      [-2.171, 0.512],
                      [-2.023, 0.698],
                      [-1.851, 0.841],
                      [-1.655, 0.941],
                      [-1.435, 0.998],
                      [-0.36, 0.306],
                      [-0.403, 0.36],
                      [-0.419, 0.402],
                      [-0.407, 0.432],
                      [-0.366, 0.449],
                      [-0.298, 0.455],
                      [-0.202, 0.448],
                      [-0.077, 0.428],
                      [2.636, -0.717],
                      [2.703, -0.836],
                      [2.769, -0.852],
                      [2.835, -0.766],
                      [2.899, -0.576],
                      [2.963, -0.283],
                      [5.432, 0.202],
                      [4.798, 1.411],
                      [4.266, 1.998],
                      [3.835, 1.963]
                    ],
                    v: [
                      [159.5, -5],
                      [155.438, -10.104],
                      [150.746, -15.261],
                      [145.446, -20.287],
                      [139.556, -24.998],
                      [133.096, -29.209],
                      [126.087, -32.736],
                      [118.549, -35.394],
                      [110.5, -37],
                      [103.204, -37.426],
                      [96.169, -36.972],
                      [89.465, -35.769],
                      [83.168, -33.944],
                      [77.35, -31.625],
                      [72.084, -28.943],
                      [67.443, -26.025],
                      [63.5, -23],
                      [62.349, -21.998],
                      [61.108, -20.852],
                      [59.862, -19.597],
                      [58.696, -18.272],
                      [57.692, -16.913],
                      [56.936, -15.557],
                      [56.51, -14.24],
                      [56.5, -13],
                      [64.509, -15.355],
                      [72.718, -17.913],
                      [81.124, -20.365],
                      [89.725, -22.403],
                      [98.518, -23.718],
                      [107.5, -24],
                      [122.82, -21.426],
                      [136.391, -16.157],
                      [148.517, -10.06]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 30,
                s: [
                  {
                    i: [
                      [-0.571, 0.651],
                      [0.641, -0.324],
                      [0.609, -0.356],
                      [0.578, -0.387],
                      [0.549, -0.416],
                      [0.522, -0.443],
                      [0.496, -0.469],
                      [0.471, -0.494],
                      [0.448, -0.517],
                      [0.426, -0.538],
                      [0.406, -0.559],
                      [0.388, -0.577],
                      [0.371, -0.594],
                      [0.355, -0.61],
                      [0.341, -0.624],
                      [0.328, -0.637],
                      [0.317, -0.648],
                      [0.307, -0.658],
                      [0.299, -0.666],
                      [0.292, -0.673],
                      [-0.647, 0.575],
                      [-0.618, 0.605],
                      [-0.591, 0.631],
                      [-0.569, 0.653],
                      [-0.55, 0.672],
                      [-0.536, 0.687],
                      [-0.525, 0.698],
                      [-0.517, 0.705],
                      [-0.514, 0.709],
                      [-0.514, 0.708],
                      [-0.518, 0.705],
                      [-0.525, 0.697],
                      [-0.537, 0.685],
                      [-0.552, 0.67]
                    ],
                    o: [
                      [-0.674, 0.29],
                      [-0.641, 0.324],
                      [-0.609, 0.356],
                      [-0.578, 0.387],
                      [-0.549, 0.416],
                      [-0.522, 0.443],
                      [-0.496, 0.469],
                      [-0.471, 0.494],
                      [-0.448, 0.517],
                      [-0.426, 0.538],
                      [-0.406, 0.559],
                      [-0.388, 0.577],
                      [-0.371, 0.594],
                      [-0.355, 0.61],
                      [-0.341, 0.624],
                      [-0.328, 0.637],
                      [-0.317, 0.648],
                      [-0.307, 0.658],
                      [-0.299, 0.666],
                      [0.681, -0.541],
                      [0.647, -0.575],
                      [0.618, -0.605],
                      [0.591, -0.631],
                      [0.569, -0.653],
                      [0.55, -0.672],
                      [0.536, -0.687],
                      [0.525, -0.698],
                      [0.517, -0.705],
                      [0.514, -0.709],
                      [0.514, -0.708],
                      [0.518, -0.705],
                      [0.525, -0.697],
                      [0.537, -0.685],
                      [0.552, -0.67]
                    ],
                    v: [
                      [491.5, -130],
                      [489.527, -129.078],
                      [487.653, -128.058],
                      [485.872, -126.944],
                      [484.181, -125.74],
                      [482.575, -124.452],
                      [481.049, -123.083],
                      [479.599, -121.638],
                      [478.22, -120.122],
                      [476.909, -118.538],
                      [475.66, -116.892],
                      [474.47, -115.188],
                      [473.333, -113.431],
                      [472.245, -111.624],
                      [471.202, -109.772],
                      [470.199, -107.88],
                      [469.231, -105.953],
                      [468.296, -103.994],
                      [467.387, -102.008],
                      [466.5, -100],
                      [468.492, -101.675],
                      [470.388, -103.445],
                      [472.201, -105.299],
                      [473.941, -107.226],
                      [475.619, -109.214],
                      [477.247, -111.253],
                      [478.837, -113.33],
                      [480.398, -115.435],
                      [481.943, -117.557],
                      [483.483, -119.684],
                      [485.029, -121.804],
                      [486.593, -123.907],
                      [488.185, -125.982],
                      [489.817, -128.016]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 31,
                s: [
                  {
                    i: [
                      [-0.665, 0.424],
                      [0.843, -0.009],
                      [0.826, -0.05],
                      [0.804, -0.091],
                      [0.778, -0.132],
                      [0.748, -0.174],
                      [0.713, -0.215],
                      [0.674, -0.258],
                      [0.63, -0.3],
                      [0.582, -0.343],
                      [0.53, -0.386],
                      [0.474, -0.429],
                      [0.413, -0.473],
                      [0.347, -0.516],
                      [0.278, -0.561],
                      [0.204, -0.605],
                      [0.125, -0.65],
                      [0.043, -0.695],
                      [-0.045, -0.74],
                      [-0.136, -0.785],
                      [-0.323, 0.766],
                      [-0.408, 0.681],
                      [-0.484, 0.605],
                      [-0.551, 0.538],
                      [-0.608, 0.481],
                      [-0.656, 0.433],
                      [-0.694, 0.395],
                      [-0.723, 0.365],
                      [-0.743, 0.346],
                      [-0.754, 0.335],
                      [-0.755, 0.334],
                      [-0.746, 0.343],
                      [-0.728, 0.361],
                      [-0.701, 0.388]
                    ],
                    o: [
                      [-0.856, -0.032],
                      [-0.843, 0.009],
                      [-0.826, 0.05],
                      [-0.804, 0.091],
                      [-0.778, 0.132],
                      [-0.748, 0.174],
                      [-0.713, 0.215],
                      [-0.674, 0.258],
                      [-0.63, 0.3],
                      [-0.582, 0.343],
                      [-0.53, 0.386],
                      [-0.474, 0.429],
                      [-0.413, 0.473],
                      [-0.347, 0.516],
                      [-0.278, 0.561],
                      [-0.204, 0.605],
                      [-0.125, 0.65],
                      [-0.043, 0.695],
                      [0.045, 0.74],
                      [0.228, -0.861],
                      [0.323, -0.766],
                      [0.408, -0.681],
                      [0.484, -0.605],
                      [0.551, -0.538],
                      [0.608, -0.481],
                      [0.656, -0.433],
                      [0.694, -0.395],
                      [0.723, -0.365],
                      [0.743, -0.346],
                      [0.754, -0.335],
                      [0.755, -0.334],
                      [0.746, -0.343],
                      [0.728, -0.361],
                      [0.701, -0.388]
                    ],
                    v: [
                      [-302.5, 16],
                      [-305.051, 15.966],
                      [-307.556, 16.053],
                      [-310.002, 16.264],
                      [-312.377, 16.597],
                      [-314.666, 17.056],
                      [-316.858, 17.639],
                      [-318.939, 18.348],
                      [-320.896, 19.185],
                      [-322.716, 20.149],
                      [-324.386, 21.241],
                      [-325.892, 22.463],
                      [-327.223, 23.816],
                      [-328.363, 25.299],
                      [-329.302, 26.914],
                      [-330.025, 28.663],
                      [-330.519, 30.544],
                      [-330.772, 32.561],
                      [-330.77, 34.712],
                      [-330.5, 37],
                      [-329.671, 34.563],
                      [-328.571, 32.396],
                      [-327.23, 30.47],
                      [-325.675, 28.759],
                      [-323.934, 27.233],
                      [-322.036, 25.864],
                      [-320.008, 24.625],
                      [-317.879, 23.488],
                      [-315.677, 22.423],
                      [-313.43, 21.404],
                      [-311.165, 20.402],
                      [-308.912, 19.388],
                      [-306.698, 18.336],
                      [-304.551, 17.215]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 32,
                s: [
                  {
                    i: [
                      [0.301, -0.166],
                      [-0.16, -0.158],
                      [-0.154, -0.183],
                      [-0.15, -0.203],
                      [-0.147, -0.218],
                      [-0.145, -0.228],
                      [-0.145, -0.233],
                      [-0.146, -0.234],
                      [-0.149, -0.229],
                      [-0.152, -0.22],
                      [-0.158, -0.206],
                      [-0.164, -0.187],
                      [-0.172, -0.163],
                      [-0.182, -0.134],
                      [-0.192, -0.1],
                      [-0.204, -0.062],
                      [-0.218, -0.019],
                      [-0.233, 0.03],
                      [-0.249, 0.083],
                      [-0.266, 0.141],
                      [0.083, 0.317],
                      [0.115, 0.319],
                      [0.144, 0.316],
                      [0.171, 0.307],
                      [0.196, 0.292],
                      [0.218, 0.272],
                      [0.237, 0.246],
                      [0.254, 0.214],
                      [0.269, 0.177],
                      [0.28, 0.134],
                      [0.29, 0.085],
                      [0.296, 0.031],
                      [0.301, -0.029],
                      [0.302, -0.095]
                    ],
                    o: [
                      [0.167, 0.129],
                      [0.16, 0.158],
                      [0.154, 0.183],
                      [0.15, 0.203],
                      [0.147, 0.218],
                      [0.145, 0.228],
                      [0.145, 0.233],
                      [0.146, 0.234],
                      [0.149, 0.229],
                      [0.152, 0.22],
                      [0.158, 0.206],
                      [0.164, 0.187],
                      [0.172, 0.163],
                      [0.182, 0.134],
                      [0.192, 0.1],
                      [0.204, 0.062],
                      [0.218, 0.019],
                      [0.233, -0.03],
                      [0.249, -0.083],
                      [-0.048, -0.309],
                      [-0.083, -0.317],
                      [-0.115, -0.319],
                      [-0.144, -0.316],
                      [-0.171, -0.307],
                      [-0.196, -0.292],
                      [-0.218, -0.272],
                      [-0.237, -0.246],
                      [-0.254, -0.214],
                      [-0.269, -0.177],
                      [-0.28, -0.134],
                      [-0.29, -0.085],
                      [-0.296, -0.031],
                      [-0.301, 0.029],
                      [-0.302, 0.095]
                    ],
                    v: [
                      [-560.5, -146],
                      [-560.011, -145.569],
                      [-559.54, -145.056],
                      [-559.085, -144.476],
                      [-558.641, -143.843],
                      [-558.203, -143.173],
                      [-557.768, -142.479],
                      [-557.332, -141.777],
                      [-556.891, -141.081],
                      [-556.439, -140.405],
                      [-555.974, -139.765],
                      [-555.492, -139.175],
                      [-554.987, -138.649],
                      [-554.457, -138.202],
                      [-553.896, -137.849],
                      [-553.302, -137.604],
                      [-552.669, -137.482],
                      [-551.994, -137.498],
                      [-551.272, -137.665],
                      [-550.5, -138],
                      [-550.696, -138.941],
                      [-550.993, -139.898],
                      [-551.382, -140.853],
                      [-551.856, -141.789],
                      [-552.407, -142.689],
                      [-553.028, -143.537],
                      [-553.711, -144.315],
                      [-554.449, -145.007],
                      [-555.234, -145.595],
                      [-556.058, -146.063],
                      [-556.913, -146.393],
                      [-557.793, -146.569],
                      [-558.689, -146.573],
                      [-559.594, -146.389]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 33,
                s: [
                  {
                    i: [
                      [0.127, 0.068],
                      [0.051, -0.107],
                      [0.052, -0.106],
                      [0.052, -0.106],
                      [0.053, -0.105],
                      [0.053, -0.105],
                      [0.053, -0.105],
                      [0.054, -0.104],
                      [0.054, -0.104],
                      [0.054, -0.104],
                      [0.054, -0.104],
                      [0.054, -0.104],
                      [0.054, -0.104],
                      [0.053, -0.105],
                      [0.053, -0.105],
                      [0.053, -0.105],
                      [0.052, -0.106],
                      [0.052, -0.106],
                      [0.051, -0.107],
                      [0.05, -0.108],
                      [-0.223, -0.021],
                      [-0.201, 0.034],
                      [-0.178, 0.082],
                      [-0.155, 0.122],
                      [-0.131, 0.155],
                      [-0.107, 0.18],
                      [-0.083, 0.198],
                      [-0.058, 0.208],
                      [-0.033, 0.211],
                      [-0.007, 0.206],
                      [0.019, 0.193],
                      [0.045, 0.173],
                      [0.072, 0.146],
                      [0.1, 0.11]
                    ],
                    o: [
                      [-0.05, 0.108],
                      [-0.051, 0.107],
                      [-0.052, 0.106],
                      [-0.052, 0.106],
                      [-0.053, 0.105],
                      [-0.053, 0.105],
                      [-0.053, 0.105],
                      [-0.054, 0.104],
                      [-0.054, 0.104],
                      [-0.054, 0.104],
                      [-0.054, 0.104],
                      [-0.054, 0.104],
                      [-0.054, 0.104],
                      [-0.053, 0.105],
                      [-0.053, 0.105],
                      [-0.053, 0.105],
                      [-0.052, 0.106],
                      [-0.052, 0.106],
                      [-0.051, 0.107],
                      [0.245, 0.085],
                      [0.223, 0.021],
                      [0.201, -0.034],
                      [0.178, -0.082],
                      [0.155, -0.122],
                      [0.131, -0.155],
                      [0.107, -0.18],
                      [0.083, -0.198],
                      [0.058, -0.208],
                      [0.033, -0.211],
                      [0.007, -0.206],
                      [-0.019, -0.193],
                      [-0.045, -0.173],
                      [-0.072, -0.146],
                      [-0.1, -0.11]
                    ],
                    v: [
                      [493.5, -147],
                      [493.348, -146.678],
                      [493.194, -146.359],
                      [493.038, -146.041],
                      [492.881, -145.724],
                      [492.722, -145.409],
                      [492.563, -145.095],
                      [492.403, -144.782],
                      [492.242, -144.469],
                      [492.081, -144.156],
                      [491.919, -143.844],
                      [491.758, -143.531],
                      [491.597, -143.218],
                      [491.437, -142.905],
                      [491.278, -142.591],
                      [491.119, -142.276],
                      [490.962, -141.959],
                      [490.806, -141.641],
                      [490.652, -141.322],
                      [490.5, -141],
                      [491.203, -140.843],
                      [491.84, -140.864],
                      [492.408, -141.04],
                      [492.908, -141.348],
                      [493.338, -141.766],
                      [493.696, -142.272],
                      [493.981, -142.841],
                      [494.193, -143.453],
                      [494.329, -144.084],
                      [494.389, -144.711],
                      [494.371, -145.312],
                      [494.275, -145.864],
                      [494.098, -146.345],
                      [493.84, -146.731]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 34,
                s: [
                  {
                    i: [
                      [0, 0.167],
                      [0.249, -0.063],
                      [0.258, -0.088],
                      [0.261, -0.11],
                      [0.259, -0.13],
                      [0.251, -0.148],
                      [0.238, -0.162],
                      [0.219, -0.175],
                      [0.195, -0.184],
                      [0.165, -0.191],
                      [0.129, -0.196],
                      [0.088, -0.198],
                      [0.042, -0.197],
                      [-0.01, -0.194],
                      [-0.068, -0.188],
                      [-0.131, -0.179],
                      [-0.16, 0.143],
                      [-0.166, 0.137],
                      [-0.175, 0.128],
                      [-0.185, 0.118],
                      [-0.196, 0.107],
                      [-0.21, 0.093],
                      [-0.225, 0.078],
                      [-0.243, 0.06],
                      [-0.262, 0.041],
                      [-0.283, 0.02],
                      [-0.305, -0.002],
                      [0, 0.167],
                      [0, 0.167],
                      [0, 0.167],
                      [0, 0.167],
                      [0, 0.167],
                      [0, 0.167],
                      [0, 0.167]
                    ],
                    o: [
                      [-0.234, 0.035],
                      [-0.249, 0.063],
                      [-0.258, 0.088],
                      [-0.261, 0.11],
                      [-0.259, 0.13],
                      [-0.251, 0.148],
                      [-0.238, 0.162],
                      [-0.219, 0.175],
                      [-0.195, 0.184],
                      [-0.165, 0.191],
                      [-0.129, 0.196],
                      [-0.088, 0.198],
                      [-0.042, 0.197],
                      [0.01, 0.194],
                      [0.068, 0.188],
                      [0.155, -0.148],
                      [0.16, -0.143],
                      [0.166, -0.137],
                      [0.175, -0.128],
                      [0.185, -0.118],
                      [0.196, -0.107],
                      [0.21, -0.093],
                      [0.225, -0.078],
                      [0.243, -0.06],
                      [0.262, -0.041],
                      [0.283, -0.02],
                      [0, -0.167],
                      [0, -0.167],
                      [0, -0.167],
                      [0, -0.167],
                      [0, -0.167],
                      [0, -0.167],
                      [0, -0.167],
                      [0, -0.167]
                    ],
                    v: [
                      [-319.5, 13],
                      [-320.226, 13.147],
                      [-320.987, 13.373],
                      [-321.766, 13.671],
                      [-322.548, 14.033],
                      [-323.314, 14.451],
                      [-324.049, 14.917],
                      [-324.736, 15.423],
                      [-325.358, 15.962],
                      [-325.899, 16.526],
                      [-326.341, 17.107],
                      [-326.669, 17.698],
                      [-326.865, 18.29],
                      [-326.914, 18.877],
                      [-326.798, 19.449],
                      [-326.5, 20],
                      [-326.027, 19.564],
                      [-325.538, 19.144],
                      [-325.027, 18.746],
                      [-324.489, 18.375],
                      [-323.918, 18.036],
                      [-323.309, 17.736],
                      [-322.656, 17.48],
                      [-321.955, 17.273],
                      [-321.198, 17.12],
                      [-320.382, 17.027],
                      [-319.5, 17],
                      [-319.5, 16.5],
                      [-319.5, 16],
                      [-319.5, 15.5],
                      [-319.5, 15],
                      [-319.5, 14.5],
                      [-319.5, 14],
                      [-319.5, 13.5]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 35,
                s: [
                  {
                    i: [
                      [-0.016, 0.117],
                      [0.115, 0.01],
                      [0.111, 0.006],
                      [0.106, 0.001],
                      [0.101, -0.004],
                      [0.096, -0.009],
                      [0.091, -0.014],
                      [0.086, -0.019],
                      [0.08, -0.025],
                      [0.075, -0.03],
                      [0.069, -0.036],
                      [0.063, -0.042],
                      [0.058, -0.048],
                      [0.052, -0.054],
                      [0.045, -0.06],
                      [0.039, -0.066],
                      [0.033, -0.073],
                      [0.026, -0.079],
                      [0.019, -0.086],
                      [0.013, -0.093],
                      [-0.145, -0.011],
                      [-0.137, -0.004],
                      [-0.129, 0.004],
                      [-0.121, 0.012],
                      [-0.113, 0.02],
                      [-0.105, 0.029],
                      [-0.096, 0.038],
                      [-0.087, 0.047],
                      [-0.077, 0.056],
                      [-0.068, 0.065],
                      [-0.058, 0.075],
                      [-0.048, 0.085],
                      [-0.038, 0.096],
                      [-0.027, 0.106]
                    ],
                    o: [
                      [-0.12, -0.015],
                      [-0.115, -0.01],
                      [-0.111, -0.006],
                      [-0.106, -0.001],
                      [-0.101, 0.004],
                      [-0.096, 0.009],
                      [-0.091, 0.014],
                      [-0.086, 0.019],
                      [-0.08, 0.025],
                      [-0.075, 0.03],
                      [-0.069, 0.036],
                      [-0.063, 0.042],
                      [-0.058, 0.048],
                      [-0.052, 0.054],
                      [-0.045, 0.06],
                      [-0.039, 0.066],
                      [-0.033, 0.073],
                      [-0.026, 0.079],
                      [-0.019, 0.086],
                      [0.152, 0.019],
                      [0.145, 0.011],
                      [0.137, 0.004],
                      [0.129, -0.004],
                      [0.121, -0.012],
                      [0.113, -0.02],
                      [0.105, -0.029],
                      [0.096, -0.038],
                      [0.087, -0.047],
                      [0.077, -0.056],
                      [0.068, -0.065],
                      [0.058, -0.075],
                      [0.048, -0.085],
                      [0.038, -0.096],
                      [0.027, -0.106]
                    ],
                    v: [
                      [-321.5, 14],
                      [-321.853, 13.963],
                      [-322.193, 13.939],
                      [-322.518, 13.929],
                      [-322.829, 13.934],
                      [-323.125, 13.954],
                      [-323.406, 13.989],
                      [-323.672, 14.039],
                      [-323.921, 14.105],
                      [-324.154, 14.188],
                      [-324.37, 14.288],
                      [-324.569, 14.404],
                      [-324.751, 14.538],
                      [-324.915, 14.69],
                      [-325.06, 14.861],
                      [-325.187, 15.05],
                      [-325.295, 15.258],
                      [-325.383, 15.485],
                      [-325.452, 15.732],
                      [-325.5, 16],
                      [-325.055, 16.045],
                      [-324.632, 16.068],
                      [-324.232, 16.068],
                      [-323.855, 16.044],
                      [-323.503, 15.996],
                      [-323.176, 15.923],
                      [-322.876, 15.824],
                      [-322.602, 15.698],
                      [-322.355, 15.544],
                      [-322.137, 15.362],
                      [-321.949, 15.151],
                      [-321.79, 14.91],
                      [-321.661, 14.639],
                      [-321.565, 14.335]
                    ],
                    c: true
                  }
                ],
                h: 1
              }
            ],
            ix: 1
          },
          o: {
            a: 1,
            k: [
              { t: 24, s: [0], h: 1 },
              { t: 25, s: [100], h: 1 },
              { t: 36, s: [0], h: 1 }
            ],
            ix: 3
          },
          x: { a: 0, k: 0, ix: 4 },
          nm: 'Mask 6'
        },
        {
          inv: false,
          mode: 'f',
          pt: {
            a: 1,
            k: [
              {
                t: 26,
                s: [
                  {
                    i: [
                      [-8.73, 1.396],
                      [3.792, 10.06],
                      [5.357, 8.011],
                      [29.715, 3.497],
                      [20.382, -19.346],
                      [0.394, -10.909],
                      [-4.565, 2.85],
                      [-20.974, 1.954],
                      [-25.694, -9.081],
                      [-10.999, -18.334]
                    ],
                    o: [
                      [2.458, -9.784],
                      [-3.976, -10.549],
                      [-17.033, -14.545],
                      [-43.481, -5.116],
                      [-7.856, 7.457],
                      [6.07, 2.514],
                      [15.32, -9.565],
                      [33.547, -3.126],
                      [14.973, 14.36],
                      [2.95, 4.383]
                    ],
                    v: [
                      [211.5, 90],
                      [203.5, 59],
                      [189.5, 30],
                      [119.5, -12],
                      [23.5, 13],
                      [5.5, 42],
                      [21.5, 36],
                      [74.5, 14],
                      [157.5, 34],
                      [201.5, 78]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 27,
                s: [
                  {
                    i: [
                      [-6.041, -1.052],
                      [28.443, 10.698],
                      [28.313, -16.582],
                      [5.9, -4.841],
                      [1.288, -6.899],
                      [-18.356, 3.17],
                      [-20.46, -3.071],
                      [-6.153, -2.917],
                      [-5.516, -3.872],
                      [-5.117, -4.274]
                    ],
                    o: [
                      [-3.915, -27.79],
                      [-28.443, -10.698],
                      [-5.114, 4.736],
                      [-5.9, 4.841],
                      [13.117, -7.392],
                      [18.356, -3.17],
                      [6.872, 2.46],
                      [6.153, 2.917],
                      [5.489, 3.853],
                      [5.117, 4.274]
                    ],
                    v: [
                      [195.5, 44],
                      [140.799, -16.279],
                      [49.5, -10],
                      [31.631, 3.877],
                      [19.5, 21],
                      [67.493, 4.654],
                      [126.5, 4],
                      [146.017, 11.941],
                      [163.5, 22],
                      [179.086, 35.1]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 28,
                s: [
                  {
                    i: [
                      [-22.07, 4.645],
                      [1.875, 1.652],
                      [-2.618, 3.136],
                      [1, 0],
                      [1, 0],
                      [7.773, 5.291],
                      [10.101, 1.088],
                      [14.136, -7.065],
                      [1.705, -9.103],
                      [-30.567, -6.4]
                    ],
                    o: [
                      [0.882, -4.256],
                      [-1.875, -1.652],
                      [-1, 0],
                      [-1, 0],
                      [-5.807, -4.748],
                      [-7.773, -5.291],
                      [-18.345, -1.976],
                      [-9.843, 4.919],
                      [24.679, -6.675],
                      [21.993, 4.605]
                    ],
                    v: [
                      [175.5, 11],
                      [172.198, 3.16],
                      [171.5, -3],
                      [168.5, -3],
                      [165.5, -3],
                      [145.221, -19.245],
                      [118.5, -30],
                      [64.5, -21],
                      [40.5, 1],
                      [114.5, -12]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 29,
                s: [
                  {
                    i: [
                      [-10.859, 5.393],
                      [2.167, 0],
                      [2.167, 0],
                      [7.34, -6.409],
                      [5.014, -7.819],
                      [3.298, 1.281],
                      [3.467, -1.457],
                      [-5.48, -4.186],
                      [-4.616, -5.05],
                      [-11.905, 8.333]
                    ],
                    o: [
                      [-2.167, 0],
                      [-2.167, 0],
                      [-11.624, -0.456],
                      [-7.34, 6.409],
                      [-3.339, -0.335],
                      [-3.298, -1.281],
                      [5.463, 4.204],
                      [5.48, 4.186],
                      [-0.139, -22.238],
                      [9.459, -6.621]
                    ],
                    v: [
                      [-275.5, 17],
                      [-282, 17],
                      [-288.5, 17],
                      [-316.457, 27.293],
                      [-334.5, 50],
                      [-344.404, 46.656],
                      [-354.5, 46],
                      [-337.865, 58.365],
                      [-322.5, 72],
                      [-305.5, 27]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 30,
                s: [
                  {
                    i: [
                      [-7.879, 1.515],
                      [2.171, 2.163],
                      [2.669, 1.664],
                      [2.914, 1.419],
                      [2.905, 1.428],
                      [8.078, -2.009],
                      [1.679, -7.926],
                      [-5.334, 2.045],
                      [-7.657, -1.786],
                      [-6.178, -2.725]
                    ],
                    o: [
                      [-1.418, -2.915],
                      [-2.171, -2.163],
                      [-2.669, -1.664],
                      [-2.914, -1.419],
                      [-9.011, -0.963],
                      [-8.078, 2.009],
                      [5.008, 2.392],
                      [5.334, -2.045],
                      [6.409, 1.614],
                      [6.178, 2.725]
                    ],
                    v: [
                      [146.5, -22],
                      [141.053, -29.553],
                      [133.73, -35.23],
                      [125.292, -39.792],
                      [116.5, -44],
                      [89.502, -43.167],
                      [73.5, -29],
                      [88.513, -30.545],
                      [107.5, -33],
                      [125.897, -25.153]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 31,
                s: [
                  {
                    i: [
                      [1.126, -0.617],
                      [-0.727, -0.394],
                      [-0.814, -0.407],
                      [-0.874, -0.318],
                      [-0.907, -0.129],
                      [-0.913, 0.162],
                      [-0.892, 0.553],
                      [1.22, 0.559],
                      [1.372, 0.416],
                      [1.341, 0.024]
                    ],
                    o: [
                      [0.613, 0.28],
                      [0.727, 0.394],
                      [0.814, 0.407],
                      [0.874, 0.318],
                      [0.907, 0.129],
                      [0.913, -0.162],
                      [-0.885, -0.454],
                      [-1.22, -0.559],
                      [-1.372, -0.416],
                      [-1.341, -0.024]
                    ],
                    v: [
                      [-356.5, 41],
                      [-354.484, 42.037],
                      [-352.167, 43.263],
                      [-349.629, 44.376],
                      [-346.951, 45.072],
                      [-344.215, 45.048],
                      [-341.5, 44],
                      [-344.704, 42.417],
                      [-348.639, 40.892],
                      [-352.754, 40.171]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 32,
                s: [
                  {
                    i: [
                      [-2.517, 0.175],
                      [1.784, 1.121],
                      [2.26, 0.798],
                      [2.442, 0.352],
                      [2.329, -0.218],
                      [1.921, -0.911],
                      [1.219, -1.729],
                      [-3.138, -0.244],
                      [-3.138, -0.541],
                      [-2.932, -0.402]
                    ],
                    o: [
                      [-1.013, -1.319],
                      [-1.784, -1.121],
                      [-2.26, -0.798],
                      [-2.442, -0.352],
                      [-2.329, 0.218],
                      [-1.921, 0.911],
                      [2.93, -0.49],
                      [3.138, 0.244],
                      [3.138, 0.541],
                      [2.932, 0.402]
                    ],
                    v: [
                      [126.5, -38],
                      [122.231, -41.691],
                      [116.091, -44.601],
                      [108.964, -46.358],
                      [101.734, -46.591],
                      [95.285, -44.929],
                      [90.5, -41],
                      [99.652, -41.26],
                      [109.118, -39.973],
                      [118.275, -38.449]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 33,
                s: [
                  {
                    i: [
                      [-2.021, 0.187],
                      [0.972, 0.623],
                      [1.426, 0.367],
                      [1.595, 0.089],
                      [1.478, -0.212],
                      [1.076, -0.534],
                      [0.388, -0.879],
                      [-1.69, -0.143],
                      [-1.65, -0.184],
                      [-1.76, -0.074]
                    ],
                    o: [
                      [-0.232, -0.857],
                      [-0.972, -0.623],
                      [-1.426, -0.367],
                      [-1.595, -0.089],
                      [-1.478, 0.212],
                      [-1.076, 0.534],
                      [1.881, -0.048],
                      [1.69, 0.143],
                      [1.65, 0.184],
                      [1.76, 0.074]
                    ],
                    v: [
                      [118.5, -46],
                      [116.622, -48.226],
                      [112.953, -49.716],
                      [108.35, -50.406],
                      [103.668, -50.227],
                      [99.766, -49.114],
                      [97.5, -47],
                      [102.819, -46.819],
                      [107.79, -46.291],
                      [112.867, -45.867]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 34,
                s: [
                  {
                    i: [
                      [-1.676, 1.574],
                      [1.978, -0.182],
                      [1.926, -0.709],
                      [1.614, -1.12],
                      [1.04, -1.413],
                      [0.206, -1.59],
                      [-0.889, -1.65],
                      [-1.663, 1.587],
                      [-2.165, 1.085],
                      [-2.17, 1.08]
                    ],
                    o: [
                      [-1.769, -0.462],
                      [-1.978, 0.182],
                      [-1.926, 0.709],
                      [-1.614, 1.12],
                      [-1.04, 1.413],
                      [-0.206, 1.59],
                      [0.663, -2.587],
                      [1.663, -1.587],
                      [2.165, -1.085],
                      [2.17, -1.08]
                    ],
                    v: [
                      [-725.5, 40],
                      [-731.185, 39.609],
                      [-737.105, 40.975],
                      [-742.48, 43.748],
                      [-746.526, 47.576],
                      [-748.46, 52.11],
                      [-747.5, 57],
                      [-743.887, 50.863],
                      [-738.02, 46.98],
                      [-731.393, 43.857]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 35,
                s: [
                  {
                    i: [
                      [-0.198, -0.971],
                      [-0.137, -0.03],
                      [-0.098, -0.068],
                      [-0.037, -0.129],
                      [0.046, -0.213],
                      [-0.167, 0],
                      [-0.167, 0],
                      [-0.167, 0],
                      [-0.167, 0],
                      [0.994, 0.185]
                    ],
                    o: [
                      [0.153, 0.014],
                      [0.137, 0.03],
                      [0.098, 0.068],
                      [0.037, 0.129],
                      [0.167, 0],
                      [0.167, 0],
                      [0.167, 0],
                      [0.167, 0],
                      [0.778, -1.23],
                      [-0.994, -0.185]
                    ],
                    v: [
                      [496.5, 42],
                      [496.939, 42.061],
                      [497.298, 42.202],
                      [497.507, 42.493],
                      [497.5, 43],
                      [498, 43],
                      [498.5, 43],
                      [499, 43],
                      [499.5, 43],
                      [498.435, 40.849]
                    ],
                    c: true
                  }
                ],
                h: 1
              }
            ],
            ix: 1
          },
          o: {
            a: 1,
            k: [
              { t: 25, s: [0], h: 1 },
              { t: 26, s: [100], h: 1 },
              { t: 36, s: [0], h: 1 }
            ],
            ix: 3
          },
          x: { a: 0, k: 0, ix: 4 },
          nm: 'Mask 7'
        },
        {
          inv: false,
          mode: 'f',
          pt: {
            a: 1,
            k: [
              {
                t: 26,
                s: [
                  {
                    i: [
                      [0.829, 6.862],
                      [2.22, -0.697],
                      [1.784, -1.133],
                      [1.422, -1.495],
                      [1.135, -1.782],
                      [-1.49, -3.26],
                      [-1.468, -3.282],
                      [-1.993, -2.757],
                      [-3.067, -1.683],
                      [0, 8.569]
                    ],
                    o: [
                      [-2.73, 0.186],
                      [-2.22, 0.697],
                      [-1.784, 1.133],
                      [-1.422, 1.495],
                      [2.062, 2.689],
                      [1.49, 3.26],
                      [1.468, 3.282],
                      [1.993, 2.757],
                      [0.829, -6.862],
                      [0, -8.569]
                    ],
                    v: [
                      [-35.5, 69],
                      [-42.907, 70.343],
                      [-48.894, 73.106],
                      [-53.684, 77.066],
                      [-57.5, 82],
                      [-52.309, 91.059],
                      [-48.009, 101.009],
                      [-42.954, 110.204],
                      [-35.5, 117],
                      [-34.257, 93]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 27,
                s: [
                  {
                    i: [
                      [-48.339, 0.934],
                      [7.237, -0.156],
                      [4.699, -0.195],
                      [11.484, -2.516],
                      [-0.918, -26.585],
                      [16.466, 5.867],
                      [-7.703, -7.964],
                      [-8.018, -7.649],
                      [-19.253, -12.414],
                      [8.371, 12.296]
                    ],
                    o: [
                      [-2.351, -3.818],
                      [-7.237, 0.156],
                      [-13.136, 0.864],
                      [-12.641, 13.026],
                      [-14.804, -7.529],
                      [4.337, 11.33],
                      [8.504, 7.163],
                      [17.084, 14.583],
                      [-8.645, -12.022],
                      [-16.918, -50.026]
                    ],
                    v: [
                      [-215.5, 26],
                      [-231.739, 21.49],
                      [-251.5, 23],
                      [-286.5, 30],
                      [-314.5, 79],
                      [-357.5, 55],
                      [-333.5, 78],
                      [-308.5, 100],
                      [-257.5, 144],
                      [-285.5, 110]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 28,
                s: [
                  {
                    i: [
                      [-9.142, 5.962],
                      [20.389, 1.611],
                      [8.524, -12.476],
                      [4.144, -5.189],
                      [11.104, -4.941],
                      [-9.49, -6.177],
                      [-18.902, -5.764],
                      [1.503, 14.563],
                      [-2.072, 8.27],
                      [-20.14, 1.338]
                    ],
                    o: [
                      [-9.233, -12.767],
                      [-16.086, 4.914],
                      [-2.355, 6.978],
                      [-9.026, -3.668],
                      [5.369, 10.297],
                      [13.336, 11.331],
                      [0.199, -11.467],
                      [-0.778, -7.54],
                      [10.898, -10.892],
                      [10.681, -0.709]
                    ],
                    v: [
                      [-222.5, 23],
                      [-280.5, 15],
                      [-317.5, 41],
                      [-323.5, 63],
                      [-354.5, 53],
                      [-328.5, 74],
                      [-288.5, 108],
                      [-301.5, 71],
                      [-298.5, 48],
                      [-253.5, 22]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 29,
                s: [
                  {
                    i: [
                      [-5.031, -4.969],
                      [3.029, 7.43],
                      [5.45, 5.002],
                      [7.82, 1.821],
                      [10.142, -2.114],
                      [-3.451, -1.466],
                      [-3.628, -1.289],
                      [-3.238, -1.679],
                      [-2.281, -2.635],
                      [-3.541, -6.459]
                    ],
                    o: [
                      [-0.56, -9.104],
                      [-3.029, -7.43],
                      [-5.45, -5.002],
                      [-7.82, -1.821],
                      [2.707, 2.209],
                      [3.451, 1.466],
                      [3.628, 1.289],
                      [3.238, 1.679],
                      [6.805, 3.195],
                      [3.541, 6.459]
                    ],
                    v: [
                      [512.5, 97],
                      [507.104, 72.01],
                      [494.373, 53.173],
                      [474.455, 42.749],
                      [447.5, 43],
                      [456.878, 48.371],
                      [467.638, 52.362],
                      [478.079, 56.671],
                      [486.5, 63],
                      [500.83, 78.669]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 30,
                s: [
                  {
                    i: [
                      [-0.955, 4.878],
                      [5.679, -1.018],
                      [4.533, -2.589],
                      [2.837, -4.276],
                      [0.591, -6.079],
                      [-2.352, 1.288],
                      [-1.732, 2.705],
                      [-1.764, 2.689],
                      [-2.448, 1.241],
                      [-4.901, 0.933]
                    ],
                    o: [
                      [-6.276, -0.437],
                      [-5.679, 1.018],
                      [-4.533, 2.589],
                      [-2.837, 4.276],
                      [3.624, 1.56],
                      [2.352, -1.288],
                      [1.732, -2.705],
                      [1.764, -2.689],
                      [3.442, -2.39],
                      [4.901, -0.933]
                    ],
                    v: [
                      [-286.5, 16],
                      [-304.571, 16.843],
                      [-320.027, 22.226],
                      [-331.22, 32.495],
                      [-336.5, 48],
                      [-327.699, 48.05],
                      [-321.736, 41.703],
                      [-316.656, 33.254],
                      [-310.5, 27],
                      [-296.634, 23.366]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 31,
                s: [
                  {
                    i: [
                      [4.587, -0.753],
                      [-2.449, -0.894],
                      [-2.053, -1.389],
                      [-1.772, -1.642],
                      [-1.606, -1.652],
                      [-1.555, -1.419],
                      [-1.619, -0.944],
                      [1.994, 3.12],
                      [3.033, 2.092],
                      [3.897, 0.801]
                    ],
                    o: [
                      [2.961, 0.156],
                      [2.449, 0.894],
                      [2.053, 1.389],
                      [1.772, 1.642],
                      [1.606, 1.652],
                      [1.555, 1.419],
                      [-0.781, -3.886],
                      [-1.994, -3.12],
                      [-3.033, -2.092],
                      [-3.897, -0.801]
                    ],
                    v: [
                      [471.5, 41],
                      [479.586, 42.635],
                      [486.311, 46.12],
                      [492.018, 50.727],
                      [497.055, 55.728],
                      [501.767, 60.395],
                      [506.5, 64],
                      [502.294, 53.424],
                      [494.71, 45.541],
                      [484.271, 41.137]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 32,
                s: [
                  {
                    i: [
                      [-1.803, 1.447],
                      [1.862, -0.305],
                      [1.512, -0.655],
                      [1.208, -0.958],
                      [0.951, -1.216],
                      [0.74, -1.427],
                      [0.575, -1.591],
                      [-1.902, 1.348],
                      [-2.012, 1.238],
                      [-1.979, 1.271]
                    ],
                    o: [
                      [-2.259, -0.092],
                      [-1.862, 0.305],
                      [-1.512, 0.655],
                      [-1.208, 0.958],
                      [-0.951, 1.216],
                      [-0.74, 1.427],
                      [1.65, -1.6],
                      [1.902, -1.348],
                      [2.012, -1.238],
                      [1.979, -1.271]
                    ],
                    v: [
                      [-307.5, 14],
                      [-313.67, 14.33],
                      [-318.72, 15.781],
                      [-322.788, 18.212],
                      [-326.015, 21.485],
                      [-328.539, 25.461],
                      [-330.5, 30],
                      [-325.136, 25.614],
                      [-319.23, 21.771],
                      [-313.208, 18.042]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 33,
                s: [
                  {
                    i: [
                      [-1.149, 1.018],
                      [1.269, -0.053],
                      [1.239, -0.456],
                      [1.059, -0.761],
                      [0.728, -0.965],
                      [0.247, -1.07],
                      [-0.385, -1.075],
                      [-1.201, 0.965],
                      [-1.382, 0.784],
                      [-1.365, 0.802]
                    ],
                    o: [
                      [-1.149, -0.451],
                      [-1.269, 0.053],
                      [-1.239, 0.456],
                      [-1.059, 0.761],
                      [-0.728, 0.965],
                      [-0.247, 1.07],
                      [0.822, -1.345],
                      [1.201, -0.965],
                      [1.382, -0.784],
                      [1.365, -0.802]
                    ],
                    v: [
                      [-313.5, 14],
                      [-317.165, 13.427],
                      [-320.965, 14.216],
                      [-324.45, 16.066],
                      [-327.169, 18.679],
                      [-328.669, 21.757],
                      [-328.5, 25],
                      [-325.415, 21.585],
                      [-321.49, 19.01],
                      [-317.32, 16.68]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 34,
                s: [
                  {
                    i: [
                      [-0.745, -0.005],
                      [0.166, 0.463],
                      [0.317, 0.343],
                      [0.415, 0.2],
                      [0.458, 0.035],
                      [0.446, -0.152],
                      [0.381, -0.361],
                      [-0.325, -0.425],
                      [-0.57, -0.18],
                      [-0.709, -0.041]
                    ],
                    o: [
                      [0.04, -0.56],
                      [-0.166, -0.463],
                      [-0.317, -0.343],
                      [-0.415, -0.2],
                      [-0.458, -0.035],
                      [-0.446, 0.152],
                      [-0.023, 0.773],
                      [0.325, 0.425],
                      [0.57, 0.18],
                      [0.709, 0.041]
                    ],
                    v: [
                      [501.5, 44],
                      [501.297, 42.46],
                      [500.559, 41.246],
                      [499.447, 40.426],
                      [498.125, 40.067],
                      [496.755, 40.236],
                      [495.5, 41],
                      [495.979, 42.771],
                      [497.348, 43.652],
                      [499.292, 43.958]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 35,
                s: [
                  {
                    i: [
                      [-0.817, 0.767],
                      [1.081, -0.096],
                      [0.902, -0.374],
                      [0.679, -0.569],
                      [0.41, -0.681],
                      [0.096, -0.71],
                      [-0.262, -0.656],
                      [-0.878, 0.705],
                      [-1.035, 0.549],
                      [-1.014, 0.569]
                    ],
                    o: [
                      [-1.215, -0.266],
                      [-1.081, 0.096],
                      [-0.902, 0.374],
                      [-0.679, 0.569],
                      [-0.41, 0.681],
                      [-0.096, 0.71],
                      [0.544, -1.039],
                      [0.878, -0.705],
                      [1.035, -0.549],
                      [1.014, -0.569]
                    ],
                    v: [
                      [-736.5, 42],
                      [-739.954, 41.765],
                      [-742.94, 42.49],
                      [-745.322, 43.925],
                      [-746.966, 45.822],
                      [-747.737, 47.93],
                      [-747.5, 50],
                      [-745.322, 47.428],
                      [-742.409, 45.591],
                      [-739.291, 43.959]
                    ],
                    c: true
                  }
                ],
                h: 1
              }
            ],
            ix: 1
          },
          o: {
            a: 1,
            k: [
              { t: 25, s: [0], h: 1 },
              { t: 26, s: [100], h: 1 },
              { t: 36, s: [0], h: 1 }
            ],
            ix: 3
          },
          x: { a: 0, k: 0, ix: 4 },
          nm: 'Mask 8'
        },
        {
          inv: false,
          mode: 'f',
          pt: {
            a: 1,
            k: [
              {
                t: 26,
                s: [
                  {
                    i: [
                      [-10.82, 13.18],
                      [17.903, -6.01],
                      [13.508, -5.979],
                      [14.71, -14.13],
                      [1.554, -7.46],
                      [-35.64, 1.36],
                      [-13.839, 10.161]
                    ],
                    o: [
                      [-19.158, -0.396],
                      [-16.395, 5.504],
                      [-20.754, 9.186],
                      [-5.398, 5.186],
                      [31.34, -5.66],
                      [14.538, -9.462],
                      [10.529, -13.471]
                    ],
                    v: [
                      [817.5, 151],
                      [762.5, 165],
                      [715.5, 180],
                      [666.5, 224],
                      [651.5, 240],
                      [736.5, 214],
                      [776.5, 182]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 27,
                s: [
                  {
                    i: [
                      [1.35, 5.316],
                      [1.492, -1.619],
                      [1.339, -1.772],
                      [0.856, -2.255],
                      [-2.347, -3.653],
                      [-4.168, -1.833],
                      [0.68, 5.987]
                    ],
                    o: [
                      [-1.315, 1.796],
                      [-1.492, 1.619],
                      [-1.339, 1.772],
                      [4.444, 1.555],
                      [2.347, 3.653],
                      [-1.93, -4.737],
                      [-0.68, -5.987]
                    ],
                    v: [
                      [-50.5, 47],
                      [-54.794, 52.039],
                      [-59.124, 57.042],
                      [-62.5, 63],
                      [-53.292, 71.792],
                      [-44.5, 81],
                      [-47.935, 64.435]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 28,
                s: [
                  {
                    i: [
                      [0.608, 2.41],
                      [1.274, -0.282],
                      [1.064, -0.492],
                      [1.067, -0.488],
                      [-1.61, -3.057],
                      [-2.939, -1.727],
                      [0.049, 4.241]
                    ],
                    o: [
                      [-1.698, -0.142],
                      [-1.274, 0.282],
                      [-1.064, 0.492],
                      [0.622, 4.044],
                      [1.61, 3.057],
                      [1.805, -1.626],
                      [-0.049, -4.241]
                    ],
                    v: [
                      [-53.5, 46],
                      [-57.904, 46.263],
                      [-61.357, 47.477],
                      [-64.5, 49],
                      [-61.238, 59.738],
                      [-54.5, 67],
                      [-52.19, 57.088]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 29,
                s: [
                  {
                    i: [
                      [1.633, 0.296],
                      [0.612, -0.499],
                      [0.523, -0.588],
                      [-0.059, -1.17],
                      [-0.848, -0.818],
                      [-1.548, -0.118],
                      [0.175, 2.252]
                    ],
                    o: [
                      [-0.207, 0.904],
                      [-0.612, 0.499],
                      [-0.523, 0.588],
                      [1.058, 0.608],
                      [0.849, 0.818],
                      [0.331, -0.697],
                      [-0.175, -2.252]
                    ],
                    v: [
                      [-59.5, 44],
                      [-60.853, 45.98],
                      [-62.68, 47.486],
                      [-63.5, 50],
                      [-60.868, 52.367],
                      [-57.5, 54],
                      [-57.027, 48.7]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 30,
                s: [
                  {
                    i: [
                      [-0.454, 3.787],
                      [0.657, -0.232],
                      [0.539, -0.35],
                      [0.697, -0.192],
                      [-2.445, -2.088],
                      [-2.779, 0.663],
                      [2.176, 1.157]
                    ],
                    o: [
                      [-1.049, -0.16],
                      [-0.657, 0.232],
                      [-0.539, 0.35],
                      [1.442, 2.31],
                      [2.445, 2.088],
                      [0.25, -3.584],
                      [-2.176, -1.157]
                    ],
                    v: [
                      [-346.5, 41],
                      [-348.989, 41.178],
                      [-350.714, 42.119],
                      [-352.5, 43],
                      [-346.503, 50.229],
                      [-338.5, 53],
                      [-342.653, 47.153]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 31,
                s: [
                  {
                    i: [
                      [-6.083, 6.25],
                      [9.638, -2.572],
                      [7.196, -4.947],
                      [5.867, -7.042],
                      [1.756, -9.173],
                      [-13.9, 9.102],
                      [-7.896, 4.437]
                    ],
                    o: [
                      [-12.254, -2.236],
                      [-9.638, 2.572],
                      [-6.776, 4.659],
                      [-5.867, 7.042],
                      [10.352, -9.317],
                      [8.366, -3.967],
                      [8.32, -4.014]
                    ],
                    v: [
                      [-680.5, 45],
                      [-713.293, 46.112],
                      [-738.5, 58],
                      [-758.265, 75.614],
                      [-770.5, 100],
                      [-733.5, 66],
                      [-709.5, 53]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 32,
                s: [
                  {
                    i: [
                      [-0.124, 1.114],
                      [0.835, 0.498],
                      [0.88, 0.268],
                      [0.605, -0.364],
                      [0.01, -1.399],
                      [-1.144, -0.371],
                      [-1.109, 0.25]
                    ],
                    o: [
                      [-0.47, -0.327],
                      [-0.835, -0.498],
                      [-0.88, -0.268],
                      [-0.605, 0.364],
                      [0.228, 0.749],
                      [1.144, 0.371],
                      [1.109, -0.25]
                    ],
                    v: [
                      [-347.5, 40],
                      [-349.537, 38.662],
                      [-352.189, 37.412],
                      [-354.497, 37.456],
                      [-355.5, 40],
                      [-353.204, 41.742],
                      [-349.587, 41.985]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 33,
                s: [
                  {
                    i: [
                      [0.355, -0.757],
                      [-0.936, -0.246],
                      [-1.08, -0.058],
                      [-0.729, 0.343],
                      [0.117, 0.954],
                      [1.215, 0.104],
                      [1.032, -0.079]
                    ],
                    o: [
                      [0.297, 0.224],
                      [0.936, 0.246],
                      [1.08, 0.058],
                      [0.729, -0.343],
                      [-0.905, 0.206],
                      [-1.215, -0.104],
                      [-1.032, 0.079]
                    ],
                    v: [
                      [-357.5, 38],
                      [-355.527, 38.758],
                      [-352.379, 39.267],
                      [-349.542, 38.892],
                      [-348.5, 37],
                      [-351.803, 37.03],
                      [-355.297, 36.869]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 34,
                s: [
                  {
                    i: [
                      [1.548, 0.74],
                      [-0.393, -1.298],
                      [-0.869, -1.18],
                      [-1.167, -0.917],
                      [-1.284, -0.508],
                      [0.817, 1.679],
                      [1.46, 1.399]
                    ],
                    o: [
                      [-0.262, 1.272],
                      [0.393, 1.298],
                      [0.869, 1.18],
                      [1.167, 0.917],
                      [0.38, -1.581],
                      [-0.817, -1.679],
                      [-1.46, -1.399]
                    ],
                    v: [
                      [882.5, 189],
                      [882.741, 192.891],
                      [884.68, 196.645],
                      [887.779, 199.826],
                      [891.5, 202],
                      [890.705, 197.015],
                      [887.151, 192.303]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 35,
                s: [
                  {
                    i: [
                      [1.348, -0.78],
                      [-0.867, -0.55],
                      [-0.732, -0.685],
                      [-0.75, -0.667],
                      [-0.919, -0.498],
                      [1.032, 1.297],
                      [1.34, 0.619]
                    ],
                    o: [
                      [1.153, 0.264],
                      [0.867, 0.55],
                      [0.732, 0.685],
                      [0.75, 0.667],
                      [-0.423, -1.253],
                      [-1.032, -1.297],
                      [-1.34, -0.619]
                    ],
                    v: [
                      [883.5, 191],
                      [886.491, 192.259],
                      [888.851, 194.149],
                      [891.036, 196.214],
                      [893.5, 198],
                      [891.242, 193.994],
                      [887.608, 190.939]
                    ],
                    c: true
                  }
                ],
                h: 1
              }
            ],
            ix: 1
          },
          o: {
            a: 1,
            k: [
              { t: 25, s: [0], h: 1 },
              { t: 26, s: [100], h: 1 },
              { t: 36, s: [0], h: 1 }
            ],
            ix: 3
          },
          x: { a: 0, k: 0, ix: 4 },
          nm: 'Mask 9'
        },
        {
          inv: false,
          mode: 'f',
          pt: {
            a: 1,
            k: [
              {
                t: 26,
                s: [
                  {
                    i: [
                      [8.377, -8.016],
                      [-26.648, 27.824],
                      [-4.212, 43.527],
                      [89.88, -16.117],
                      [7.71, -4.652],
                      [3.477, -8.361],
                      [-10.151, 2.353],
                      [-13.263, -40.854],
                      [22.795, -32.028],
                      [27.563, -10.301]
                    ],
                    o: [
                      [57.634, 11.036],
                      [17.341, -30.076],
                      [7.978, -82.456],
                      [-8.019, 3.764],
                      [-7.181, 4.333],
                      [10.936, 6.966],
                      [56.77, -13.158],
                      [3.53, 56.137],
                      [-16.378, 23.011],
                      [-11.577, 4.327]
                    ],
                    v: [
                      [721.5, 496],
                      [849.5, 449],
                      [902.5, 351],
                      [761.5, 210],
                      [738.5, 224],
                      [717.5, 240],
                      [748.5, 234],
                      [864.5, 301],
                      [821.5, 427],
                      [754.5, 486]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 27,
                s: [
                  {
                    i: [
                      [-6.455, -1.878],
                      [8.988, 5.439],
                      [8.838, 4.938],
                      [44.837, -51.26],
                      [-3.109, -50.04],
                      [-11.66, -8.754],
                      [-12.938, 28.912],
                      [-18.979, 21.362],
                      [-48.776, 3.27],
                      [-29.465, -3.982]
                    ],
                    o: [
                      [-1.938, -9.575],
                      [-9.223, -5.581],
                      [-86.683, -22.11],
                      [-30.77, 35.178],
                      [1.17, 18.829],
                      [6.49, -37.674],
                      [12.955, -28.949],
                      [30.892, -20.053],
                      [30.591, -2.051],
                      [6.576, 1.758]
                    ],
                    v: [
                      [-486.5, 104],
                      [-508.5, 84],
                      [-537.5, 69],
                      [-747.5, 128],
                      [-798.5, 264],
                      [-782.5, 307],
                      [-763.5, 204],
                      [-713.5, 132],
                      [-590.5, 85],
                      [-502.5, 95]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 28,
                s: [
                  {
                    i: [
                      [-9.801, -5.532],
                      [2.015, 5.166],
                      [2.158, 4.362],
                      [2.64, 4.782],
                      [2.918, 3.769],
                      [36.217, -2.216],
                      [0.81, -0.19],
                      [0, -1],
                      [-18.301, -11.699],
                      [-7.565, -7.435]
                    ],
                    o: [
                      [3.216, -4.971],
                      [-2.015, -5.167],
                      [-2.523, -5.101],
                      [-2.64, -4.782],
                      [-18.799, -15.201],
                      [-1.003, -0.003],
                      [0, 1],
                      [18.533, 11.468],
                      [9.121, 5.879],
                      [7.216, 8.117]
                    ],
                    v: [
                      [524.5, 127],
                      [525.031, 111.543],
                      [517.5, 97],
                      [509.797, 82.001],
                      [501.5, 69],
                      [422.5, 46],
                      [420.5, 47],
                      [420.5, 50],
                      [482.5, 78],
                      [507.5, 98]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 29,
                s: [
                  {
                    i: [
                      [-2.006, 2.63],
                      [22.432, -4.328],
                      [16.71, -10.914],
                      [10.048, -17.129],
                      [2.445, -22.973],
                      [-12.081, 14.586],
                      [-17.036, 9.63],
                      [-16.987, 6.767],
                      [-19.555, 2.633],
                      [-3.097, 0.255]
                    ],
                    o: [
                      [-27.215, -2.63],
                      [-22.432, 4.328],
                      [-16.71, 10.914],
                      [-10.048, 17.129],
                      [8.64, -18.026],
                      [12.081, -14.586],
                      [14.496, -8.299],
                      [16.987, -6.767],
                      [2.604, -0.35],
                      [3.097, -0.255]
                    ],
                    v: [
                      [-597.5, 48],
                      [-672.205, 50.641],
                      [-731.154, 73.597],
                      [-771.526, 115.754],
                      [-790.5, 176],
                      [-759.797, 126.703],
                      [-716.5, 90],
                      [-669.294, 66.75],
                      [-614.5, 52],
                      [-605.551, 51.71]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 30,
                s: [
                  {
                    i: [
                      [1.663, -0.344],
                      [2.072, -0.428],
                      [-0.089, -2.588],
                      [-4.414, -0.253],
                      [-3.695, -0.972],
                      [-2.173, -4.827],
                      [-3.495, -3.505],
                      [5.295, 6.014],
                      [10.711, -1.17],
                      [-0.089, 0.719]
                    ],
                    o: [
                      [-1.802, 0.698],
                      [-2.072, 0.428],
                      [2.651, 2.016],
                      [4.414, 0.253],
                      [4.814, 2.186],
                      [2.173, 4.827],
                      [1.89, -8.113],
                      [-5.295, -6.014],
                      [0.305, -0.466],
                      [0.089, -0.719]
                    ],
                    v: [
                      [474.5, 43],
                      [468.082, 44.082],
                      [464.5, 48],
                      [475.717, 50.783],
                      [488.5, 52],
                      [497.99, 63.51],
                      [505.5, 77],
                      [499.951, 54.538],
                      [475.5, 46],
                      [476.477, 43.892]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 31,
                s: [
                  {
                    i: [
                      [-0.592, 0.908],
                      [0.834, -0.166],
                      [0.706, -0.294],
                      [0.569, -0.431],
                      [0.423, -0.577],
                      [0.268, -0.732],
                      [0.105, -0.895],
                      [-1.045, 0.455],
                      [-0.712, 0.788],
                      [-0.561, 0.939]
                    ],
                    o: [
                      [-0.953, 0.047],
                      [-0.834, 0.166],
                      [-0.706, 0.294],
                      [-0.569, 0.431],
                      [-0.423, 0.577],
                      [-0.268, 0.732],
                      [1.559, 0.059],
                      [1.045, -0.455],
                      [0.712, -0.788],
                      [0.561, -0.939]
                    ],
                    v: [
                      [867.5, 120],
                      [864.817, 120.317],
                      [862.505, 121.005],
                      [860.591, 122.091],
                      [859.101, 123.601],
                      [858.062, 125.562],
                      [857.5, 128],
                      [861.36, 127.36],
                      [863.951, 125.45],
                      [865.816, 122.816]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 32,
                s: [
                  {
                    i: [
                      [-2.567, 4.014],
                      [7.388, -1.257],
                      [6.282, -3.21],
                      [4.503, -4.973],
                      [2.051, -6.547],
                      [-1.979, 3.021],
                      [-2.447, 2.553],
                      [-4.071, 2.219],
                      [-5.126, 1.627],
                      [-5.566, 0.836]
                    ],
                    o: [
                      [-7.822, -0.886],
                      [-7.388, 1.257],
                      [-6.282, 3.21],
                      [-4.503, 4.973],
                      [4.637, -0.363],
                      [1.979, -3.021],
                      [3.591, -2.495],
                      [4.071, -2.219],
                      [5.169, -1.641],
                      [5.566, -0.836]
                    ],
                    v: [
                      [-694.5, 43],
                      [-717.484, 43.603],
                      [-738.157, 50.35],
                      [-754.502, 62.672],
                      [-764.5, 80],
                      [-755.358, 74.142],
                      [-749.5, 65],
                      [-738.151, 57.849],
                      [-724.5, 52],
                      [-707.548, 49.279]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 33,
                s: [
                  {
                    i: [
                      [-2.333, 2.834],
                      [4.61, -1.14],
                      [3.431, -2.319],
                      [2.481, -3.269],
                      [1.76, -3.99],
                      [-1.873, 1.294],
                      [-1.674, 1.493],
                      [-1.714, 1.452],
                      [-1.994, 1.172],
                      [-4.013, 1.153]
                    ],
                    o: [
                      [-6.017, -0.267],
                      [-4.61, 1.14],
                      [-3.431, 2.319],
                      [-2.481, 3.269],
                      [2.311, -0.856],
                      [1.873, -1.294],
                      [1.674, -1.493],
                      [1.714, -1.452],
                      [2.613, -2.553],
                      [4.013, -1.153]
                    ],
                    v: [
                      [-715.5, 41],
                      [-731.383, 42.367],
                      [-743.386, 47.614],
                      [-752.196, 56.054],
                      [-758.5, 67],
                      [-752.284, 63.715],
                      [-747.025, 59.475],
                      [-742.003, 54.997],
                      [-736.5, 51],
                      [-725.79, 46.21]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 34,
                s: [
                  {
                    i: [
                      [0, -2],
                      [-0.203, -1.13],
                      [-0.22, -1.113],
                      [-0.532, -0.801],
                      [-1.139, -0.194],
                      [-0.146, 6.412],
                      [-2.808, 4.603],
                      [1.421, -2.77],
                      [1.248, -2.185],
                      [0, -2]
                    ],
                    o: [
                      [0.481, 0.852],
                      [0.203, 1.13],
                      [0.22, 1.113],
                      [0.532, 0.801],
                      [1.61, -4.748],
                      [0.146, -6.412],
                      [-3.067, -1.265],
                      [-1.421, 2.77],
                      [0, 2],
                      [0, 2]
                    ],
                    v: [
                      [-959.5, 427],
                      [-958.547, 430.048],
                      [-957.987, 433.487],
                      [-956.933, 436.433],
                      [-954.5, 438],
                      [-952.899, 420.392],
                      [-949.5, 403],
                      [-955.864, 406.413],
                      [-959.5, 415],
                      [-959.5, 421]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 35,
                s: [
                  {
                    i: [
                      [0, -1.333],
                      [-0.528, 0.449],
                      [-0.307, 2.612],
                      [-0.376, 2.858],
                      [-0.735, 1.184],
                      [0.785, -1.381],
                      [-0.05, -2.216],
                      [0.167, 0],
                      [0.167, 0],
                      [0, -1.333]
                    ],
                    o: [
                      [1.039, 3.634],
                      [0.528, -0.449],
                      [0.307, -2.612],
                      [0.376, -2.858],
                      [-1.908, 0.259],
                      [-0.786, 1.381],
                      [-0.167, 0],
                      [-0.167, 0],
                      [0, 1.333],
                      [0, 1.333]
                    ],
                    v: [
                      [-959.5, 423],
                      [-957.222, 427.298],
                      [-956.043, 422.227],
                      [-955.093, 413.542],
                      [-953.5, 407],
                      [-957.468, 409.532],
                      [-958.5, 415],
                      [-959, 415],
                      [-959.5, 415],
                      [-959.5, 419]
                    ],
                    c: true
                  }
                ],
                h: 1
              }
            ],
            ix: 1
          },
          o: {
            a: 1,
            k: [
              { t: 25, s: [0], h: 1 },
              { t: 26, s: [100], h: 1 },
              { t: 36, s: [0], h: 1 }
            ],
            ix: 3
          },
          x: { a: 0, k: 0, ix: 4 },
          nm: 'Mask 10'
        },
        {
          inv: false,
          mode: 'f',
          pt: {
            a: 1,
            k: [
              {
                t: 27,
                s: [
                  {
                    i: [
                      [-7.54, 8.627],
                      [10.098, -4.152],
                      [9.356, -4.894],
                      [8.08, -6.17],
                      [6.269, -7.981],
                      [-5.223, 1.666],
                      [-5.339, 1.55],
                      [-6.108, 0.781],
                      [-10.368, 8.381]
                    ],
                    o: [
                      [-10.306, 3.944],
                      [-10.098, 4.152],
                      [-9.356, 4.894],
                      [-8.08, 6.17],
                      [5.762, -1.127],
                      [5.223, -1.666],
                      [5.339, -1.55],
                      [9.987, -7.846],
                      [10.368, -8.382]
                    ],
                    v: [
                      [843.5, 132],
                      [812.76, 144.01],
                      [783.445, 157.445],
                      [757.158, 173.908],
                      [735.5, 195],
                      [751.814, 190.648],
                      [767.493, 185.66],
                      [784.5, 182],
                      [815.835, 157.586]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 28,
                s: [
                  {
                    i: [
                      [-6.872, 6.543],
                      [7.508, 1.076],
                      [20.29, -1.477],
                      [14.617, -98.68],
                      [-9.045, -12.55],
                      [-1.061, 6.253],
                      [-7.605, 17.258],
                      [-18.651, 17.682],
                      [-71.754, -4.081]
                    ],
                    o: [
                      [-3.338, -6.136],
                      [-20.765, -2.976],
                      [-103.533, 7.536],
                      [-1.931, 13.034],
                      [9.254, -1.413],
                      [3.996, -23.555],
                      [13.514, -22.819],
                      [38.537, -31.714],
                      [8.274, 0.471]
                    ],
                    v: [
                      [-527.5, 66],
                      [-548.5, 58],
                      [-620.5, 55],
                      [-800.5, 220],
                      [-797.5, 263],
                      [-789.5, 244],
                      [-768.5, 183],
                      [-720.5, 122],
                      [-556.5, 67]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 29,
                s: [
                  {
                    i: [
                      [-3.407, -7.426],
                      [3.179, 8.523],
                      [6.584, 5.534],
                      [8.863, 1.619],
                      [10.015, -3.222],
                      [-5.612, -1.5],
                      [-4.94, -2.171],
                      [-4.054, -3.057],
                      [-3.385, -7.448]
                    ],
                    o: [
                      [1.352, -10.587],
                      [-3.179, -8.523],
                      [-6.584, -5.534],
                      [-8.863, -1.619],
                      [6.068, 1.043],
                      [5.612, 1.5],
                      [4.94, 2.171],
                      [6.052, 4.781],
                      [3.385, 7.448]
                    ],
                    v: [
                      [896.5, 252],
                      [893.478, 223.103],
                      [878.55, 201.786],
                      [855.098, 190.826],
                      [826.5, 193],
                      [844.073, 196.76],
                      [859.955, 202.212],
                      [873.5, 210],
                      [886.984, 229.017]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 30,
                s: [
                  {
                    i: [
                      [-2.524, 6.417],
                      [7.498, -0.086],
                      [7.253, -0.528],
                      [11.345, -3.537],
                      [13.391, -15.943],
                      [0.073, -18.296],
                      [-3.006, 4.695],
                      [-16.997, 11.429],
                      [-27.054, 4.418]
                    ],
                    o: [
                      [-5.755, -2.87],
                      [-7.498, 0.086],
                      [-15.173, 1.104],
                      [-18.626, 10.707],
                      [-5.648, 16.045],
                      [7.971, 0.3],
                      [11.123, -17.37],
                      [20.176, -13.567],
                      [5.628, -0.919]
                    ],
                    v: [
                      [-633.5, 50],
                      [-653.877, 46.452],
                      [-676.5, 48],
                      [-718.5, 54],
                      [-767.5, 93],
                      [-786.5, 141],
                      [-772.5, 128],
                      [-731.5, 86],
                      [-652.5, 55]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 31,
                s: [
                  {
                    i: [
                      [-2.129, -4.288],
                      [1.316, 4.179],
                      [2.391, 3.28],
                      [3.328, 2.077],
                      [4.128, 0.57],
                      [4.788, -1.24],
                      [-4.312, -2.105],
                      [-3.081, -3.335],
                      [-2.354, -4.063]
                    ],
                    o: [
                      [-0.103, -4.775],
                      [-1.316, -4.179],
                      [-2.391, -3.28],
                      [-3.328, -2.077],
                      [-4.128, -0.57],
                      [6.046, 0.371],
                      [4.312, 2.105],
                      [3.081, 3.335],
                      [2.354, 4.063]
                    ],
                    v: [
                      [896.5, 227],
                      [894.337, 213.493],
                      [888.741, 202.228],
                      [880.127, 194.117],
                      [868.908, 190.071],
                      [855.5, 191],
                      [870.911, 194.839],
                      [881.876, 203.125],
                      [889.902, 214.348]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 32,
                s: [
                  {
                    i: [
                      [1.627, 1.039],
                      [-0.909, -1.405],
                      [-1.12, -1.285],
                      [-1.218, -1.053],
                      [-1.205, -0.71],
                      [-1.081, -0.255],
                      [1.155, 1.512],
                      [1.375, 1.292],
                      [1.532, 1.134]
                    ],
                    o: [
                      [0.588, 1.414],
                      [0.909, 1.405],
                      [1.12, 1.285],
                      [1.218, 1.053],
                      [1.205, 0.71],
                      [-0.873, -1.794],
                      [-1.155, -1.512],
                      [-1.375, -1.292],
                      [-1.532, -1.134]
                    ],
                    v: [
                      [487.5, 42],
                      [489.773, 46.257],
                      [492.845, 50.32],
                      [496.379, 53.854],
                      [500.043, 56.526],
                      [503.5, 58],
                      [500.442, 53.058],
                      [496.631, 48.868],
                      [492.255, 45.244]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 33,
                s: [
                  {
                    i: [
                      [1.669, -0.747],
                      [-1.044, -0.49],
                      [-0.854, -0.679],
                      [-0.741, -0.793],
                      [-0.702, -0.831],
                      [-0.739, -0.794],
                      [0.687, 1.51],
                      [1.221, 0.985],
                      [1.548, 0.233]
                    ],
                    o: [
                      [1.308, 0.225],
                      [1.044, 0.49],
                      [0.854, 0.679],
                      [0.741, 0.793],
                      [0.702, 0.831],
                      [0.053, -1.807],
                      [-0.687, -1.51],
                      [-1.221, -0.985],
                      [-1.548, -0.233]
                    ],
                    v: [
                      [488.5, 42],
                      [492.009, 43.091],
                      [494.837, 44.863],
                      [497.211, 47.089],
                      [499.357, 49.544],
                      [501.5, 52],
                      [500.497, 46.968],
                      [497.583, 43.169],
                      [493.377, 41.286]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 34,
                s: [
                  {
                    i: [
                      [0, 0.667],
                      [0.709, -0.875],
                      [0.451, -1.485],
                      [0.541, -0.93],
                      [-1.142, 0.918],
                      [0.468, 1.674],
                      [-0.167, 0],
                      [-0.167, 0],
                      [0, 0.667]
                    ],
                    o: [
                      [-1.314, -0.901],
                      [-0.709, 0.875],
                      [-0.451, 1.485],
                      [0.899, 1.346],
                      [1.142, -0.918],
                      [0.167, 0],
                      [0.167, 0],
                      [0, -0.667],
                      [0, -0.667]
                    ],
                    v: [
                      [960.5, 429],
                      [957.553, 429.253],
                      [955.9, 433.085],
                      [954.5, 437],
                      [958.026, 437.265],
                      [959.5, 433],
                      [960, 433],
                      [960.5, 433],
                      [960.5, 431]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 35,
                s: [
                  {
                    i: [
                      [0.791, 0.387],
                      [0.102, -0.564],
                      [0.189, -0.478],
                      [0.205, -0.461],
                      [0.153, -0.514],
                      [0.03, -0.636],
                      [-0.648, 0.604],
                      [-0.164, 0.937],
                      [0.316, 0.865]
                    ],
                    o: [
                      [0.053, 0.72],
                      [-0.102, 0.564],
                      [-0.189, 0.478],
                      [-0.205, 0.461],
                      [-0.153, 0.514],
                      [1.135, 0.136],
                      [0.648, -0.604],
                      [0.164, -0.937],
                      [-0.316, -0.865]
                    ],
                    v: [
                      [957.5, 429],
                      [957.409, 430.909],
                      [956.955, 432.456],
                      [956.347, 433.847],
                      [955.792, 435.292],
                      [955.5, 437],
                      [958.173, 436.197],
                      [959.389, 433.784],
                      [959.161, 430.979]
                    ],
                    c: true
                  }
                ],
                h: 1
              }
            ],
            ix: 1
          },
          o: {
            a: 1,
            k: [
              { t: 26, s: [0], h: 1 },
              { t: 27, s: [100], h: 1 },
              { t: 36, s: [0], h: 1 }
            ],
            ix: 3
          },
          x: { a: 0, k: 0, ix: 4 },
          nm: 'Mask 11'
        },
        {
          inv: false,
          mode: 'f',
          pt: {
            a: 1,
            k: [
              {
                t: 27,
                s: [
                  {
                    i: [
                      [-6.124, 0.458],
                      [0.511, 4.946],
                      [38.086, 6.657],
                      [13.97, -9.697],
                      [-17.931, -14.585],
                      [-8.201, -13.133],
                      [-2.152, -13.848]
                    ],
                    o: [
                      [4.833, -3.723],
                      [-4.251, -41.15],
                      [-24.37, -0.704],
                      [27.213, 1.971],
                      [10.552, 10.781],
                      [5.445, 10.555],
                      [2.289, 3.377]
                    ],
                    v: [
                      [898.5, 305],
                      [901.5, 285],
                      [832.5, 196],
                      [772.5, 207],
                      [852.5, 222],
                      [880.5, 258],
                      [893.5, 293]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 28,
                s: [
                  {
                    i: [
                      [-6.939, 11.727],
                      [1.044, 0.123],
                      [1.233, -0.066],
                      [8.572, -0.761],
                      [6.981, -5.451],
                      [3.645, -8.352],
                      [-13.918, 3.415]
                    ],
                    o: [
                      [-0.591, -0.576],
                      [-1.044, -0.123],
                      [-6.083, 3.25],
                      [-8.134, 4.046],
                      [-6.765, 5.283],
                      [13.868, -3.465],
                      [7.361, -11.305]
                    ],
                    v: [
                      [851.5, 129],
                      [848.981, 128.019],
                      [845.5, 128],
                      [824.5, 135],
                      [801.5, 149],
                      [782.5, 168],
                      [822.5, 156]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 29,
                s: [
                  {
                    i: [
                      [1.333, 0],
                      [13.049, -15.891],
                      [-1.151, -24.1],
                      [-43.814, -6.107],
                      [-4.263, 48.888],
                      [-16.02, 17.763],
                      [-2.135, 7.531]
                    ],
                    o: [
                      [-15.956, 10.337],
                      [-13.049, 15.891],
                      [2.188, 45.81],
                      [-36.641, -11.861],
                      [2.564, -29.395],
                      [5.275, -4.392],
                      [-1.333, 0]
                    ],
                    v: [
                      [-857.5, 330],
                      [-903.83, 368.678],
                      [-924.5, 428],
                      [-840.5, 497],
                      [-902.5, 411],
                      [-869.5, 343],
                      [-853.5, 330]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 30,
                s: [
                  {
                    i: [
                      [-1.897, 2.103],
                      [2.33, -0.532],
                      [2.138, -1.121],
                      [1.989, -1.279],
                      [1.883, -1.005],
                      [-3.22, 0.78],
                      [-2.487, 1.513]
                    ],
                    o: [
                      [-2.566, -0.49],
                      [-2.33, 0.532],
                      [-2.138, 1.121],
                      [-1.989, 1.279],
                      [4.095, 0.095],
                      [3.22, -0.78],
                      [2.487, -1.513]
                    ],
                    v: [
                      [867.5, 123],
                      [860.167, 123.17],
                      [853.477, 125.757],
                      [847.298, 129.466],
                      [841.5, 133],
                      [852.436, 131.936],
                      [860.96, 128.46]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 31,
                s: [
                  {
                    i: [
                      [3.401, 1.147],
                      [0.167, 0],
                      [0.167, 0],
                      [-25.252, -33.402],
                      [-8.517, -0.483],
                      [-2.818, 28.152],
                      [-9.198, 9.802]
                    ],
                    o: [
                      [-0.167, 0],
                      [-0.167, 0],
                      [-25.985, 15.511],
                      [5.65, 3.35],
                      [-10.433, -14.901],
                      [3.39, -15.61],
                      [0.829, -3.472]
                    ],
                    v: [
                      [-907.5, 358],
                      [-908, 358],
                      [-908.5, 358],
                      [-933.5, 458],
                      [-916.5, 468],
                      [-931.5, 407],
                      [-914.5, 367]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 32,
                s: [
                  {
                    i: [
                      [0.072, 1.294],
                      [0.767, -0.394],
                      [0.423, -0.696],
                      [-0.055, -0.829],
                      [-0.668, -0.793],
                      [-0.617, 0.606],
                      [-0.266, 0.956]
                    ],
                    o: [
                      [-0.975, -0.076],
                      [-0.767, 0.394],
                      [-0.423, 0.696],
                      [0.055, 0.829],
                      [0.98, -0.242],
                      [0.617, -0.606],
                      [0.266, -0.956]
                    ],
                    v: [
                      [871.5, 115],
                      [868.854, 115.52],
                      [867.035, 117.196],
                      [866.449, 119.525],
                      [867.5, 122],
                      [869.892, 120.725],
                      [871.212, 118.379]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 33,
                s: [
                  {
                    i: [
                      [0.153, 1.041],
                      [0.545, -0.122],
                      [0.372, -0.295],
                      [0.266, -0.4],
                      [0.228, -0.438],
                      [-0.792, 0.097],
                      [-0.423, 0.466]
                    ],
                    o: [
                      [-0.785, -0.118],
                      [-0.545, 0.122],
                      [-0.372, 0.295],
                      [-0.266, 0.4],
                      [0.954, 0.066],
                      [0.792, -0.097],
                      [0.423, -0.466]
                    ],
                    v: [
                      [872.5, 118],
                      [870.522, 118.022],
                      [869.165, 118.665],
                      [868.225, 119.725],
                      [867.5, 121],
                      [870.171, 121.004],
                      [872.043, 120.21]
                    ],
                    c: true
                  }
                ],
                h: 1
              }
            ],
            ix: 1
          },
          o: {
            a: 1,
            k: [
              { t: 26, s: [0], h: 1 },
              { t: 27, s: [100], h: 1 },
              { t: 34, s: [0], h: 1 }
            ],
            ix: 3
          },
          x: { a: 0, k: 0, ix: 4 },
          nm: 'Mask 12'
        },
        {
          inv: false,
          mode: 'f',
          pt: {
            a: 1,
            k: [
              {
                t: 27,
                s: [
                  {
                    i: [
                      [-33.097, 10.935],
                      [-2.203, -64.627],
                      [-8.811, -11.856],
                      [-39.602, -0.736],
                      [-39.444, -0.632],
                      [10.867, 0],
                      [30.544, 8.033],
                      [-0.272, 42.47]
                    ],
                    o: [
                      [-63.885, -8.095],
                      [3.216, 17.45],
                      [19.972, 21.378],
                      [38.61, 0.717],
                      [-10.069, -5.514],
                      [-34.226, 0],
                      [-37.263, -9.799],
                      [0.285, -44.462]
                    ],
                    v: [
                      [-780.5, 328],
                      [-878.5, 421],
                      [-859.5, 464],
                      [-770.5, 499],
                      [-648.5, 496],
                      [-681.5, 493],
                      [-785.5, 488],
                      [-850.5, 406]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 28,
                s: [
                  {
                    i: [
                      [-5.971, -2.533],
                      [0.832, 5.608],
                      [-1.322, 6.01],
                      [5.996, 9.67],
                      [36.631, -1.298],
                      [1.615, 0.282],
                      [-0.147, -2.48],
                      [-13.51, -37.656]
                    ],
                    o: [
                      [4.005, -2.56],
                      [-0.832, -5.608],
                      [-3.178, -12.489],
                      [-15.442, -19.892],
                      [-1.278, 0.055],
                      [-2.187, 0.147],
                      [48.078, 3.701],
                      [1.632, 4.549]
                    ],
                    v: [
                      [901.5, 278],
                      [904.512, 265.087],
                      [903.5, 247],
                      [888.5, 215],
                      [803.5, 194],
                      [800.5, 195],
                      [798.5, 200],
                      [895.5, 264]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 29,
                s: [
                  {
                    i: [
                      [5.769, -4.453],
                      [-10.591, 19.636],
                      [13.805, 21.438],
                      [2.424, 2.355],
                      [1.284, -1.491],
                      [0.801, -15.782],
                      [9.396, -10.428],
                      [6.484, -3.417]
                    ],
                    o: [
                      [23.443, -0.018],
                      [10.591, -19.636],
                      [-2.02, -1.072],
                      [-2.424, -2.355],
                      [10.599, 11.444],
                      [-0.801, 15.782],
                      [-5.296, 5.878],
                      [-6.484, 3.417]
                    ],
                    v: [
                      [884.5, 483],
                      [938.436, 449.066],
                      [936.5, 383],
                      [929.448, 376.577],
                      [923.5, 374],
                      [937.496, 417.262],
                      [921.5, 459],
                      [903.354, 472.068]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 30,
                s: [
                  {
                    i: [
                      [-11.202, -22.131],
                      [6.035, 6.041],
                      [5.49, 5.69],
                      [5.488, 0.179],
                      [5.675, -0.008],
                      [0.273, -0.227],
                      [0.405, -0.095],
                      [-0.529, -0.804]
                    ],
                    o: [
                      [0.629, -10.145],
                      [-6.035, -6.041],
                      [-4.375, -1.291],
                      [-5.487, -0.179],
                      [-0.502, -0.001],
                      [-0.273, 0.227],
                      [-0.043, 1.377],
                      [35.992, -2.659]
                    ],
                    v: [
                      [897.5, 231],
                      [887.589, 207.658],
                      [868.5, 191],
                      [853.475, 189.025],
                      [836.5, 189],
                      [835.427, 189.428],
                      [834.5, 190],
                      [835.5, 193]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 31,
                s: [
                  {
                    i: [
                      [2.648, 4.164],
                      [0.343, -5.407],
                      [1.126, -4.624],
                      [1.845, -3.905],
                      [2.499, -3.251],
                      [-2.842, 4.176],
                      [-1.226, 5.657],
                      [0.604, 5.653]
                    ],
                    o: [
                      [0.504, 6.254],
                      [-0.343, 5.407],
                      [-1.126, 4.624],
                      [-1.845, 3.905],
                      [4.245, -1.209],
                      [2.842, -4.176],
                      [1.226, -5.657],
                      [-0.604, -5.653]
                    ],
                    v: [
                      [947.5, 401],
                      [947.726, 418.476],
                      [945.505, 433.505],
                      [941.032, 446.282],
                      [934.5, 457],
                      [945.184, 448.551],
                      [951.339, 433.432],
                      [952.325, 416.096]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 32,
                s: [
                  {
                    i: [
                      [-1.585, -2.415],
                      [0.946, 3.474],
                      [1.993, 2.864],
                      [2.551, 1.458],
                      [2.619, -0.744],
                      [-1.905, -2.094],
                      [-1.403, -2.597],
                      [-1.296, -2.704]
                    ],
                    o: [
                      [0.592, -3.289],
                      [-0.946, -3.474],
                      [-1.993, -2.864],
                      [-2.551, -1.458],
                      [2.805, 1.195],
                      [1.906, 2.095],
                      [1.403, 2.597],
                      [1.296, 2.704]
                    ],
                    v: [
                      [897.5, 220],
                      [896.847, 209.656],
                      [892.316, 199.95],
                      [885.377, 193.269],
                      [877.5, 192],
                      [884.466, 197.034],
                      [889.329, 204.171],
                      [893.278, 212.222]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 33,
                s: [
                  {
                    i: [
                      [3.051, 0.365],
                      [-1.787, -1.911],
                      [-1.657, -2.051],
                      [-1.541, -1.74],
                      [-1.44, -0.978],
                      [1.056, 2.361],
                      [1.558, 1.859],
                      [2.223, 1.194]
                    ],
                    o: [
                      [1.932, 1.32],
                      [1.787, 1.911],
                      [1.657, 2.051],
                      [1.541, 1.74],
                      [-0.716, -2.7],
                      [-1.056, -2.361],
                      [-1.558, -1.859],
                      [-2.223, -1.194]
                    ],
                    v: [
                      [875.5, 189],
                      [881.075, 193.958],
                      [886.237, 200.013],
                      [891.031, 205.811],
                      [895.5, 210],
                      [892.882, 202.368],
                      [889.002, 195.998],
                      [883.371, 191.379]
                    ],
                    c: true
                  }
                ],
                h: 1
              }
            ],
            ix: 1
          },
          o: {
            a: 1,
            k: [
              { t: 26, s: [0], h: 1 },
              { t: 27, s: [100], h: 1 },
              { t: 34, s: [0], h: 1 }
            ],
            ix: 3
          },
          x: { a: 0, k: 0, ix: 4 },
          nm: 'Mask 13'
        },
        {
          inv: false,
          mode: 'f',
          pt: {
            a: 1,
            k: [
              {
                t: 27,
                s: [
                  {
                    i: [
                      [49.785, -2.96],
                      [-30.707, 8.893],
                      [-13.719, 24.413],
                      [6.865, 14.743],
                      [16.845, 2.579],
                      [0.804, -0.529],
                      [-0.973, -0.027],
                      [-4.611, -5.25],
                      [22.31, -17.071]
                    ],
                    o: [
                      [37.454, 3.985],
                      [30.707, -8.893],
                      [6.304, -19.45],
                      [-6.865, -14.743],
                      [-1.377, -0.043],
                      [0.224, 0.776],
                      [5.617, 5.968],
                      [20.963, 23.868],
                      [-31.73, 24.279]
                    ],
                    v: [
                      [757.5, 495],
                      [862.301, 488.299],
                      [931.5, 439],
                      [929.862, 385.847],
                      [893.5, 358],
                      [890.5, 359],
                      [891.5, 361],
                      [907.5, 374],
                      [895.5, 459]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 28,
                s: [
                  {
                    i: [
                      [-2.36, 10.068],
                      [3.246, -0.58],
                      [15.353, -31.647],
                      [0.973, -0.027],
                      [0, -17.667],
                      [-35.446, -7.888],
                      [0.321, 7.987],
                      [8.434, 11.233],
                      [-35.726, 23.396]
                    ],
                    o: [
                      [-2.348, -0.319],
                      [-40.682, 6.317],
                      [-0.224, 0.776],
                      [0, 17.667],
                      [9.312, 34.022],
                      [6.698, -0.969],
                      [-13.571, -6.096],
                      [-28.343, -40.243],
                      [6.428, -4.209]
                    ],
                    v: [
                      [-810.5, 318],
                      [-817.5, 317],
                      [-891.5, 384],
                      [-892.5, 386],
                      [-892.5, 439],
                      [-827.5, 504],
                      [-809.5, 499],
                      [-847.5, 478],
                      [-827.5, 332]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 29,
                s: [
                  {
                    i: [
                      [-5.388, 1.388],
                      [4.772, 6.228],
                      [4.481, 6.519],
                      [0.805, 1.029],
                      [1.236, 0.597],
                      [1.001, 1.274],
                      [-0.102, -2.039],
                      [-11.49, -14.177],
                      [-2.169, -0.169]
                    ],
                    o: [
                      [-4.432, -6.569],
                      [-4.772, -6.228],
                      [-0.545, -1.288],
                      [-0.805, -1.029],
                      [-0.1, -0.942],
                      [-1.001, -1.274],
                      [8.988, 16.679],
                      [0.547, -1.453],
                      [4.809, -0.809]
                    ],
                    v: [
                      [-265.5, -139],
                      [-279.463, -158.037],
                      [-293.5, -177],
                      [-295.481, -180.518],
                      [-298.5, -183],
                      [-300.652, -187.235],
                      [-302.5, -187],
                      [-275.5, -137],
                      [-274.5, -142]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 30,
                s: [
                  {
                    i: [
                      [-0.215, 5.785],
                      [0.846, 0.488],
                      [1.729, -0.396],
                      [9.413, -15.087],
                      [-3.05, -27.55],
                      [-22.727, -7.607],
                      [4.966, 12.68],
                      [-0.626, 20.519],
                      [-11.56, 15.107]
                    ],
                    o: [
                      [-0.889, -0.444],
                      [-0.846, -0.488],
                      [-15.397, 9.103],
                      [-9.413, 15.087],
                      [7.565, 22.769],
                      [-2.502, -11.627],
                      [-5.562, -14.203],
                      [6.316, -20.351],
                      [3.773, -2.227]
                    ],
                    v: [
                      [-874.5, 341],
                      [-876.87, 339.37],
                      [-880.5, 339],
                      [-919.335, 373.665],
                      [-930.5, 436],
                      [-888.5, 485],
                      [-906.5, 453],
                      [-912.5, 401],
                      [-883.5, 350]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 31,
                s: [
                  {
                    i: [
                      [2.391, 0.45],
                      [-0.481, -1.269],
                      [-0.401, -1.349],
                      [-0.594, -1.156],
                      [-1.061, -0.689],
                      [-0.95, -0.439],
                      [-0.761, -0.628],
                      [-0.26, 0.874],
                      [2.407, 3.007]
                    ],
                    o: [
                      [0.834, 0.916],
                      [0.481, 1.269],
                      [0.401, 1.349],
                      [0.594, 1.156],
                      [0.827, -1.441],
                      [0.95, 0.439],
                      [0.761, 0.628],
                      [-1.981, -2.524],
                      [-2.407, -3.007]
                    ],
                    v: [
                      [-307.5, -196],
                      [-305.595, -192.655],
                      [-304.34, -188.66],
                      [-302.915, -184.835],
                      [-300.5, -182],
                      [-297.756, -183.079],
                      [-295.11, -181.055],
                      [-293.5, -181],
                      [-300.192, -190.056]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 32,
                s: [
                  {
                    i: [
                      [-2.28, 7.255],
                      [0.667, 0],
                      [0.667, 0],
                      [3.583, -9.156],
                      [-2.733, -11.744],
                      [-3.091, -5.311],
                      [-5.75, -0.118],
                      [0.37, 16.89],
                      [-3.271, 5.952]
                    ],
                    o: [
                      [-0.667, 0],
                      [-0.667, 0],
                      [-7.402, 4.63],
                      [-3.583, 9.156],
                      [3.888, 3.637],
                      [3.091, 5.311],
                      [-0.745, -13.75],
                      [-0.182, -8.318],
                      [3.255, -5.924]
                    ],
                    v: [
                      [-926.5, 379],
                      [-928.5, 379],
                      [-930.5, 379],
                      [-947.602, 400.164],
                      [-949.5, 432],
                      [-939.896, 447.139],
                      [-927.5, 457],
                      [-944.5, 418],
                      [-938.5, 396]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 33,
                s: [
                  {
                    i: [
                      [-0.697, 3.066],
                      [1.756, -3.114],
                      [1.276, -3.705],
                      [0.394, -3.872],
                      [-0.89, -3.615],
                      [-2.576, -2.935],
                      [-0.338, 4.636],
                      [-0.834, 4.525],
                      [-0.954, 4.001]
                    ],
                    o: [
                      [-1.835, 2.099],
                      [-1.756, 3.114],
                      [-1.276, 3.705],
                      [-0.394, 3.872],
                      [0.89, 3.615],
                      [-0.535, -4.336],
                      [0.338, -4.636],
                      [0.834, -4.525],
                      [0.954, -4.001]
                    ],
                    v: [
                      [-942.5, 391],
                      [-947.987, 398.926],
                      [-952.636, 409.261],
                      [-955.241, 420.732],
                      [-954.598, 432.069],
                      [-949.5, 442],
                      [-949.702, 428.44],
                      [-947.85, 414.595],
                      [-945.072, 401.703]
                    ],
                    c: true
                  }
                ],
                h: 1
              }
            ],
            ix: 1
          },
          o: {
            a: 1,
            k: [
              { t: 26, s: [0], h: 1 },
              { t: 27, s: [100], h: 1 },
              { t: 34, s: [0], h: 1 }
            ],
            ix: 3
          },
          x: { a: 0, k: 0, ix: 4 },
          nm: 'Mask 14'
        },
        {
          inv: false,
          mode: 'f',
          pt: {
            a: 1,
            k: [
              {
                t: 28,
                s: [
                  {
                    i: [
                      [-2.535, 2.443],
                      [5.742, 49.854],
                      [17.596, 6.247],
                      [0.667, 0],
                      [-0.127, -1.794],
                      [-0.21, -19.434],
                      [4.583, -10.225],
                      [4.753, -10.581],
                      [9.282, -3.028],
                      [4.974, -7.974],
                      [-3.509, -5.158]
                    ],
                    o: [
                      [35.755, -10.916],
                      [-2.225, -19.319],
                      [-0.667, 0],
                      [0.224, 1.891],
                      [9.041, 10.429],
                      [0.128, 11.815],
                      [-8.158, 7.175],
                      [-7.451, 3.284],
                      [-8.696, 2.837],
                      [3.247, 5.419],
                      [1.108, -3.869]
                    ],
                    v: [
                      [872.5, 488],
                      [946.5, 405],
                      [914.5, 365],
                      [912.5, 365],
                      [911.5, 369],
                      [932.5, 409],
                      [924.5, 442],
                      [903.5, 467],
                      [879.5, 478],
                      [853.5, 488],
                      [873.5, 494]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 29,
                s: [
                  {
                    i: [
                      [-1.491, 2.093],
                      [1.883, -0.736],
                      [1.825, -0.794],
                      [1.736, -0.883],
                      [1.618, -1.001],
                      [1.469, -1.15],
                      [1.29, -1.329],
                      [1.08, -1.539],
                      [-3.491, 1.373],
                      [-2.851, 2.024],
                      [-2.184, 2.264]
                    ],
                    o: [
                      [-1.91, 0.709],
                      [-1.883, 0.736],
                      [-1.825, 0.794],
                      [-1.736, 0.883],
                      [-1.618, 1.001],
                      [-1.469, 1.15],
                      [-1.29, 1.329],
                      [4.106, -0.312],
                      [3.491, -1.373],
                      [2.851, -2.024],
                      [2.184, -2.264]
                    ],
                    v: [
                      [859.5, 124],
                      [853.803, 126.16],
                      [848.235, 128.449],
                      [842.886, 130.957],
                      [837.847, 133.775],
                      [833.209, 136.994],
                      [829.063, 140.706],
                      [825.5, 145],
                      [836.902, 142.37],
                      [846.422, 137.172],
                      [853.981, 130.639]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 30,
                s: [
                  {
                    i: [
                      [0.333, 0],
                      [0.195, -0.36],
                      [0.113, -0.442],
                      [-0.141, -0.697],
                      [1.955, -12.059],
                      [4.613, -7.42],
                      [2.447, -1.887],
                      [0.389, -3.945],
                      [-4.236, 17.547],
                      [12.455, 8.981],
                      [0.333, 0]
                    ],
                    o: [
                      [-0.105, 0.451],
                      [-0.195, 0.36],
                      [-0.113, 0.442],
                      [8.432, 7.346],
                      [-1.955, 12.059],
                      [-1.824, 2.509],
                      [-2.447, 1.887],
                      [14.51, -1.829],
                      [4.236, -17.547],
                      [-0.333, 0],
                      [-0.333, 0]
                    ],
                    v: [
                      [940.5, 384],
                      [940.007, 385.173],
                      [939.501, 386.335],
                      [939.5, 388],
                      [947.284, 419.444],
                      [935.5, 451],
                      [928.423, 456.923],
                      [923.5, 465],
                      [953.223, 429.864],
                      [942.5, 384],
                      [941.5, 384]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 31,
                s: [
                  {
                    i: [
                      [-0.045, 2.207],
                      [0.692, -1.165],
                      [0.727, -1.13],
                      [0.746, -1.112],
                      [0.747, -1.11],
                      [0.732, -1.125],
                      [0.7, -1.157],
                      [0.652, -1.205],
                      [-1.807, 1.724],
                      [-1.311, 2.23],
                      [-0.723, 2.391]
                    ],
                    o: [
                      [-0.64, 1.218],
                      [-0.692, 1.165],
                      [-0.727, 1.13],
                      [-0.746, 1.112],
                      [-0.747, 1.11],
                      [-0.732, 1.125],
                      [-0.7, 1.157],
                      [2.213, -0.872],
                      [1.807, -1.724],
                      [1.311, -2.23],
                      [0.723, -2.391]
                    ],
                    v: [
                      [491.5, -143],
                      [489.499, -139.43],
                      [487.366, -135.991],
                      [485.153, -132.633],
                      [482.909, -129.305],
                      [480.686, -125.957],
                      [478.532, -122.539],
                      [476.5, -119],
                      [482.553, -122.98],
                      [487.252, -128.997],
                      [490.326, -136.016]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 32,
                s: [
                  {
                    i: [
                      [-0.607, 4.505],
                      [0.333, 0],
                      [0.333, 0],
                      [0.333, 0],
                      [0.333, 0],
                      [0.876, -2.896],
                      [1.035, -2.732],
                      [0.767, -2.595],
                      [0.071, -2.486],
                      [-1.724, 3.387],
                      [-0.824, 4.287]
                    ],
                    o: [
                      [-0.333, 0],
                      [-0.333, 0],
                      [-0.333, 0],
                      [-0.333, 0],
                      [-0.288, 3.087],
                      [-0.876, 2.896],
                      [-1.035, 2.732],
                      [-0.767, 2.595],
                      [3.305, -1.806],
                      [1.724, -3.387],
                      [0.824, -4.287]
                    ],
                    v: [
                      [957.5, 416],
                      [956.5, 416],
                      [955.5, 416],
                      [954.5, 416],
                      [953.5, 416],
                      [951.647, 424.967],
                      [948.674, 433.401],
                      [945.864, 441.385],
                      [944.5, 449],
                      [951.873, 441.04],
                      [955.524, 429.358]
                    ],
                    c: true
                  }
                ],
                h: 1
              },
              {
                t: 33,
                s: [
                  {
                    i: [
                      [1.903, 2.189],
                      [0.13, -1.571],
                      [0.264, -1.532],
                      [0.34, -1.469],
                      [0.358, -1.383],
                      [0.318, -1.273],
                      [0.221, -1.14],
                      [0.066, -0.983],
                      [-1.287, 2.307],
                      [-0.802, 2.714],
                      [0.262, 2.675]
                    ],
                    o: [
                      [0.061, 1.586],
                      [-0.13, 1.571],
                      [-0.264, 1.532],
                      [-0.34, 1.469],
                      [-0.358, 1.383],
                      [-0.318, 1.273],
                      [-0.221, 1.14],
                      [1.195, -1.455],
                      [1.287, -2.307],
                      [0.802, -2.714],
                      [-0.262, -2.675]
                    ],
                    v: [
                      [954.5, 417],
                      [954.382, 421.741],
                      [953.777, 426.401],
                      [952.857, 430.908],
                      [951.797, 435.192],
                      [950.768, 439.183],
                      [949.945, 442.809],
                      [949.5, 446],
                      [953.369, 440.245],
                      [956.647, 432.602],
                      [957.602, 424.408]
                    ],
                    c: true
                  }
                ],
                h: 1
              }
            ],
            ix: 1
          },
          o: {
            a: 1,
            k: [
              { t: 27, s: [0], h: 1 },
              { t: 28, s: [100], h: 1 },
              { t: 34, s: [0], h: 1 }
            ],
            ix: 3
          },
          x: { a: 0, k: 0, ix: 4 },
          nm: 'Mask 15'
        }
      ],
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: { a: 0, k: [1920, 1021], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              r: { a: 0, k: 0, ix: 4 },
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.924705954159, 0.924705954159, 0.924705954159, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.945098039216, 0.642317887848, 0.11489426108, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Rectangle 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 20,
      op: 37,
      st: 20,
      bm: 0
    }
  ],
  markers: []
};

export const textLayer = 0;
