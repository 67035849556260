import React from 'react';
import { PropTypes } from 'prop-types';
import './styles.css';
import { toAbsoluteUrl } from 'utils/formatUrl';

Input.propTypes = {
  props: PropTypes.any
};

export default function Input(props) {
  return (
    <div className="input-container position-relative">
      <input {...props} />
      <img src={toAbsoluteUrl('/icons/search.svg')} alt="search" />
    </div>
  );
}
