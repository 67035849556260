import { createTypes } from 'reduxsauce';
import { ENDPOINTS } from './config';
import axiosClient from '../../utils/axiosClient2';

export const PackageTypes = createTypes(`
  PACKAGES_FETCH_SUCCESS
  PACKAGES_FETCH_FAILURE
  PAYMENT_LIST_FETCH_SUCCESS
  PAYMENT_LIST_FETCH_FAILURE
`);

export const fetchSuccess = (response) => ({ type: PackageTypes.PACKAGES_FETCH_SUCCESS, data: response });
export const fetchFailure = (error) => ({ type: PackageTypes.PACKAGES_FETCH_FAILURE, error });
export const fetchPackages = (params) => async (dispatch) => {
  try {
    const response = await axiosClient.get(ENDPOINTS.packages(), { params });
    dispatch(fetchSuccess(response.data));
  } catch (error) {
    dispatch(fetchFailure(error));
  }
};

export const createPackage = (params) => async (dispatch) => {
  try {
    const response = await axiosClient.post(ENDPOINTS.newPackage(), params);
    return response;
  } catch (error) {
    dispatch(fetchFailure(error));
  }
};

export const fetchPaymentSuccess = (response) => ({ type: PackageTypes.PAYMENT_LIST_FETCH_SUCCESS, data: response });
export const fetchPaymentFailure = (error) => ({ type: PackageTypes.PAYMENT_LIST_FETCH_FAILURE, error });
export const fetchPayment = (params) => async (dispatch) => {
  try {
    const response = await axiosClient.get(ENDPOINTS.payment(), { params });
    dispatch(fetchPaymentSuccess({ ...response.data, historyType: params.type }));
  } catch (error) {
    dispatch(fetchPaymentFailure(error));
  }
};

export const approvePayment = (orderId) => async (dispatch) => {
  try {
    const response = await axiosClient.post(ENDPOINTS.approvePayment(orderId));
    return response.data;
  } catch (error) {
    dispatch(fetchFailure(error));
  }
};
