import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as Selectors from '../../App/selectors';
import '../styles.css';
import { toAbsoluteUrl } from 'utils/formatUrl';
import PaidIcon from '@mui/icons-material/Paid';

export default function ProfileInfo() {
  const user = useSelector(Selectors.getUser());
  const location = useLocation();
  const navigate = useNavigate();

  const onActiveProfileLink = (value) => location.pathname.includes(value);

  const onClickProfileLink = (value) => {
    navigate(`/${value}`);
  };

  return (
    <>
      <div className="avatar-info">
        <img
          className="avatar"
          src={user.avatar || toAbsoluteUrl('/static/user-avatar-placeholder.png')}
          alt="avatar"
        />
        <h4 className="user-name">{user.name}</h4>
      </div>
      <div className="information d-flex flex-direct-column">
        {onActiveProfileLink('profile') ? (
          <div className="d-flex pointer mb-15 text-primary-2 text-bold align-items-center">
            <img className="mr-12" src={`${toAbsoluteUrl('/icons/profile-active.svg')}`} alt="profile-active" />
            <div onClick={() => onClickProfileLink('profile')} aria-hidden="true">
              Thông tin cá nhân
            </div>
          </div>
        ) : (
          <div className="d-flex pointer mb-15 align-items-center">
            <img className="mr-12" src={`${toAbsoluteUrl('/icons/profile.svg')}`} alt="profile" />
            <div onClick={() => onClickProfileLink('profile')} aria-hidden="true">
              Thông tin cá nhân
            </div>
          </div>
        )}
        {onActiveProfileLink('account') ? (
          <div className="d-flex pointer mb-15 text-primary-2 text-bold align-items-center">
            <img className="mr-12" src={`${toAbsoluteUrl('/icons/profile-active.svg')}`} alt="account-active" />
            <div onClick={() => onClickProfileLink('account')} aria-hidden="true">
              Tài khoản
            </div>
          </div>
        ) : (
          <div className="d-flex pointer mb-15 align-items-center">
            <img className="mr-12" src={`${toAbsoluteUrl('/icons/profile.svg')}`} alt="account" />
            <div onClick={() => onClickProfileLink('account')} aria-hidden="true">
              Tài khoản
            </div>
          </div>
        )}
        {onActiveProfileLink('group') ? (
          <div className="d-flex pointer mb-15 text-primary-2 text-bold align-items-center">
            <img className="mr-12" src={`${toAbsoluteUrl('/icons/group-active.svg')}`} alt="group-active" />
            <div onClick={() => onClickProfileLink('group')} aria-hidden="true">
              Nhóm làm việc
            </div>
          </div>
        ) : (
          <div className="d-flex pointer mb-15 align-items-center">
            <img className="mr-12" src={`${toAbsoluteUrl('/icons/group.svg')}`} alt="group" />
            <div onClick={() => onClickProfileLink('group')} aria-hidden="true">
              Nhóm làm việc
            </div>
          </div>
        )}
        {onActiveProfileLink('history') ? (
          <div className="d-flex pointer mb-15 text-primary-2 text-bold align-items-center">
            <img className="mr-12" src={`${toAbsoluteUrl('/icons/history-active.svg')}`} alt="history-active" />
            <div onClick={() => onClickProfileLink('history')} aria-hidden="true">
              Lịch sử giao dịch
            </div>
          </div>
        ) : (
          <div className="d-flex pointer mb-15 align-items-center">
            <img className="mr-12" src={`${toAbsoluteUrl('/icons/history.svg')}`} alt="history" />
            <div onClick={() => onClickProfileLink('history')} aria-hidden="true">
              Lịch sử giao dịch
            </div>
          </div>
        )}
      </div>
    </>
  );
}
