import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Select from '../../../components/Common/Select';
import { fetchGroupMembers } from '../actions';

GroupDetailVideoFilter.propTypes = {
  groupId: PropTypes.string,
  onChangeUser: PropTypes.func
};

export default function GroupDetailVideoFilter({ groupId, onChangeUser }) {
  const dispatch = useDispatch();
  const groupState = useSelector((state) => state.group);
  const { members, timeTrigger } = groupState;
  const selectedRef = useRef();

  useEffect(() => {
    dispatch(fetchGroupMembers(groupId));
  }, []);

  useEffect(() => {
    if (timeTrigger > 0) {
      selectedRef.current.setFieldValue('ALL');
    }
  }, [timeTrigger]);

  return (
    <div className="d-flex justify-content-end mt-10 mb-20">
      {members && (
        <Select
          defaultValue="ALL"
          options={members}
          onChange={onChangeUser}
          style={{ width: '50%' }}
          ref={selectedRef}
          all="Tất cả thành viên"
        />
      )}
    </div>
  );
}
