import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import { getVideosByUserRequest } from '../../HomeVideo/components/HomeTemplate/actions';
import { DEFAULT_LIST_GROUP_PARAMS } from '../constants';

function UseGroupDetailVideo({ params }) {
  const dispatch = useDispatch();
  const groupId = useParams();
  const [videoParams, setVideoParams] = useState(params);

  useEffect(() => {
    dispatch(getVideosByUserRequest(groupId.id, videoParams));
  }, [videoParams]);

  const handleTableVideoChange = (page) => {
    setVideoParams((prevState) => ({
      ...prevState,
      page
    }));
  };

  const onChangeUser = (value) => {
    if (value === 'ALL') {
      setVideoParams(() => ({
        ...DEFAULT_LIST_GROUP_PARAMS,
        user_id: null
      }));
    } else {
      setVideoParams(() => ({
        ...DEFAULT_LIST_GROUP_PARAMS,
        user_id: value
      }));
    }
  };

  return {
    videoParams,
    handleTableVideoChange,
    onChangeUser
  };
}

UseGroupDetailVideo.propTypes = {
  params: PropTypes.object
};

export default UseGroupDetailVideo;
