export const defaultJson = {
  v: '5.9.2',
  fr: 25,
  ip: 0,
  op: 50,
  w: 1920,
  h: 600,
  nm: '3-candy',
  ddd: 0,
  assets: [
    {
      id: 'comp_0',
      nm: 'Pre-comp 1',
      fr: 25,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Shape Layer 7',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: -45, ix: 10 },
            p: { a: 0, k: [1552.664, 187.336, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-261.586, -87.811, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [412, 118], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 60, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [0.243137000589, 0.278430983599, 0.60784295774, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.083921567131, 0.139355992336, 0.419607843137, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-467.586, -87.811], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false
            }
          ],
          ip: 0,
          op: 50,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Shape Layer 6',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: -45, ix: 10 },
            p: { a: 0, k: [1027.818, 548.182, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-812.912, -86.397, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 0,
                  s: [0, 100, 100]
                },
                { t: 10, s: [100, 100, 100] }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [586, 118], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 60, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [0.243137000589, 0.278430983599, 0.60784295774, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.109803929048, 0.180392156863, 0.537254901961, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-519.912, -86.397], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false
            }
          ],
          ip: 0,
          op: 50,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Shape Layer 5',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: -45, ix: 10 },
            p: { a: 0, k: [1373.468, 36.532, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-137.412, -86.397, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 0,
                  s: [0, 100, 100]
                },
                { t: 11, s: [100, 100, 100] }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [765, 118], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 60, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [0.243137000589, 0.278430983599, 0.60784295774, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.183007004682, 0.265897982728, 0.686274988511, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-519.912, -86.397], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false
            }
          ],
          ip: 0,
          op: 50,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'Shape Layer 4',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: -45, ix: 10 },
            p: { a: 0, k: [747.818, 496.182, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-812.912, -86.397, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 0,
                  s: [0, 100, 100]
                },
                { t: 9, s: [100, 100, 100] }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [586, 118], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 60, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [0.243137000589, 0.278430983599, 0.60784295774, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.109557858635, 0.180001905853, 0.537254901961, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-519.912, -86.397], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false
            }
          ],
          ip: 0,
          op: 50,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: 'Shape Layer 3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: -45, ix: 10 },
            p: { a: 0, k: [966.182, 111.818, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-226.912, -86.397, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 0,
                  s: [0, 100, 100]
                },
                { t: 12, s: [100, 100, 100] }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [586, 118], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 60, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [0.243137000589, 0.278430983599, 0.60784295774, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.183007004682, 0.265897982728, 0.686274988511, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-519.912, -86.397], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false
            }
          ],
          ip: 0,
          op: 50,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: 'Shape Layer 2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: -45, ix: 10 },
            p: { a: 0, k: [466.566, 445.434, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-777.912, -86.397, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 0,
                  s: [0, 100, 100]
                },
                { t: 7, s: [100, 100, 100] }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [516, 118], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 60, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [0.243137000589, 0.278430983599, 0.60784295774, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.109803929048, 0.180392156863, 0.537254901961, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-519.912, -86.397], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false
            }
          ],
          ip: 0,
          op: 50,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: 'Shape Layer 1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: -45, ix: 10 },
            p: { a: 0, k: [678.664, 67.336, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-261.586, -87.811, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 0,
                  s: [0, 100, 100]
                },
                { t: 8, s: [100, 100, 100] }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [412, 118], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 60, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [0.243137000589, 0.278430983599, 0.60784295774, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.083921567131, 0.139355992336, 0.419607843137, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-467.586, -87.811], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Rectangle 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false
            }
          ],
          ip: 0,
          op: 50,
          st: 0,
          bm: 0
        }
      ]
    }
  ],
  fonts: {
    list: [
      {
        origin: 0,
        fPath: '',
        fClass: '',
        fFamily: 'Montserrat',
        fWeight: '',
        fStyle: 'ExtraBold',
        fName: 'Montserrat-ExtraBold',
        ascent: 69.5092065427452
      }
    ]
  },
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 5,
      nm: 'text',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 8, s: [0] },
            { t: 20, s: [100] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 0,
          k: [940, 239.25, 0],
          ix: 2,
          l: 2,
          x: 'var $bm_rt;\nvar H, W;\nH = thisComp.height;\nW = thisComp.width;\n$bm_rt = [\n    W / 2,\n    H / 2\n];'
        },
        a: {
          a: 0,
          k: [2.791, -54.25, 0],
          ix: 1,
          l: 2,
          x: 'var $bm_rt;\nvar sourceSize, T, L, W, H;\nsourceSize = thisLayer.sourceRectAtTime(time, false);\nT = sourceSize.top;\nL = sourceSize.left;\nW = sourceSize.width;\nH = sourceSize.height;\n$bm_rt = [\n    L + W / 2,\n    T + H / 2\n];'
        },
        s: { a: 0, k: [110, 110, 100], ix: 6, l: 2 }
      },
      ao: 0,
      t: {
        d: {
          k: [
            {
              s: {
                sz: [1430.81811523438, 558.636413574219],
                ps: [-674.5, -236.826522827148],
                s: 50,
                f: 'Montserrat-ExtraBold',
                t: 'HELLO',
                ca: 1,
                j: 2,
                tr: 0,
                lh: 60.0000038146973,
                ls: 0,
                fc: [1, 1, 1]
              },
              t: 0
            }
          ]
        },
        p: {},
        m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
        a: []
      },
      ip: 0,
      op: 50,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 0,
      nm: 'Pre-comp 1',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [960, 300, 0], ix: 2, l: 2 },
        a: { a: 0, k: [960, 300, 0], ix: 1, l: 2 },
        s: {
          a: 0,
          k: [40, 40, 100],
          ix: 6,
          l: 2,
          x: "var $bm_rt;\nvar text_width, text_height, scaleX, scaleY;\ntext_width = thisComp.layer('text').sourceRectAtTime().width;\ntext_height = thisComp.layer('text').sourceRectAtTime().height;\nscaleX = $bm_div(text_width, 8) > 40 ? $bm_div(text_width, 8) : 40;\nscaleY = $bm_div(text_height, 2) > 40 ? $bm_div(text_height, 2) : 40;\n$bm_rt = [\n    scaleX,\n    scaleY\n];"
        }
      },
      ao: 0,
      w: 1920,
      h: 600,
      ip: 0,
      op: 50,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};

export const textLayer = 0;
