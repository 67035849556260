import * as amplitude from '@amplitude/analytics-browser';
import { getAnalytics } from '@firebase/analytics';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from 'firebaseConfig';

export const analytics = () => {
  const app = initializeApp(firebaseConfig);
  getAnalytics(app);
  amplitude.init('5a2330297a1c9744f64d51bcf3bb9930');
};
