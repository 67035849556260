export const defaultJson = {
  v: '5.9.2',
  fr: 25,
  ip: 0,
  op: 50,
  w: 1920,
  h: 1080,
  nm: '2-Scale',
  ddd: 0,
  assets: [],
  fonts: {
    list: [
      {
        origin: 0,
        fPath: '',
        fClass: '',
        fFamily: 'Montserrat',
        fWeight: '',
        fStyle: 'ExtraBold',
        fName: 'Montserrat-ExtraBold',
        ascent: 70.9991455078125
      }
    ]
  },
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 5,
      nm: 'Hello',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [960, 299.25, 0], ix: 2, l: 2 },
        a: { a: 0, k: [2.791, -54.25, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.758, 0.758, 0.333], y: [0.001, 0.001, 0] },
              t: 0,
              s: [0, 0, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 25,
              s: [110, 110, 100]
            },
            { t: 30, s: [100, 100, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      t: {
        d: {
          k: [
            {
              s: {
                sz: [1349, 175],
                ps: [-674.5, -115.008323669434],
                s: 155,
                f: 'Montserrat-ExtraBold',
                t: 'Hello',
                ca: 1,
                j: 2,
                tr: 0,
                lh: 186,
                ls: 0,
                fc: [0, 0, 0]
              },
              t: 0
            }
          ]
        },
        p: {},
        m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
        a: []
      },
      ip: 0,
      op: 50,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};

export const textLayer = 0;
