import { createTypes } from 'reduxsauce';
import axiosClient2 from 'utils/axiosClient2';
import axiosClient from 'utils/axiosClient';
import { ENDPOINTS } from './config';
import { updateSceneFEOnly } from 'containers/EditVideo/actions';

export const CreateVideoTypes = createTypes(`
  GET_QUIZ_REQUEST
  GET_QUIZ_SUCCESS
  GET_QUIZ_FAILURE
`);

export const getQuizSuccess = (listQuiz) => ({
  type: CreateVideoTypes.GET_QUIZ_SUCCESS,
  listQuiz
});

export const getQuizFailure = (error) => ({
  type: CreateVideoTypes.GET_QUIZ_FAILURE,
  error
});

export const getQuizRequest = (params) => async (dispatch, getState) => {
  try {
    dispatch({ type: CreateVideoTypes.GET_QUIZ_REQUEST });
    const response = await axiosClient2.get(ENDPOINTS.getQuiz, { params: params });
    const resListQuiz = response.data?.result || [];
    dispatch(getQuizSuccess(resListQuiz));
    return response;
  } catch (e) {
    dispatch(getQuizFailure(e.listVideo));
    return e;
  }
};

export const getQuizDetail = (quizDetail) => async () => {
  try {
    const response = await axiosClient2.get(ENDPOINTS.getQuizDetail(quizDetail));
    return response.data?.result;
  } catch (e) {
    return e;
  }
};

export const updateNewQuiz = (quizId, data) => async () => {
  try {
    const response = await axiosClient2.put(ENDPOINTS.updateQuiz(quizId), data);
    return response.data?.result;
  } catch (e) {
    return e;
  }
};

export const addNewQuestion = (params) => async () => {
  try {
    const response = await axiosClient2.post(ENDPOINTS.addQuestion, params);
    return response.data.result;
  } catch (e) {
    return e;
  }
};

export const updateNewQuestion = (questionId, data) => async () => {
  try {
    const response = await axiosClient2.put(ENDPOINTS.updateQuestion(questionId), data);
    return response.data.result;
  } catch (e) {
    return e;
  }
};

export const deleteQuestion = (questionId) => async () => {
  try {
    const response = await axiosClient2.delete(ENDPOINTS.deleteQuestion(questionId));
    return response.data.result;
  } catch (e) {
    return e;
  }
};

export const addNewFolder = (data) => async (dispatch) => {
  try {
    const response = await axiosClient2.post(ENDPOINTS.addNewFolder(), data);
    return response.data.result;
  } catch (e) {
    return e;
  }
};

export const uploadFile = (params) => async (dispatch) => {
  const bodyFormData = new FormData();
  bodyFormData.append('files[]', params.file);
  if (params.folderId) {
    bodyFormData.append('folder_id', params.folderId);
  }

  try {
    const response = await axiosClient2.post(ENDPOINTS.uploadFile(), bodyFormData);
    return response.data.result;
  } catch (e) {
    return e;
  }
};

export const getFolders = (params) => async (dispatch) => {
  try {
    const response = await axiosClient2.post(ENDPOINTS.getFolders(), params);
    return response.data.result;
  } catch (e) {
    return e;
  }
};

export const updateScene = (VideoID, sceneID, data) => async (dispatch) => {
  try {
    const response = await axiosClient.patch(ENDPOINTS.updateScene(VideoID, sceneID), data);
    return response.data.result;
  } catch (e) {
    return e;
  }
};

export const createQuiz = (data) => async (dispatch) => {
  try {
    const response = await axiosClient2.post(ENDPOINTS.createQuiz, data);
    return response.data.result;
  } catch (e) {
    return e;
  }
};

export const deleteQuiz = (quizId) => async (dispatch) => {
  try {
    const response = await axiosClient2.delete(ENDPOINTS.deleteQuiz(quizId));
    return response.data.result;
  } catch (e) {
    return e;
  }
};

export const updateThumbnailAvatar = (VideoID, sceneID, file) => async (dispatch) => {
  const bodyFormData = new FormData();
  bodyFormData.append('file', file);

  try {
    const response = await axiosClient.post(ENDPOINTS.updateMedia(VideoID, sceneID), bodyFormData);
    dispatch(updateSceneFEOnly(sceneID, { ...response.scene, loadingUploadScene: false }));

    return response.data.result;
  } catch (e) {
    return e;
  }
};
