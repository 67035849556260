export const defaultJson = {
  v: '5.7.4',
  fr: 30,
  ip: 0,
  op: 94,
  w: 1920,
  h: 1080,
  nm: 'howdy-parented',
  ddd: 0,
  assets: [],
  fonts: {
    list: [
      {
        origin: 1,
        fPath: '',
        fClass: '',
        fFamily: '',
        fWeight: '',
        fStyle: '',
        fName: 'Roboto-BoldItalic',
        ascent: 78.9948770217597
      }
    ]
  },
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 3,
      nm: 'scaler',
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 0,
          ix: 11
        },
        r: {
          a: 0,
          k: 0,
          ix: 10
        },
        p: {
          a: 0,
          k: [960, 540, 0],
          ix: 2,
          l: 2
        },
        a: {
          a: 0,
          k: [0, 0, 0],
          ix: 1,
          l: 2
        },
        s: {
          a: 1,
          k: [
            {
              i: {
                x: [0.667, 0.667, 0.667],
                y: [1, 1, 1]
              },
              o: {
                x: [0.813, 0.813, 0.333],
                y: [0, 0, 0]
              },
              t: 0,
              s: [55, 55, 100]
            },
            {
              i: {
                x: [0.667, 0.667, 0.667],
                y: [1, 1, 1]
              },
              o: {
                x: [0.792, 0.792, 0.333],
                y: [0, 0, 0]
              },
              t: 63,
              s: [95, 95, 100]
            },
            {
              t: 93,
              s: [55, 55, 100]
            }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      ip: 0,
      op: 18000,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 5,
      nm: 'parented',
      parent: 1,
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11
        },
        r: {
          a: 0,
          k: 0,
          ix: 10
        },
        p: {
          a: 0,
          k: [-10.033, 3.153, 0],
          ix: 2,
          l: 2
        },
        a: {
          a: 0,
          k: [149.727, -22.636, 0],
          ix: 1,
          l: 2
        },
        s: {
          a: 0,
          k: [133.333, 133.333, 100],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      t: {
        d: {
          k: [
            {
              s: {
                s: 72,
                t: 'Welcome to the world of the future!',
                j: 0,
                tr: 0,
                lh: 86.4,
                ls: 0,
                fc: [0, 0, 0.059]
              },
              t: 0
            }
          ]
        },
        p: {},
        m: {
          g: 1,
          a: {
            a: 0,
            k: [0, 0],
            ix: 2
          }
        },
        a: []
      },
      ip: 0,
      op: 18000,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};

export const textLayer = 1;
